
import * as React from 'react';
import { useLocation } from 'react-router-dom';


import axios, { AxiosError } from "axios";

import './SpeedConsumption.css'; // スタイルのために追加
import { SelectTheme } from '../../components/theme/SelectTheme';
import { Main, Drawer, BootstrapInput } from '../../components/styled/styled';
import { callAuth } from '../../components/components/auth';
import { SearchContext } from '../../App';
import { MyAppBar } from '../../components/components/AppBar';
import { SelectDate } from '../../components/components/select-date';
import { FilterComponent } from './FilterComponent';
import { CommonIconPlus } from "../../components/components/CommonIconPlus";
import { SelectVesseName } from '../../components/components/SelectVesseNameComponent';
import CourseIcon from '../../assets/images/cog.png';
import OilIcon_svg from '../../assets/images/oil.svg';
import EngineIcon_svg from '../../assets/images/engine.svg';
import ShipIcon_svg from '../../assets/images/ship.png';
import DateIcon_svg from '../../assets/images/date.svg';


// MUI @mui/material/
import { ThemeProvider } from '@mui/material/styles'
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import CssBaseline from '@mui/material/CssBaseline';
import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';
import CircularProgress from '@mui/material/CircularProgress';
import InputAdornment from '@mui/material/InputAdornment';
import Input from '@mui/material/Input';
import Switch from '@mui/material/Switch';
import Radio from '@mui/material/Radio';

// MUI @mui/icons-material/
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import SearchIcon from '@mui/icons-material/Search';
import AirIcon from '@mui/icons-material/Air';
import TsunamiIcon from '@mui/icons-material/Tsunami';
import WavesIcon from '@mui/icons-material/Waves';
import SettingsIcon from '@mui/icons-material/Settings';


// Dialog
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

// Date
import { getDate, getMonth, getYear, getHours, getMinutes } from 'date-fns'
// import _ from 'lodash'

// Select Date
import dayjs from "dayjs";

// Highcharts
// import * as Highcharts from 'highcharts';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
// import HighchartsMore from 'highcharts/highcharts-more'; // オプションのHighchartsモジュール
// import HighchartsExporting from 'highcharts/modules/exporting'; // オプションのHighchartsモジュール

import downloadIcon from '../../assets/images/engine-download-smallwhite.png';
import downloadWhite from '../../assets/images/download-white.png';

// Axios Error
interface ErrorData {
  message: string;
  status: number;
  error: string;
}

const AntSwitch = (props) => {
  return (
    <>
      <Box sx={{}}>
        <Switch
          inputProps={{ 'aria-label': 'ant design' }}
          onChange={props.onChange}
          checked={props.checked}
          name={props.name}
          sx={{
            '& .MuiSwitch-thumb': {
              marginLeft: "4px",
              marginTop: "5px",
              width: "12px",
              height: "12px",
              borderRadius: 2,
            },
            '& .MuiSwitch-track': {
              borderRadius: 16 / 2,
              opacity: 1,
              width: "28px",
              height: "16px",
              backgroundColor: '#333333',
            },
            '& .MuiSwitch-switchBase': {
              transition: 'transform 0.2s',
              padding: 1.2,
              color: "rgb(105, 105, 105)",
              '&.Mui-checked': {
                transform: 'translateX(12px)',
                color: props.ToggleColor,
                '& + .MuiSwitch-track': {
                  border: '1px solid #f1f1f1',
                  opacity: 1,
                  backgroundColor: '#333333',
                },
              },
            },
          }} />
      </Box>
    </>
  )
}

function formatNumber(num) {
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
}

export default function SpeedConsumption({ setSeachInfo }) {

  // userId有効期限チェック----------------------------------------------------------------------------------------------------------------------------
  const [message, setMessage] = React.useState<string>();
  const [userCheck, setUserCheck] = React.useState(false);
  let init = true

  const [user_id, setUser_id] = React.useState<string>();
  const [group_id, setGroup_id] = React.useState<string>();
  const [company_id, setCompany_id] = React.useState<string>();
  const [gid, setGid] = React.useState<string>();
  const [gidList, setGidList] = React.useState<string>();
  const [imoList, setImoList] = React.useState<string>();
  const [jwt, setJwt] = React.useState<string>();
  const [role, setRole] = React.useState<string>();

    // SelectVesselNameコンポーネント用
    const [vesselOpen, setVesselOpen] = React.useState<boolean>(false);
    const [currentFocusVessel, setCurrentFocusVessel] = React.useState<string>(''); // 矢印キー(↑↓)でドロップダウン船名変更時のフォーカスしている船
    const [vesselName, setVesselName] = React.useState<string>(''); // 表示船名
  
  // Navigation取得----------------------------------------------------------------------------------------------------------------------------
  const location = useLocation();

  React.useEffect(() => {
    if (init === true) {

      // localStrage取得----------------------------------------------------------------------------------------------------------------------------
      let jwt_ls
      if (localStorage.getItem("payload")) {
        const pay_json = localStorage.getItem("payload")
        if (pay_json !== null) {
          const pay = JSON.parse(pay_json)
          jwt_ls = pay["jwt"]
          setUser_id(pay["user_id"])
          setGroup_id(pay["group_id"])
          setCompany_id(pay["company_id"])
          setGid(pay["gid"])
          setGidList(pay["gidList"])
          setImoList(pay["imoList"])
          setJwt(jwt_ls)
          setRole(pay["role"])
          setSeachInfo({ imoNo: `${SeachInfo.imoNo}`, from: SeachInfo.from, to: SeachInfo.to, gid: pay["gid"], gidList: pay["gidList"], imoList: pay["imoList"] })
        
          // 選択中の船のimo設定
          setCurrentFocusVessel(SeachInfo.imoNo)

          // 選択中の船名がある場合、船名取得し初期表示として設定
          for (const imoData of pay["imoList"]) {
            if (imoData.imoNo == SeachInfo.imoNo) {
              setVesselName(imoData.VesselName)
            }
          }
        }
      }

      if (jwt_ls) {
        callAuth(jwt_ls).then(result => {
          if (result) {
            console.log("認証成功[SpeedConsumption]");

            // Navigation取得----------------------------------------------------------------------------------------------------------------------------
            if (location.state !== null) {
              let location_beaufort = location.state['beaufort']
              let location_BallastLaden = location.state['BallastLaden']
              let location_fromLogSpeed = location.state['fromLogSpeed']
              let location_toLogSpeed = location.state['toLogSpeed']

              if (location_beaufort !== "" && location_BallastLaden !== "") {
                checkboxesBallastLaden[0].checked = true
                const BallastLadencheck_set = JSON.parse(JSON.stringify(checkboxesBallastLaden))
                setCheckboxesBallastLaden([...BallastLadencheck_set])
                setBallastLaden(location_BallastLaden)

                const location_beaufort_in = [
                  { name: "Beaufort0", data: "0", color: "grey", checked: true },
                  { name: "Beaufort1", data: "1", color: "grey", checked: true },
                  { name: "Beaufort2", data: "2", color: "grey", checked: true },
                  { name: "Beaufort3", data: "3", color: "grey", checked: true },
                  { name: "Beaufort4", data: "4", color: "grey", checked: true },
                  { name: "Beaufort5", data: "5", color: "grey", checked: true },
                  { name: "Beaufort6", data: "6", color: "grey", checked: true },
                  { name: "Beaufort7", data: "7", color: "grey", checked: true },
                  { name: "Beaufort8", data: "8", color: "grey", checked: true },
                  { name: "Beaufort9", data: "9", color: "grey", checked: true },
                  { name: "Beaufort10", data: "10", color: "grey", checked: true },
                  { name: "Beaufort11", data: "11", color: "grey", checked: true },
                  { name: "Beaufort12", data: "12", color: "grey", checked: true },
                ]

                setBeaufortcheckAllShow(true);
                setBeaufortShow("-0-1-2-3-4-5-6-7-8-9-10-11-12")
                setBeaufortcheckShow(location_beaufort_in);

                setBeaufortcheckAll(true);
                setBeaufort("-0-1-2-3-4-5-6-7-8-9-10-11-12")
                setBeaufortcheck(location_beaufort_in)

                let str: string = ""
                for (var i = 0; i <= 12; i++) {
                  str = str + "-" + Beaufortcheck[i].data
                }
                setBeaufort(str)
                // VesselListから遷移した時は、スピード範囲は「5~23」とする。
                setFromLogSpeed(location_fromLogSpeed)
                setToLogSpeed(location_toLogSpeed)
                setParameters()
                getNoonReport(`${base_url}${SeachInfo.imoNo}?Timestamp_from=${SeachInfo.from}&Timestamp_to=${SeachInfo.to}&Beaufort=${Beaufort}&fromDisp=${fromDisp}&toDisp=${toDisp}&BallastLaden=${BallastLaden}&fromLogSpeed=${location_fromLogSpeed}&toLogSpeed=${location_toLogSpeed}&fromEngineLoad=${fromEngineLoad}&toEngineLoad=${toEngineLoad}&fromLogSpeedAF=${paraFromLogSpeedAF}&toLogSpeedAF=${paraToLogSpeedAF}&fromFOCAF=${paraFromFOCAF}&toFOCAF=${paraToFOCAF}`, jwt_ls);
              }
            } else {
              // Loading解除
              setSuccess(true);
              setLoading(false);
            }
            setMessage("Authentication success.")
          } else {
            setUserCheck(true)
            console.log("認証失敗[SpeedConsumption]");
            setMessage("Authentication error.")
            localStorage.clear()
          }
        });
      }
    }

    init = false
  }, []);


  // WADATSUMIログイン画面遷移----------------------------------------------------------------------------------------------------------------------------
  const handleClickWADATSUMI = () => {
    window.location.replace(`${process.env.REACT_APP_WADATSUMIURL}`)
  };

  // コンテクスト取得----------------------------------------------------------------------------------------------------------------------------
  const SeachInfo: any = React.useContext(SearchContext);


  // 検索フォーム----------------------------------------------------------------------------------------------------------------------------
  const [base_url, setBase_url] = React.useState(`${process.env.REACT_APP_API_URL}/SpeedConsumption/`);

  // Select Imo----------------------------------------------------------------------------------------------------------------------------
  const selectImo = (imoNo: string) => {
    // setImo(event.target.value as string);
    setSeachInfo({ imoNo: imoNo, from: SeachInfo.from, to: SeachInfo.to, gid: gid, gidList: gidList, imoList: imoList })
  };


  // Select Beaufort----------------------------------------------------------------------------------------------------------------------------
  const [openDialogBeaufort, setOpenDialogBeaufort] = React.useState(false);
  const handleClickOpenDialogBeaufort = () => {
    setOpenDialogBeaufort(true);
  };
  // Saveボタン
  const handleCloseDialogBeaufort = () => {
    const BeaufortAll_show = JSON.parse(JSON.stringify(BeaufortcheckAll))
    const Beaufort_show = JSON.parse(JSON.stringify(Beaufort))
    const Beaufortcheck_show = JSON.parse(JSON.stringify(Beaufortcheck))
    // 表示を更新
    setBeaufortcheckAllShow(BeaufortAll_show);
    setBeaufortShow(Beaufort_show)
    setBeaufortcheckShow([...Beaufortcheck_show]);
    setOpenDialogBeaufort(false);
  };
  // Cancelボタン
  const handleNoCloseDialogBeaufort = () => {
    const Beaufortcheck_set = JSON.parse(JSON.stringify(BeaufortcheckShow))
    const BeaufortAll_set = JSON.parse(JSON.stringify(BeaufortcheckAllShow))
    const Beaufort_set = JSON.parse(JSON.stringify(BeaufortShow))
    // 変更前の状態に戻す
    setBeaufortcheckAll(BeaufortAll_set)
    setBeaufort(Beaufort_set)
    setBeaufortcheck([...Beaufortcheck_set]);
    setOpenDialogBeaufort(false);
  };

  const first_beaufort = [
    { name: "Beaufort0", data: "0", color: "grey", checked: true },
    { name: "Beaufort1", data: "1", color: "grey", checked: true },
    { name: "Beaufort2", data: "2", color: "grey", checked: true },
    { name: "Beaufort3", data: "3", color: "grey", checked: true },
    { name: "Beaufort4", data: "4", color: "grey", checked: true },
    { name: "Beaufort5", data: "5", color: "grey", checked: true },
    { name: "Beaufort6", data: "6", color: "grey", checked: true },
    { name: "Beaufort7", data: "7", color: "grey", checked: true },
    { name: "Beaufort8", data: "8", color: "grey", checked: true },
    { name: "Beaufort9", data: "9", color: "grey", checked: true },
    { name: "Beaufort10", data: "10", color: "grey", checked: true },
    { name: "Beaufort11", data: "11", color: "grey", checked: true },
    { name: "Beaufort12", data: "12", color: "grey", checked: true },
  ]

  // 表示用の状態管理
  const [BeaufortcheckAllShow, setBeaufortcheckAllShow] = React.useState<boolean>(true)
  const [BeaufortShow, setBeaufortShow] = React.useState<string>("0-1-2-3-4-5-6-7-8-9-10-11-12");
  const [BeaufortcheckShow, setBeaufortcheckShow] = React.useState<Array<{ name: string, data: string, color: string, checked: (boolean) }>>(first_beaufort);

  // 更新中の状態を保持
  const [BeaufortcheckAll, setBeaufortcheckAll] = React.useState<boolean>(true)
  const [Beaufort, setBeaufort] = React.useState<string>("0-1-2-3-4-5-6-7-8-9-10-11-12");
  const [Beaufortcheck, setBeaufortcheck] = React.useState<Array<{ name: string, data: string, color: string, checked: (boolean) }>>(first_beaufort);
  const handleChangeBeaufort = (event) => {
    const Beaufortcheck_show = JSON.parse(JSON.stringify(BeaufortcheckShow))
    setBeaufortcheck([...Beaufortcheck_show]);
    let str: string = ""
    let count = 0
    for (var i = 0; i <= 12; i++) {
      if (event.target.name === Beaufortcheck[i].name) {
        Beaufortcheck[i].checked = !Beaufortcheck[i].checked
      }
      if (Beaufortcheck[i].checked === true) {
        str = str + "-" + Beaufortcheck[i].data
        count += 1
      }
    }

    const Beaufort_set = JSON.parse(JSON.stringify(str))
    const Beaufortcheck_set = JSON.parse(JSON.stringify(Beaufortcheck))
    setBeaufort(Beaufort_set)
    setBeaufortcheck([...Beaufortcheck_set])

    console.log("count", count)
    if (count < 13) {
      setBeaufortcheckAll(false)
    } else if (count === 13) {
      setBeaufortcheckAll(true)
    }

  };

  const handleChangeBeaufortAll = (event) => {
    const Beaufortcheck_show = JSON.parse(JSON.stringify(BeaufortcheckShow))
    setBeaufortcheck([...Beaufortcheck_show]);
    let str: string = ""
    for (var i = 0; i <= 12; i++) {
      if (BeaufortcheckAll === true) {
        Beaufortcheck[i].checked = false
      } else { Beaufortcheck[i].checked = true }

      if (Beaufortcheck[i].checked === true) {
        str = str + "-" + Beaufortcheck[i].data
      }
    }

    if (BeaufortcheckAll === true) {
      setBeaufortcheckAll(false)
    } else { setBeaufortcheckAll(true) }

    const Beaufort_set = JSON.parse(JSON.stringify(str))
    const Beaufortcheck_set = JSON.parse(JSON.stringify(Beaufortcheck))
    setBeaufort(Beaufort_set)
    setBeaufortcheck([...Beaufortcheck_set])
  };

  // Text Ballast/Laden----------------------------------------------------------------------------------------------------------------------------
  const [BallastLaden, setBallastLaden] = React.useState("All")
  const [checkboxesBallastLaden, setCheckboxesBallastLaden] = React.useState([
    { name: "All", nameLeft: "50px", checked: true, checkedLeft: "10px" },
    { name: "Laden", nameLeft: "110px", checked: false, checkedLeft: "70px" },
    { name: "Ballast", nameLeft: "190px", checked: false, checkedLeft: "150px" },
  ]);
  const handleCheckboxChangeBallastLaden = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedValue(event.target.value);

    for (var i = 0; i <= checkboxesBallastLaden.length - 1; i++) {
      if (event.target.value === checkboxesBallastLaden[i].name) {
        checkboxesBallastLaden[i].checked = true
        setBallastLaden(checkboxesBallastLaden[i].name)
      }
      else {
        checkboxesBallastLaden[i].checked = false
      }
    }
    const BallastLadencheck_set = JSON.parse(JSON.stringify(checkboxesBallastLaden))
    setCheckboxesBallastLaden([...BallastLadencheck_set])
  };
  const [selectedValue, setSelectedValue] = React.useState('a');
  const controlProps = (item: string) => ({
    checked: selectedValue === item,
    onChange: handleChange,
    value: item,
    name: 'color-radio-button-demo',
    inputProps: { 'aria-label': item },
  });
  // Text Displacement----------------------------------------------------------------------------------------------------------------------------
  const [fromDisp, setFromDisp] = React.useState<string>("");
  const [toDisp, setToDisp] = React.useState<string>("");
  const UpperLimitDisp = (event) => {
    if (/^\d*$/.test(event)) { setToDisp(event); }
    else { alert("Please enter numbers in half-width characters.") }
  };
  const LowerLimitDisp = (event) => {
    if (/^\d*$/.test(event)) { setFromDisp(event); }
    else { alert("Please enter numbers in half-width characters.") }
  };
  // Text Log Speed----------------------------------------------------------------------------------------------------------------------------
  const [fromLogSpeed, setFromLogSpeed] = React.useState<string>("");
  const [toLogSpeed, setToLogSpeed] = React.useState<string>("");
  const UpperLimitLogSpeed = (event) => {
    if (/^\d*$/.test(event)) { setToLogSpeed(event); }
    else { alert("Please enter numbers in half-width characters.") }
  };
  const LowerLimitLogSpeed = (event) => {
    if (/^\d*$/.test(event)) { setFromLogSpeed(event); }
    else { alert("Please enter numbers in half-width characters.") }
  };
  // Text Engine Load----------------------------------------------------------------------------------------------------------------------------
  const [fromEngineLoad, setFromEngineLoad] = React.useState<string>("");
  const [toEngineLoad, setToEngineLoad] = React.useState<string>("");
  const UpperLimitEngineLoad = (event) => {
    if (/^\d*$/.test(event)) { setToEngineLoad(event); }
    else { alert("Please enter numbers in half-width characters.") }
  };
  const LowerLimitEngineLoad = (event) => {
    if (/^\d*$/.test(event)) { setFromEngineLoad(event); }
    else { alert("Please enter numbers in half-width characters.") }
  };

  // Drawer----------------------------------------------------------------------------------------------------------------------------
  const [openDrwer, setOpenDrwer] = React.useState(true);
  const handleDrawerOpen = () => {
    setOpenDrwer(true);
  };
  const handleDrawerClose = () => {
    setOpenDrwer(false);
  };


  // DataRnage----------------------------------------------------------------------------------------------------------------------------
  const [openDialog, setOpenDialog] = React.useState(false);
  const handleClickOpenDialog = () => {
    setOpenDialog(true);
  };


  // plot_information
  const plot = {
    utc_date: "",
    lat: "",
    lng: "",
    foc: "",
    co2_factor: "",
    co2: "",
    me_foc: "",
    me_load: "",
    log_speed: "",
    me_rpm: "",
    distance: "",
    displacement: "",
    wind_direction: "",
    wind_directionColor: "transparent",
    wind_directionCalcDeg: "",
    wind_directionCalcLeft: "",
    wind_directionCalcTop: "",
    wind_speed: "",
    wave_direction: "",
    wave_directionColor: "transparent",
    wave_directionCalcDeg: "",
    wave_directionCalcLeft: "",
    wave_directionCalcTop: "",
    wave_height: "",
    wave_period: "",
    swell_direction: "",
    swell_directionColor: "transparent",
    swell_directionCalcDeg: "",
    swell_directionCalcLeft: "",
    swell_directionCalcTop: "",
    swell_height: "",
    swell_period: "",
    course: "",
    beaufort: "",
    index: "",
  }
  const [plotInformation, setPlotInformation] = React.useState<any | null>(plot);

  // Legend CheckBox
  const handleChange = (event) => {
    const newHcOptions = { ...hcOptions };
    if (SpeedConsumptionCurve !== undefined) {
      for (var i = 0; i <= SpeedConsumptionCurve.length - 1; i++) {
        if (event.target.name === SpeedConsumptionCurve[i].name) {
          SpeedConsumptionCurve[i].checked = !SpeedConsumptionCurve[i].checked;
          newHcOptions.series[i + 2].visible = !newHcOptions.series[i + 2].visible;
        }
      }
    }
    if (SpeedConsumptionCurveTrendLine !== undefined) {
      if (event.target.name === "TrendLine") {
        SpeedConsumptionCurveTrendLine.checked = !SpeedConsumptionCurveTrendLine.checked;
        newHcOptions.series[1].visible = !newHcOptions.series[1].visible;
      }
    }
    setHcOptions(newHcOptions);
  };

  // Graph----------------------------------------------------------------------------------------------------------------------------
  // DataSet
  const [VesselMaster, setVesselMaster] = React.useState<any>();
  // const [SpeedConsumptionFormula, setSpeedConsumptionFormula] = React.useState<any>();
  const [SpeedConsumption, setSpeedConsumption] = React.useState<Array<Array<any>>>();
  const [SpeedConsumptionCurve, setSpeedConsumptionCurve] = React.useState<Array<{ name: String, data: Array<Array<any>>, color: String, checked: (boolean), formula: any, description: String }>>();
  const [SpeedConsumptionCurveTrendLine, setSpeedConsumptionCurveTrendLine] = React.useState<{ name: String, data: Array<Array<any>>, color: String, checked: (boolean), formula: any }>();
  const [AllTimeSereis, setAllTimeSereis] = React.useState<any>([]);
  // Axis
  const [xAxisMin, setxAxisMin] = React.useState<any>(0);
  const [xAxisMax, setxAxisMax] = React.useState<any>(25);
  const [xAxisMinShow, setxAxisMinShow] = React.useState<any>(0);
  const [xAxisMaxShow, setxAxisMaxShow] = React.useState<any>(25);
  const [yAxisMin, setyAxisMin] = React.useState<any>(0);
  const [yAxisMax, setyAxisMax] = React.useState<any>(100);
  const [yAxisMinShow, setyAxisMinShow] = React.useState<any>(0);
  const [yAxisMaxShow, setyAxisMaxShow] = React.useState<any>(100);
  // const [tickInterval, settickInterval] = React.useState<any>();
  const [openDialogyAxis, setOpenDialogAxis] = React.useState(false);
  const handleClickOpenDialogAxis = () => {
    setOpenDialogAxis(true);
  };
  const handleNoClickOpenDialogAxis = () => {
    setxAxisMinShow(xAxisMin)
    setxAxisMaxShow(xAxisMax)
    setyAxisMinShow(yAxisMin)
    setyAxisMaxShow(yAxisMax)
    setOpenDialogAxis(false);
  };
  const handleClickSetAxis = () => {
    console.log("yAxisMin", yAxisMin)
    if (yAxisMin >= yAxisMax) {
      alert("Please enter a value greater than Min for Max.")
    }
    else if (yAxisMin === "" || yAxisMax === "" || yAxisMin === undefined || yAxisMax === undefined) {
      alert("No spaces allowed. Please enter a value.")
    }
    else {
      setxAxisMin(xAxisMinShow)
      setxAxisMax(xAxisMaxShow)
      setyAxisMin(yAxisMinShow)
      setyAxisMax(yAxisMaxShow)
      setOpenDialogAxis(false);
      setKey(key + 1);
    }
  };
  const handleClickxAxisMinShow = (event) => {
    if (/^\d*$/.test(event)) { setxAxisMinShow(event); }
    else { alert("Please enter numbers in half-width characters.") }
  }
  const handleClickxAxisMaxShow = (event) => {
    if (/^\d*$/.test(event)) { setxAxisMaxShow(event); }
    else { alert("Please enter numbers in half-width characters.") }
  }
  const handleClickyAxisMinShow = (event) => {
    if (/^\d*$/.test(event)) { setyAxisMinShow(event); }
    else { alert("Please enter numbers in half-width characters.") }
  }
  const handleClickyAxisMaxShow = (event) => {
    if (/^\d*$/.test(event)) { setyAxisMaxShow(event); }
    else { alert("Please enter numbers in half-width characters.") }
  }
  React.useEffect(() => {
    const newHcOptions = { ...hcOptions };
    newHcOptions.xAxis.min = parseFloat(xAxisMin)
    newHcOptions.xAxis.max = parseFloat(xAxisMax)
    newHcOptions.yAxis.min = parseFloat(yAxisMin)
    newHcOptions.yAxis.max = parseFloat(yAxisMax)
    setHcOptions(newHcOptions)
  }, [yAxisMin, yAxisMax, xAxisMin, xAxisMax])

  // Options
  const firstOptions: Highcharts.Options = {
    navigation: {
      buttonOptions: {
        enabled: false,
      }
    },
    credits: {
      enabled: false
    },
    chart: {
      zooming: { type: "xy" },
      // width: window.innerWidth-1041,
      height: window.innerHeight - 163,
      alignTicks: false,
      backgroundColor: 'rgba(0, 0, 0, 0)', // 背景色を透明にする
      // plotBackgroundColor: {
      //   linearGradient: { x1: 0, x2: 0, y1: 1, y2: 0 },
      //   stops: [
      //     [0, 'rgba(255, 255, 255, 0.03)'],
      //     [0.8529, 'rgba(255, 255, 255, 0.13)'],
      //     [1, 'rgba(255, 255, 255, 0.15)']
      //   ]
      // },
    },
    rangeSelector: {
      selected: 1
    },
    title: {
      text: '',
      style: {
        fontSize: '12px',
        color: 'white'
      }
    },
    tooltip: {
      enabled: false,
      headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
      pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
        '<td style="padding:0"><b>{point.y:.1f} mm</b></td></tr>',
      footerFormat: '</table>',
      shared: true,
      useHTML: true
    },
    legend: {
      enabled: false,
    },
    plotOptions: {
      series: {
        cursor: 'pointer',
        point: {
        }
      }
    },
    xAxis: {
      min: 0,
      max: 25,
      // tickInterval: 5,
      title: {
        text: "Log Speed (knot)",
        style: {
          color: '#ffffff',
          opacity: 0.70,
          fontSize: "13px",
          fontFamily: 'Roboto Condensed',
          fontWeight: '400',
          lineHeight: "24px",
          wordWrap: 'break-word'
        }
      },
      labels: {
        enabled: true,
        style: {
          color: '#ffffff',
          opacity: 0.70,
          fontSize: "13px",
          fontFamily: 'Roboto Condensed',
          fontWeight: '400',
          lineHeight: "24px",
          wordWrap: 'break-word'
        },
        // format: '{value:.2f}', // 2桁の小数点まで表示
      }
    },
    yAxis: {
      opposite: false,
      min: 0,
      max: 100,
      // tickInterval: 50,
      gridLineDashStyle: 'Dot',
      gridLineWidth: 0.5,
      title: {
        text: 'M/E Fuel Oil Consumption (MT)',
        // align: 'center',
        rotation: -90,
        // x: 0,
        // y: 0,
        style: {
          color: '#ffffff',
          opacity: 0.70,
          fontSize: "13px",
          fontFamily: 'Roboto Condensed',
          fontWeight: '400',
          lineHeight: "24px",
          wordWrap: 'break-word'
        },
      },
      labels: {
        // x: 0,
        // y: 0,
        enabled: true,
        style: {
          color: '#ffffff',
          opacity: 0.70,
          fontSize: "13px",
          fontFamily: 'Roboto Condensed',
          fontWeight: '400',
          lineHeight: "24px",
          wordWrap: 'break-word'
        },
        formatter: function (this: Highcharts.AxisLabelsFormatterContextObject) {
          const test = this.value.valueOf().toString()
          return test;
        }
      },
    },
    series: [
      {
        type: 'line',
        data: []
      }
    ],
  }
  const chartComponentRef = React.useRef(null);
  const [hcOptions, setHcOptions] = React.useState<any>(firstOptions);

  // Loading--------------------------------------------------------------------
  const [loading, setLoading] = React.useState(true);
  const [success, setSuccess] = React.useState(false);

  // 時系列データ取得--------------------------------------------------------------------
  const [authCheck, setAuthCheck] = React.useState(false);
  const [authMessage, setAuthMessage] = React.useState("");

  const handleClickSearch = () => {
    setLoading(true);
    setSuccess(false);
    setParameters()
    getNoonReport(`${base_url}${SeachInfo.imoNo}?Timestamp_from=${SeachInfo.from}&Timestamp_to=${SeachInfo.to}&Beaufort=${Beaufort}&fromDisp=${fromDisp}&toDisp=${toDisp}&BallastLaden=${BallastLaden}&fromLogSpeed=${fromLogSpeed}&toLogSpeed=${toLogSpeed}&fromEngineLoad=${fromEngineLoad}&toEngineLoad=${toEngineLoad}&fromLogSpeedAF=${paraFromLogSpeedAF}&toLogSpeedAF=${paraToLogSpeedAF}&fromFOCAF=${paraFromFOCAF}&toFOCAF=${paraToFOCAF}`, jwt);

  };

  // Seach実行
  const getNoonReport = (url, jwt) => {
    console.log("url", url);

    const options = {
      headers: {
        'Authorization': jwt,
      }
    }
    axios.get(url, options).then((res) => {
      console.log("axios", res);

      // 認可チェック
      if (res.data === 401) {
        setAuthCheck(true)
        setAuthMessage("不正な操作が行われた可能性があります。")
      } else if (res.data === 500) {
        setAuthCheck(true)
        setAuthMessage("JWTが改ざんされている可能性があります。")
      } else {
        setAuthCheck(false)
      }


      const datas = res.data.datas.TIMESERIES;
      const SpeedConsumptionCurveList = res.data.datas.SPEEDCONSUMTION;
      const SpeedConsumptionCurveTrendLineList = res.data.datas.SPEEDCONSUMTIONTRENDLINE;
      const SpeedConsumptionList: any = [];
      // const SpeedConsumptionFormulaList: any = [];
      const DataList: any = [];
      const DownLoadList: any =[];

      for (let i = 0; i < datas.length; i++) {
        const utc_date = datas[i].utc_date;
        const state = datas[i].state;
        const foc = datas[i].foc
        const co2_factor = datas[i].co2_factor
        const co2 = datas[i].co2
        const me_foc = datas[i].me_foc
        const me_load = datas[i].me_load
        const log_speed = datas[i].log_speed
        const me_rpm = datas[i].me_rpm
        const distance = datas[i].distance
        const displacement = datas[i].displacement
        const wind_direction = datas[i].wind_direction
        const wind_speed = datas[i].wind_speed
        const wave_direction = datas[i].wave_direction
        const wave_height = datas[i].wave_height
        const wave_period = datas[i].wave_period
        const swell_direction = datas[i].swell_direction
        const swell_height = datas[i].swell_height
        const swell_period = datas[i].swell_period
        const course = datas[i].course
        const beaufort = datas[i].beaufort

        const foc_float = parseFloat(datas[i].foc)
        const co2_factor_float = parseFloat(datas[i].co2_factor)
        const co2_float = parseFloat(datas[i].co2)
        const me_foc_float = parseFloat(datas[i].me_foc)
        const me_load_float = parseFloat(datas[i].me_load)
        const log_speed_float = parseFloat(datas[i].log_speed)
        const me_rpm_float = parseFloat(datas[i].me_rpm)
        const distance_float = parseFloat(datas[i].distance)
        const displacement_float = parseFloat(datas[i].displacement)
        const wind_direction_float = parseFloat(datas[i].wind_direction)
        const wind_speed_float = parseFloat(datas[i].wind_speed)
        const wave_direction_float = parseFloat(datas[i].wave_direction)
        const wave_height_float = parseFloat(datas[i].wave_height)
        const wave_period_float = parseFloat(datas[i].wave_period)
        const swell_direction_float = parseFloat(datas[i].swell_direction)
        const swell_height_float = parseFloat(datas[i].swell_height)
        const swell_period_float = parseFloat(datas[i].swell_period)
        const course_float = parseFloat(datas[i].course)
        const beaufort_float = parseFloat(datas[i].beaufort)

        // Time Series Graph描画用---------------------------------------------
        const SpeedConsumptionData: any = {
          x: log_speed_float,
          y: me_foc_float,
          marker: {
            radius: 2,
            fillColor: "rgb(216, 255, 248,0.5)",
            states: {
              hover: {
                radius: 8,
                fillColor: 'rgb(216, 255, 248,0.5)'
              }
            }
          },
          pointIndex: i
        }
        SpeedConsumptionList.push(SpeedConsumptionData)

        // ↓ 全チャンネルセット--------------------------------------------- ↓

        // 風の方向
        let wind_directionCalcDeg = 0;
        let wind_directionCalcLeft = 0;
        let wind_directionCalcTop = 0;
        if (wind_direction_float < 22.5) {
          wind_directionCalcDeg = 0;
          wind_directionCalcLeft = 8
          wind_directionCalcTop = 60
        } else if (wind_direction_float < 67.5) {
          wind_directionCalcDeg = 45;
          wind_directionCalcLeft = -7
          wind_directionCalcTop = 50
        } else if (wind_direction_float < 112.5) {
          wind_directionCalcDeg = 90;
          wind_directionCalcLeft = -12
          wind_directionCalcTop = 40
        } else if (wind_direction_float < 157.5) {
          wind_directionCalcDeg = 135;
          wind_directionCalcLeft = -7
          wind_directionCalcTop = 30
        } else if (wind_direction_float < 202.5) {
          wind_directionCalcDeg = 180;
          wind_directionCalcLeft = 8
          wind_directionCalcTop = 20
        } else if (wind_direction_float < 247.5) {
          wind_directionCalcDeg = 225;
          wind_directionCalcLeft = 23
          wind_directionCalcTop = 30
        } else if (wind_direction_float < 292.5) {
          wind_directionCalcDeg = 270;
          wind_directionCalcLeft = 26
          wind_directionCalcTop = 40
        } else if (wind_direction_float < 337.5) {
          wind_directionCalcDeg = 315;
          wind_directionCalcLeft = 23
          wind_directionCalcTop = 50
        } else if (wind_direction_float <= 360) {
          wind_directionCalcDeg = 0;
          wind_directionCalcLeft = 8
          wind_directionCalcTop = 60
        } else {
          wind_directionCalcDeg = 0;
          wind_directionCalcLeft = 8
          wind_directionCalcTop = 10
        }

        // 波の方向
        let wave_directionCalcDeg = 0;
        let wave_directionCalcLeft = 0;
        let wave_directionCalcTop = 0;
        if (wave_direction_float < 22.5) {
          wave_directionCalcDeg = 0;
          wave_directionCalcLeft = 8
          wave_directionCalcTop = 60
        } else if (wave_direction_float < 67.5) {
          wave_directionCalcDeg = 45;
          wave_directionCalcLeft = -7
          wave_directionCalcTop = 50
        } else if (wave_direction_float < 112.5) {
          wave_directionCalcDeg = 90;
          wave_directionCalcLeft = -12
          wave_directionCalcTop = 40
        } else if (wave_direction_float < 157.5) {
          wave_directionCalcDeg = 135;
          wave_directionCalcLeft = -7
          wave_directionCalcTop = 30
        } else if (wave_direction_float < 202.5) {
          wave_directionCalcDeg = 180;
          wave_directionCalcLeft = 8
          wave_directionCalcTop = 20
        } else if (wave_direction_float < 247.5) {
          wave_directionCalcDeg = 225;
          wave_directionCalcLeft = 23
          wave_directionCalcTop = 30
        } else if (wave_direction_float < 292.5) {
          wave_directionCalcDeg = 270;
          wave_directionCalcLeft = 26
          wave_directionCalcTop = 40
        } else if (wave_direction_float < 337.5) {
          wave_directionCalcDeg = 315;
          wave_directionCalcLeft = 23
          wave_directionCalcTop = 50
        } else if (wave_direction_float <= 360) {
          wave_directionCalcDeg = 0;
          wave_directionCalcLeft = 8
          wave_directionCalcTop = 60
        } else {
          wave_directionCalcDeg = 0;
          wave_directionCalcLeft = 8
          wave_directionCalcTop = 10
        }

        // うねりの方向
        let swell_directionCalcDeg = 0;
        let swell_directionCalcLeft = 0;
        let swell_directionCalcTop = 0;
        if (swell_direction_float < 22.5) {
          swell_directionCalcDeg = 0;
          swell_directionCalcLeft = 8
          swell_directionCalcTop = 60
        } else if (swell_direction_float < 67.5) {
          swell_directionCalcDeg = 45;
          swell_directionCalcLeft = -7
          swell_directionCalcTop = 50
        } else if (swell_direction_float < 112.5) {
          swell_directionCalcDeg = 90;
          swell_directionCalcLeft = -12
          swell_directionCalcTop = 40
        } else if (swell_direction_float < 157.5) {
          swell_directionCalcDeg = 135;
          swell_directionCalcLeft = -7
          swell_directionCalcTop = 30
        } else if (swell_direction_float < 202.5) {
          swell_directionCalcDeg = 180;
          swell_directionCalcLeft = 8
          swell_directionCalcTop = 20
        } else if (swell_direction_float < 247.5) {
          swell_directionCalcDeg = 225;
          swell_directionCalcLeft = 23
          swell_directionCalcTop = 30
        } else if (swell_direction_float < 292.5) {
          swell_directionCalcDeg = 270;
          swell_directionCalcLeft = 26
          swell_directionCalcTop = 40
        } else if (swell_direction_float < 337.5) {
          swell_directionCalcDeg = 315;
          swell_directionCalcLeft = 23
          swell_directionCalcTop = 50
        } else if (swell_direction_float <= 360) {
          swell_directionCalcDeg = 0;
          swell_directionCalcLeft = 8
          swell_directionCalcTop = 60
        } else {
          swell_directionCalcDeg = 0;
          swell_directionCalcLeft = 8
          swell_directionCalcTop = 10
        }

        DataList.push({
          utc_date: utc_date,
          log_speed: log_speed,
          foc: foc,
          co2_factor: co2_factor,
          co2: co2,
          me_foc: me_foc,
          me_load: me_load,
          me_rpm: me_rpm,
          distance: distance,
          displacement: displacement,
          wind_direction: wind_direction,
          wind_directionColor: "#00FFFF",
          wind_directionCalcDeg: wind_directionCalcDeg,
          wind_directionCalcLeft: wind_directionCalcLeft,
          wind_directionCalcTop: wind_directionCalcTop,
          wind_speed: wind_speed,
          wave_direction: wave_direction,
          wave_directionColor: "#00FFFF",
          wave_directionCalcDeg: wave_directionCalcDeg,
          wave_directionCalcLeft: wave_directionCalcLeft,
          wave_directionCalcTop: wave_directionCalcTop,
          wave_height: wave_height,
          wave_period: wave_period,
          swell_direction: swell_direction,
          swell_directionColor: "#00FFFF",
          swell_directionCalcDeg: swell_directionCalcDeg,
          swell_directionCalcLeft: swell_directionCalcLeft,
          swell_directionCalcTop: swell_directionCalcTop,
          swell_height: swell_height,
          swell_period: swell_period,
          course: course,
          beaufort: beaufort
          // ↑ 全チャンネルセット--------------------------------------------- ↑

        })

        // ダウンロード用データ
        const defDate = new Date(utc_date)
        const Year = getYear(defDate)
  
        let Month = (getMonth(defDate) + 1).toString()
        if (Month.length > 1) { } else { Month = "0" + Month }
  
        let Day = getDate(defDate).toString()
        if (Day.length > 1) { } else { Day = "0" + Day }
  
        let Hour = getHours(defDate).toString()
        if (Hour.length > 1) { } else { Hour = "0" + Hour }
  
        let Minute = getMinutes(defDate).toString()
        if (Minute.length > 1) { } else { Minute = "0" + Minute }
  
        const plotDate = Year + "/" + Month + "/" + Day + " " + Hour + ":" + Minute
  
        DownLoadList.push({
          utc_date: plotDate,
          log_speed: log_speed,
          me_foc: me_foc
        })
      }

      // setSpeedConsumptionFormula(SpeedConsumptionFormulaList)
      setSpeedConsumption(SpeedConsumptionList)
      setSpeedConsumptionCurveTrendLine(SpeedConsumptionCurveTrendLineList)
      setSpeedConsumptionCurve(SpeedConsumptionCurveList)
      setAllTimeSereis(DataList)

      // VesselMaster
      setVesselMaster(res.data.datas.VESSELMASTER)

      // plotInformation初期化
      setPlotInformation(plot)

      // Loading解除
      setLoading(false);
      setSuccess(true);

      // ダウンロード用データ
      setRows(DownLoadList);

    })
      .catch((e: AxiosError<ErrorData>) => {
        // Loading解除
        setSuccess(true);
        setLoading(false);
        reset_all()
        if (e.response === undefined) {
          console.log("undefinedError: " + e);
          return;
        }
        console.log(e + ", message: " + e.response.data.message);
      });
  }

  const reset_all = () => {
    setSpeedConsumption([])
    setSpeedConsumptionCurveTrendLine({ name: "", data: [], color: "", checked: true, formula: {} })
    setSpeedConsumptionCurve([])
    setAllTimeSereis([])
    setPlotInformation(plot)
  }

  const [key, setKey] = React.useState(0);
  // APIから取得データHcOptionsを更新
  React.useEffect(() => {
    if (SpeedConsumption !== undefined && SpeedConsumptionCurve !== undefined && SpeedConsumptionCurveTrendLine !== undefined) {
      setKey(key + 1);

      // 初期化
      const newHcOptions = { ...hcOptions };
      newHcOptions.series = []

      // 実測値の散布図
      newHcOptions.series[0] = {
        type: 'scatter',
        name: "分布図",
        visible: true,
        data: SpeedConsumption,
        cursor: 'pointer',
        events: {
          click: (event) => {
            onClickGraph(event);
          }
        },
      }

      // 傾向線
      newHcOptions.series[1] = {
        type: 'spline',
        name: SpeedConsumptionCurveTrendLine.name,
        color: SpeedConsumptionCurveTrendLine.color,
        data: SpeedConsumptionCurveTrendLine.data,
        visible: true,
        marker: {
          enabled: false
        },
        events: {
          click: null,
          point: {
            events: {
              click: null
            }
          }
        },
      }

      // // 各種スピコンカーブ
      if (SpeedConsumptionCurve.length) {
        for (let i = 0; i < SpeedConsumptionCurve.length; i++) {
          newHcOptions.series[i + 2] = {
            type: 'spline',
            name: SpeedConsumptionCurve[i].name,
            color: SpeedConsumptionCurve[i].color,
            data: SpeedConsumptionCurve[i].data,
            visible: true,
            marker: {
              enabled: false
            },
            events: {
              click: null,
              point: {
                events: {
                  click: null
                }
              }
            },
          }
        }
      }
      setHcOptions(newHcOptions)
    }

  }, [AllTimeSereis])





  // グラフ上の線をクリックされたら
  const [changeClickGraph, setChangeClickGraph] = React.useState<number>(0);
  const [clickItemGraph, setClickItemGraph] = React.useState({ click_log_speed: null, click_foc: null, click_index: null }); // クリックした点の値

  const onClickGraph = (event: any) => { //グラフ上の点をクリック
    setChangeClickGraph((prev) => (prev + 1));
    setClickItemGraph({
      click_log_speed: event.point.x,
      click_foc: event.point.y,
      click_index: event.point.index
    });
  }

  React.useEffect(() => {
    if (changeClickGraph > 0) {
      changeclickSeries(clickItemGraph)
    }
  }, [changeClickGraph])




  // クリックされた点の各チャンネルの値をセット ※ここでインデックスが変わっている。
  const changeclickSeries = (item) => {
    if (AllTimeSereis !== undefined) {
      console.log("AllTimeSereis", AllTimeSereis)
      console.log("item", item)
      const SelectDate = new Date(AllTimeSereis[item.click_index].utc_date)
      const Year = getYear(SelectDate)

      let Month = (getMonth(SelectDate) + 1).toString()
      if (Month.length > 1) { } else { Month = "0" + Month }

      let Day = getDate(SelectDate).toString()
      if (Day.length > 1) { } else { Day = "0" + Day }

      let Hour = getHours(SelectDate).toString()
      if (Hour.length > 1) { } else { Hour = "0" + Hour }

      let Minute = getMinutes(SelectDate).toString()
      if (Minute.length > 1) { } else { Minute = "0" + Minute }

      const plotDate = Year + "/" + Month + "/" + Day + " " + Hour + ":" + Minute

      const plotInformation = {
        utc_date: plotDate,
        log_speed: AllTimeSereis[item.click_index].log_speed,
        foc: AllTimeSereis[item.click_index].foc,
        index: item.click_index,
        lat: AllTimeSereis[item.click_index].lat,
        lng: AllTimeSereis[item.click_index].lng,
        co2_factor: AllTimeSereis[item.click_index].co2_factor,
        co2: AllTimeSereis[item.click_index].co2,
        me_foc: AllTimeSereis[item.click_index].me_foc,
        me_load: AllTimeSereis[item.click_index].me_load,
        me_rpm: AllTimeSereis[item.click_index].me_rpm,
        distance: AllTimeSereis[item.click_index].distance,
        displacement: AllTimeSereis[item.click_index].displacement,
        wind_direction: AllTimeSereis[item.click_index].wind_direction,
        wind_directionColor: "#00FFFF",
        wind_directionCalcDeg: AllTimeSereis[item.click_index].wind_directionCalcDeg,
        wind_directionCalcLeft: AllTimeSereis[item.click_index].wind_directionCalcLeft,
        wind_directionCalcTop: AllTimeSereis[item.click_index].wind_directionCalcTop,
        wind_speed: AllTimeSereis[item.click_index].wind_speed,
        wave_direction: AllTimeSereis[item.click_index].wave_direction,
        wave_directionColor: "#00FFFF",
        wave_directionCalcDeg: AllTimeSereis[item.click_index].wave_directionCalcDeg,
        wave_directionCalcLeft: AllTimeSereis[item.click_index].wave_directionCalcLeft,
        wave_directionCalcTop: AllTimeSereis[item.click_index].wave_directionCalcTop,
        wave_height: AllTimeSereis[item.click_index].wave_height,
        wave_period: AllTimeSereis[item.click_index].wave_period,
        swell_direction: AllTimeSereis[item.click_index].swell_direction,
        swell_directionColor: "#00FFFF",
        swell_directionCalcDeg: AllTimeSereis[item.click_index].swell_directionCalcDeg,
        swell_directionCalcLeft: AllTimeSereis[item.click_index].swell_directionCalcLeft,
        swell_directionCalcTop: AllTimeSereis[item.click_index].swell_directionCalcTop,
        swell_height: AllTimeSereis[item.click_index].swell_height,
        swell_period: AllTimeSereis[item.click_index].swell_period,
        course: AllTimeSereis[item.click_index].course,
        beaufort: AllTimeSereis[item.click_index].beaufort,
      }
      console.log("plotInformation", plotInformation)
      setPlotInformation(plotInformation);


      const newHcOptions = { ...hcOptions };
      newHcOptions.series[0].data = JSON.parse(JSON.stringify(SpeedConsumption))
      newHcOptions.series[0].data[item.click_index] = JSON.parse(JSON.stringify({
        x: item.click_log_speed,
        y: item.click_foc,
        marker: {
          radius: 6,
          lineColor: "red",
          lineWidth: 2.5,
          fillColor: {
            radialGradient: { cx: 0.49, cy: 0.48, r: 0.2 },
            stops: [
              [0, 'rgb(255, 255, 255, 1)'],
              [1, 'rgb(216, 255, 248, 0)']
            ],
          },
          states: {
            hover: {
              radius: 8,
              fillColor: 'rgb(216, 255, 248,0.5)'
            }
          }
        },
      }))
      setHcOptions(newHcOptions)
      setKey(key + 1);
    }
  }


  // サイドバー展開--------------------------------------------------------------------
  // 現在のアクティブなタブを管理するための状態
  // const [activeTab, setActiveTab] = React.useState();
  const [SeachTab, setSeachTab] = React.useState<boolean>(true);
  const [VesselInformationTab, setVesselInformationTab] = React.useState<boolean>(true);
  const [AdvancedFilterTab1, setAdvancedFilterTab1] = React.useState<boolean>(false);

  // タブがクリックされた時に呼び出されるハンドラー関数
  const handleTabClick = (index) => {
    if (index === 1) {
      setSeachTab(!SeachTab)
    }
    else if (index === 2) {
      setVesselInformationTab(!VesselInformationTab)
    }
    else if (index === 3) {
      setAdvancedFilterTab1(!AdvancedFilterTab1)
    }
  }

  // フィルターの入力値を保持するリスト
  const count_row = {
    "key"            : 0,
    "fromLogSpeedAF" : "",
    "toLogSpeedAF"   : "",
    "fromFOCAF"      : "",
    "toFOCAF"        : ""
  }
  const [CountAFTabList, setCountAFTabList] = React.useState([count_row]);
  const [count, setCount] = React.useState(1);
  const handleAFTabAdd = () => {
    if (AdvancedFilterTab1) {
      setCount(count + 1)
      CountAFTabList.push({
        key            : count,
        fromLogSpeedAF : "",
        toLogSpeedAF   : "",
        fromFOCAF      : "",
        toFOCAF        : ""
      })
    }
  };

  // リスト内の値の更新
  const updateFromLogSpeedAF = (inputKey, updateValue) =>{
    const updatedList :any = CountAFTabList.map((row) =>
      row.key === inputKey
        ?
          {
            ...row,
            fromLogSpeedAF : updateValue
          }
        :
          { ...row }
    );
    setCountAFTabList(updatedList)
  }
  const updateToLogSpeedAF= (inputKey, updateValue) =>{
    const updatedList :any = CountAFTabList.map((row) =>
      row.key === inputKey
        ?
          {
            ...row,
            toLogSpeedAF : updateValue,
          }
        :
          { ...row }
    );
    setCountAFTabList(updatedList)
  }
  const updateFromFOCAF = (inputKey, updateValue) =>{
    const updatedList :any = CountAFTabList.map((row) =>
      row.key === inputKey
        ?
          {
            ...row,
            fromFOCAF : updateValue,
          }
        :
          { ...row }
    );
    setCountAFTabList(updatedList)
  }
  const updateToFOCAF = (inputKey, updateValue) =>{
    const updatedList :any = CountAFTabList.map((row) =>
      row.key === inputKey
        ?
          {
            ...row,
            toFOCAF : updateValue
          }
        :
          { ...row }
    );
    setCountAFTabList(updatedList)
  }

  // URLにセットするパラメータの設定---------------------------------------------------------------------------------------------------------------------------
  let paraFromLogSpeedAF = "";
  let paraToLogSpeedAF = "";
  let paraFromFOCAF = "";
  let paraToFOCAF = "";
  const setParameters = () => {
    let arrayFromLogSpeedAF = "";
    let arrayToLogSpeedAF = "";
    let arrayFromFOCAF = "";
    let arrayToFOCAF = "";
    for (let i=0; i < CountAFTabList.length; i++) {
      if (i === 0) {
        arrayFromLogSpeedAF += CountAFTabList[i].fromLogSpeedAF;
        arrayToLogSpeedAF += CountAFTabList[i].toLogSpeedAF;
        arrayFromFOCAF += CountAFTabList[i].fromFOCAF;
        arrayToFOCAF += CountAFTabList[i].toFOCAF;
      } else {
        arrayFromLogSpeedAF += "-" + CountAFTabList[i].fromLogSpeedAF;
        arrayToLogSpeedAF += "-" + CountAFTabList[i].toLogSpeedAF;
        arrayFromFOCAF += "-" + CountAFTabList[i].fromFOCAF;
        arrayToFOCAF += "-" + CountAFTabList[i].toFOCAF;
      }
    }
    paraFromLogSpeedAF = arrayFromLogSpeedAF;
    paraToLogSpeedAF = arrayToLogSpeedAF;
    paraFromFOCAF = arrayFromFOCAF;
    paraToFOCAF = arrayToFOCAF;
  }

  // CSVダウンロード---------------------------------------------------------------------------------------------------------------------------
  const [openDialogDownloadCSV, setOpenDialogDownloadCSV] = React.useState(false);
  const handleClickOpenDialogDownloadCSV = () => {
    setOpenDialogDownloadCSV(true)
  }
  // Cancelボタン
  const handleNoCloseDialogDownloadCSV = () => {
    setOpenDialogDownloadCSV(false)
  }
  // リストをCSV形式の文字列に変換する関数
  const convertToCSV = (data) => {
    const csv = data.map(row => Object.values(row).join(',')).join('\n');
    console.log(csv)
    return csv;
  }
  // CSVファイルをダウンロードする関数
  const csvHeader = {
    "Local Date": "Local Date",
    "LOG Speed": "LOG Speed",
    "M/E FOC": "M/E FOC"
  }
  const init_row = {
    "utc_date": "",
    "log_speed": "",
    "me_load": ""
  }
  const [rows, setRows] = React.useState([init_row])

  // 現在表示している船名の取得
  const getVesselName = ( inputNo ) => {
    let vesselName = "";
    SeachInfo.imoList?.map((imo) => {
      if ( inputNo === imo.imoNo ) {
        vesselName = imo.VesselName;
      }
    })
    return vesselName
  }

  const handleCloseDialogDownloadCSV = () => {
    if (rows.length > 0 && rows[0].utc_date != "") {
      const newItem = [csvHeader, ...rows]
      console.log(newItem)
      const csv = convertToCSV(newItem);
      const csvBlob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
      const csvUrl = window.URL.createObjectURL(csvBlob);
      const tempLink = document.createElement('a');
      tempLink.href = csvUrl;
      const newDate = new Date()
      let selectedVesselName = getVesselName(SeachInfo.imoNo);
      const csvPath = selectedVesselName + '_' + dayjs(newDate).format("YYYYMMDDHHmmss") + '.csv'
      tempLink.setAttribute('download', csvPath);
      document.body.appendChild(tempLink);
      tempLink.click();
      document.body.removeChild(tempLink);
      setOpenDialogDownloadCSV(false)
    }
    else {
      alert("No target records found")
    }
  }


  return (
    <>
      <Box height="100vh" sx={{ display: 'flex' }}>
        <CssBaseline />
        {/* useId有効期限切れダイアログ */}
        <Dialog
          open={userCheck}
          sx={{
            "& .MuiDialog-container": {
              "& .MuiPaper-root": {
                width: "100%",
                maxWidth: "600px",  // Set your width here
                height: "100%",
                maxHeight: "210px",  // Set your width here
              },
            },
          }}
        >
          <DialogTitle
            sx={{
              bgcolor: "#9ea2ad",
              height: "50px",
              color: "white",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center"
            }}>
            UserId Update
          </DialogTitle>
          <DialogContent
            sx={{
              background: "linear-gradient(#40475d, #0d1532)",
              height: "100px",
            }}>
            <Typography align="left" sx={{ marginTop: "32px", color: 'white', fontSize: 13, fontFamily: 'Roboto Condensed', fontWeight: '400', wordWrap: 'break-word' }}>
              {message === "" ? "" : message}
            </Typography>
          </DialogContent>
          <DialogActions
            sx={{
              height: "50px",
              bgcolor: "#474C53",
            }}>
            <Button
              sx={{
                marginLeft: "0px",
                marginRight: "-8px",
                backgroundColor: "#e66300",
                width: "150px",
                height: "50px",
                padding: "0",
                fontSize: "18px",
                fontFamily: "'Roboto Bold', 'Meiryo', sans-serif",
                fontWeight: "bold",
                color: "white",
                borderRadius: "0px",
                borderBottomRightRadius: "2px",
                ":hover": {
                  bgcolor: "#FFFFFF",
                  color: "#40475D",
                },
                textTransform: 'none'
              }}
              onClick={handleClickWADATSUMI}>
              OK
            </Button>
          </DialogActions>
        </Dialog>

        {/* 認可チェックダイアログ */}
        <Dialog
          open={authCheck}
          sx={{
            "& .MuiDialog-container": {
              "& .MuiPaper-root": {
                width: "100%",
                maxWidth: "600px",  // Set your width here
                height: "100%",
                maxHeight: "210px",  // Set your width here
              },
            },
          }}
        >
          <DialogTitle
            sx={{
              bgcolor: "#9ea2ad",
              height: "50px",
              color: "white",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center"
            }}>
            Auth check
          </DialogTitle>
          <DialogContent
            sx={{
              background: "linear-gradient(#40475d, #0d1532)",
              height: "100px",
            }}>
            <Typography align="left" sx={{ marginTop: "32px", color: 'white', fontSize: 13, fontFamily: 'Roboto Condensed', fontWeight: '400', wordWrap: 'break-word' }}>
              {authMessage === "" ? "" : authMessage}
            </Typography>
          </DialogContent>
          <DialogActions
            sx={{
              height: "50px",
              bgcolor: "#474C53",
            }}>
            <Button
              sx={{
                marginLeft: "0px",
                marginRight: "-8px",
                backgroundColor: "#e66300",
                width: "150px",
                height: "50px",
                padding: "0",
                fontSize: "18px",
                fontFamily: "'Roboto Bold', 'Meiryo', sans-serif",
                fontWeight: "bold",
                color: "white",
                borderRadius: "0px",
                borderBottomRightRadius: "2px",
                ":hover": {
                  bgcolor: "#FFFFFF",
                  color: "#40475D",
                },
                textTransform: 'none'
              }}
              onClick={() => setAuthCheck(false)}>
              OK
            </Button>
          </DialogActions>
        </Dialog>

        {/* menu bar */}
        <MyAppBar company_id={company_id} group_id={group_id} user_id={user_id} role={role} />

        {/* side bar */}
        <Drawer
          variant="permanent"
          open={openDrwer}
          sx={{
            // background: 'linear-gradient(180deg, #3B3D43 0%, #383A40 100%)',
            flexShrink: 0,
            '& .MuiDrawer-paper': {
              background: '#282F35',
              boxSizing: 'border-box',
              borderRight: "1px solid rgb(201,201,201,0.2)"
            },
          }}>
          <React.Fragment>
            {/* 検索フォーム開閉 */}
            {openDrwer ?
              <Box
                sx={{
                  marginTop: "64px",
                  overflowY: 'auto',

                  '&::-webkit-scrollbar': {
                    width: "6px",
                    height: "6px",
                  },
                  '&::-webkit-scrollbar-track': {
                    borderRadius: "10px",
                    background: "rgba(255, 255, 255, 0.5)",
                  },
                  '&::-webkit-scrollbar-thumb': {
                    background: "#fff",
                    borderRadius: "10px",
                  },
                  '&::-webkit-scrollbar-thumb:hover': {
                    backgroundColor: '#555',
                  },
                  "&::-webkit-scrollbar-corner": {
                    display: "none",
                  }
                }}>

                {/* ↓ Space ↓ */}
                <Stack alignItems="center" sx={{ position: "relative", height: "20px" }}>
                </Stack>
                {/* ↑ Space ↑ */}

                {/* ↓ Select Search Conditions ↓ */}
                <Stack alignItems="left" sx={{ position: "relative" }}>
                  {
                    SeachTab !== true ?
                      <>
                        {/* title */}
                        <Stack sx={{ position: "relative", height: "40px", cursor: "pointer", background: "rgba(59.32, 64.07, 71.19, 0.0)" }} onClick={() => handleTabClick(1)}>
                          <Box sx={{ position: "absolute", top: "14px", left: "25px", }}>
                            <Box sx={{ borderTop: "solid 2px rgb(255, 255, 255, 0.7)", borderRight: "solid 2px rgb(255, 255, 255, 0.7)", width: "8px", height: "8px", transform: "rotate(45deg)" }}></Box>
                          </Box>
                          <Box sx={{ position: "absolute", top: "0px", left: "42px" }}>
                            <Typography sx={{ color: "rgba(255, 255, 255, 1)", fontSize: "14px", fontFamily: "Roboto", fontWeight: 700, lineHeight: "38px", wordWrap: "break-word" }}>
                              Select Search Conditions
                            </Typography>
                          </Box>
                        </Stack>
                      </>
                      :
                      <>
                        {/* title */}
                        <Stack sx={{ position: "relative", height: "40px", cursor: "pointer", background: "rgba(59.32, 64.07, 71.19, 0.0)" }} onClick={() => handleTabClick(1)}>
                          <Box sx={{ position: "absolute", top: "11px", left: "25px", }}>
                            <Box sx={{ borderTop: "solid 2px rgb(255, 255, 255, 0.7)", borderRight: "solid 2px rgb(255, 255, 255, 0.7)", width: "8px", height: "8px", transform: "rotate(135deg)" }}></Box>
                          </Box>
                          <Box sx={{ position: "absolute", top: "0px", left: "42px" }}>
                            <Typography sx={{ color: "rgba(255, 255, 255, 1)", fontSize: "14px", fontFamily: "Roboto", fontWeight: 700, lineHeight: "38px", wordWrap: "break-word" }}>
                              Select Search Conditions
                            </Typography>
                          </Box>
                        </Stack>

                        {/* main */}
                        <Stack sx={{ width: `calc(100% - 22px)` }}>

                          {/* Vessel Name */}
                          <Stack alignItems="center" sx={{ position: "relative", top: "10px", left: "22px", height: "110px" }}>
                            <Typography
                              align="left"
                              sx={{
                                position: "absolute",
                                top: "0px",
                                left: "20px",
                                color: 'white',
                                fontSize: "14px",
                                fontFamily: "'Roboto Bold'",
                                fontWeight: '700',
                                lineHeight: "24px",
                                wordWrap: 'break-word',
                              }}>
                              Vessel Name
                            </Typography>
                            <SelectVesseName
                              vesselName = {vesselName}
                              vesselOpen = {vesselOpen}
                              currentFocusVessel = {currentFocusVessel}
                              imoNo = {SeachInfo.imoNo}
                              imoList = {SeachInfo.imoList}
                              setVesseName = {(updateValue: string) => setVesselName(updateValue)}
                              setVesselOpen = {(updateValue: boolean) => setVesselOpen(updateValue)}
                              setCurrentFocusVessel = {(updateValue: string) => setCurrentFocusVessel(updateValue)}
                              selectImo = {(updateValue: string) => selectImo(updateValue)}
                            />
                          </Stack>

                          {/* Data Range */}
                          <Stack alignItems="center" sx={{ position: "relative", top: "0px", left: "22px", height: "110px" }}>
                            <Typography
                              align="left"
                              sx={{
                                position: "absolute",
                                top: "0px",
                                left: "20px",
                                color: 'white',
                                fontSize: "14px",
                                fontFamily: "'Roboto Bold'",
                                fontWeight: '700',
                                lineHeight: "24px",
                                wordWrap: 'break-word',
                              }}>
                              Data Range
                            </Typography>
                            <Button
                              variant="outlined"
                              onClick={handleClickOpenDialog}
                              sx={{
                                position: "absolute",
                                top: "30px",
                                left: "20px",
                                width: "265px",
                                height: 45,
                                background: 'rgba(255, 255, 255, 0.15)',
                                borderRadius: 30,
                                border: '1px #CBCBCB solid',
                                color: 'white',
                                fontSize: 15,
                                fontFamily: 'Roboto',
                                fontWeight: '700',
                                wordWrap: 'break-word',
                                ":hover": { backgroundColor: "#7a86a0", border: '1px #CBCBCB solid' },
                              }}>
                              <Stack direction='row' sx={{ height: 20 }} alignItems="center" justifyContent="center">
                                <CalendarMonthIcon />
                              </Stack>
                              <Typography align="left" sx={{ color: 'white', width: "100%", fontSize: "15px", marginLeft: 2 }}>
                                {dayjs(SeachInfo.from).utc().year() + "/" + (dayjs(SeachInfo.from).utc().month() + 1) + "/" + dayjs(SeachInfo.from).utc().date()}
                                ~
                                {dayjs(SeachInfo.to).utc().year() + "/" + (dayjs(SeachInfo.to).utc().month() + 1) + "/" + dayjs(SeachInfo.to).utc().date()}
                              </Typography>
                            </Button>
                          </Stack>
                          <Box >{<SelectDate setSeachInfo={setSeachInfo} SeachInfo={SeachInfo} openDialog={openDialog} setOpenDialog={setOpenDialog} />}</Box>


                          {/* Beaufort */}
                          <Stack alignItems="center" sx={{ position: "relative", top: "0px", left: "22px", height: "120px" }}>
                            <Typography align="left"
                              sx={{
                                position: "absolute",
                                top: "0px",
                                left: "20px",
                                color: 'white',
                                fontSize: "14px",
                                fontFamily: "'Roboto Bold'",
                                fontWeight: '700',
                                lineHeight: "24px",
                                wordWrap: 'break-word',
                              }}>
                              Beaufort
                            </Typography>
                            <Button
                              variant="outlined"
                              onClick={handleClickOpenDialogBeaufort}
                              sx={{
                                position: "absolute",
                                top: "30px",
                                left: "20px",
                                width: "265px",
                                height: "45px",
                                background: 'rgba(255, 255, 255, 0.15)',
                                borderRadius: "30px",
                                border: '1px #CBCBCB solid',
                                color: 'white',
                                fontSize: "15px",
                                fontFamily: 'Roboto',
                                fontWeight: '700',
                                wordWrap: 'break-word',
                                ":hover": {
                                  backgroundColor: "#7a86a0",
                                  border: '1px #CBCBCB solid'
                                },
                              }}>
                              <Typography
                                align="left"
                                sx={{
                                  position: "absolute",
                                  top: "10px",
                                  left: "20px",
                                  color: 'white',
                                  fontSize: "16px",
                                }}>
                                {
                                  BeaufortcheckShow?.map((data, index) => (
                                    data.checked === true ? data.data + "," : ""
                                  ))
                                }
                              </Typography>
                            </Button>
                          </Stack>


                          {/* Displacement */}
                          <Stack alignItems="center" sx={{ position: "relative", top: "0px", left: "22px", height: "110px" }}>
                            <Typography align="left"
                              sx={{
                                position: "absolute",
                                top: "0px",
                                left: "20px",
                                color: 'white',
                                fontSize: "14px",
                                fontFamily: "'Roboto Bold'",
                                fontWeight: '700',
                                lineHeight: "24px",
                                wordWrap: 'break-word',
                              }}>
                              Displacement
                            </Typography>
                            <FormControl
                              sx={{
                                position: "absolute",
                                top: "20px",
                                left: "10px",
                              }}
                            >
                              <Grid container direction={"row"} alignItems={"center"}>
                                <Grid item xs={2}>
                                  <Radio
                                    {...controlProps('a')}
                                    sx={{ color: "rgba(255,255,255,0.5)", '&.Mui-checked': { color: "rgba(255,255,255,0.5)" } }}
                                    value="All"
                                    onChange={handleCheckboxChangeBallastLaden}
                                    checked={checkboxesBallastLaden[0].checked}
                                  />
                                </Grid>
                                <Grid item xs={1}>
                                  <Typography align="left"
                                    sx={{
                                      color: 'white',
                                      fontSize: "14px",
                                      fontFamily: "'Roboto Bold'",
                                      fontWeight: '500',
                                      lineHeight: "24px",
                                      wordWrap: 'break-word',
                                    }}>
                                    All
                                  </Typography>
                                </Grid>
                                <Grid item xs={2}>
                                  <Radio
                                    {...controlProps('b')}
                                    sx={{ color: "rgba(255,255,255,0.5)", '&.Mui-checked': { color: "rgba(255,255,255,0.5)" } }}
                                    value="Laden"
                                    onChange={handleCheckboxChangeBallastLaden}
                                    checked={checkboxesBallastLaden[1].checked}
                                  />
                                </Grid>
                                <Grid item xs={2}>
                                  <Typography align="left"
                                    sx={{
                                      color: 'white',
                                      fontSize: "14px",
                                      fontFamily: "'Roboto Bold'",
                                      fontWeight: '500',
                                      lineHeight: "24px",
                                      wordWrap: 'break-word',
                                    }}>
                                    Laden
                                  </Typography>
                                </Grid>
                                <Grid item xs={2}>
                                  <Radio
                                    {...controlProps('c')}
                                    sx={{ color: "rgba(255,255,255,0.5)", '&.Mui-checked': { color: "rgba(255,255,255,0.5)" } }}
                                    value="Ballast"
                                    onChange={handleCheckboxChangeBallastLaden}
                                    checked={checkboxesBallastLaden[2].checked}
                                  />
                                </Grid>
                                <Grid item xs={3}>
                                  <Typography align="left"
                                    sx={{
                                      color: 'white',
                                      fontSize: "14px",
                                      fontFamily: "'Roboto Bold'",
                                      fontWeight: '500',
                                      lineHeight: "24px",
                                      wordWrap: 'break-word',
                                    }}>
                                    Ballast
                                  </Typography>
                                </Grid>
                              </Grid>

                            </FormControl>
                            <FormControl
                              sx={{
                                position: "absolute",
                                top: "62px",
                                left: "20px",
                                width: "100px"
                              }}>
                              <Input
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                  LowerLimitDisp(event.target.value);
                                }}
                                placeholder="lower"
                                value={fromDisp}
                                type='tel'
                                sx={{
                                  fontFamily: 'Roboto Regular',
                                  // fontWeight: '400',
                                  wordWrap: 'break-word',
                                  border: '0px grey solid',
                                  color: "white",
                                  fontSize: "15px",
                                  fontstyle: "italic",
                                  "& .MuiInput-input": {
                                    height: "30px",
                                    borderRadius: '0px',
                                    border: '0px white solid',
                                    background: "rgba(255, 255, 255, 0.15)",
                                    textAlign: "center",
                                  },
                                  ":hover": {
                                    backgroundColor: "#7a86a0",
                                    border: '0px #CBCBCB solid',
                                    borderBottom: "0px solid white",
                                  },
                                  ":hover:after": {
                                    borderBottom: "0px solid green",
                                  },
                                  ":hover:not(.Mui-disabled, .Mui-error):before": {
                                    borderBottom: "2px solid white",
                                  },
                                  ":after": {
                                    borderBottom: "2px solid white",
                                  },
                                  ":before": {
                                    borderBottom: "2px solid #A4A7B1",
                                  },
                                }}
                              />
                            </FormControl>
                            <Typography align="left"
                              sx={{
                                position: "absolute",
                                top: "87px",
                                left: "122px",
                                color: 'rgba(255, 255, 255, 0.30)',
                                fontSize: 13,
                                fontFamily: 'Roboto Condensed',
                                fontWeight: '400',
                                lineHeight: "24px",
                                wordWrap: 'break-word'
                              }}>
                              MT
                            </Typography>
                            <Box sx={{
                              position: "absolute",
                              top: "82px",
                              left: "120px",
                              width: "40px",
                              borderRadius: '0px ',
                              border: "1.5px solid #A4A7B1",
                              fontFamily: 'Roboto',
                              wordWrap: 'break-word'
                            }}>
                            </Box>
                            <FormControl
                              sx={{
                                position: "absolute",
                                top: "62px",
                                left: "160px",
                                width: "100px"
                              }}>
                              <Input
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                  UpperLimitDisp(event.target.value)
                                }}
                                placeholder="upper"
                                value={toDisp}
                                type='tel'
                                sx={{
                                  fontFamily: 'Roboto',
                                  fontWeight: '400',
                                  wordWrap: 'break-word',
                                  border: '0px grey solid',
                                  color: "white",
                                  fontSize: "15px",
                                  "& .MuiInput-input": {
                                    height: "30px",
                                    borderRadius: '0px',
                                    border: '0px white solid',
                                    background: "rgba(255, 255, 255, 0.15)",
                                    textAlign: "center",
                                  },
                                  ":hover": {
                                    backgroundColor: "#7a86a0",
                                    border: '0px #CBCBCB solid',
                                    borderBottom: "0px solid white",
                                  },
                                  ":hover:after": {
                                    borderBottom: "0px solid green",
                                  },
                                  ":hover:not(.Mui-disabled, .Mui-error):before": {
                                    borderBottom: "2px solid white",
                                  },
                                  ":after": {
                                    borderBottom: "2px solid white",
                                  },
                                  ":before": {
                                    borderBottom: "2px solid #A4A7B1",
                                  },
                                }}
                              />
                            </FormControl>
                            <Typography align="left"
                              sx={{
                                position: "absolute",
                                top: "87px",
                                left: "262px",
                                color: 'rgba(255, 255, 255, 0.30)',
                                fontSize: 13,
                                fontFamily: 'Roboto Condensed',
                                fontWeight: '400',
                                lineHeight: "24px",
                                wordWrap: 'break-word'
                              }}>
                              MT
                            </Typography>
                          </Stack>



                          {/* Log Speed */}
                          <Stack alignItems="center" sx={{ position: "relative", top: "20px", left: "22px", height: "80px" }}>
                            <Typography align="left"
                              sx={{
                                position: "absolute",
                                top: "0px",
                                left: "20px",
                                color: 'white',
                                fontSize: "14px",
                                fontFamily: "'Roboto Bold'",
                                fontWeight: '700',
                                lineHeight: "24px",
                                wordWrap: 'break-word',
                              }}>
                              Log Speed
                            </Typography>
                            <FormControl
                              sx={{
                                position: "absolute",
                                top: "30px",
                                left: "20px",
                                width: "100px"
                              }}>
                              <Input
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                  LowerLimitLogSpeed(event.target.value);
                                }}
                                placeholder="lower"
                                value={fromLogSpeed}
                                type='tel'
                                sx={{
                                  fontFamily: 'Roboto',
                                  fontWeight: '400',
                                  wordWrap: 'break-word',
                                  border: '0px grey solid',
                                  color: "white",
                                  fontSize: "15px",
                                  "& .MuiInput-input": {
                                    height: "30px",
                                    borderRadius: '0px',
                                    border: '0px white solid',
                                    background: "rgba(255, 255, 255, 0.15)",
                                    textAlign: "center",
                                  },
                                  ":hover": {
                                    backgroundColor: "#7a86a0",
                                    border: '0px #CBCBCB solid',
                                    borderBottom: "0px solid white",
                                  },
                                  ":hover:after": {
                                    borderBottom: "0px solid green",
                                  },
                                  ":hover:not(.Mui-disabled, .Mui-error):before": {
                                    borderBottom: "2px solid white",
                                  },
                                  ":after": {
                                    borderBottom: "2px solid white",
                                  },
                                  ":before": {
                                    borderBottom: "2px solid #A4A7B1",
                                  },
                                }}
                              />
                            </FormControl>
                            <Typography align="left"
                              sx={{
                                position: "absolute",
                                top: "55px",
                                left: "122px",
                                color: 'rgba(255, 255, 255, 0.30)',
                                fontSize: 13,
                                fontFamily: 'Roboto Condensed',
                                fontWeight: '400',
                                lineHeight: "24px",
                                wordWrap: 'break-word'
                              }}>
                              knot
                            </Typography>
                            <Box sx={{
                              position: "absolute",
                              top: "50px",
                              left: "120px",
                              width: "40px",
                              borderRadius: '0px ',
                              border: "1.5px solid #A4A7B1",
                              fontFamily: 'Roboto',
                              wordWrap: 'break-word'
                            }}>
                            </Box>
                            <FormControl
                              sx={{
                                position: "absolute",
                                top: "30px",
                                left: "160px",
                                width: "100px"
                              }}>
                              <Input
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                  UpperLimitLogSpeed(event.target.value)
                                }}
                                placeholder="upper"
                                value={toLogSpeed}
                                type='tel'
                                sx={{
                                  fontFamily: 'Roboto',
                                  fontWeight: '400',
                                  wordWrap: 'break-word',
                                  border: '0px grey solid',
                                  color: "white",
                                  fontSize: "15px",
                                  "& .MuiInput-input": {
                                    height: "30px",
                                    borderRadius: '0px',
                                    border: '0px white solid',
                                    background: "rgba(255, 255, 255, 0.15)",
                                    textAlign: "center",
                                  },
                                  ":hover": {
                                    backgroundColor: "#7a86a0",
                                    border: '0px #CBCBCB solid',
                                    borderBottom: "0px solid white",
                                  },
                                  ":hover:after": {
                                    borderBottom: "0px solid green",
                                  },
                                  ":hover:not(.Mui-disabled, .Mui-error):before": {
                                    borderBottom: "2px solid white",
                                  },
                                  ":after": {
                                    borderBottom: "2px solid white",
                                  },
                                  ":before": {
                                    borderBottom: "2px solid #A4A7B1",
                                  },
                                }}
                              />
                            </FormControl>
                            <Typography align="left"
                              sx={{
                                position: "absolute",
                                top: "55px",
                                left: "262px",
                                color: 'rgba(255, 255, 255, 0.30)',
                                fontSize: 13,
                                fontFamily: 'Roboto Condensed',
                                fontWeight: '400',
                                lineHeight: "24px",
                                wordWrap: 'break-word'
                              }}>
                              knot
                            </Typography>
                          </Stack>

                          {/* Engine Load */}
                          <Stack alignItems="center" sx={{ position: "relative", top: "20px", left: "22px", height: "100px" }}>
                            <Typography align="left"
                              sx={{
                                position: "absolute",
                                top: "0px",
                                left: "20px",
                                color: 'white',
                                fontSize: "14px",
                                fontFamily: "'Roboto Bold'",
                                fontWeight: '700',
                                lineHeight: "24px",
                                wordWrap: 'break-word',
                              }}>
                              Engine Load
                            </Typography>
                            <FormControl
                              sx={{
                                position: "absolute",
                                top: "30px",
                                left: "20px",
                                width: "100px"
                              }}>
                              <Input
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                  LowerLimitEngineLoad(event.target.value);
                                }}
                                placeholder="lower"
                                value={fromEngineLoad}
                                type='tel'
                                sx={{
                                  fontFamily: 'Roboto',
                                  fontWeight: '400',
                                  wordWrap: 'break-word',
                                  border: '0px grey solid',
                                  color: "white",
                                  fontSize: "15px",
                                  "& .MuiInput-input": {
                                    height: "30px",
                                    borderRadius: '0px',
                                    border: '0px white solid',
                                    background: "rgba(255, 255, 255, 0.15)",
                                    textAlign: "center",
                                  },
                                  ":hover": {
                                    backgroundColor: "#7a86a0",
                                    border: '0px #CBCBCB solid',
                                    borderBottom: "0px solid white",
                                  },
                                  ":hover:after": {
                                    borderBottom: "0px solid green",
                                  },
                                  ":hover:not(.Mui-disabled, .Mui-error):before": {
                                    borderBottom: "2px solid white",
                                  },
                                  ":after": {
                                    borderBottom: "2px solid white",
                                  },
                                  ":before": {
                                    borderBottom: "2px solid #A4A7B1",
                                  },
                                }}
                              />
                            </FormControl>
                            <Typography align="left"
                              sx={{
                                position: "absolute",
                                top: "55px",
                                left: "122px",
                                color: 'rgba(255, 255, 255, 0.30)',
                                fontSize: 13,
                                fontFamily: 'Roboto Condensed',
                                fontWeight: '400',
                                lineHeight: "24px",
                                wordWrap: 'break-word'
                              }}>
                              %
                            </Typography>
                            <Box sx={{
                              position: "absolute",
                              top: "50px",
                              left: "120px",
                              width: "40px",
                              borderRadius: '0px ',
                              border: "1px solid #A4A7B1",
                              fontFamily: 'Roboto',
                              wordWrap: 'break-word'
                            }}>
                            </Box>
                            <FormControl
                              sx={{
                                position: "absolute",
                                top: "30px",
                                left: "160px",
                                width: "100px"
                              }}>
                              <Input
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                  UpperLimitEngineLoad(event.target.value)
                                }}
                                placeholder="upper"
                                value={toEngineLoad}
                                type='tel'
                                sx={{
                                  fontFamily: 'Roboto',
                                  fontWeight: '400',
                                  wordWrap: 'break-word',
                                  border: '0px grey solid',
                                  color: "white",
                                  fontSize: "15px",
                                  "& .MuiInput-input": {
                                    height: "30px",
                                    borderRadius: '0px',
                                    border: '0px white solid',
                                    background: "rgba(255, 255, 255, 0.15)",
                                    textAlign: "center",
                                  },
                                  ":hover": {
                                    backgroundColor: "#7a86a0",
                                    border: '0px #CBCBCB solid',
                                    borderBottom: "0px solid white",
                                  },
                                  ":hover:after": {
                                    borderBottom: "0px solid green",
                                  },
                                  ":hover:not(.Mui-disabled, .Mui-error):before": {
                                    borderBottom: "2px solid white",
                                  },
                                  ":after": {
                                    borderBottom: "2px solid white",
                                  },
                                  ":before": {
                                    borderBottom: "2px solid #A4A7B1",
                                  },
                                }}
                              />
                            </FormControl>
                            <Typography align="left"
                              sx={{
                                position: "absolute",
                                top: "55px",
                                left: "262px",
                                color: 'rgba(255, 255, 255, 0.30)',
                                fontSize: 13,
                                fontFamily: 'Roboto Condensed',
                                fontWeight: '400',
                                lineHeight: "24px",
                                wordWrap: 'break-word'
                              }}>
                              %
                            </Typography>
                          </Stack>

                          {/* ↓ Advanced Filter ↓ */}
                          <Stack alignItems="left" sx={{ position: "relative", top: "20px" }}>
                            {
                              AdvancedFilterTab1 !== true ?
                                <>
                                  {/* title */}
                                  <Stack sx={{ position: "relative", height: "40px", marginBottom: "15px", cursor: "pointer", background: "rgba(59.32, 64.07, 71.19, 0.0)" }} onClick={() => handleTabClick(3)}>
                                    <Box sx={{ position: "absolute", top: "14px", left: "25px", }}>
                                      <Box sx={{ borderTop: "solid 2px rgb(255, 255, 255, 0.7)", borderRight: "solid 2px rgb(255, 255, 255, 0.7)", width: "8px", height: "8px", transform: "rotate(45deg)" }}></Box>
                                    </Box>
                                    <Box sx={{ position: "absolute", top: "0px", left: "42px" }}>
                                      <Typography sx={{ color: "rgba(255, 255, 255, 1)", fontSize: "14px", fontFamily: "Roboto", fontWeight: 700, lineHeight: "38px", wordWrap: "break-word" }}>
                                        Advanced Filter
                                      </Typography>
                                    </Box>
                                  </Stack>
                                </>
                                :
                                <>
                                  {/* title */}
                                  <Stack sx={{ position: "relative", height: "40px", cursor: "pointer", background: "rgba(59.32, 64.07, 71.19, 0.0)" }} onClick={() => handleTabClick(3)}>
                                    <Box sx={{ position: "absolute", top: "12px", left: "25px", }}>
                                      <Box sx={{ borderTop: "solid 2px rgb(255, 255, 255, 0.7)", borderRight: "solid 2px rgb(255, 255, 255, 0.7)", width: "8px", height: "8px", transform: "rotate(135deg)" }}></Box>
                                    </Box>
                                    <Box sx={{ position: "absolute", top: "0px", left: "42px" }}>
                                      <Typography sx={{ color: "rgba(255, 255, 255, 1)", fontSize: "14px", fontFamily: "Roboto", fontWeight: 700, lineHeight: "38px", wordWrap: "break-word" }}>
                                        Advanced Filter
                                      </Typography>
                                    </Box>
                                  </Stack>

                                  {/* Filter */}
                                  {CountAFTabList.map((row) => {
                                    return (
                                      <FilterComponent
                                        fromLogSpeedAF={row.fromLogSpeedAF}
                                        toLogSpeedAF={row.toLogSpeedAF}
                                        fromFOCAF={row.fromFOCAF}
                                        toFOCAF={row.toFOCAF}
                                        setFromLogSpeedAF={(updateValue: string) => updateFromLogSpeedAF(row.key, updateValue)}
                                        setToLogSpeedAF={(updateValue: string) => updateToLogSpeedAF(row.key, updateValue)}
                                        setFromFOCAF={(updateValue: string) => updateFromFOCAF(row.key, updateValue)}
                                        setToFOCAF={(updateValue: string) => updateToFOCAF(row.key, updateValue)}
                                      />
                                    )
                                  })}
                                  <Stack sx={{ position: "relative", left: "29px", width: "296px", height: "60px" }}>
                                    <Box sx={{ marginTop:"5px", marginLeft:"278px" }}>
                                      <IconButton className='plusButton'
                                        disabled = { CountAFTabList.length < 10 ? false : true }
                                        sx ={{ height : "18px", padding: 0, borderRadius: 30 }}
                                        onClick={() => handleAFTabAdd()}
                                      >
                                        <CommonIconPlus />                                            
                                      </IconButton>
                                    </Box>
                                  </Stack>
                                </>
                            }
                          </Stack>
                          {/* ↑ Advanced Filter ↑ */}

                          {/* Search */}
                          <Stack sx={{ position: "relative", height: "0px", padding: "30px 0px 50px 0px"}}>
                            <Button
                              variant="contained"
                              onClick={handleClickSearch}
                              sx={{
                                position: "absolute",
                                top: "30px",
                                left: "65px",
                                alignItems: "center",
                                justifyContent: "center",
                                height: "40px",
                                width: 200,
                                background: '#E66300',
                                borderRadius: 30,
                                color: "#fff",
                                ":hover": {
                                  bgcolor: "#FFFFFF",
                                  color: "#40475D",
                                },
                                textTransform: 'none'
                              }}
                            >
                              <SearchIcon sx={{ marginRight: "4px", width: "20px", height: "20px" }} />
                              <Typography align="center" sx={{ fontSize: 15 }}>Search</Typography>
                            </Button>
                          </Stack>
                        </Stack>
                      </>
                  }
                </Stack>
                {/* ↑ Select Search Conditions ↑ */}

                {/* ↓ Border ↓ */}
                <Stack alignItems="center" sx={{ position: "relative", height: "60px" }}>
                  <Box sx={{ position: "absolute", top: "30px", width: `80%`, border: '1px rgba(255, 255, 255, 0.30) dotted' }}></Box>
                </Stack>
                {/* ↑ Border ↑ */}

                {/* ↓ VesselInformation ↓ */}
                <Stack alignItems="left" sx={{ position: "relative" }}>
                  {
                    VesselInformationTab !== true ?
                      <>
                        {/* title */}
                        <Stack sx={{ position: "relative", height: "40px", cursor: "pointer", background: "rgba(59.32, 64.07, 71.19, 0.0)" }} onClick={() => handleTabClick(2)}>
                          <Box sx={{ position: "absolute", top: "14px", left: "25px", }}>
                            <Box sx={{ borderTop: "solid 2px rgb(255, 255, 255, 0.7)", borderRight: "solid 2px rgb(255, 255, 255, 0.7)", width: "8px", height: "8px", transform: "rotate(45deg)" }}></Box>
                          </Box>
                          <Box sx={{ position: "absolute", top: "0px", left: "42px" }}>
                            <Typography sx={{ color: "rgba(255, 255, 255, 1)", fontSize: "14px", fontFamily: "Roboto", fontWeight: 700, lineHeight: "38px", wordWrap: "break-word" }}>
                              Vessel Information
                            </Typography>
                          </Box>
                        </Stack>
                      </>
                      :
                      <>
                        {/* title */}
                        <Stack sx={{ position: "relative", height: "40px", cursor: "pointer", background: "rgba(59.32, 64.07, 71.19, 0.0)" }} onClick={() => handleTabClick(2)}>
                          <Box sx={{ position: "absolute", top: "12px", left: "25px", }}>
                            <Box sx={{ borderTop: "solid 2px rgb(255, 255, 255, 0.7)", borderRight: "solid 2px rgb(255, 255, 255, 0.7)", width: "8px", height: "8px", transform: "rotate(135deg)" }}></Box>
                          </Box>
                          <Box sx={{ position: "absolute", top: "0px", left: "42px" }}>
                            <Typography sx={{ color: "rgba(255, 255, 255, 1)", fontSize: "14px", fontFamily: "Roboto", fontWeight: 700, lineHeight: "38px", wordWrap: "break-word" }}>
                              Vessel Information
                            </Typography>
                          </Box>
                        </Stack>

                        {/* main */}
                        {success ?

                          <Stack sx={{ position: "relative", height: "330px", width: `calc(100% - 22px)` }}>
                            <Grid container direction="row" sx={{ position: "absolute", left: "22px", top: "0px" }}>

                              {/* Vessel Type */}
                              <Grid item xs={12}>
                                <Grid container direction="row" >
                                  <Grid item xs={1}>
                                  </Grid>
                                  <Grid item xs={5}>
                                    <Typography sx={{ color: "rgba(255, 255, 255, 0.50)", fontSize: "13px", fontFamily: "Roboto", fontWeight: 700, lineHeight: "38px", wordWrap: "break-word" }}>
                                      Vessel Type
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={1}>
                                    <Box sx={{ color: "rgba(255, 255, 255, 0.30)", fontSize: "14px", fontFamily: "Roboto", fontWeight: 700, lineHeight: "38px", wordWrap: "break-word" }}>
                                      :
                                    </Box>
                                  </Grid>
                                  <Grid item xs={5}>
                                    <Typography sx={{ color: "rgba(255, 255, 255, 1)", fontSize: "14px", fontFamily: "Roboto", fontWeight: 400, lineHeight: "38px", wordWrap: "break-word" }}>
                                      {VesselMaster ? VesselMaster.VesselType : ""}
                                    </Typography>
                                  </Grid>
                                </Grid>
                              </Grid>

                              {/* Fuel Type */}
                              <Grid item xs={12}>
                                <Grid container direction="row" >
                                  <Grid item xs={1}>
                                  </Grid>
                                  <Grid item xs={5}>
                                    <Typography sx={{ color: "rgba(255, 255, 255, 0.50)", fontSize: "13px", fontFamily: "Roboto", fontWeight: 700, lineHeight: "38px", wordWrap: "break-word" }}>
                                      Fuel Type
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={1}>
                                    <Box sx={{ color: "rgba(255, 255, 255, 0.30)", fontSize: "14px", fontFamily: "Roboto", fontWeight: 700, lineHeight: "38px", wordWrap: "break-word" }}>
                                      :
                                    </Box>
                                  </Grid>
                                  <Grid item xs={5}>
                                    <Typography sx={{ color: "rgba(255, 255, 255, 1)", fontSize: "14px", fontFamily: "Roboto", fontWeight: 400, lineHeight: "38px", wordWrap: "break-word" }}>
                                      {VesselMaster ? VesselMaster.OilType : ""}
                                    </Typography>
                                  </Grid>
                                </Grid>
                              </Grid>

                              {/* Segmental */}
                              <Grid item xs={12}>
                                <Grid container direction="row" >
                                  <Grid item xs={1}>
                                  </Grid>
                                  <Grid item xs={5}>
                                    <Typography sx={{ color: "rgba(255, 255, 255, 0.50)", fontSize: "13px", fontFamily: "Roboto", fontWeight: 700, lineHeight: "38px", wordWrap: "break-word" }}>
                                      Segmental
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={1}>
                                    <Box sx={{ color: "rgba(255, 255, 255, 0.30)", fontSize: "14px", fontFamily: "Roboto", fontWeight: 700, lineHeight: "38px", wordWrap: "break-word" }}>
                                      :
                                    </Box>
                                  </Grid>
                                  <Grid item xs={5}>
                                    <Typography sx={{ color: "rgba(255, 255, 255, 1)", fontSize: "14px", fontFamily: "Roboto", fontWeight: 400, lineHeight: "38px", wordWrap: "break-word" }}>
                                      {VesselMaster ? VesselMaster.Size : ""}
                                    </Typography>
                                  </Grid>
                                </Grid>
                              </Grid>

                              {/* DeadWeight */}
                              <Grid item xs={12}>
                                <Grid container direction="row" alignItems={"center"}>
                                  <Grid item xs={1}>
                                  </Grid>
                                  <Grid item xs={5}>
                                    <Typography sx={{ color: "rgba(255, 255, 255, 0.50)", fontSize: "13px", fontFamily: "Roboto", fontWeight: 700, lineHeight: "38px", wordWrap: "break-word" }}>
                                      DeadWeight
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={1}>
                                    <Box sx={{ color: "rgba(255, 255, 255, 0.30)", fontSize: "14px", fontFamily: "Roboto", fontWeight: 700, lineHeight: "38px", wordWrap: "break-word" }}>
                                      :
                                    </Box>
                                  </Grid>
                                  <Grid item xs={2.5}>
                                    <Typography align="left" sx={{ color: "rgba(255, 255, 255, 1)", fontSize: "14px", fontFamily: "Roboto", fontWeight: 400, lineHeight: "38px", wordWrap: "break-word" }}>
                                      {VesselMaster ? formatNumber(parseFloat(VesselMaster.Deadweight)) : ""}
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={2.5}>
                                    <Typography align="left" sx={{ color: 'rgba(255, 255, 255, 0.5)', fontSize: "14px", fontFamily: "'Roboto Condensed Regular'", wordWrap: 'break-word' }}>
                                      {VesselMaster ? "ton" : ""}
                                    </Typography>
                                  </Grid>
                                </Grid>
                              </Grid>

                              {/* Gross */}
                              <Grid item xs={12}>
                                <Grid container direction="row" alignItems={"center"} >
                                  <Grid item xs={1}>
                                  </Grid>
                                  <Grid item xs={5}>
                                    <Typography sx={{ color: "rgba(255, 255, 255, 0.50)", fontSize: "13px", fontFamily: "Roboto", fontWeight: 700, lineHeight: "38px", wordWrap: "break-word" }}>
                                      Gross
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={1}>
                                    <Box sx={{ color: "rgba(255, 255, 255, 0.30)", fontSize: "14px", fontFamily: "Roboto", fontWeight: 700, lineHeight: "38px", wordWrap: "break-word" }}>
                                      :
                                    </Box>
                                  </Grid>
                                  <Grid item xs={2.5}>
                                    <Typography align="left" sx={{ color: "rgba(255, 255, 255, 1)", fontSize: "14px", fontFamily: "Roboto", fontWeight: 400, lineHeight: "38px", wordWrap: "break-word" }}>
                                      {VesselMaster ? formatNumber(parseFloat(VesselMaster.Grosstongue)) : ""}
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={2.5}>
                                    <Typography align="left" sx={{ color: 'rgba(255, 255, 255, 0.5)', fontSize: "14px", fontFamily: "'Roboto Condensed Regular'", wordWrap: 'break-word' }}>
                                      {VesselMaster ? "ton" : ""}
                                    </Typography>
                                  </Grid>
                                </Grid>
                              </Grid>

                              {/* Built Date */}
                              <Grid item xs={12}>
                                <Grid container direction="row" >
                                  <Grid item xs={1}>
                                  </Grid>
                                  <Grid item xs={5}>
                                    <Typography sx={{ color: "rgba(255, 255, 255, 0.50)", fontSize: "13px", fontFamily: "Roboto", fontWeight: 700, lineHeight: "38px", wordWrap: "break-word" }}>
                                      Built Date
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={1}>
                                    <Box sx={{ color: "rgba(255, 255, 255, 0.30)", fontSize: "14px", fontFamily: "Roboto", fontWeight: 700, lineHeight: "38px", wordWrap: "break-word" }}>
                                      :
                                    </Box>
                                  </Grid>
                                  <Grid item xs={5}>
                                    <Typography sx={{ color: "rgba(255, 255, 255, 1)", fontSize: "14px", fontFamily: "Roboto", fontWeight: 400, lineHeight: "38px", wordWrap: "break-word" }}>
                                      {VesselMaster ? VesselMaster.BuildDate : ""}
                                    </Typography>
                                  </Grid>
                                </Grid>
                              </Grid>

                              {/* Hull Cleaning Date */}
                              <Grid item xs={12}>
                                <Grid container direction="row" >
                                  <Grid item xs={1}>
                                  </Grid>
                                  <Grid item xs={5}>
                                    <Typography sx={{ color: "rgba(255, 255, 255, 0.50)", fontSize: "13px", fontFamily: "Roboto", fontWeight: 700, lineHeight: "38px", wordWrap: "break-word" }}>
                                      Hull Cleaning Date
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={1}>
                                    <Box sx={{ color: "rgba(255, 255, 255, 0.30)", fontSize: "14px", fontFamily: "Roboto", fontWeight: 700, lineHeight: "38px", wordWrap: "break-word" }}>
                                      :
                                    </Box>
                                  </Grid>
                                  <Grid item xs={5}>
                                    <Typography sx={{ color: "rgba(255, 255, 255, 1)", fontSize: "14px", fontFamily: "Roboto", fontWeight: 400, lineHeight: "38px", wordWrap: "break-word" }}>
                                      {VesselMaster ? VesselMaster.HullCleaningDate : ""}
                                    </Typography>
                                  </Grid>
                                </Grid>
                              </Grid>

                              {/* Last Dock Date */}
                              <Grid item xs={12}>
                                <Grid container direction="row" >
                                  <Grid item xs={1}>
                                  </Grid>
                                  <Grid item xs={5}>
                                    <Typography sx={{ color: "rgba(255, 255, 255, 0.50)", fontSize: "13px", fontFamily: "Roboto", fontWeight: 700, lineHeight: "38px", wordWrap: "break-word" }}>
                                      Last Dock Date
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={1}>
                                    <Box sx={{ color: "rgba(255, 255, 255, 0.30)", fontSize: "14px", fontFamily: "Roboto", fontWeight: 700, lineHeight: "38px", wordWrap: "break-word" }}>
                                      :
                                    </Box>
                                  </Grid>
                                  <Grid item xs={5}>
                                    <Typography sx={{ color: "rgba(255, 255, 255, 1)", fontSize: "14px", fontFamily: "Roboto", fontWeight: 400, lineHeight: "38px", wordWrap: "break-word" }}>
                                      {VesselMaster ? VesselMaster.LastDockDate : ""}
                                    </Typography>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Stack>
                          :

                          <Stack sx={{ position: "relative", height: "330px", width: `${300 - 20}px` }}>
                            {loading && (
                              <CircularProgress
                                size={68}
                                sx={{
                                  color: "grey",
                                  position: 'absolute',
                                  top: "100px",
                                  left: "100px",
                                  zIndex: 1,
                                }}
                              />
                            )}
                          </Stack>
                        }
                      </>
                  }
                </Stack>
                {/* ↑ VesselInformation ↑ */}

              </Box>
              :
              <Box>
              </Box>
            }

            <Dialog
              open={openDialogBeaufort}
              sx={{
                "& .MuiDialog-container": {
                  "& .MuiPaper-root": {
                    width: "100%",
                    maxWidth: "770px",
                    height: "100%",
                    maxHeight: "270px",
                  },
                },
              }}
            >
              <DialogTitle
                sx={{
                  bgcolor: "#474C53",
                  height: "50px",
                  color: "white",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}>
                Select Beaufort
                <IconButton
                  aria-label="close"
                  onClick={handleNoCloseDialogBeaufort}
                  sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[1000],
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </DialogTitle>
              <DialogContent
                sx={{
                  background: "linear-gradient(180deg, #0E1B25 0%, #17232D 100%)",
                }}>
                <Box sx={{ height: "16px" }}></Box>

                {/* １行目 */}
                <Stack direction='row'>
                  <Grid container sx={{ width: "100px" }} alignItems={"center"} justifyContent={"flex-end"}>
                    < Grid item xs={6} >
                      <Checkbox
                        onChange={handleChangeBeaufortAll}
                        checked={BeaufortcheckAll}
                        sx={{ color: "#CBCBCB", '&.Mui-checked': { color: "#CBCBCB", }, }}
                      />
                    </Grid>
                    <Grid item xs={6} >
                      <Typography align="left" sx={{ marginLeft: "5px", color: 'rgba(255, 255, 255, 0.70)', fontSize: 14, fontFamily: 'Roboto', fontWeight: '400', wordWrap: 'break-word' }}>All</Typography>
                    </Grid>
                  </Grid>
                </Stack>

                {/* ２行目 */}
                <Stack direction='row' sx={{ marginLeft: "16px" }}>
                  <Grid container sx={{ width: "100px" }} alignItems={"center"} justifyContent={"flex-end"}>
                    < Grid item xs={6} >
                      <Checkbox
                        onChange={handleChangeBeaufort}
                        checked={Beaufortcheck[0].checked}
                        name={`${Beaufortcheck[0].name}`}
                        sx={{ color: "#CBCBCB", '&.Mui-checked': { color: "#CBCBCB", }, }}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Typography align="left" sx={{ marginLeft: "5px", color: 'rgba(255, 255, 255, 0.70)', fontSize: 14, fontFamily: 'Roboto', fontWeight: '400', wordWrap: 'break-word' }}>0</Typography>
                    </Grid>
                  </Grid>
                  <Grid container sx={{ width: "100px" }} alignItems={"center"} justifyContent={"flex-end"}>
                    < Grid item xs={6} >
                      <Checkbox
                        onChange={handleChangeBeaufort}
                        checked={Beaufortcheck[1].checked}
                        name={`${Beaufortcheck[1].name}`}
                        sx={{ color: "#CBCBCB", '&.Mui-checked': { color: "#CBCBCB", }, }}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Typography align="left" sx={{ marginLeft: "5px", color: 'rgba(255, 255, 255, 0.70)', fontSize: 14, fontFamily: 'Roboto', fontWeight: '400', wordWrap: 'break-word' }}>1</Typography>
                    </Grid>
                  </Grid>
                  <Grid container sx={{ width: "100px" }} alignItems={"center"} justifyContent={"flex-end"}>
                    < Grid item xs={6} >
                      <Checkbox
                        onChange={handleChangeBeaufort}
                        checked={Beaufortcheck[2].checked}
                        name={`${Beaufortcheck[2].name}`}
                        sx={{ color: "#CBCBCB", '&.Mui-checked': { color: "#CBCBCB", }, }}
                      />
                    </Grid>
                    <Grid item xs={6} >
                      <Typography align="left" sx={{ marginLeft: "5px", color: 'rgba(255, 255, 255, 0.70)', fontSize: 14, fontFamily: 'Roboto', fontWeight: '400', wordWrap: 'break-word' }}>2</Typography>
                    </Grid>
                  </Grid>
                  <Grid container sx={{ width: "100px" }} alignItems={"center"} justifyContent={"flex-end"}>
                    < Grid item xs={6} >
                      <Checkbox
                        onChange={handleChangeBeaufort}
                        checked={Beaufortcheck[3].checked}
                        name={`${Beaufortcheck[3].name}`}
                        sx={{ color: "#CBCBCB", '&.Mui-checked': { color: "#CBCBCB", }, }}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Typography align="left" sx={{ marginLeft: "5px", color: 'rgba(255, 255, 255, 0.70)', fontSize: 14, fontFamily: 'Roboto', fontWeight: '400', wordWrap: 'break-word' }}>3</Typography>
                    </Grid>
                  </Grid>
                  <Grid container sx={{ width: "100px" }} alignItems={"center"} justifyContent={"flex-end"}>
                    < Grid item xs={6} >
                      <Checkbox
                        onChange={handleChangeBeaufort}
                        checked={Beaufortcheck[4].checked}
                        name={`${Beaufortcheck[4].name}`}
                        sx={{ color: "#CBCBCB", '&.Mui-checked': { color: "#CBCBCB", }, }}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Typography align="left" sx={{ marginLeft: "5px", color: 'rgba(255, 255, 255, 0.70)', fontSize: 14, fontFamily: 'Roboto', fontWeight: '400', wordWrap: 'break-word' }}>4</Typography>
                    </Grid>
                  </Grid>
                  <Grid container sx={{ width: "100px" }} alignItems={"center"} justifyContent={"flex-end"}>
                    < Grid item xs={6} >
                      <Checkbox
                        onChange={handleChangeBeaufort}
                        checked={Beaufortcheck[5].checked}
                        name={`${Beaufortcheck[5].name}`}
                        sx={{ color: "#CBCBCB", '&.Mui-checked': { color: "#CBCBCB", }, }}
                      />
                    </Grid>
                    <Grid item xs={6} >
                      <Typography align="left" sx={{ marginLeft: "5px", color: 'rgba(255, 255, 255, 0.70)', fontSize: 14, fontFamily: 'Roboto', fontWeight: '400', wordWrap: 'break-word' }}>5</Typography>
                    </Grid>
                  </Grid>
                  <Grid container sx={{ width: "100px" }} alignItems={"center"} justifyContent={"flex-end"}>
                    < Grid item xs={6} >
                      <Checkbox
                        onChange={handleChangeBeaufort}
                        checked={Beaufortcheck[6].checked}
                        name={`${Beaufortcheck[6].name}`}
                        sx={{ color: "#CBCBCB", '&.Mui-checked': { color: "#CBCBCB", }, }}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Typography align="left" sx={{ marginLeft: "5px", color: 'rgba(255, 255, 255, 0.70)', fontSize: 14, fontFamily: 'Roboto', fontWeight: '400', wordWrap: 'break-word' }}>6</Typography>
                    </Grid>
                  </Grid>
                </Stack>

                {/* ３行目 */}
                <Stack direction='row' sx={{ marginLeft: "16px" }}>
                  <Grid container sx={{ width: "100px" }} alignItems={"center"} justifyContent={"flex-end"}>
                    < Grid item xs={6} >
                      <Checkbox
                        onChange={handleChangeBeaufort}
                        checked={Beaufortcheck[7].checked}
                        name={`${Beaufortcheck[7].name}`}
                        sx={{ color: "#CBCBCB", '&.Mui-checked': { color: "#CBCBCB", }, }}
                      />
                    </Grid>
                    <Grid item xs={6} >
                      <Typography align="left" sx={{ marginLeft: "5px", color: 'rgba(255, 255, 255, 0.70)', fontSize: 14, fontFamily: 'Roboto', fontWeight: '400', wordWrap: 'break-word' }}>7</Typography>
                    </Grid>
                  </Grid>
                  <Grid container sx={{ width: "100px" }} alignItems={"center"} justifyContent={"flex-end"}>
                    < Grid item xs={6} >
                      <Checkbox
                        onChange={handleChangeBeaufort}
                        checked={Beaufortcheck[8].checked}
                        name={`${Beaufortcheck[8].name}`}
                        sx={{ color: "#CBCBCB", '&.Mui-checked': { color: "#CBCBCB", }, }}
                      />
                    </Grid>
                    <Grid item xs={6} >
                      <Typography align="left" sx={{ marginLeft: "5px", color: 'rgba(255, 255, 255, 0.70)', fontSize: 14, fontFamily: 'Roboto', fontWeight: '400', wordWrap: 'break-word' }}>8</Typography>
                    </Grid>
                  </Grid>
                  <Grid container sx={{ width: "100px" }} alignItems={"center"} justifyContent={"flex-end"}>
                    < Grid item xs={6} >
                      <Checkbox
                        onChange={handleChangeBeaufort}
                        checked={Beaufortcheck[9].checked}
                        name={`${Beaufortcheck[9].name}`}
                        sx={{ color: "#CBCBCB", '&.Mui-checked': { color: "#CBCBCB", }, }}
                      />
                    </Grid>
                    <Grid item xs={6} >
                      <Typography align="left" sx={{ marginLeft: "5px", color: 'rgba(255, 255, 255, 0.70)', fontSize: 14, fontFamily: 'Roboto', fontWeight: '400', wordWrap: 'break-word' }}>9</Typography>
                    </Grid>
                  </Grid>
                  <Grid container sx={{ width: "100px" }} alignItems={"center"} justifyContent={"flex-end"}>
                    < Grid item xs={6} >
                      <Checkbox
                        onChange={handleChangeBeaufort}
                        checked={Beaufortcheck[10].checked}
                        name={`${Beaufortcheck[10].name}`}
                        sx={{ color: "#CBCBCB", '&.Mui-checked': { color: "#CBCBCB", }, }}
                      />
                    </Grid>
                    <Grid item xs={6} >
                      <Typography align="left" sx={{ marginLeft: "5px", color: 'rgba(255, 255, 255, 0.70)', fontSize: 14, fontFamily: 'Roboto', fontWeight: '400', wordWrap: 'break-word' }}>10</Typography>
                    </Grid>
                  </Grid>
                  <Grid container sx={{ width: "100px" }} alignItems={"center"} justifyContent={"flex-end"}>
                    < Grid item xs={6} >
                      <Checkbox
                        onChange={handleChangeBeaufort}
                        checked={Beaufortcheck[11].checked}
                        name={`${Beaufortcheck[11].name}`}
                        sx={{ color: "#CBCBCB", '&.Mui-checked': { color: "#CBCBCB", }, }}
                      />
                    </Grid>
                    <Grid item xs={6} >
                      <Typography align="left" sx={{ marginLeft: "5px", color: 'rgba(255, 255, 255, 0.70)', fontSize: 14, fontFamily: 'Roboto', fontWeight: '400', wordWrap: 'break-word' }}>11</Typography>
                    </Grid>
                  </Grid>
                  <Grid container sx={{ width: "100px" }} alignItems={"center"} justifyContent={"flex-end"}>
                    < Grid item xs={6} >
                      <Checkbox
                        onChange={handleChangeBeaufort}
                        checked={Beaufortcheck[12].checked}
                        name={`${Beaufortcheck[12].name}`}
                        sx={{ color: "#CBCBCB", '&.Mui-checked': { color: "#CBCBCB", }, }}
                      />
                    </Grid>
                    <Grid item xs={6} >
                      <Typography align="left" sx={{ marginLeft: "5px", color: 'rgba(255, 255, 255, 0.70)', fontSize: 14, fontFamily: 'Roboto', fontWeight: '400', wordWrap: 'break-word' }}>12</Typography>
                    </Grid>
                  </Grid>
                </Stack>

              </DialogContent>
              <DialogActions
                sx={{
                  height: "50px",
                  bgcolor: "#474C53",
                }}>
                <Button
                  sx={{
                    marginLeft: "0px",
                    marginRight: "-8px",
                    backgroundColor: "rgba(255, 255, 255, 0.5)",
                    width: "127px",
                    height: "50px",
                    padding: "0",
                    fontSize: "18px",
                    fontFamily: "'Roboto Bold', 'Meiryo', sans-serif",
                    fontWeight: "bold",
                    color: "white",
                    borderRadius: "0px",
                    ":hover": {
                      bgcolor: "#FFFFFF",
                      color: "#40475D",
                    },
                    textTransform: 'none'
                  }}
                  onClick={handleNoCloseDialogBeaufort}
                >
                  Cancel
                </Button>
                <Button
                  sx={{
                    marginLeft: "0px",
                    marginRight: "-8px",
                    backgroundColor: "#e66300",
                    width: "127px",
                    height: "50px",
                    padding: "0",
                    fontSize: "18px",
                    fontFamily: "'Roboto Bold', 'Meiryo', sans-serif",
                    fontWeight: "bold",
                    color: "white",
                    borderRadius: "0px",
                    borderBottomRightRadius: "2px",
                    ":hover": {
                      bgcolor: "#FFFFFF",
                      color: "#40475D",
                    },
                    textTransform: 'none'
                  }}
                  onClick={handleCloseDialogBeaufort}
                >
                  Save
                </Button>
              </DialogActions>
            </Dialog >
          </React.Fragment >

        </Drawer >

        {/* メイン部分 */}
        < Main >

          {/* ↓ OpenClose ↓ */}
          <Stack
            sx={{
              position: "absolute", top: "64px", left: `${openDrwer ? "345px" : "0px"}`, width: '10px', height: '20px',
              background: 'rgba(255, 255, 255, 0.25)',
              cursor: "pointer",
              transition: "0.5s",
            }}
            onClick={openDrwer ? handleDrawerClose : handleDrawerOpen}>
            <Box sx={{ position: "absolute", top: "7px", left: "2px", width: "6px", height: "6px", borderTop: "solid 2px #01103B", borderRight: "solid 2px #01103B", transform: `${openDrwer ? "rotate(225deg)}" : "rotate(45deg)}"}` }}></Box>
          </Stack>
          {/* ↑ OpenClose ↑ */}

          <Grid container spacing={2} sx={{height:"100%"}}>

            {/* ↓ メイン ↓ */}
            <Grid item xs={12}>

              <Stack
                sx={{
                  height: "100%",
                  width: "100%",
                  background: 'linear-gradient(180deg, rgba(45.82, 46.41, 47.81, 0.20) 0%, rgba(65.37, 66.21, 68.21, 0.20) 20%)',
                }}>

                {/* ↓ タイトル ↓ */}
                <Box sx={{ position: "relative", height: "40px" }}>
                  <Typography sx={{ position: "absolute", top: "10px", left: "10px", color: 'white', fontSize: "14px", fontFamily: "'Roboto Bold'", fontWeight: 'bold', wordWrap: 'break-word' }}>
                    Speed & Consumption Curve
                  </Typography>
                  {/* ↑ タイトル ↑ */}

                  {/* ↓ ダウンロードボタン ↓ */}
                  <Box sx={{position: "absolute", top: "10px", right: 0 , marginRight: "16px", display: "flex", justifyContent: "column", alignItems: "center",}}>
                    <Typography 
                      sx={{
                        position : "relative",
                        color : `${rows.length > 0 && rows[0]?.utc_date !== "" ? 'white' : 'rgba(255, 255, 255, 0.3)'}`,
                        fontSize: "14px",
                        fontFamily: "'Roboto Bold'",
                        fontWeight: 'bold',
                        wordWrap: 'break-word'
                      }}
                    >
                      CSV
                    </Typography>
                    <IconButton
                      disabled={rows.length > 0 && rows[0]?.utc_date !== "" ? false : true}
                      sx={{
                        opacity: `${rows.length > 0 && rows[0]?.utc_date !== "" ? 1 : 0.3}`,
                        position : "relative",
                        border: "solid 2px rgba(255, 255, 255, 0.7)",
                        backgroundColor: "rgba(255, 255, 255, 0.20)",
                        marginLeft: "10px",
                        padding: "5px",
                        ":hover": { backgroundColor: "rgba(255, 255, 255, 0.50)"}
                      }}
                      onClick={() => handleClickOpenDialogDownloadCSV()}
                    >
                        <img src={downloadIcon} alt="logo" style={{ width: "10px", height: "10px" }} />
                    </IconButton>
                  </Box>
                  {/* ↑ ダウンロードボタン ↑ */}
                </Box>

                {/* CSVダウンロードダイアログ */}
                <Dialog
                  open={openDialogDownloadCSV}
                  sx={{
                    "& .MuiDialog-container": {
                      "& .MuiPaper-root": {
                        width: "100%",
                        maxWidth: "450px",
                        height: "100%",
                        maxHeight: "240px",
                      },
                    },
                  }}
                >
                  <DialogTitle
                    sx={{
                      bgcolor: "#474C53",
                      height: "50px",
                      color: "white",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}>
                    Download CSV
                    <IconButton
                      aria-label="close"
                      onClick={handleNoCloseDialogDownloadCSV}
                      sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[1000],
                      }}
                    >
                      <CloseIcon />
                    </IconButton>
                  </DialogTitle>
                  <DialogContent
                    sx={{
                      background: "linear-gradient(180deg, #0E1B25 0%, #17232D 100%)",
                    }}>
                    <Box sx={{
                      position: 'absolute',
                      color: "white",
                      left: "110px",
                      top: "80px",
                    }}>
                      Data is exported as a csv file.
                    </Box>
                    <Box sx={{
                      position: 'absolute',
                      color: "white",
                      left: "140px",
                      top: "120px",
                    }}>
                      <Button
                        sx={{
                          backgroundColor: "#e66300",
                          width: "140px",
                          height: "40px",
                          fontSize: "14px",
                          fontFamily: "Roboto Bold",
                          fontWeight: "bold",
                          color: "white",
                          borderBottomRightRadius: "20px",
                          borderBottomLeftRadius: "20px",
                          borderTopRightRadius: "20px",
                          borderTopLeftRadius: "20px",
                          ":hover": {
                            bgcolor: "#FFFFFF",
                            color: "#40475D",
                          },
                          textTransform: 'none'
                        }}
                        onClick={handleCloseDialogDownloadCSV}
                      >
                        <img
                          src={downloadWhite}
                          alt="logo"
                          className="download-csv"
                        />
                        Download
                      </Button>
                    </Box>
                  </DialogContent>
                  <DialogActions
                    sx={{
                      height: "50px",
                      bgcolor: "#474C53"
                    }}>
                    <Button
                      sx={{
                        marginLeft: "0px",
                        marginRight: "-8px",
                        backgroundColor: "rgba(255, 255, 255, 0.5)",
                        width: "127px",
                        height: "50px",
                        padding: "0",
                        fontSize: "18px",
                        fontFamily: "'Roboto Bold', 'Meiryo', sans-serif",
                        fontWeight: "bold",
                        color: "white",
                        borderRadius: "0px",
                        ":hover": {
                          bgcolor: "#FFFFFF",
                          color: "#40475D",
                        },
                        textTransform: 'none'
                      }}
                      onClick={handleNoCloseDialogDownloadCSV}
                    >
                      Cancel
                    </Button>
                  </DialogActions>
                </Dialog>


                {/* ↓ メイン　ボディ ↓ */}
                <Box sx={{ position: "relative", height: "100%" }}>

                  <div className="mainContainer">

                    {/* ↓ LeftBoard ↓ */}
                    <div className="mainChart">
                      <Stack>
                        {/* ↓ Highchartsグラフ ↓ */}
                        <Box sx={{ marginLeft: 0.5, marginTop: 1 }}>
                          {success ?
                            <>
                              <Box sx={{ position: "relative" }}>
                                <Box>
                                  <HighchartsReact
                                    key={key}
                                    highcharts={Highcharts}
                                    options={hcOptions}
                                    ref={chartComponentRef}
                                    allowChartUpdate={true}
                                  />
                                </Box>

                                {/* Y軸設定ダイアログ */}
                                <Box
                                  sx={{
                                    position: "absolute",
                                    top: "-2px",
                                    left: "8px",
                                    width: "20px",
                                    height: "20px",
                                    background: "rgb(255,255,255,0.3)",
                                    // background: "linear-gradient(to bottom, #ff0000 0%, #ff0000 70%, #0000ff 70%, #0000ff 100%)",
                                    cursor: "pointer",
                                    borderRadius: "50%",
                                    border: "2px solid rgb(255,255,255,0.7)",
                                    zIndex: 10,

                                  }}
                                  onClick={handleClickOpenDialogAxis}
                                >
                                  <SettingsIcon sx={{ position: "absolute", top: "1px", left: "1px", width: "14px", height: "14px", color: "rgb(255,255,255,0.7)" }} />
                                </Box>


                                <Dialog
                                  open={openDialogyAxis}
                                  sx={{
                                    "& .MuiDialog-container": {
                                      "& .MuiPaper-root": {
                                        width: "100%",
                                        maxWidth: "500px",
                                        height: "100%",
                                        maxHeight: "320px",
                                      },
                                    },
                                  }}
                                >
                                  <DialogTitle
                                    sx={{
                                      bgcolor: "#474C53",
                                      height: "50px",
                                      color: "white",
                                      display: "flex",
                                      justifyContent: "space-between",
                                      alignItems: "center",
                                    }}>
                                    Axis Setting
                                    <IconButton
                                      aria-label="close"
                                      onClick={handleNoClickOpenDialogAxis}
                                      sx={{
                                        position: 'absolute',
                                        right: 8,
                                        top: 8,
                                        color: (theme) => theme.palette.grey[1000],
                                      }}
                                    >
                                      <CloseIcon />
                                    </IconButton>
                                  </DialogTitle>
                                  <DialogContent
                                    sx={{
                                      background: "linear-gradient(180deg, #0E1B25 0%, #17232D 100%)",
                                    }}>

                                    <Box sx={{ position: "relative" }}>
                                      <Box sx={{ position: "absolute", top: "60px", left: "20px", height: "1px", width: "400px", borderRadius: '0px ', border: "1px dotted #A4A7B1" }}></Box>
                                      <Typography align="center"
                                        sx={{
                                          position: "absolute", top: "30px", left: "40px",
                                          color: 'rgba(255, 255, 255, 0.70)', fontSize: 13, fontFamily: 'Roboto Condensed', fontWeight: '400', lineHeight: "24px", wordWrap: 'break-word'
                                        }}>
                                        Channel Name
                                      </Typography>
                                      <Typography align="center"
                                        sx={{
                                          position: "absolute", top: "30px", left: "240px",
                                          color: 'rgba(255, 255, 255, 0.70)', fontSize: 13, fontFamily: 'Roboto Condensed', fontWeight: '400', lineHeight: "24px", wordWrap: 'break-word'
                                        }}>
                                        Min
                                      </Typography>
                                      <Typography align="center"
                                        sx={{
                                          position: "absolute", top: "30px", left: "350px",
                                          color: 'rgba(255, 255, 255, 0.70)', fontSize: 13, fontFamily: 'Roboto Condensed', fontWeight: '400', lineHeight: "24px", wordWrap: 'break-word'
                                        }}>
                                        Max
                                      </Typography>
                                    </Box>
                                    <Box sx={{ position: "relative" }}>
                                      <Stack direction={"row"}>
                                        <Typography align="left"
                                          sx={{
                                            position: "absolute", top: "90px", left: "70px",
                                            color: 'rgba(255, 255, 255, 0.70)', fontSize: 13, fontFamily: 'Roboto Condensed', fontWeight: '400', lineHeight: "24px", wordWrap: 'break-word'
                                          }}>
                                          Log Speed
                                        </Typography>
                                        <FormControl>
                                          <Input
                                            value={xAxisMinShow} onChange={(event: React.ChangeEvent<HTMLInputElement>) => { handleClickxAxisMinShow(event.target.value) }}
                                            type='tel'
                                            sx={{
                                              position: "absolute", top: "80px", left: "200px", width: "100px", fontFamily: 'Roboto', fontWeight: '700', wordWrap: 'break-word', border: '0px grey solid', color: "white", fontSize: "15px",
                                              "& .MuiInput-input": { height: "30px", borderRadius: '0px', border: '0px white solid', background: "rgba(255, 255, 255, 0.15)", textAlign: "center", },
                                              ":hover": { backgroundColor: "#7a86a0", border: '0px #CBCBCB solid', borderBottom: "0px solid white", },
                                              ":hover:after": { borderBottom: "0px solid green", },
                                              ":hover:not(.Mui-disabled, .Mui-error):before": { borderBottom: "2px solid white", },
                                              ":after": { borderBottom: "2px solid white", },
                                              ":before": { borderBottom: "2px solid #A4A7B1", },
                                            }} />
                                        </FormControl>
                                        <Box sx={{ position: "absolute", top: "100px", left: "300px", height: "1px", width: "20px", borderRadius: '0px ', border: "1px solid #A4A7B1" }}></Box>
                                        <FormControl>
                                          <Input
                                            value={xAxisMaxShow} onChange={(event: React.ChangeEvent<HTMLInputElement>) => { handleClickxAxisMaxShow(event.target.value) }}
                                            type='tel'
                                            sx={{
                                              position: "absolute", top: "80px", left: "320px", width: "100px", fontFamily: 'Roboto', fontWeight: '700', wordWrap: 'break-word', border: '0px grey solid', color: "white", fontSize: "15px",
                                              "& .MuiInput-input": { height: "30px", borderRadius: '0px', border: '0px white solid', background: "rgba(255, 255, 255, 0.15)", textAlign: "center", },
                                              ":hover": { backgroundColor: "#7a86a0", border: '0px #CBCBCB solid', borderBottom: "0px solid white", },
                                              ":hover:after": { borderBottom: "0px solid green", },
                                              ":hover:not(.Mui-disabled, .Mui-error):before": { borderBottom: "2px solid white", },
                                              ":after": { borderBottom: "2px solid white", },
                                              ":before": { borderBottom: "2px solid #A4A7B1", },
                                            }} />
                                        </FormControl>
                                      </Stack>
                                    </Box>
                                    <Box sx={{ position: "relative" }}>
                                      <Stack direction={"row"}>
                                        <Typography align="left"
                                          sx={{
                                            position: "absolute", top: "150px", left: "70px",
                                            color: 'rgba(255, 255, 255, 0.70)', fontSize: 13, fontFamily: 'Roboto Condensed', fontWeight: '400', lineHeight: "24px", wordWrap: 'break-word'
                                          }}>
                                          M/E FOC
                                        </Typography>
                                        <FormControl>
                                          <Input
                                            value={yAxisMinShow} onChange={(event: React.ChangeEvent<HTMLInputElement>) => { handleClickyAxisMinShow(event.target.value) }}
                                            type='tel'
                                            sx={{
                                              position: "absolute", top: "140px", left: "200px", width: "100px", fontFamily: 'Roboto', fontWeight: '700', wordWrap: 'break-word', border: '0px grey solid', color: "white", fontSize: "15px",
                                              "& .MuiInput-input": { height: "30px", borderRadius: '0px', border: '0px white solid', background: "rgba(255, 255, 255, 0.15)", textAlign: "center", },
                                              ":hover": { backgroundColor: "#7a86a0", border: '0px #CBCBCB solid', borderBottom: "0px solid white", },
                                              ":hover:after": { borderBottom: "0px solid green", },
                                              ":hover:not(.Mui-disabled, .Mui-error):before": { borderBottom: "2px solid white", },
                                              ":after": { borderBottom: "2px solid white", },
                                              ":before": { borderBottom: "2px solid #A4A7B1", },
                                            }} />
                                        </FormControl>
                                        <Box sx={{ position: "absolute", top: "160px", left: "300px", height: "1px", width: "20px", borderRadius: '0px ', border: "1px solid #A4A7B1" }}></Box>
                                        <FormControl>
                                          <Input
                                            value={yAxisMaxShow} onChange={(event: React.ChangeEvent<HTMLInputElement>) => { handleClickyAxisMaxShow(event.target.value) }}
                                            type='tel'
                                            sx={{
                                              position: "absolute", top: "140px", left: "320px", width: "100px", fontFamily: 'Roboto', fontWeight: '700', wordWrap: 'break-word', border: '0px grey solid', color: "white", fontSize: "15px",
                                              "& .MuiInput-input": { height: "30px", borderRadius: '0px', border: '0px white solid', background: "rgba(255, 255, 255, 0.15)", textAlign: "center", },
                                              ":hover": { backgroundColor: "#7a86a0", border: '0px #CBCBCB solid', borderBottom: "0px solid white", },
                                              ":hover:after": { borderBottom: "0px solid green", },
                                              ":hover:not(.Mui-disabled, .Mui-error):before": { borderBottom: "2px solid white", },
                                              ":after": { borderBottom: "2px solid white", },
                                              ":before": { borderBottom: "2px solid #A4A7B1", },
                                            }} />
                                        </FormControl>
                                      </Stack>
                                    </Box>
                                  </DialogContent>
                                  <DialogActions
                                    sx={{
                                      height: "50px",
                                      bgcolor: "#474C53",
                                    }}>
                                    <Button
                                      sx={{
                                        marginLeft: "0px",
                                        marginRight: "-8px",
                                        backgroundColor: "rgba(255, 255, 255, 0.5)",
                                        width: "127px",
                                        height: "50px",
                                        padding: "0",
                                        fontSize: "18px",
                                        fontFamily: "'Roboto Bold', 'Meiryo', sans-serif",
                                        fontWeight: "bold",
                                        color: "white",
                                        borderRadius: "0px",
                                        ":hover": {
                                          bgcolor: "#FFFFFF",
                                          color: "#40475D",
                                        },
                                        textTransform: 'none'
                                      }}
                                      onClick={handleNoClickOpenDialogAxis}
                                    >
                                      Cancel
                                    </Button>
                                    <Button
                                      sx={{
                                        marginLeft: "0px",
                                        marginRight: "-8px",
                                        backgroundColor: "#e66300",
                                        width: "127px",
                                        height: "50px",
                                        padding: "0",
                                        fontSize: "18px",
                                        fontFamily: "'Roboto Bold', 'Meiryo', sans-serif",
                                        fontWeight: "bold",
                                        color: "white",
                                        borderRadius: "0px",
                                        ":hover": {
                                          bgcolor: "#FFFFFF",
                                          color: "#40475D",
                                        },
                                        textTransform: 'none'
                                      }}
                                      onClick={handleClickSetAxis}
                                    >
                                      Save
                                    </Button>
                                  </DialogActions>
                                </Dialog>
                              </Box>
                            </>
                            :
                            <Box
                              sx={{ height: 425 }}>
                              {loading && (
                                <CircularProgress
                                  size={68}
                                  sx={{
                                    color: "grey",
                                    position: 'absolute',
                                    top: 300,
                                    left: 400,
                                    zIndex: 1,
                                  }}
                                />
                              )}
                            </Box>
                          }
                        </Box>
                        {/* ↑ Highchartsグラフ ↑ */}
                      </Stack>
                    </div>
                    {/* ↑ LeftBoard ↑ */}


                    {/* RightBoard */}
                    <div className="side">

                      {/* ↓ Speed Consumption Graph 凡例 ↓ */}
                      <Stack sx={{ height: "calc(75vh - 371px)", marginLeft: "0px", marginRight: "16px", marginTop: "20px", marginBottom: "0px", borderRadius: "10px" }}>

                        <Stack>
                          <Typography sx={{ color: 'rgba(255, 255, 255, 1)', fontSize: "13px", fontFamily: 'Roboto', fontWeight: 700, wordWrap: 'break-word' }} >
                            ＜ Legend ＞
                          </Typography>
                        </Stack>

                        {success ?
                          <Box 
                            sx={{
                              marginTop: "16px",
                              overflowY: 'auto',
                              '&::-webkit-scrollbar': {
                                width: "6px",
                                height: "6px",
                              },
                              '&::-webkit-scrollbar-track': {
                                borderRadius: "10px",
                                background: "rgba(255, 255, 255, 0.5)",
                              },
                              '&::-webkit-scrollbar-thumb': {
                                background: "#fff",
                                borderRadius: "10px",
                              },
                              '&::-webkit-scrollbar-thumb:hover': {
                                backgroundColor: '#555',
                              },
                              "&::-webkit-scrollbar-corner": {
                                display: "none",
                                }
                              }}>
                            {SpeedConsumptionCurveTrendLine !== undefined && SpeedConsumptionCurveTrendLine.name !== "" ?
                              <Stack
                                direction='row'
                                sx={{
                                  marginLeft: "16px",
                                  marginTop: "0px",
                                  opacity: 0.80,
                                  color: 'rgba(255, 255, 255, 0.50)',
                                  fontSize: 13,
                                  fontFamily: 'Roboto',
                                  fontWeight: '700',
                                  wordWrap: 'break-word',
                                  background: 'linear-gradient(10deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.25) 100%)',
                                }}>
                                <Grid item xs={1} container alignItems={"center"} justifyContent={"flex-start"}>
                                  <AntSwitch onChange={handleChange} checked={SpeedConsumptionCurveTrendLine.checked} name={SpeedConsumptionCurveTrendLine.name} ToggleColor="#D8FFF8"></AntSwitch>
                                </Grid>
                                <Grid item xs={3} container alignItems={"center"} justifyContent={"flex-start"}>
                                  <Typography align="left" sx={{ marginLeft: "5px", color: 'rgba(255, 255, 255, 0.70)', fontSize: 14, fontFamily: 'Roboto', fontWeight: '400', wordWrap: 'break-word' }}>
                                    {SpeedConsumptionCurveTrendLine.name}
                                  </Typography>
                                </Grid>
                                <Grid item xs={8} container alignItems={"center"} justifyContent={"flex-start"}>
                                  <Typography align="left" sx={{ marginLeft: "5px", color: 'rgba(255, 255, 255, 0.70)', fontSize: 14, fontFamily: 'Roboto', fontWeight: '400', wordWrap: 'break-word' }}>
                                    y = {SpeedConsumptionCurveTrendLine.formula.a}x^2.85
                                  </Typography>
                                </Grid>
                              </Stack>
                              :
                              <Stack
                                direction='row'
                                sx={{
                                  marginLeft: "16px",
                                  marginTop: "0px",
                                  opacity: 0.80,
                                  color: 'rgba(255, 255, 255, 0.50)',
                                  fontSize: 13,
                                  fontFamily: 'Roboto',
                                  fontWeight: '700',
                                  wordWrap: 'break-word',
                                  background: 'linear-gradient(10deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.25) 100%)',
                                }}>
                                <Grid item xs={1} container alignItems={"center"} justifyContent={"flex-start"}>
                                  <AntSwitch inputProps={{ 'aria-label': 'ant design' }} checked={false} name={"Not exist"} ToggleColor="#D8FFF8"></AntSwitch>
                                </Grid>
                                <Grid item xs={11} container alignItems={"center"} justifyContent={"flex-start"}>
                                  <Typography align="left" sx={{ marginLeft: "5px", color: 'rgba(255, 255, 255, 0.70)', fontSize: 14, fontFamily: 'Roboto', fontWeight: '400', wordWrap: 'break-word' }}>
                                    Does not exist according to the specified search criteria
                                  </Typography>
                                </Grid>
                              </Stack>
                            }
                            {SpeedConsumptionCurve?.length !== 0 ?
                              SpeedConsumptionCurve?.map((data, index) => (
                                <Stack
                                  direction='row'
                                  sx={{
                                    marginLeft: "16px",
                                    marginTop: "4px",
                                    opacity: 0.80,
                                    color: 'rgba(255, 255, 255, 0.50)',
                                    fontSize: 13,
                                    fontFamily: 'Roboto',
                                    fontWeight: '700',
                                    wordWrap: 'break-word',
                                    background: (index % 2 === 0) ? "" : 'linear-gradient(10deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.25) 100%)'
                                  }}>
                                  <Grid container>
                                    <Grid item xs={1} container alignItems={"center"} justifyContent={"flex-start"}>
                                      <AntSwitch inputProps={{ 'aria-label': 'ant design' }} onChange={handleChange} checked={data.checked} name={data.name} ToggleColor={data.color} ></AntSwitch>
                                    </Grid>
                                    <Grid item xs={3} container alignItems={"center"} justifyContent={"flex-start"}>
                                      <Typography align="left" sx={{ marginLeft: "5px", color: 'rgba(255, 255, 255, 0.70)', fontSize: 14, fontFamily: 'Roboto', fontWeight: '400', wordWrap: 'break-word' }}>
                                        {data.name}
                                      </Typography>
                                    </Grid>
                                    <Grid item xs={8} container alignItems={"center"} justifyContent={"flex-start"}>
                                      <Typography align="left" sx={{ marginLeft: "5px", color: 'rgba(255, 255, 255, 0.70)', fontSize: 14, fontFamily: 'Roboto', fontWeight: '400', wordWrap: 'break-word' }}>                                      
                                        {data.description === "" ? 
                                          data.formula.C !== 0 ?
                                            "y = " + data.formula.alpha + "*x^" + data.formula.a + "+ " + data.formula.C
                                              : "y = " + data.formula.alpha + "*x^" + data.formula.a
                                        : data.description}
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                </Stack>
                              ))
                              :
                              ""
                            }
                          </Box>
                          :
                          <Box
                            sx={{ height: 270 }}>
                            {loading && (
                              <CircularProgress
                                size={68}
                                sx={{
                                  color: "grey",
                                  position: 'absolute',
                                  top: 150,
                                  left: 1200,
                                  zIndex: 1,
                                }}
                              />
                            )}
                          </Box>
                        }

                      </Stack>
                      {/* ↑ Speed Consumption Graph 凡例 ↑ */}


                      {/* ↓ Point Information ↓ */}
                      <Stack sx={{ marginTop: "24px", borderRadius: "10px", height:"372px"}}>
                        <Stack direction='column' spacing={0} alignItems="flex-start" justifyContent="flex-start" >

                          {/* Title */}
                          <Stack
                            sx={{
                              height: "30px",
                              marginBottom: "0px",
                              // background:"linear-gradient(352deg, rgba(255, 255, 255, 0.01) 1.98%, rgba(255, 255, 255, 0.03) 89.82%)", 
                              borderRadius: "5px"
                            }} >
                            <Grid container direction="row" >
                              <Grid item xs={12}>
                                <Stack direction='column' sx={{ marginLeft: "8px", height: "30px" }} alignItems="flex-start" justifyContent="center">
                                  <Typography sx={{ color: 'rgba(255, 255, 255, 1)', fontSize: "13px", fontFamily: 'Roboto', fontWeight: 700, wordWrap: 'break-word' }} >
                                    ＜ Point Information ＞
                                  </Typography>
                                </Stack>
                              </Grid>
                            </Grid>
                          </Stack>
                          {
                            success ?
                              <>

                                < Grid container direction={"row"}>
                                  {/* ↓ Voyage ↓ */}
                                  < Grid item xs={6.5}>

                                    {/* utc date */}
                                    <Stack
                                      sx={{
                                        height: "30px",
                                        marginLeft: "16px",
                                        marginRight: "8px",
                                        marginBottom: "8px",
                                        // background:"linear-gradient(352deg, rgba(255, 255, 255, 0.01) 1.98%, rgba(255, 255, 255, 0.03) 89.82%)", 
                                        borderRadius: "5px"
                                      }} >
                                      <Grid container direction="row" >
                                        <Grid item xs={1}>
                                          <Stack direction='column' sx={{ marginLeft: "8px", height: "30px" }} alignItems="center" justifyContent="center">
                                            <Box sx={{ color: 'white' }}>
                                              <img src={DateIcon_svg} alt="iconLike" style={{ height: "20px", width: "20px" }} />
                                            </Box>
                                          </Stack>
                                        </Grid>
                                        <Grid item xs={11}>
                                          <Stack direction='column' sx={{ marginLeft: "16px", height: "30px" }} alignItems="flex-start" justifyContent="center">
                                            <Typography sx={{ color: 'rgba(255, 255, 255, 0.7)', fontSize: "14px", fontFamily: "'Roboto Regular'", wordWrap: 'break-word' }} >
                                              {plotInformation.utc_date === "" ? "" : plotInformation.utc_date}
                                            </Typography>
                                          </Stack>
                                        </Grid>
                                      </Grid>
                                    </Stack>

                                    {/* Emission */}
                                    <Stack
                                      sx={{
                                        height: "100px",
                                        width: "325px",
                                        marginTop: "8px",
                                        marginLeft: "16px",
                                        marginRight: "8px",
                                        marginBottom: "8px",
                                        background: "linear-gradient(352deg, rgba(255, 255, 255, 0.01) 1.98%, rgba(255, 255, 255, 0.03) 89.82%)",
                                        borderRadius: "5px"
                                      }} >
                                      <Grid container direction="row" >
                                        <Grid item xs={1}>
                                          <Stack direction='column' sx={{ marginLeft: "8px", height: "100px" }} alignItems="center" justifyContent="center">
                                            <Box sx={{ color: 'white' }}>
                                              <img src={OilIcon_svg} alt="iconLike" style={{ height: "20px", width: "20px" }} />
                                            </Box>
                                          </Stack>
                                        </Grid>
                                        <Grid item xs={5}>
                                          <Stack direction='column' sx={{ marginLeft: "16px", height: "100px" }} alignItems="flex-start" justifyContent="center">
                                            <Typography sx={{ marginTop: "4px", marginBottom: "4px", color: 'rgba(255, 255, 255, 0.50)', fontSize: 13, fontFamily: 'Roboto', fontWeight: '700', wordWrap: 'break-word' }}>
                                              FOC
                                            </Typography>
                                            <Typography sx={{ marginTop: "4px", marginBottom: "4px", color: 'rgba(255, 255, 255, 0.50)', fontSize: 13, fontFamily: 'Roboto', fontWeight: '700', wordWrap: 'break-word' }}>
                                              M/E FOC
                                            </Typography>
                                            <Typography sx={{ marginTop: "4px", marginBottom: "4px", color: 'rgba(255, 255, 255, 0.50)', fontSize: 13, fontFamily: 'Roboto', fontWeight: '700', wordWrap: 'break-word' }}>
                                              CO2
                                            </Typography>
                                          </Stack>
                                        </Grid>
                                        <Grid item xs={3}>
                                          <Stack direction='column' sx={{ marginLeft: "8px", height: "100px" }} alignItems="flex-end" justifyContent="center">
                                            <Typography sx={{ marginTop: "4px", marginBottom: "4px", color: 'rgba(255, 255, 255, 1)', fontSize: "15px", fontFamily: "'Roboto Regular'", wordWrap: 'break-word' }}>
                                              {isNaN(plotInformation.foc) ? "-" : formatNumber(plotInformation.foc)}
                                            </Typography>
                                            <Typography sx={{ marginTop: "4px", marginBottom: "4px", color: 'rgba(255, 255, 255, 1)', fontSize: "15px", fontFamily: "'Roboto Regular'", wordWrap: 'break-word' }}>
                                              {isNaN(plotInformation.me_foc) ? "-" : formatNumber(plotInformation.me_foc)}
                                            </Typography>
                                            <Typography sx={{ marginTop: "4px", marginBottom: "4px", color: 'rgba(255, 255, 255, 1)', fontSize: "15px", fontFamily: "'Roboto Regular'", wordWrap: 'break-word' }}>
                                              {isNaN(plotInformation.co2) ? "-" : formatNumber(plotInformation.co2)}
                                            </Typography>
                                          </Stack>
                                        </Grid>
                                        <Grid item xs={3}>
                                          <Stack direction='column' sx={{ marginLeft: "8px", height: "100px" }} alignItems="flex-start" justifyContent="center">
                                            <Typography sx={{ marginTop: "4px", marginBottom: "4px", color: 'rgba(255, 255, 255, 0.5)', fontSize: "14px", fontFamily: "'Roboto Condensed Regular'", wordWrap: 'break-word' }}>
                                              MT/day
                                            </Typography>
                                            <Typography sx={{ marginTop: "4px", marginBottom: "4px", color: 'rgba(255, 255, 255, 0.5)', fontSize: "14px", fontFamily: "'Roboto Condensed Regular'", wordWrap: 'break-word' }}>
                                              MT/day
                                            </Typography>
                                            <Typography sx={{ marginTop: "4px", marginBottom: "4px", color: 'rgba(255, 255, 255, 0.5)', fontSize: "14px", fontFamily: "'Roboto Condensed Regular'", wordWrap: 'break-word' }}>
                                              MT/day
                                            </Typography>
                                          </Stack>
                                        </Grid>
                                      </Grid>
                                    </Stack>

                                    {/* M/E */}
                                    <Stack
                                      sx={{
                                        height: "100px",
                                        width: "325px",
                                        marginTop: "8px",
                                        marginLeft: "16px",
                                        marginRight: "8px",
                                        marginBottom: "8px",
                                        background: "linear-gradient(352deg, rgba(255, 255, 255, 0.01) 1.98%, rgba(255, 255, 255, 0.03) 89.82%)",
                                        borderRadius: "5px"
                                      }} >
                                      <Grid container direction="row" >
                                        <Grid item xs={1}>
                                          <Stack direction='column' sx={{ marginLeft: "8px", height: "100px" }} alignItems="center" justifyContent="center">
                                            <Box sx={{ color: 'white' }}>
                                              <img src={EngineIcon_svg} alt="iconLike" style={{ height: "20px", width: "20px" }} />
                                            </Box>
                                          </Stack>
                                        </Grid>
                                        <Grid item xs={5}>
                                          <Stack direction='column' sx={{ marginLeft: "16px", height: "100px" }} alignItems="flex-start" justifyContent="center">
                                            <Typography sx={{ marginTop: "4px", marginBottom: "4px", color: 'rgba(255, 255, 255, 0.50)', fontSize: 13, fontFamily: 'Roboto', fontWeight: '700', wordWrap: 'break-word' }}>
                                              Avg. LOG Speed
                                            </Typography>
                                            <Typography sx={{ marginTop: "4px", marginBottom: "4px", color: 'rgba(255, 255, 255, 0.50)', fontSize: 13, fontFamily: 'Roboto', fontWeight: '700', wordWrap: 'break-word' }}>
                                              Avg. M/E Load
                                            </Typography>
                                            <Typography sx={{ marginTop: "4px", marginBottom: "4px", color: 'rgba(255, 255, 255, 0.50)', fontSize: 13, fontFamily: 'Roboto', fontWeight: '700', wordWrap: 'break-word' }}>
                                              Avg. M/E RPM
                                            </Typography>
                                          </Stack>
                                        </Grid>
                                        <Grid item xs={3}>
                                          <Stack direction='column' sx={{ marginLeft: "8px", height: "100px" }} alignItems="flex-end" justifyContent="center">
                                            <Typography sx={{ marginTop: "4px", marginBottom: "4px", color: 'rgba(255, 255, 255, 1)', fontSize: "15px", fontFamily: "'Roboto Regular'", wordWrap: 'break-word' }}>
                                              {isNaN(plotInformation.log_speed) ? "-" : formatNumber(plotInformation.log_speed)}
                                            </Typography>
                                            <Typography sx={{ marginTop: "4px", marginBottom: "4px", color: 'rgba(255, 255, 255, 1)', fontSize: "15px", fontFamily: "'Roboto Regular'", wordWrap: 'break-word' }}>
                                              {isNaN(plotInformation.me_load) ? "-" : formatNumber(plotInformation.me_load)}
                                            </Typography>
                                            <Typography sx={{ marginTop: "4px", marginBottom: "4px", color: 'rgba(255, 255, 255, 1)', fontSize: "15px", fontFamily: "'Roboto Regular'", wordWrap: 'break-word' }}>
                                              {isNaN(plotInformation.me_rpm) ? "-" : formatNumber(plotInformation.me_rpm)}
                                            </Typography>
                                          </Stack>
                                        </Grid>
                                        <Grid item xs={3}>
                                          <Stack direction='column' sx={{ marginLeft: "8px", height: "100px" }} alignItems="flex-start" justifyContent="center">
                                            <Typography sx={{ marginTop: "4px", marginBottom: "4px", color: 'rgba(255, 255, 255, 0.5)', fontSize: "14px", fontFamily: "'Roboto Condensed Regular'", wordWrap: 'break-word' }}>
                                              knot
                                            </Typography>
                                            <Typography sx={{ marginTop: "4px", marginBottom: "4px", color: 'rgba(255, 255, 255, 0.5)', fontSize: "14px", fontFamily: "'Roboto Condensed Regular'", wordWrap: 'break-word' }}>
                                              %
                                            </Typography>
                                            <Typography sx={{ marginTop: "4px", marginBottom: "4px", color: 'rgba(255, 255, 255, 0.5)', fontSize: "14px", fontFamily: "'Roboto Condensed Regular'", wordWrap: 'break-word' }}>
                                              rpm
                                            </Typography>
                                          </Stack>
                                        </Grid>
                                      </Grid>
                                    </Stack>

                                    {/* Distance, Displacement */}
                                    <Stack
                                      sx={{
                                        height: "80px",
                                        width: "325px",
                                        marginTop: "8px",
                                        marginLeft: "16px",
                                        marginRight: "8px",
                                        marginBottom: "8px",
                                        background: "linear-gradient(352deg, rgba(255, 255, 255, 0.01) 1.98%, rgba(255, 255, 255, 0.03) 89.82%)",
                                        borderRadius: "5px"
                                      }} >
                                      <Grid container direction="row" >
                                        <Grid item xs={1}>
                                          <Stack direction='column' sx={{ marginLeft: "8px", height: "80px" }} alignItems="center" justifyContent="center">
                                            <Box sx={{ color: 'white' }}>
                                              <img src={ShipIcon_svg} alt="iconLike" style={{ height: "25px", width: "25px" }} />
                                            </Box>
                                          </Stack>
                                        </Grid>
                                        <Grid item xs={5}>
                                          <Stack direction='column' sx={{ marginLeft: "16px", height: "80px" }} alignItems="flex-start" justifyContent="center">
                                            <Typography sx={{ marginTop: "4px", marginBottom: "4px", color: 'rgba(255, 255, 255, 0.50)', fontSize: 13, fontFamily: 'Roboto', fontWeight: '700', wordWrap: 'break-word' }}>
                                              Distance
                                            </Typography>
                                            <Typography sx={{ marginTop: "4px", marginBottom: "4px", color: 'rgba(255, 255, 255, 0.50)', fontSize: 13, fontFamily: 'Roboto', fontWeight: '700', wordWrap: 'break-word' }}>
                                              Displacement
                                            </Typography>
                                          </Stack>
                                        </Grid>
                                        <Grid item xs={3}>
                                          <Stack direction='column' sx={{ marginLeft: 1, height: "80px" }} alignItems="flex-end" justifyContent="center">
                                            <Typography sx={{ marginTop: "4px", marginBottom: "4px", color: 'rgba(255, 255, 255, 1)', fontSize: "15px", fontFamily: "'Roboto Regular'", wordWrap: 'break-word' }}>
                                              {isNaN(plotInformation.distance) ? "-" : formatNumber(plotInformation.distance)}
                                            </Typography>
                                            <Typography sx={{ marginTop: "4px", marginBottom: "4px", color: 'rgba(255, 255, 255, 1)', fontSize: "15px", fontFamily: "'Roboto Regular'", wordWrap: 'break-word' }}>
                                              {isNaN(plotInformation.displacement) ? "-" : formatNumber(plotInformation.displacement)}
                                            </Typography>
                                          </Stack>
                                        </Grid>
                                        <Grid item xs={3}>
                                          <Stack direction='column' sx={{ marginLeft: 1, height: "80px" }} alignItems="flex-start" justifyContent="center">
                                            <Typography sx={{ marginTop: "4px", marginBottom: "4px", color: 'rgba(255, 255, 255, 0.5)', fontSize: "14px", fontFamily: "'Roboto Condensed Regular'", wordWrap: 'break-word' }}>
                                              NM/day
                                            </Typography>
                                            <Typography sx={{ marginTop: "4px", marginBottom: "4px", color: 'rgba(255, 255, 255, 0.5)', fontSize: "14px", fontFamily: "'Roboto Condensed Regular'", wordWrap: 'break-word' }}>
                                              ton
                                            </Typography>
                                          </Stack>
                                        </Grid>
                                      </Grid>
                                    </Stack>
                                  </Grid>
                                  {/* ↑ Voyage ↑ */}

                                  {/* ↓ Weather ↓ */}
                                  <Grid item xs={5.5}>
                                    {/* utc_dateの間隔を空ける */}
                                    <Stack direction='row' alignItems="center" justifyContent="flex-start" sx={{ height: "30px", marginBottom: "0px" }} ></Stack>

                                    {/* Wind */}
                                    <Stack
                                      sx={{
                                        height: "90px",
                                        width: "260px",
                                        marginLeft: "16px",
                                        marginRight: "16px",
                                        marginTop: "8px",
                                        marginBottom: "8px",
                                        background: "linear-gradient(352deg, rgba(255, 255, 255, 0.01) 1.98%, rgba(255, 255, 255, 0.03) 89.82%)",
                                        borderRadius: "5px"
                                      }} >
                                      <Grid container direction="row" >
                                        <Grid item xs={3}>
                                          <Stack direction='column' sx={{ marginLeft: "8px", height: "90px", position: 'relative', opacity: 0.60 }} >
                                            <AirIcon
                                              sx={{
                                                position: 'absolute',
                                                color: 'white',
                                                top: "30px",
                                                opacity: 0.7,
                                              }} />
                                            <Typography sx={{
                                              position: 'absolute',
                                              color: 'rgba(255, 255, 255, 0.50)',
                                              fontSize: 13,
                                              fontFamily: 'Roboto',
                                              fontWeight: '700',
                                              wordWrap: 'break-word',
                                              top: "55px",
                                            }}>
                                              Wind
                                            </Typography>
                                          </Stack>
                                        </Grid>
                                        <Grid item xs={3}>
                                          <Stack direction='column' sx={{ marginLeft: "16px", height: "90px", position: 'relative', opacity: 0.60 }} alignItems="flex-start" justifyContent="center">
                                            {/* 船アイコン */}
                                            <Box
                                              sx={{
                                                position: 'absolute',
                                                color: 'white',
                                                top: "30px",
                                                transform: "rotate(" + plotInformation.course + "deg)",
                                              }}>
                                              <img src={CourseIcon} alt="logo" className='course-icon' />
                                            </Box>
                                            {/* 風向アイコン */}
                                            <Box
                                              sx={{
                                                position: 'absolute',
                                                width: "0 !important",
                                                height: "0 !important",
                                                borderWidth: "0 3.5px 3px 3.5px",
                                                borderStyle: "solid",
                                                borderColor: "transparent transparent " + plotInformation.wind_directionColor + " transparent",
                                                left: plotInformation.wind_directionCalcLeft + "px",
                                                top: plotInformation.wind_directionCalcTop + "px",
                                                transform: "rotate(" + plotInformation.wind_directionCalcDeg + "deg)",
                                              }}
                                            >
                                            </Box>

                                          </Stack>
                                        </Grid>
                                        <Grid item xs={3}>
                                          <Stack direction='column' sx={{ marginLeft: "8px", height: "90px" }} alignItems="flex-end" justifyContent="center">
                                            <Typography sx={{ marginTop: "4px", marginBottom: "4px", color: 'rgba(255, 255, 255, 1)', fontSize: "15px", fontFamily: "'Roboto Regular'", wordWrap: 'break-word' }}>
                                              {isNaN(plotInformation.wind_speed) ? "-" : formatNumber(plotInformation.wind_speed)}
                                            </Typography>
                                            <Typography sx={{ marginTop: "4px", marginBottom: "4px", color: 'rgba(255, 255, 255, 1)', fontSize: "15px", fontFamily: "'Roboto Regular'", wordWrap: 'break-word' }}>
                                              {isNaN(plotInformation.wind_speed) ? "-" : formatNumber(plotInformation.beaufort)}
                                            </Typography>
                                          </Stack>
                                        </Grid>
                                        <Grid item xs={3}>
                                          <Stack direction='column' sx={{ marginLeft: "8px", height: "90px" }} alignItems="flex-start" justifyContent="center">
                                            <Typography sx={{ marginTop: "4px", marginBottom: "4px", color: 'rgba(255, 255, 255, 0.5)', fontSize: "14px", fontFamily: "'Roboto Condensed Regular'", wordWrap: 'break-word' }}>
                                              m/s
                                            </Typography>
                                            <Typography sx={{ marginTop: "4px", marginBottom: "4px", color: 'rgba(255, 255, 255, 0.5)', fontSize: "14px", fontFamily: "'Roboto Condensed Regular'", wordWrap: 'break-word' }}>
                                              BF
                                            </Typography>
                                          </Stack>
                                        </Grid>
                                      </Grid>
                                    </Stack>

                                    {/* Wave */}
                                    <Stack
                                      sx={{
                                        height: "90px",
                                        width: "260px",
                                        marginLeft: "16px",
                                        marginRight: "16px",
                                        marginTop: "8px",
                                        marginBottom: "8px",
                                        background: "linear-gradient(352deg, rgba(255, 255, 255, 0.01) 1.98%, rgba(255, 255, 255, 0.03) 89.82%)",
                                        borderRadius: "5px"
                                      }} >
                                      <Grid container direction="row" >
                                        <Grid item xs={3}>
                                          <Stack direction='column' sx={{ marginLeft: "8px", height: "90px", position: 'relative', opacity: 0.60 }} >
                                            <TsunamiIcon
                                              sx={{
                                                position: 'absolute',
                                                color: 'white',
                                                top: "30px",
                                                opacity: 0.7,
                                              }} />
                                            <Typography sx={{
                                              position: 'absolute',
                                              color: 'rgba(255, 255, 255, 0.50)',
                                              fontSize: 13,
                                              fontFamily: 'Roboto',
                                              fontWeight: '700',
                                              wordWrap: 'break-word',
                                              top: "55px",
                                            }}>
                                              Wave
                                            </Typography>
                                          </Stack>
                                        </Grid>
                                        <Grid item xs={3}>
                                          <Stack direction='column' sx={{ marginLeft: "16px", height: "90px", position: 'relative', opacity: 0.60 }} alignItems="flex-start" justifyContent="center">
                                            <Box
                                              sx={{
                                                position: 'absolute',
                                                color: 'white',
                                                top: "30px",
                                                transform: "rotate(" + plotInformation.course + "deg)",
                                              }}>
                                              <img src={CourseIcon} alt="logo" className='course-icon' />
                                            </Box>
                                            <Box
                                              sx={{
                                                position: 'absolute',
                                                width: "0 !important",
                                                height: "0 !important",
                                                borderWidth: "0 3.5px 3px 3.5px",
                                                borderStyle: "solid",
                                                borderColor: "transparent transparent " + plotInformation.wave_directionColor + " transparent",
                                                left: plotInformation.wave_directionCalcLeft + "px",
                                                top: plotInformation.wave_directionCalcTop + "px",
                                                transform: "rotate(" + plotInformation.wave_directionCalcDeg + "deg)",
                                              }}
                                            >
                                            </Box>
                                          </Stack>
                                        </Grid>
                                        <Grid item xs={3}>
                                          <Stack direction='column' sx={{ marginLeft: "8px", height: "90px" }} alignItems="flex-end" justifyContent="center">
                                            <Typography sx={{ marginTop: "4px", marginBottom: "4px", color: 'rgba(255, 255, 255, 1)', fontSize: "15px", fontFamily: "'Roboto Regular'", wordWrap: 'break-word' }}>
                                              {isNaN(plotInformation.wave_height) ? "-" : formatNumber(plotInformation.wave_height)}
                                            </Typography>
                                            <Typography sx={{ marginTop: "4px", marginBottom: "4px", color: 'rgba(255, 255, 255, 1)', fontSize: "15px", fontFamily: "'Roboto Regular'", wordWrap: 'break-word' }}>
                                              {isNaN(plotInformation.wave_period) ? "-" : formatNumber(plotInformation.wave_period)}
                                            </Typography>
                                          </Stack>
                                        </Grid>
                                        <Grid item xs={3}>
                                          <Stack direction='column' sx={{ marginLeft: "8px", height: "90px" }} alignItems="flex-start" justifyContent="center">
                                            <Typography sx={{ marginTop: "4px", marginBottom: "4px", color: 'rgba(255, 255, 255, 0.5)', fontSize: "14px", fontFamily: "'Roboto Condensed Regular'", wordWrap: 'break-word' }}>
                                              m
                                            </Typography>
                                            <Typography sx={{ marginTop: "4px", marginBottom: "4px", color: 'rgba(255, 255, 255, 0.5)', fontSize: "14px", fontFamily: "'Roboto Condensed Regular'", wordWrap: 'break-word' }}>
                                              s
                                            </Typography>
                                          </Stack>
                                        </Grid>
                                      </Grid>
                                    </Stack>

                                    {/* Swell */}
                                    <Stack
                                      sx={{
                                        height: "90px",
                                        width: "260px",
                                        marginLeft: "16px",
                                        marginRight: "16px",
                                        marginTop: "8px",
                                        marginBottom: "8px",
                                        background: "linear-gradient(352deg, rgba(255, 255, 255, 0.01) 1.98%, rgba(255, 255, 255, 0.03) 89.82%)",
                                        borderRadius: "5px"
                                      }} >
                                      <Grid container direction="row" >
                                        <Grid item xs={3}>
                                          <Stack direction='column' sx={{ marginLeft: "8px", height: "90px", position: 'relative', opacity: 0.60 }} >
                                            <WavesIcon
                                              sx={{
                                                position: 'absolute',
                                                color: 'white',
                                                top: "30px",
                                                opacity: 0.7,
                                              }} />
                                            <Typography sx={{
                                              position: 'absolute',
                                              color: 'rgba(255, 255, 255, 0.50)',
                                              fontSize: 13,
                                              fontFamily: 'Roboto',
                                              fontWeight: '700',
                                              wordWrap: 'break-word',
                                              top: "55px",
                                            }}>
                                              Swell
                                            </Typography>
                                          </Stack>
                                        </Grid>
                                        <Grid item xs={3}>
                                          <Stack direction='column' sx={{ marginLeft: "16px", height: "90px", position: 'relative', opacity: 0.60 }} alignItems="flex-start" justifyContent="center">
                                            <Box
                                              sx={{
                                                position: 'absolute',
                                                color: 'white',
                                                top: "30px",
                                                transform: "rotate(" + plotInformation.course + "deg)",
                                              }}>
                                              <img src={CourseIcon} alt="logo" className='course-icon' />
                                            </Box>
                                            <Box
                                              sx={{
                                                position: 'absolute',
                                                width: "0 !important",
                                                height: "0 !important",
                                                borderWidth: "0 3.5px 3px 3.5px",
                                                borderStyle: "solid",
                                                borderColor: "transparent transparent " + plotInformation.swell_directionColor + " transparent",
                                                left: plotInformation.swell_directionCalcLeft + "px",
                                                top: plotInformation.swell_directionCalcTop + "px",
                                                transform: "rotate(" + plotInformation.swell_directionCalcDeg + "deg)",
                                              }}
                                            >
                                            </Box>
                                          </Stack>
                                        </Grid>
                                        <Grid item xs={3}>
                                          <Stack direction='column' sx={{ marginLeft: "8px", height: "90px" }} alignItems="flex-end" justifyContent="center">
                                            <Typography sx={{ marginTop: "4px", marginBottom: "4px", color: 'rgba(255, 255, 255, 1)', fontSize: "15px", fontFamily: "'Roboto Regular'", wordWrap: 'break-word' }}>
                                              {isNaN(plotInformation.swell_height) ? "-" : formatNumber(plotInformation.swell_height)}
                                            </Typography>
                                            <Typography sx={{ marginTop: "4px", marginBottom: "4px", color: 'rgba(255, 255, 255, 1)', fontSize: "15px", fontFamily: "'Roboto Regular'", wordWrap: 'break-word' }}>
                                              {isNaN(plotInformation.swell_period) ? "-" : formatNumber(plotInformation.swell_period)}
                                            </Typography>
                                          </Stack>
                                        </Grid>
                                        <Grid item xs={3}>
                                          <Stack direction='column' sx={{ marginLeft: "8px", height: "90px" }} alignItems="flex-start" justifyContent="center">
                                            <Typography sx={{ marginTop: "4px", marginBottom: "4px", color: 'rgba(255, 255, 255, 0.5)', fontSize: "14px", fontFamily: "'Roboto Condensed Regular'", wordWrap: 'break-word' }}>
                                              m
                                            </Typography>
                                            <Typography sx={{ marginTop: "4px", marginBottom: "4px", color: 'rgba(255, 255, 255, 0.5)', fontSize: "14px", fontFamily: "'Roboto Condensed Regular'", wordWrap: 'break-word' }}>
                                              s
                                            </Typography>
                                          </Stack>
                                        </Grid>
                                      </Grid>
                                    </Stack>
                                  </Grid>
                                  {/* ↑ Weather ↑ */}
                                </Grid>
                              </>
                              :
                              <Box
                                sx={{ height: 270 }}>
                                {loading && (
                                  <CircularProgress
                                    size={68}
                                    sx={{
                                      color: "grey",
                                      position: 'absolute',
                                      top: 550,
                                      left: 1200,
                                      zIndex: 1,
                                    }}
                                  />
                                )}
                              </Box>
                          }
                        </Stack>
                      </Stack>
                      {/* ↓ Point Information ↓ */}

                    </div>
                    {/* RightBoard */}
                  </div>

                </Box>
                {/* ↓ メイン　ボディ ↓ */}

              </Stack>

            </Grid>
            {/* ↑ メイン ↑ */}

          </Grid>
        </Main >
      </Box >
    </>
  );
}