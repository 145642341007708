
// @mui/material/
import { createTheme } from '@mui/material/styles';

export const SelectTheme = createTheme({
    components: {
        MuiMenu: {
            styleOverrides: {
                paper: {
                    maxHeight: "300px", // メニュー全体の最大高さを設定
                    overflowY: "auto", // スクロールバーを有効にする
                    backgroundColor: "#313741", // 背景色を指定
                    /* スクロールバーのカスタマイズ */
                    "&::-webkit-scrollbar": {
                        width: "8px", // スクロールバーの幅
                    },
                    "&::-webkit-scrollbar-track": {
                        backgroundColor: "rgba(255, 255, 255, 0.5)", // スクロールバーのトラック（背景）
                    },
                    "&::-webkit-scrollbar-thumb": {
                        backgroundColor: "#fff", // スクロールバー自体の色
                        borderRadius: "10px", // スクロールバーの角丸
                    },
                    "&::-webkit-scrollbar-corner": {
                        display: "none",
                    },
                },
            },
        },
        MuiMenuItem: {
            styleOverrides: {
                root: {
                    color: "white",
                    height: "300px",
                    overflowY: "auto",
                    "&:nth-of-type(odd)": {
                        backgroundColor: "#313741",
                        height: "45px"
                    },
                    "&:nth-of-type(even)": {
                        backgroundColor: "#3F454F",
                        height: "45px"
                    },
                    "&.Mui-selected": {
                        backgroundColor: "#777D86",
                        "&:hover": { backgroundColor: "#777D86" }
                    },
                    "&:hover": { backgroundColor: "#777D86" },
                    "&.Mui-focusVisible": {
                        backgroundColor: "#777D86",
                        "&.Mui-selected": { backgroundColor: "#777D86" }
                    },
                }
            }
        }
    }
});
