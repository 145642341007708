
import * as React from 'react';
import { TabComponent } from '../../components/components/TabComponent';
import { SettingComponent } from '../../components/components/SettingComponent';

// @mui/material/
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Box from '@mui/material/Box';

// ロゴ、システム名
import Logo from '../../assets/images/eco_logo.svg';
import SystemName from '../../assets/images/eco_system-name.svg';

export const MyAppBar = (props) => {
  return (
    <>
      <MuiAppBar
        position="fixed"
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 1,
          background: 'rgba(71, 76, 83, 1)'
        }}>
        <Toolbar>
          <img src={Logo} alt="logo" style={{ height: "35.5px", width: "37px", marginRight: "2px"}} />
          <img src={SystemName} alt="systemName" style={{ height: "26px", width: "113px", marginTop: "8px" }} />
          <Box sx={{ marginLeft: "40px", marginRight: "20px", height: "25px", width: "2px", background: '#f1f1f1', borderRadius: '2px ', border: '1px #f1f1f1 solid' }}></Box>
          <TabComponent />
          <SettingComponent company_id={props.company_id} group_id={props.group_id} user_id={props.user_id} role={props.role} />
          {/* <SettingComponent company_id={company_id} group_id={group_id} user_id={user_id} role={role} /> */}
        </Toolbar>
      </MuiAppBar>
    </>
  );
}