
import * as React from 'react';

import axios, { AxiosError } from "axios";

import './CiiSimulation.css'; // スタイルのために追加
import { CiiEditLeg } from './CiiEditLeg';

// MUI @mui/material/
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';

// Dialog
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

// アイコン
import CommonIconPlus from '../../../components/components/CommonIconPlus';

// Axios Error
interface ErrorData {
  message: string;
  status: number;
  error: string;
}

// 登録データの型を定義
interface RegistdData {
  imo: string;
  year_and_serial_number: string;
  departure_port: string;
  departure_time: string;
  arrival_port: string;
  arrival_time: string;
  distance: string;
  dispracement: string;
  fuel: string;
}

// 入力チェック結果
interface InputCheckResult {
  error_index: number;
  error_message: string;
}

// 親コンポーネントから持ち回る値
export type Props = {
  openDialogFlg: boolean;
  setOpenDialogFlg: (value : boolean) => void;
  loading: boolean;
  setLoading: (value : boolean) => void;
  success: boolean;
  setSuccess: (value : boolean) => void;
  fuelOileList: any[];
  onClickSaveFlg: boolean;
  setOnClickSaveFlg: (value : boolean) => void;

  paretsLegInfoList: any[];
  setParetsLegInfoList: (value: any[]) => void;

  SeachInfo: any;
  setSeachInfo: (value: any) => void;
}

// EU Edit Conditions Dialog Component--------------------------------------------------------------------
export const CiiEditConditionsDialog: React.FC<Props> = props => {

  const [jwt, setJwt] = React.useState<string>();

  React.useEffect(() => {

    // localStrage取得----------------------------------------------------------------------------------------------------------------------------
    let jwt_ls
    if (localStorage.getItem("payload")) {
      const pay_json = localStorage.getItem("payload")
      if (pay_json !== null) {
        const pay = JSON.parse(pay_json)
        jwt_ls = pay["jwt"]
        setJwt(jwt_ls)
      }
    }

  }, []);

  // 時系列データ取得--------------------------------------------------------------------
  const [authCheck, setAuthCheck] = React.useState(false);
  const [authMessage, setAuthMessage] = React.useState("");

  // URL
  const [base_url, setBase_url] = React.useState(`${process.env.REACT_APP_API_URL}/CiiAnalysis/Simulation/EditConditions/%23/`);

  // yyyy/mm/dd、hour、minutesの文字列を使って日付オブジェクトを作成
  const createDateObject = (yyyymmdd, hour, minute) => {
    let parts = yyyymmdd.split("/");
    let returnDateObject = new Date(parts[0], parts[1] - 1, parts[2], hour, minute);
    return returnDateObject
  }
  // Saveボタン押下イベント
  const handleClickSaveEditConditions = () => {

    let registdData: RegistdData[] = [];
    let checkResult: InputCheckResult[] = [];
    const currentYear = new Date().getFullYear(); // 現在の年を取得

    // 登録用データ作成
    legInfoList.forEach(function(element, index) {

      // DepartureTime ＜ ArrivalTimeのチェック
      let tmpDepartureTime = createDateObject(element.departureTime_yyyymmdd, element.departureTime_hour, element.departureTime_min);
      let tmpArrivalTime = createDateObject(element.arrivalTime_yyyymmdd, element.arrivalTime_hour, element.arrivalTime_min);

      if (tmpDepartureTime >= tmpArrivalTime) {
        const error = {
          "error_index": index,
          "error_message": "Please specify a departure time before the arrival time.",
        }
        checkResult.push(error)
      }

      // FuelRateの合計が100%になるかのチェック
      let tmpFuelRate1 = 0
      let tmpFuelRate2 = 0
      let tmpFuelRate3 = 0
      let tmpTotalFuelRate = 0
      if (element.fuelRate1 !== "") { tmpFuelRate1 = Number(element.fuelRate1) }
      if (element.fuelRate2 !== "") { tmpFuelRate2 = Number(element.fuelRate2) }
      if (element.fuelRate3 !== "") { tmpFuelRate3 = Number(element.fuelRate3) }
      tmpTotalFuelRate = tmpFuelRate1 + tmpFuelRate2 + tmpFuelRate3
      if (tmpTotalFuelRate !== 100) {
        const error = {
          "error_index": index,
          "error_message": "Please enter FuelRate so that the total is 100.",
        }
        checkResult.push(error)
      }

      let tempFuelString = "";

      // fuel登録用の文字列作成
      if (element.fuel1 !== "-") {
        tempFuelString = "(" + element.fuel1 + "," + element.fuelRate1 + ")"
      }
      if (element.fuel2 !== "-") {

        if (tempFuelString !== "") {
          tempFuelString = tempFuelString + ","
        }

        tempFuelString = tempFuelString + "(" + element.fuel2 + "," + element.fuelRate2 + ")"
      }
      if (element.fuel3 !== "-") {

        if (tempFuelString !== "") {
          tempFuelString = tempFuelString + ","
        }

        tempFuelString = tempFuelString + "(" + element.fuel3 + "," + element.fuelRate3 + ")"
      }

      const value = {
        // EditConditions
        "imo": String(props.SeachInfo.imoNo),
        "year_and_serial_number": String(currentYear) + "E" + String(index + 1) ,
        "departure_port": element.departurePort,
        "departure_time": element.departureTime_yyyymmdd + " " + element.departureTime_hour + ":" + element.departureTime_min,
        "arrival_port": element.arrivalPort,
        "arrival_time": element.arrivalTime_yyyymmdd + " " + element.arrivalTime_hour + ":" + element.arrivalTime_min,
        "distance": element.distance,
        "dispracement": element.displacement,
        "fuel": tempFuelString,
      }

      registdData.push(value)
    });

    if (checkResult.length === 0) {
      // 入力チェックエラーなしの場合、登録実行     
      // 登録
      insertEditConditions(registdData);
      // ダイアログ閉じる。
      props.setOpenDialogFlg(false);
      props.setOnClickSaveFlg(true);
    }
    else {
      // エラーメッセージを作成しalertで表示

      let error_string = "";

      checkResult.forEach(function(element) {

        let leg_no = "LEG No.E" + String(element.error_index + 1);
        let message = leg_no + " : " + element.error_message;

        if (error_string !== "") {
          error_string = error_string + "\n";
        }
        error_string = error_string + message;
      });

      alert(error_string);
    }
  };

  // EditConditions登録
  const insertEditConditions = (value) => {
    console.log("insertEditConditions")

    // 親画面ローディング表示
    props.setLoading(true);
    props.setSuccess(false);

    console.log('Data to send:', JSON.stringify(value));

    if (props.SeachInfo.imoNo !== "" && jwt !== undefined) {
      console.log("insertEditConditionsスタート", value)

      const options = {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': jwt
        }
      }
      const url = `${base_url}`
      console.log("url[insertFocFormulas]:", url);
      axios.post(url, JSON.stringify(value), options).then((res) => {
        console.log("API Response", res.data);

        // 認可チェック
        if (res.data === 401) {
          setAuthCheck(true)
          setAuthMessage("不正な操作が行われた可能性があります。")
        } else if (res.data === 500) {
          setAuthCheck(true)
          setAuthMessage("JWTが改ざんされている可能性があります。")
        } else {
          setAuthCheck(false)
        }

        // 親コンポーネントのLegInfoListにセット
        props.setParetsLegInfoList(res.data.datas)

        // 親画面ローディング解除
        props.setLoading(false);
        props.setSuccess(true);

      })
      .catch((e: AxiosError<ErrorData>) => {
        // 親画面ローディング解除
        props.setLoading(false);
        props.setSuccess(true);
        if (e.response === undefined) {
          console.log("undefinedError: " + e);
          return;
        }
        console.log(e + ", message: " + e.response.data.message);
      });
    }
  }

  // キャンセルボタン押下
  const handleNoClickOpenDialogEditConditions = () => {
    props.setOpenDialogFlg(false);
  };

  // Leg情報をを保持するリスト
  const legInfo = {
    "departurePort"          : "",
    "departureTime_yyyymmdd" : "",
    "departureTime_hour"     : "",
    "departureTime_min"      : "",
    "arrivalPort"            : "",
    "arrivalTime_yyyymmdd"   : "",
    "arrivalTime_hour"       : "",
    "arrivalTime_min"        : "",
    "distance"               : "",
    "displacement"           : "Ballast",
    "fuel1"                  : "-",
    "fuelRate1"              : "",
    "fuel2"                  : "-",
    "fuelRate2"              : "",
    "fuel3"                  : "-",
    "fuelRate3"              : "",
  }

  const [legInfoList, setLegInfoList] = React.useState([legInfo]);
  const [countLegInfoList, setCountLegInfoList] = React.useState(1);

  // LegInfoList追加
  const handleAddLegInfoList = (key: number) => {

    // saveボタンを非活性
    setSaveEnableFlg(false);

    setCountLegInfoList(countLegInfoList + 1) 

    if (countLegInfoList === key + 1) {
      // 一番下のプラスボタン押下の場合、リスト最後尾に追加
      legInfoList.push({
        departurePort          : "",
        departureTime_yyyymmdd : "",
        departureTime_hour     : "",
        departureTime_min      : "",
        arrivalPort            : "",
        arrivalTime_yyyymmdd   : "",
        arrivalTime_hour       : "",
        arrivalTime_min        : "",
        distance               : "",
        displacement           : "Ballast",
        fuel1                  : "-",
        fuelRate1              : "",
        fuel2                  : "-",
        fuelRate2              : "",
        fuel3                  : "-",
        fuelRate3              : "",
      })

    }
    else {
      // 間に追加の場合、該当のインデックスを特定し、差し込む

      // 一時保存用のリスト定義
      let tempLegInfoList = legInfoList;
      tempLegInfoList = [];

      legInfoList.forEach(function(element, index) {
  
        if (index === key) {
          // キーがインデックスと一致する場合、下に空の用を追加

          tempLegInfoList.push(element);

          tempLegInfoList.push({
            departurePort          : "",
            departureTime_yyyymmdd : "",
            departureTime_hour     : "",
            departureTime_min      : "",
            arrivalPort            : "",
            arrivalTime_yyyymmdd   : "",
            arrivalTime_hour       : "",
            arrivalTime_min        : "",
            distance               : "",
            displacement           : "Ballast",
            fuel1                  : "-",
            fuelRate1              : "",
            fuel2                  : "-",
            fuelRate2              : "",
            fuel3                  : "-",
            fuelRate3              : "",
          })
  
        }
        else {
          // 上記以外の場合、後ろに追加
          tempLegInfoList.push(element);
        }
      });

      // 一時保存用のリストで上書き
      setLegInfoList(tempLegInfoList);

    }
  };

  // LegInfoList削除
  const handleDeleteLegInfoList = (deleteFlg: boolean, key: number) => {

    setCountLegInfoList(countLegInfoList - 1) 

    if (deleteFlg === true) {
      
      // 一時保存用のリスト定義
      let tempLegInfoList = legInfoList;
      tempLegInfoList = [];

      legInfoList.forEach(function(element, index) {
      
        if (index === key) {
          // キーがインデックスと一致する場合、削除対象のため何もしない。
      
        }
        else {
          // 上記以外の場合、リストに追加
          tempLegInfoList.push(element);
        }
      });

      // 一時保存用のリストで上書き
      setLegInfoList(tempLegInfoList);
    } 
  };

  // LegInfoList更新
  const handleUpdateLegInfoList = (legInfo: any, key: number) => {

      // 一時保存用のリスト定義
      let tempLegInfoList = legInfoList;
      tempLegInfoList = [];

      legInfoList.forEach(function(element, index) {
      
        if (index === key) {
          // キーがインデックスと一致する場合、変更後のオブジェクトを設定
          tempLegInfoList.push(legInfo);
        }
        else {
          // 上記以外の場合、元の値をリストに追加
          tempLegInfoList.push(element);
        }
      });

      // 一時保存用のリストで上書き
      setLegInfoList(tempLegInfoList);
  };

  // LegInfoListの親との同期（親から飛んできたやつをこの画面表示用に整形）
  React.useEffect(() => {

    if (props.paretsLegInfoList.length !== 0) {
     
      let tempLegInfoList = legInfoList;
      tempLegInfoList = [];

      props.paretsLegInfoList.forEach(paretsLegInfo => {

        // 日付と時間を分割
        const [departureDate, departureTime] = paretsLegInfo.departure_time.split(' ');
        const [arrivalDate, arrivalTime] = paretsLegInfo.arrival_time.split(' ');
        // 時間部分をさらに分割（":" で分割）
        const [departureHour, departureMinute] = departureTime.split(':');
        const [arrivalHour, arrivalMinute] = arrivalTime.split(':');

        // Fuelの設定値作成
        let tmpFuel1 = "-";
        let tmpFuelRate1 = "";
        let tmpFuel2 = "-";
        let tmpFuelRate2 = "";
        let tmpFuel3 = "-";
        let tmpFuelRate3 = "";
        paretsLegInfo.fuel.forEach(function(fuelInfo, index) {   
          // idexは3が最大
          if (index === 0) {
            tmpFuel1 = fuelInfo.fuel_type;
            tmpFuelRate1 = fuelInfo.fuel_rate;
          }
          else if (index === 1) {
            tmpFuel2 = fuelInfo.fuel_type;
            tmpFuelRate2 = fuelInfo.fuel_rate;
          }
          else if (index === 2) {
            tmpFuel3 = fuelInfo.fuel_type;
            tmpFuelRate3 = fuelInfo.fuel_rate;
          }
      
        });
  
        tempLegInfoList.push({
          departurePort          : paretsLegInfo.departure_port,
          departureTime_yyyymmdd : departureDate,
          departureTime_hour     : departureHour,
          departureTime_min      : departureMinute,
          arrivalPort            : paretsLegInfo.arrival_port,
          arrivalTime_yyyymmdd   : arrivalDate,
          arrivalTime_hour       : arrivalHour,
          arrivalTime_min        : arrivalMinute,
          distance               : paretsLegInfo.distance,
          displacement           : paretsLegInfo.dispracement,
          fuel1                  : tmpFuel1,
          fuelRate1              : tmpFuelRate1,
          fuel2                  : tmpFuel2,
          fuelRate2              : tmpFuelRate2,
          fuel3                  : tmpFuel3,
          fuelRate3              : tmpFuelRate3,
        })
      });

      setLegInfoList(tempLegInfoList);

    }
    else {
      // 初期化
      let tempLegInfoList = legInfoList;
      tempLegInfoList = [];

      tempLegInfoList.push({
        departurePort          : "",
        departureTime_yyyymmdd : "",
        departureTime_hour     : "",
        departureTime_min      : "",
        arrivalPort            : "",
        arrivalTime_yyyymmdd   : "",
        arrivalTime_hour       : "",
        arrivalTime_min        : "",
        distance               : "",
        displacement           : "Ballast",
        fuel1                  : "-",
        fuelRate1              : "",
        fuel2                  : "-",
        fuelRate2              : "",
        fuel3                  : "-",
        fuelRate3              : "",
      })

      setLegInfoList(tempLegInfoList);
    }
  
  }, [props.paretsLegInfoList]);

  // Saveボタンの活性、非活性制御
  const [saveEnableFlg, setSaveEnableFlg] = React.useState<boolean>(false);
  React.useEffect(() => {

    let tmpCheckOkFlag = true;

    // LegIngoListの入力必須欄がすべて埋まっていることを確認
    legInfoList.forEach(legInfo => {

      // Fuel、FuelRateのチェック
      let ng_flag = false;
      if (legInfo.fuel1 !== "-" && (legInfo.fuelRate1 === undefined || legInfo.fuelRate1 === "")){
        ng_flag = true;
      }
      if (legInfo.fuel1 === "-" && (legInfo.fuelRate1 !== undefined && legInfo.fuelRate1 !== "")){
        ng_flag = true;
      }
      if (legInfo.fuel2 !== "-" && (legInfo.fuelRate2 === undefined || legInfo.fuelRate2 === "")){
        ng_flag = true;
      }
      if (legInfo.fuel2 === "-" && (legInfo.fuelRate2 !== undefined && legInfo.fuelRate2 !== "")){
        ng_flag = true;
      }
      if (legInfo.fuel3 !== "-" && (legInfo.fuelRate3 === undefined || legInfo.fuelRate3 === "")){
        ng_flag = true;
      }
      if (legInfo.fuel3 === "-" && (legInfo.fuelRate3 !== undefined && legInfo.fuelRate3 !== "")){
        ng_flag = true;
      }

      let ok_flg = false;
      if (legInfo.fuel1 !== "-" && /^-?\d+(\.\d+)?$/.test(legInfo.fuelRate1)){
        ok_flg = true;
      }
      if (legInfo.fuel2 !== "-" && /^-?\d+(\.\d+)?$/.test(legInfo.fuelRate2)){
        ok_flg = true;
      }
      if (legInfo.fuel3 !== "-" && /^-?\d+(\.\d+)?$/.test(legInfo.fuelRate3)){
        ok_flg = true;
      }

      let fuel_ok_flg = false;
      if (ng_flag === true) {
        fuel_ok_flg = false;
      }
      else if (ok_flg === true) {
        fuel_ok_flg = true;
      }

      // 各項目のチェック
      if (legInfo.departureTime_yyyymmdd === "") {
        tmpCheckOkFlag = false;
      }
      else if (legInfo.departureTime_hour === "") {
        tmpCheckOkFlag = false;
      }
      else if (legInfo.departureTime_min === "") {
        tmpCheckOkFlag = false;
      }
      else if (legInfo.arrivalTime_yyyymmdd === "") {
        tmpCheckOkFlag = false;
      }
      else if (legInfo.arrivalTime_hour === "") {
        tmpCheckOkFlag = false;
      }
      else if (legInfo.arrivalTime_min === "") {
        tmpCheckOkFlag = false;
      }
      else if (legInfo.distance === "") {
        tmpCheckOkFlag = false;
      }
      else if (!fuel_ok_flg) {
        tmpCheckOkFlag = false;
      }
    });

    // 最終チェック
    if (tmpCheckOkFlag) {
      setSaveEnableFlg(true);
    }
    else {
      setSaveEnableFlg(false);
    }

  }, [legInfoList]);

  return (
    <>

      {/* Edit Conditions Daialog */}
      <Dialog
        open={props.openDialogFlg}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "65.5%",
              maxWidth: "1254px",
              height: "89%",
              maxHeight: "850px",
            },
          },
        }}
        >
        <DialogTitle
          sx={{
            bgcolor: "#474C53",
            height: "60px",
            width: "1254px",
            color: "white",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            fontFamily: "'Roboto', 'Meiryo', sans-serif",
            fontWeight: "700"
          }}>
            Edit Conditions
          <IconButton
            aria-label="close"
            onClick={handleNoClickOpenDialogEditConditions}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[1000],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent
          sx={{
            background: "linear-gradient(180deg, #0E1B25 0%, #17232D 100%)",
          }}>
          
          <Box sx={{ height: "100%", width: "100%", overflowX: "auto"
            , 
            overflowY: 'auto',
            '&::-webkit-scrollbar': {
              width: "6px",
              height: "6px",
            },
            '&::-webkit-scrollbar-track': {
              borderRadius: "10px",
              background: "rgba(255, 255, 255, 0.5)",
            },
            '&::-webkit-scrollbar-thumb': {
              background: "#fff",
              borderRadius: "10px",
            },
            '&::-webkit-scrollbar-thumb:hover': {
              backgroundColor: '#555',
            },
            "&::-webkit-scrollbar-corner": {
              display: "none",
              }
          }}>
            <Box sx={{ height: "40px"}}>
            </Box>
            
            {/* Leg情報入力コンポーネント */}
            {legInfoList.map((item, index) => {
              return (
                <Box>
                  <CiiEditLeg
                    elementKey = {index}
                    SeachInfo = {props.SeachInfo}
                    legInfo = {item}
                    setSeachInfo = {(updateValue: any) => props.setSeachInfo(updateValue)}
                    setDeleteLegFlg = {(updateValue: boolean) => handleDeleteLegInfoList(updateValue, index)}
                    setLegInfo = {(updateValue: any) => handleUpdateLegInfoList(updateValue, index)}
                    fuelOileList = {props.fuelOileList}
                  />
     
                  {/* プラスボタン */}
                  <Stack sx={{ position: "relative", width: "100%", height: "30px" }} alignItems={'center'} >                         
                    <IconButton className='plusButton'
                      sx ={{ height : "18px", padding: 0, borderRadius: 30, position: "absolute", bottom: "-2px", marginLeft: "-25px"}}
                      onClick={() => handleAddLegInfoList(index)}
                      >
                      <CommonIconPlus />                                            
                    </IconButton>
                  </Stack>
                </Box>
              )
            })}
          </Box>


        </DialogContent>
        <DialogActions sx={{
          height: "60px",
          bgcolor: "#474C53"
          }}>
          <Button
            sx={{
              marginLeft: "0px",
              marginRight: "-8px",
              backgroundColor: "rgba(255, 255, 255, 0.5)",
              width: "300px",
              height: "60px",
              padding: "0",
              fontSize: "18px",
              fontFamily: "'Roboto', 'Meiryo', sans-serif",
              fontWeight: "bold",
              color: "white",
              borderRadius: "0px",
              ":hover": {
                bgcolor: "#FFFFFF",
                color: "#40475D",
              },
              textTransform: 'none'
            }}
            onClick={handleNoClickOpenDialogEditConditions}
          >
            Cancel
          </Button>
          <Button
            sx={{
              marginLeft: "0px",
              marginRight: "-8px",
              backgroundColor : saveEnableFlg ? "#e66300" : "rgba(230, 99, 0, 0.15)",
              width: "300px",
              height: "60px",
              padding: "0",
              fontSize: "18px",
              fontFamily: "'Roboto', 'Meiryo', sans-serif",
              fontWeight: "bold",
              color: "white",
              borderRadius: "0px",
              ":hover": {
                bgcolor: "#FFFFFF",
                color: "#40475D",
              },
              textTransform: 'none'
            }}
            onClick={handleClickSaveEditConditions}
            disabled={saveEnableFlg ? false : true}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>

    </>
  );
} 

export default CiiEditConditionsDialog;