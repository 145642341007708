import React from 'react';
import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import axios, { AxiosError } from "axios";
import VesselList from './pages/VesselList/VesselList';
import VesselOverview from './pages/VesselOverview/VesselOverview';
import SpeedConsumption from './pages/SpeedConsumption/SpeedConsumption';
import DataViewer from './pages/DataViewer/DataViewer';
import MasterInput from './pages/MasterInput/MasterInput';
import EuEtsAndFuelEu from './pages/EuEtsAndFuelEu/EuEtsAndFuelEu';
import CiiAnalysis from './pages/CiiAnalysis/CiiAnalysis';
import FleetOverview from './pages/FleetOverview/FleetOverview';
import Error from './pages/Error/Error';
import { callAuth } from './components/components/auth';
import dayjs from "dayjs";
import ja_locale from 'dayjs/locale/ja';
import utc_plugin from "dayjs/plugin/utc";
import isBetween from "dayjs/plugin/isBetween";
import duration from "dayjs/plugin/duration";
import customParseFormat from "dayjs/plugin/customParseFormat";


interface ErrorData {
  message: string;
  status: number;
  error: string;
}

dayjs.locale(ja_locale);
dayjs.extend(isBetween);
dayjs.extend(utc_plugin);
dayjs.extend(duration);
dayjs.extend(customParseFormat);

type SeachInfo = {
  imoNo: string,
  from: string,
  to: string,
  gid: string,
  gidList: Array<string>,
  imoList: Array<string>,
}

interface payload {
  user_id: string | null
  group_id: string | null
  company_id: string | null
  gid: string | null
  gidList: Array<string>
  imoList: Array<string>
  role: string | null
  jwt: string | null
}

const dateObject_start = new Date().setDate(new Date().getDate() - 2);
const dateObject_end = new Date();
const utcDate_start = dayjs(dateObject_start).utc();
const utcDate_end = dayjs(dateObject_end).utc();
const formattedDate_start = utcDate_start.format("YYYY-MM-DDThh:mm:ss");
const formattedDate_end = utcDate_end.format("YYYY-MM-DDThh:mm:ss");

export const SearchContext = React.createContext({ imoNo: "", from: "", to: "", gid: "", gidList: [""], imoList: [""] });

export default function App() {
  const [SeachInfo, setSeachInfo] = React.useState<SeachInfo>({ imoNo: "", from: formattedDate_start, to: formattedDate_end, gid: "", gidList: [""], imoList: [""] });
  const [authCheckResult, setAuthCheckResult] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);
  const initRef = React.useRef(true);

  // URL
  const [base_url, setBase_url] = React.useState(`${process.env.REACT_APP_API_URL}/Admin`);

  React.useEffect(() => {
    const windowLocation = window.location;
    const urlParams = new URLSearchParams(windowLocation.search);
    const jwt_urlParams = urlParams.get('jwt');

    const authenticate = async () => {
      if (initRef.current) {
        let jwt
        let result
        if (jwt_urlParams) {
          console.log("JWT新規発行");
          result = await callAuth(jwt_urlParams);
          jwt = jwt_urlParams
          if (result) {
            console.log("[App]認証成功");
            await getAdmin(base_url, jwt);
            setAuthCheckResult(true);
            setLoading(true);
          } else {
            console.log("[App]認証失敗");
            setAuthCheckResult(false);
            setLoading(true);
          }
        }
        else {
          console.log("LocalStorageのJWTを利用");
          let jwt_ls
          if (localStorage.getItem("payload")) {
            const pay_json = localStorage.getItem("payload")
            if (pay_json !== null) {
              const pay = JSON.parse(pay_json)
              jwt_ls = pay["jwt"]
            }
          }
          result = await callAuth(jwt_ls);
          if (result) {
            console.log("[App]認証成功");
            await getAdmin(base_url, jwt_ls);
            setAuthCheckResult(true);
            setLoading(true);
          } else {
            console.log("[App]認証失敗");
            setAuthCheckResult(false);
            setLoading(true);
          }
          jwt = jwt_ls
        }
        initRef.current = false;
      }
    };
    authenticate();
  }, []);

  const getAdmin = async (url: string, jwt: string) => {
    const options = {
      headers: {
        'Authorization': jwt,
      }
    };
    try {
      let user_id_ls
      let group_id_ls
      let company_id_ls
      let gid_ls
      let gidList_ls
      let imoList_ls
      // let role_ls
      // let jwt_ls
      if (localStorage.getItem("payload")) {
        const pay_json = localStorage.getItem("payload")
        if (pay_json !== null) {
          const pay = JSON.parse(pay_json)
          user_id_ls = pay["user_id"]
          group_id_ls = pay["group_id"]
          company_id_ls = pay["company_id"]
          gid_ls = pay["gid"]
          gidList_ls = pay["gidList"]
          imoList_ls = pay["imoList"]
          // role_ls = pay["role"]
          // jwt_ls = pay["jwt"]
        }
      }

      const res = await axios.get(url, options);
      console.log("axios[admin]", res)
      localStorage.clear();
      const payload: payload = {
        user_id: user_id_ls,
        group_id: group_id_ls,
        company_id: company_id_ls,
        gid: gid_ls,
        gidList: gidList_ls,
        imoList: imoList_ls,
        role: res.data,
        jwt: jwt
      };
      localStorage.setItem("payload", JSON.stringify(payload));
    } catch (e) {
      const error = e as AxiosError<ErrorData>;
      if (!error.response) {
        console.log("undefinedError: " + error);
        return;
      }
      console.log(error + ", message: " + error.response.data.message);
    }
  };

  return (
    <BrowserRouter>
      {loading && authCheckResult ? (
        <SearchContext.Provider value={SeachInfo}>
          <Routes>
            <Route path="/authjwt?" element={<VesselList setSeachInfo={setSeachInfo} />} />
            <Route path="/" element={<VesselList setSeachInfo={setSeachInfo} />} />
            <Route path="/VesselList/" element={<VesselList setSeachInfo={setSeachInfo} />} />
            <Route path="/VesselOverview/" element={<VesselOverview setSeachInfo={setSeachInfo} />} />
            <Route path="/CiiAnalysis/" element={<CiiAnalysis setSeachInfo={setSeachInfo} />} />
            <Route path="/SpeedConsumption/" element={<SpeedConsumption setSeachInfo={setSeachInfo} />} />
            <Route path="/DataViewer/" element={<DataViewer setSeachInfo={setSeachInfo} />} />
            <Route path="/MasterInput/" element={<MasterInput setSeachInfo={setSeachInfo} />} />
            <Route path="/EuEtsAndFuelEu/" element={<EuEtsAndFuelEu setSeachInfo={setSeachInfo} />} />
            <Route path="/FleetOverview/" element={<FleetOverview setSeachInfo={setSeachInfo} />} />
          </Routes>
        </SearchContext.Provider>
      ) : (
        <Routes>
          <Route path="/*" element={<Error loading={loading} authCheckResult={authCheckResult} />} />
        </Routes>
      )}
    </BrowserRouter>
  );
}
