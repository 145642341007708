
import * as React from 'react';

import './SelectVesseNameComponent.css'; // スタイルのために追加

// MUI @mui/material/
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import InputAdornment from '@mui/material/InputAdornment';

// Dialog
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';

// 親コンポーネントから持ち回る値
export type Props = {
  vesselName: string;
  vesselOpen: boolean;
  currentFocusVessel: string;    // 矢印キー(↑↓)でドロップダウン船名変更時のフォーカスしている船
  imoNo: string;                 // SeachInfo.imoNo
  imoList: any[any];             // SeachInfo.imoList
  setVesseName: (value: string) => void;
  setVesselOpen: (value: boolean) => void;
  setCurrentFocusVessel: (value : string) => void;
  selectImo: (value: string) => void;
}

export const SelectVesseName: React.FC<Props> = props => {

  const [vesselName, setVesselName] = React.useState<string>(props.vesselName);
  const [tmpVesselName, setTmpVesselName] = React.useState<string>(''); // 表示船名一時保持用
  const [vesselOpen, setVesselOpen] = React.useState<boolean>(props.vesselOpen);
  const [filterDropDownVesselList, setFilterDropDownVesselList] = React.useState<any>(props.imoList); // 検索プルダウン用船名リスト
  const [currentFocusVessel, setCurrentFocusVessel] = React.useState<string>(props.currentFocusVessel); // 矢印キー(↑↓)でドロップダウン船名変更時のフォーカスしている船

  // VesselNameのテキストボックスにフォーカスを当てる。
  const ref = (element: HTMLInputElement | null) => {
    element?.focus();
  };

  // 船名クリック
  const onClickVesselOpen = () => {
    // 船名ドロップダウンオープン
    setVesselOpen(true);
    // 船名ドロップダウンを初期状態に更新
    setFilterDropDownVesselList(props.imoList);
    // 選択中の船名更新
    setCurrentFocusVessel(props.imoNo);
  }

  // 船名入力テキストボックスのチェンジイベント
  const onChangeVesselName = (event) => {
    // 入力文字列含む船名で絞り込み
    setFilterDropDownVesselList(props.imoList.filter(vessel => vessel.VesselName.toLowerCase().includes(event.target.value.toLowerCase())));
  }

  // 船名ドロップダウンクリック
  const onClickVesselName = (event) => {
    // 表示用船名更新
    setVesselName(event.target.innerHTML);
    props.setVesseName(event.target.innerHTML);
    // SerchInfoを更新
    props.selectImo(event.target.value);
    // 船名プルダウンクローズ
    setVesselOpen(false);
  }

  React.useEffect(() => {

    // キーボードイベントリスナーの設定
    const handleKeyDown = (event) => {
      if (event.key === 'ArrowDown') {
        changeFocusVessel(1);
      } else if (event.key === 'ArrowUp') {
        changeFocusVessel(-1);
      } else if (event.key === 'Enter') {
        // Enterキーで選択
        props.selectImo(currentFocusVessel);
        setVesselName(tmpVesselName);
        props.setVesseName(tmpVesselName);
        setVesselOpen(false);
      }
    };

    if (vesselOpen) {
      window.addEventListener('keydown', handleKeyDown);
      return () => {
        window.removeEventListener('keydown', handleKeyDown);
      };
    }
  }, [filterDropDownVesselList, currentFocusVessel, vesselOpen]);

  // 矢印キーでプルダウンを操作するときの処理
  const changeFocusVessel = (changeIndex: number) => {
    let nowIndex: number = -1;
    filterDropDownVesselList.forEach((vessel, index) => {
      if (vessel.imoNo == currentFocusVessel) {
        nowIndex = index
      }
    })

    let newFocusVessel;
    let newFocusVesselName;

    // indexが取れない場合、リストの最初の値を設定
    if (nowIndex < 0) {
      newFocusVessel = filterDropDownVesselList[0].imoNo.toString();
      newFocusVesselName = filterDropDownVesselList[0].VesselName.toString();
      
      setCurrentFocusVessel(newFocusVessel);
      setTmpVesselName(newFocusVesselName);

      return;
    }

    // 変更する船のindexがマイナス値の場合、リストの最後の値を取得
    if (nowIndex + changeIndex < 0) {
      newFocusVessel = filterDropDownVesselList[filterDropDownVesselList.length - 1].imoNo.toString();
      newFocusVesselName = filterDropDownVesselList[filterDropDownVesselList.length - 1].VesselName.toString();
    } else if (nowIndex + changeIndex > filterDropDownVesselList.length - 1) {
      // 変更する船のindexがリストの長さより大きい場合、リストの最初の値を取得
      newFocusVessel = filterDropDownVesselList[0].imoNo.toString();
      newFocusVesselName = filterDropDownVesselList[0].VesselName.toString();
    } else {
      newFocusVessel = filterDropDownVesselList[nowIndex + changeIndex].imoNo.toString();
      newFocusVesselName = filterDropDownVesselList[nowIndex + changeIndex].VesselName.toString();
    }

    if (newFocusVessel) {
      setCurrentFocusVessel(newFocusVessel);
      setTmpVesselName(newFocusVesselName);
    }
  }

  const handleClose = () => {
    setVesselOpen(false);
  }

  return (
    <>
      {/* VesselName選択ダイアログ */}
      <FormControl
        sx={{
          position: "absolute",
          top: "30px",
          left: "20px",
        }}>
        <div style={{ position: "absolute", top: "20px", left: "235px" }}>
          <InputAdornment position="start" disablePointerEvents>
            <Box sx={{ borderTop: "solid 2px rgb(255, 255, 255, 0.7)", borderRight: "solid 2px rgb(255, 255, 255, 0.7)", width: "8px", height: "8px", transform: "rotate(135deg)" }}></Box>
          </InputAdornment>
        </div>
        <div className="searchVesselName" onClick={onClickVesselOpen}>
          {/* <span>{vesselName}</span> */}
          <span>{vesselName.length != 0 ? vesselName : props.vesselName}</span>
        </div>
        <Dialog className="search-vessel-name-dialog" open={vesselOpen} onClose={handleClose}>
          <DialogTitle className="panel-title">
            <div className="search-vessel-container">
              <input
                className="searchInput"
                type="tel"
                onChange={onChangeVesselName}
                ref={ref}
              />
            </div>
          </DialogTitle>
          <DialogContent className="panel-body">
            <div className="vessel-container">
              <ul>
                {filterDropDownVesselList.map((imo, index) => (
                  <li
                    key={index}
                    style={{
                      backgroundColor: imo.imoNo == props.imoNo ?  currentFocusVessel != props.imoNo ? 'rgba(255, 255, 255, 0.35)' : '#777D86' : (imo.imoNo == currentFocusVessel ? '#777D86' : undefined),
                    }}
                    onClick={onClickVesselName} value={imo.imoNo}
                    >   
                    {imo.VesselName}
                  </li>
                ))}
              </ul>
            </div>
          </DialogContent>
        </Dialog>
      </FormControl>
    </>
  )
}

export default SelectVesseName;