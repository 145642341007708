
import * as React from 'react';

import './EmissionBoardSideBar.css'; // スタイルのために追加
import { SearchContext } from '../../App';
import { SelectVesseName } from '../../components/components/SelectVesseNameComponent';
import { SelectDate } from '../../components/components/select-date';
import { SelectTheme } from '../../components/theme/SelectTheme';
import { BootstrapInput } from '../../components/styled/styled';

// MUI @mui/material/
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import { ThemeProvider } from '@mui/material/styles'
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import InputAdornment from '@mui/material/InputAdornment';
import MenuItem from '@mui/material/MenuItem';

// MUI @mui/icons-material/
import SearchIcon from '@mui/icons-material/Search';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';

// Select Date
import dayjs from "dayjs";

// Axios Error
interface ErrorData {
  message: string;
  status: number;
  error: string;
}

function formatNumber(num) {
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

// 親コンポーネントから持ち回る値
export type Props = {
  vesselName: string;
  vesselOpen: boolean;
  currentFocusVessel: string;    // 矢印キー(↑↓)でドロップダウン船名変更時のフォーカスしている船
  unit: string;
  cliclSearchFlg: boolean;
  setVesselName: (value: string) => void;
  setVesselOpen: (value: boolean) => void;
  setCurrentFocusVessel: (value : string) => void;
  setUnit: (value: string) => void;
  setClickSearchFlg: (value: boolean) => void;
  selectImo: (value: string) => void;

  vesselMaster: any;
  setVesselMaster: (value: any) => void;

  SeachInfo: any;
  setSeachInfo: (value: any) => void;

  calledFunctionName: string; // このコンポーネントを呼び出した機能名
}

export const EmissionBoardSideBar: React.FC<Props> = props => {

  // userId有効期限チェック----------------------------------------------------------------------------------------------------------------------------
  const [message, setMessage] = React.useState<string>();
  const [userCheck, setUserCheck] = React.useState(false);
  let init = true

  const [user_id, setUser_id] = React.useState<string>();
  const [group_id, setGroup_id] = React.useState<string>();
  const [company_id, setCompany_id] = React.useState<string>();
  const [gid, setGid] = React.useState<string>();
  const [gidList, setGidList] = React.useState<string>();
  const [imoList, setImoList] = React.useState<string>();
  const [jwt, setJwt] = React.useState<string>();
  const [role, setRole] = React.useState<string>();

  // SelectVesselNameコンポーネント用
  const [vesselOpen, setVesselOpen] = React.useState<boolean>(false);
  const [currentFocusVessel, setCurrentFocusVessel] = React.useState<string>(''); // 矢印キー(↑↓)でドロップダウン船名変更時のフォーカスしている船
  const [vesselName, setVesselName] = React.useState<string>(''); // 表示船名

  const [aggregationUnitList, setAggregationUnitList] = React.useState<string[]>([]); // AggregationUnitの設定項目リスト

  // 初回のみ実行する処理
  React.useEffect(() => {

    // 当コンポーネントを呼び出した機能によって、設定値を変える。
    if (props.calledFunctionName === "cii-analysis") {
      // CII Analysisから呼び出された場合
      
      if (props.unit === undefined || props.unit === "") {
        props.setUnit("Monthly");
      }

      let tempAggregationUnitList = ["Monthly", "Weekly"]
      setAggregationUnitList(tempAggregationUnitList);
    }
    else {
      // 上記以外の場合（実質、Eu－ETS & FuelEUのみ）

      props.setUnit("Leg");

      let tempAggregationUnitList = ["Leg", "Voyage"]
      setAggregationUnitList(tempAggregationUnitList);
    }

  }, []);

  // Select Imo
  const selectImo = (imoNo: string) => {
    props.selectImo(imoNo)
  };

  // AggregationUnit変更イベント
  const selectUnit = (event: SelectChangeEvent) => {
    props.setUnit(event.target.value as string)
  };

  // Searchボタン押下イベント
  const handleClickSearch = () => {
    setLoading(true)
    setSuccess(false)
    
    // 親コンポーネントにボタンが押されたことを通知
    props.setClickSearchFlg(true);
  };

  // Vessel Information欄の表示制御
  React.useEffect(() => {
    setLoading(false)
    setSuccess(true)
  }, [props.vesselMaster]);

  // Loading--------------------------------------------------------------------
  const [loading, setLoading] = React.useState(false);
  const [success, setSuccess] = React.useState(true);

  // DataRnage----------------------------------------------------------------------------------------------------------------------------
  const [openDialog, setOpenDialog] = React.useState(false);
  const handleClickOpenDialog = () => {
    setOpenDialog(true);
  };

  // サイドバー展開--------------------------------------------------------------------
  // 現在のアクティブなタブを管理するための状態
  const [SeachTab, setSeachTab] = React.useState<boolean>(true);
  const [VesselInformationTab, setVesselInformationTab] = React.useState<boolean>(true);

  // サイドバーがクリックされた時に呼び出されるハンドラー関数
  const handleTabClick = (index) => {
    console.log("handleTabClick", index)
    if (index === 1) {
      setSeachTab(!SeachTab)
    }
    else if (index === 2) {
      setVesselInformationTab(!VesselInformationTab)
    }
  };

  return (
    <>
      <Box
        sx={{
          marginTop: "64px",
          overflowY: 'auto',
          height: "1000px",

          '&::-webkit-scrollbar': {
            width: "6px",
            height: "6px",
          },
          '&::-webkit-scrollbar-track': {
            borderRadius: "10px",
            background: "rgba(255, 255, 255, 0.5)",
          },
          '&::-webkit-scrollbar-thumb': {
            background: "#fff",
            borderRadius: "10px",
          },
          '&::-webkit-scrollbar-thumb:hover': {
            backgroundColor: '#555',
          },
          "&::-webkit-scrollbar-corner": {
            display: "none",
          }
        }}>

        {/* ↓ Space ↓ */}
        <Stack alignItems="center" sx={{ position: "relative", height: "20px" }}>
        </Stack>
        {/* ↑ Space ↑ */}

        {/* ↓ Select Search Conditions ↓ */}
        <Stack alignItems="left" sx={{ position: "relative" }}>
          {
            SeachTab !== true ?
              <>
                {/* title */}
                <Stack sx={{ position: "relative", height: "40px", cursor: "pointer", background: "rgba(59.32, 64.07, 71.19, 0.0)" }} onClick={() => handleTabClick(1)}>
                  <Box sx={{ position: "absolute", top: "14px", left: "25px", }}>
                    <Box sx={{ borderTop: "solid 2px rgb(255, 255, 255, 0.7)", borderRight: "solid 2px rgb(255, 255, 255, 0.7)", width: "8px", height: "8px", transform: "rotate(45deg)" }}></Box>
                  </Box>
                  <Box sx={{ position: "absolute", top: "0px", left: "42px" }}>
                    <Typography sx={{ color: "rgba(255, 255, 255, 1)", fontSize: "14px", fontFamily: "Roboto", fontWeight: 700, lineHeight: "38px", wordWrap: "break-word" }}>
                      Select Search Conditions
                    </Typography>
                  </Box>
                </Stack>
              </>
              :
              <>
                {/* title */}
                <Stack sx={{ position: "relative", height: "40px", cursor: "pointer", background: "rgba(59.32, 64.07, 71.19, 0.0)" }} onClick={() => handleTabClick(1)}>
                  <Box sx={{ position: "absolute", top: "11px", left: "25px", }}>
                    <Box sx={{ borderTop: "solid 2px rgb(255, 255, 255, 0.7)", borderRight: "solid 2px rgb(255, 255, 255, 0.7)", width: "8px", height: "8px", transform: "rotate(135deg)" }}></Box>
                  </Box>
                  <Box sx={{ position: "absolute", top: "0px", left: "42px" }}>
                    <Typography sx={{ color: "rgba(255, 255, 255, 1)", fontSize: "14px", fontFamily: "Roboto", fontWeight: 700, lineHeight: "38px", wordWrap: "break-word" }}>
                      Select Search Conditions
                    </Typography>
                  </Box>
                </Stack>

                {/* main */}
                <Stack sx={{ position: "relative", height: "450px", width: `calc(100% - 22px)` }}>

                  {/* Vessel Name */}
                  <Stack alignItems="center" sx={{ position: "relative", top: "10px", left: "22px", height: "110px" }}>
                    <Typography
                      align="left"
                      sx={{
                        position: "absolute",
                        top: "0px",
                        left: "20px",
                        color: 'white',
                        fontSize: "14px",
                        fontFamily: "'Roboto Bold'",
                        fontWeight: '700',
                        lineHeight: "24px",
                        wordWrap: 'break-word',
                      }}>
                      Vessel Name
                    </Typography>
                    <SelectVesseName
                      vesselName = {props.vesselName}
                      vesselOpen = {props.vesselOpen}
                      currentFocusVessel = {props.currentFocusVessel}
                      imoNo = {props.SeachInfo.imoNo}
                      imoList = {props.SeachInfo.imoList}
                      setVesseName = {(updateValue: string) => props.setVesselName(updateValue)}
                      setVesselOpen = {(updateValue: boolean) => props.setVesselOpen(updateValue)}
                      setCurrentFocusVessel = {(updateValue: string) => props.setCurrentFocusVessel(updateValue)}
                      selectImo = {(updateValue: string) => props.selectImo(updateValue)}
                      />
                  </Stack>

                  {/* Data Range */}
                  <Stack alignItems="center" sx={{ position: "relative", top: "0px", left: "22px", height: "110px" }}>
                    <Typography
                      align="left"
                      sx={{
                        position: "absolute",
                        top: "0px",
                        left: "20px",
                        color: 'white',
                        fontSize: "14px",
                        fontFamily: "'Roboto Bold'",
                        fontWeight: '700',
                        lineHeight: "24px",
                        wordWrap: 'break-word',
                      }}>
                      Data Range
                    </Typography>
                    <Button
                      variant="outlined"
                      onClick={handleClickOpenDialog}
                      sx={{
                        position: "absolute",
                        top: "30px",
                        left: "20px",
                        width: "265px",
                        height: 45,
                        background: 'rgba(255, 255, 255, 0.15)',
                        borderRadius: 30,
                        border: '1px #CBCBCB solid',
                        color: 'white',
                        fontSize: 15,
                        fontFamily: 'Roboto',
                        fontWeight: '700',
                        wordWrap: 'break-word',
                        ":hover": { backgroundColor: "#7a86a0", border: '1px #CBCBCB solid' },
                      }}>
                      <Stack direction='row' sx={{ height: 20 }} alignItems="center" justifyContent="center">
                        <CalendarMonthIcon />
                      </Stack>
                      <Typography align="left" sx={{ color: 'white', width: "100%", fontSize: "15px", marginLeft: 2 }}>
                        {dayjs(props.SeachInfo.from).utc().year() + "/" + (dayjs(props.SeachInfo.from).utc().month() + 1) + "/" + dayjs(props.SeachInfo.from).utc().date()}
                        ~
                        {dayjs(props.SeachInfo.to).utc().year() + "/" + (dayjs(props.SeachInfo.to).utc().month() + 1) + "/" + dayjs(props.SeachInfo.to).utc().date()}
                      </Typography>
                    </Button>
                  </Stack>
                  <Box >{<SelectDate setSeachInfo={props.setSeachInfo} SeachInfo={props.SeachInfo} openDialog={openDialog} setOpenDialog={setOpenDialog} />}</Box>

                  {/* Aggregation Unit */}
                  <Stack alignItems="center" sx={{ position: "relative", top: "0px", left: "22px", height: "110px" }}>
                    <Typography
                      align="left"
                      sx={{
                        position: "absolute",
                        top: "0px",
                        left: "20px",
                        color: 'white',
                        fontSize: "14px",
                        fontFamily: "'Roboto Bold'",
                        fontWeight: '700',
                        lineHeight: "24px",
                        wordWrap: 'break-word',
                      }}>
                      Aggregation Unit
                    </Typography>

                    <ThemeProvider theme={SelectTheme}>
                      <FormControl
                        sx={{
                          position: "absolute",
                          top: "30px",
                          left: "20px"
                        }}
                      >
                        <Select
                          IconComponent={() =>
                            <div style={{ position: "absolute", top: "20px", left: "235px" }}>
                              <InputAdornment position="start" disablePointerEvents>
                                <Box sx={{ borderTop: "solid 2px rgb(255, 255, 255, 0.7)", borderRight: "solid 2px rgb(255, 255, 255, 0.7)", width: "8px", height: "8px", transform: "rotate(135deg)" }}></Box>
                              </InputAdornment>
                            </div>
                          }
                          defaultValue={props.unit}
                          MenuProps={{
                            MenuListProps: {
                              disablePadding: true,
                            },
                            style: { zIndex: 100000, }
                          }}
                          sx={{
                            width: 265,
                            height: 40,
                            color: "white",
                            ":hover": { backgroundColor: "#7a86a0" },
                            borderRadius: 30,
                          }}
                          labelId="demo-controlled-open-select-label"
                          id="demo-controlled-open-select"
                          label="range"
                          input={<BootstrapInput />}
                          onChange={selectUnit}
                          value={props.unit}
                        >
                                        {aggregationUnitList.map((unit) => {
                                          return (
                                            <MenuItem key={unit} value={unit}>{unit}</MenuItem>
                                          )
                                        })}
                        </Select>
                      </FormControl>
                    </ThemeProvider>
                  </Stack>

                  {/* Search */}
                  <Stack sx={{ position: "relative" }}>
                    <Button
                      variant="contained"
                      onClick={handleClickSearch}
                      sx={{
                        position: "absolute",
                        top: "30px",
                        left: "65px",
                        alignItems: "center",
                        justifyContent: "center",
                        height: "40px",
                        width: 200,
                        background: '#E66300',
                        borderRadius: 30,
                        color: "#fff",
                        ":hover": {
                          bgcolor: "#FFFFFF",
                          color: "#40475D",
                        },
                        textTransform: 'none'
                      }}
                    >
                      <SearchIcon sx={{ marginRight: "4px", width: "20px", height: "20px" }} />
                      <Typography align="center" sx={{ fontSize: 15 }}>Search</Typography>
                    </Button>
                  </Stack>
                </Stack>
              </>
          }
        </Stack>
        {/* ↑ Select Search Conditions ↑ */}

        {/* ↓ Border ↓ */}
        <Stack alignItems="center" sx={{ position: "relative", height: "60px" }}>
          <Box sx={{ position: "absolute", top: "30px", width: `80%`, border: '1px rgba(255, 255, 255, 0.30) dotted' }}></Box>
        </Stack>
        {/* ↑ Border ↑ */}

        {/* ↓ VesselInformation ↓ */}
        <Stack alignItems="left" sx={{ position: "relative" }}>
          {
            VesselInformationTab !== true ?
              <>
                {/* title */}
                <Stack sx={{ position: "relative", height: "40px", cursor: "pointer", background: "rgba(59.32, 64.07, 71.19, 0.0)" }} onClick={() => handleTabClick(2)}>
                  <Box sx={{ position: "absolute", top: "14px", left: "25px", }}>
                    <Box sx={{ borderTop: "solid 2px rgb(255, 255, 255, 0.7)", borderRight: "solid 2px rgb(255, 255, 255, 0.7)", width: "8px", height: "8px", transform: "rotate(45deg)" }}></Box>
                  </Box>
                  <Box sx={{ position: "absolute", top: "0px", left: "42px" }}>
                    <Typography sx={{ color: "rgba(255, 255, 255, 1)", fontSize: "14px", fontFamily: "Roboto", fontWeight: 700, lineHeight: "38px", wordWrap: "break-word" }}>
                      Vessel Information
                    </Typography>
                  </Box>
                </Stack>
              </>
              :
              <>
                {/* title */}
                <Stack sx={{ position: "relative", height: "40px", cursor: "pointer", background: "rgba(59.32, 64.07, 71.19, 0.0)" }} onClick={() => handleTabClick(2)}>
                  <Box sx={{ position: "absolute", top: "12px", left: "25px", }}>
                    <Box sx={{ borderTop: "solid 2px rgb(255, 255, 255, 0.7)", borderRight: "solid 2px rgb(255, 255, 255, 0.7)", width: "8px", height: "8px", transform: "rotate(135deg)" }}></Box>
                  </Box>
                  <Box sx={{ position: "absolute", top: "0px", left: "42px" }}>
                    <Typography sx={{ color: "rgba(255, 255, 255, 1)", fontSize: "14px", fontFamily: "Roboto", fontWeight: 700, lineHeight: "38px", wordWrap: "break-word" }}>
                      Vessel Information
                    </Typography>
                  </Box>
                </Stack>

                {/* main */}
                {success ?

                  <Stack sx={{ position: "relative", height: "330px", width: `calc(100% - 22px)` }}>
                    <Grid container direction="row" sx={{ position: "absolute", left: "22px", top: "0px" }}>

                      {/* Vessel Type */}
                      <Grid item xs={12}>
                        <Grid container direction="row" >
                          <Grid item xs={1}>
                          </Grid>
                          <Grid item xs={5}>
                            <Typography sx={{ color: "rgba(255, 255, 255, 0.50)", fontSize: "13px", fontFamily: "Roboto", fontWeight: 700, lineHeight: "38px", wordWrap: "break-word" }}>
                              Vessel Type
                            </Typography>
                          </Grid>
                          <Grid item xs={1}>
                            <Box sx={{ color: "rgba(255, 255, 255, 0.30)", fontSize: "14px", fontFamily: "Roboto", fontWeight: 700, lineHeight: "38px", wordWrap: "break-word" }}>
                              :
                            </Box>
                          </Grid>
                          <Grid item xs={5}>
                            <Typography sx={{ color: "rgba(255, 255, 255, 1)", fontSize: "14px", fontFamily: "Roboto", fontWeight: 400, lineHeight: "38px", wordWrap: "break-word" }}>
                              {props.vesselMaster ? props.vesselMaster.VesselType : ""}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>

                      {/* Fuel Type */}
                      <Grid item xs={12}>
                        <Grid container direction="row" >
                          <Grid item xs={1}>
                          </Grid>
                          <Grid item xs={5}>
                            <Typography sx={{ color: "rgba(255, 255, 255, 0.50)", fontSize: "13px", fontFamily: "Roboto", fontWeight: 700, lineHeight: "38px", wordWrap: "break-word" }}>
                              Fuel Type
                            </Typography>
                          </Grid>
                          <Grid item xs={1}>
                            <Box sx={{ color: "rgba(255, 255, 255, 0.30)", fontSize: "14px", fontFamily: "Roboto", fontWeight: 700, lineHeight: "38px", wordWrap: "break-word" }}>
                              :
                            </Box>
                          </Grid>
                          <Grid item xs={5}>
                            <Typography sx={{ color: "rgba(255, 255, 255, 1)", fontSize: "14px", fontFamily: "Roboto", fontWeight: 400, lineHeight: "38px", wordWrap: "break-word" }}>
                              {props.vesselMaster ? props.vesselMaster.OilType : ""}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>

                      {/* Segmental */}
                      <Grid item xs={12}>
                        <Grid container direction="row" >
                          <Grid item xs={1}>
                          </Grid>
                          <Grid item xs={5}>
                            <Typography sx={{ color: "rgba(255, 255, 255, 0.50)", fontSize: "13px", fontFamily: "Roboto", fontWeight: 700, lineHeight: "38px", wordWrap: "break-word" }}>
                              Segmental
                            </Typography>
                          </Grid>
                          <Grid item xs={1}>
                            <Box sx={{ color: "rgba(255, 255, 255, 0.30)", fontSize: "14px", fontFamily: "Roboto", fontWeight: 700, lineHeight: "38px", wordWrap: "break-word" }}>
                              :
                            </Box>
                          </Grid>
                          <Grid item xs={5}>
                            <Typography sx={{ color: "rgba(255, 255, 255, 1)", fontSize: "14px", fontFamily: "Roboto", fontWeight: 400, lineHeight: "38px", wordWrap: "break-word" }}>
                              {props.vesselMaster ? props.vesselMaster.Size : ""}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>

                      {/* DeadWeight */}
                      <Grid item xs={12}>
                        <Grid container direction="row" alignItems={"center"}>
                          <Grid item xs={1}>
                          </Grid>
                          <Grid item xs={5}>
                            <Typography sx={{ color: "rgba(255, 255, 255, 0.50)", fontSize: "13px", fontFamily: "Roboto", fontWeight: 700, lineHeight: "38px", wordWrap: "break-word" }}>
                              DeadWeight
                            </Typography>
                          </Grid>
                          <Grid item xs={1}>
                            <Box sx={{ color: "rgba(255, 255, 255, 0.30)", fontSize: "14px", fontFamily: "Roboto", fontWeight: 700, lineHeight: "38px", wordWrap: "break-word" }}>
                              :
                            </Box>
                          </Grid>
                          <Grid item xs={2.5}>
                            <Typography align="left" sx={{ color: "rgba(255, 255, 255, 1)", fontSize: "14px", fontFamily: "Roboto", fontWeight: 400, lineHeight: "38px", wordWrap: "break-word" }}>
                              {props.vesselMaster ? formatNumber(parseFloat(props.vesselMaster.Deadweight)) : ""}
                            </Typography>
                          </Grid>
                          <Grid item xs={2.5}>
                            <Typography align="left" sx={{ color: 'rgba(255, 255, 255, 0.5)', fontSize: "14px", fontFamily: "'Roboto Condensed Regular'", wordWrap: 'break-word' }}>
                              {props.vesselMaster ? "ton" : ""}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>

                      {/* Gross */}
                      <Grid item xs={12}>
                        <Grid container direction="row" alignItems={"center"} >
                          <Grid item xs={1}>
                          </Grid>
                          <Grid item xs={5}>
                            <Typography sx={{ color: "rgba(255, 255, 255, 0.50)", fontSize: "13px", fontFamily: "Roboto", fontWeight: 700, lineHeight: "38px", wordWrap: "break-word" }}>
                              Gross
                            </Typography>
                          </Grid>
                          <Grid item xs={1}>
                            <Box sx={{ color: "rgba(255, 255, 255, 0.30)", fontSize: "14px", fontFamily: "Roboto", fontWeight: 700, lineHeight: "38px", wordWrap: "break-word" }}>
                              :
                            </Box>
                          </Grid>
                          <Grid item xs={2.5}>
                            <Typography align="left" sx={{ color: "rgba(255, 255, 255, 1)", fontSize: "14px", fontFamily: "Roboto", fontWeight: 400, lineHeight: "38px", wordWrap: "break-word" }}>
                              {props.vesselMaster ? formatNumber(parseFloat(props.vesselMaster.Grosstongue)) : ""}
                            </Typography>
                          </Grid>
                          <Grid item xs={2.5}>
                            <Typography align="left" sx={{ color: 'rgba(255, 255, 255, 0.5)', fontSize: "14px", fontFamily: "'Roboto Condensed Regular'", wordWrap: 'break-word' }}>
                              {props.vesselMaster ? "ton" : ""}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>

                      {/* Built Date */}
                      <Grid item xs={12}>
                        <Grid container direction="row" >
                          <Grid item xs={1}>
                          </Grid>
                          <Grid item xs={5}>
                            <Typography sx={{ color: "rgba(255, 255, 255, 0.50)", fontSize: "13px", fontFamily: "Roboto", fontWeight: 700, lineHeight: "38px", wordWrap: "break-word" }}>
                              Built Date
                            </Typography>
                          </Grid>
                          <Grid item xs={1}>
                            <Box sx={{ color: "rgba(255, 255, 255, 0.30)", fontSize: "14px", fontFamily: "Roboto", fontWeight: 700, lineHeight: "38px", wordWrap: "break-word" }}>
                              :
                            </Box>
                          </Grid>
                          <Grid item xs={5}>
                            <Typography sx={{ color: "rgba(255, 255, 255, 1)", fontSize: "14px", fontFamily: "Roboto", fontWeight: 400, lineHeight: "38px", wordWrap: "break-word" }}>
                              {props.vesselMaster ? props.vesselMaster.BuildDate : ""}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>

                      {/* Hull Cleaning Date */}
                      <Grid item xs={12}>
                        <Grid container direction="row" >
                          <Grid item xs={1}>
                          </Grid>
                          <Grid item xs={5}>
                            <Typography sx={{ color: "rgba(255, 255, 255, 0.50)", fontSize: "13px", fontFamily: "Roboto", fontWeight: 700, lineHeight: "38px", wordWrap: "break-word" }}>
                              Hull Cleaning Date
                            </Typography>
                          </Grid>
                          <Grid item xs={1}>
                            <Box sx={{ color: "rgba(255, 255, 255, 0.30)", fontSize: "14px", fontFamily: "Roboto", fontWeight: 700, lineHeight: "38px", wordWrap: "break-word" }}>
                              :
                            </Box>
                          </Grid>
                          <Grid item xs={5}>
                            <Typography sx={{ color: "rgba(255, 255, 255, 1)", fontSize: "14px", fontFamily: "Roboto", fontWeight: 400, lineHeight: "38px", wordWrap: "break-word" }}>
                              {props.vesselMaster ? props.vesselMaster.HullCleaningDate : ""}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>

                      {/* Last Dock Date */}
                      <Grid item xs={12}>
                        <Grid container direction="row" >
                          <Grid item xs={1}>
                          </Grid>
                          <Grid item xs={5}>
                            <Typography sx={{ color: "rgba(255, 255, 255, 0.50)", fontSize: "13px", fontFamily: "Roboto", fontWeight: 700, lineHeight: "38px", wordWrap: "break-word" }}>
                              Last Dock Date
                            </Typography>
                          </Grid>
                          <Grid item xs={1}>
                            <Box sx={{ color: "rgba(255, 255, 255, 0.30)", fontSize: "14px", fontFamily: "Roboto", fontWeight: 700, lineHeight: "38px", wordWrap: "break-word" }}>
                              :
                            </Box>
                          </Grid>
                          <Grid item xs={5}>
                            <Typography sx={{ color: "rgba(255, 255, 255, 1)", fontSize: "14px", fontFamily: "Roboto", fontWeight: 400, lineHeight: "38px", wordWrap: "break-word" }}>
                              {props.vesselMaster ? props.vesselMaster.LastDockDate : ""}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Stack>
                  :

                  <Stack sx={{ position: "relative", height: "330px", width: `${300 - 20}px` }}>
                    {loading && (
                      <CircularProgress
                        size={68}
                        sx={{
                          color: "grey",
                          position: 'absolute',
                          top: "100px",
                          left: "100px",
                          zIndex: 1,
                        }}
                      />
                    )}
                  </Stack>
                }
              </>
          }
        </Stack>
        {/* ↑ VesselInformation ↑ */}

      </Box>
    </>   
  )
}

export default EmissionBoardSideBar;