
import * as React from 'react';

import axios, { AxiosError } from "axios";

import './FleetOverview.css'; // スタイルのために追加
import { Main, Drawer } from '../../components/styled/styled';
import { callAuth } from '../../components/components/auth';
import { SearchContext } from '../../App';
import { MyAppBar } from '../../components/components/AppBar';

// MUI @mui/material/
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CssBaseline from '@mui/material/CssBaseline';
import Button from '@mui/material/Button';

// Dialog
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

// Axios Error
interface ErrorData {
  message: string;
  status: number;
  error: string;
}

export default function VesselList({ setSeachInfo }) {

  // userId有効期限チェック----------------------------------------------------------------------------------------------------------------------------
  const [message, setMessage] = React.useState<string>();
  const [userCheck, setUserCheck] = React.useState(false);
  let init = true

  const [user_id, setUser_id] = React.useState<string>();
  const [group_id, setGroup_id] = React.useState<string>();
  const [company_id, setCompany_id] = React.useState<string>();
  const [gid, setGid] = React.useState<string>();
  const [gidList, setGidList] = React.useState<string>();
  const [imoList, setImoList] = React.useState<string>();
  const [jwt, setJwt] = React.useState<string>();
  const [role, setRole] = React.useState<string>();

  React.useEffect(() => {
    if (init === true) {

      // localStrage取得----------------------------------------------------------------------------------------------------------------------------
      let jwt_ls
      if (localStorage.getItem("payload")) {
        const pay_json = localStorage.getItem("payload")
        if (pay_json !== null) {
          const pay = JSON.parse(pay_json)
          jwt_ls = pay["jwt"]
          setUser_id(pay["user_id"])
          setGroup_id(pay["group_id"])
          setCompany_id(pay["company_id"])
          setGid(pay["gid"])
          setGidList(pay["gidList"])
          setImoList(pay["imoList"])
          setJwt(jwt_ls)
          setRole(pay["role"])
          setSeachInfo({ imoNo: `${SeachInfo.imoNo}`, from: SeachInfo.from, to: SeachInfo.to, gid: pay["gid"], gidList: pay["gidList"], imoList: pay["imoList"] })
        }
      }

      if (jwt_ls) {
        callAuth(jwt_ls).then(result => {
          if (result) {
            console.log("認証成功[DataViewer]");

            // Loading解除
            setLoading(false);
            setSuccess(true);
            setMessage("Authentication success.")
          } else {
            console.log("認証失敗[DataViewer]");
            setUserCheck(true)
            setMessage("Authentication error.")
            localStorage.clear()
          }
        });
      }
    }

    init = false
  }, []);

  // WADATSUMIログイン画面遷移----------------------------------------------------------------------------------------------------------------------------
  const handleClickWADATSUMI = () => {
    window.location.replace(`${process.env.REACT_APP_WADATSUMIURL}`)
  };

  // コンテクスト取得----------------------------------------------------------------------------------------------------------------------------
  const SeachInfo: any = React.useContext(SearchContext);

  // URL----------------------------------------------------------------------------------------------------------------------------
  const [base_url, setBase_url] = React.useState(`${process.env.REACT_APP_API_URL}/FleetOverview/`);

  // Drawer（右側のVesselList部分（この画面を開いている間は常にtrue））--------------------------------------------------------------------------------
  const [openDrwer, setOpenDrwer] = React.useState(true);
  const handleDrawerOpen = () => {
    setOpenDrwer(true);
  };
  const handleDrawerClose = () => {
    setOpenDrwer(false);
  };

  // Loading--------------------------------------------------------------------
  const [loading, setLoading] = React.useState(false);
  const [success, setSuccess] = React.useState(true);


  // 時系列データ取得--------------------------------------------------------------------
  const [authCheck, setAuthCheck] = React.useState(false);
  const [authMessage, setAuthMessage] = React.useState("");

  // API呼び出し
  const getVesselInfo = () => {

    if (SeachInfo.imoNo !== "" && SeachInfo.from !== null && SeachInfo.to !== null) {

      const options = {
        headers: {
          'Authorization': jwt,
        }
      }
      const url = `${base_url}` // BaseURL＋必要なインプットを設定
      console.log("url:", url);
      axios.get(url, options).then((res) => {
        console.log("axios", res)

        // 認可チェック
        if (res.data === 401) {
          setAuthCheck(true)
          setAuthMessage("不正な操作が行われた可能性があります。")
        } else if (res.data === 500) {
          setAuthCheck(true)
          setAuthMessage("JWTが改ざんされている可能性があります。")
        } else {
          setAuthCheck(false)
        }

        // APIからの戻りをフロント側の変数に設定する処理


        // Loading解除
        setLoading(false);
        setSuccess(true);
      })
        .catch((e: AxiosError<ErrorData>) => {
          if (e.response === undefined) {
            console.log("undefinedError: " + e);
            return;
          }
          console.log(e + ", message: " + e.response.data.message);
        });
    }
  }

  return (
    <Box height="100vh" sx={{ display: 'flex' }}>
      <CssBaseline />
      {/* useId有効期限切れダイアログ */}
      <Dialog
        open={userCheck}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "600px",  // Set your width here
              height: "100%",
              maxHeight: "210px",  // Set your width here
            },
          },
        }}
      >
        <DialogTitle
          sx={{
            bgcolor: "#9ea2ad",
            height: "50px",
            color: "white",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center"
          }}>
          UserId Update
        </DialogTitle>
        <DialogContent
          sx={{
            background: "linear-gradient(#40475d, #0d1532)",
            height: "100px",
          }}>
          <Typography align="left" sx={{ marginTop: "32px", color: 'white', fontSize: 13, fontFamily: 'Roboto Condensed', fontWeight: '400', wordWrap: 'break-word' }}>
            {message === "" ? "" : message}
          </Typography>
        </DialogContent>
        <DialogActions
          sx={{
            height: "50px",
            bgcolor: "#474C53",
          }}>
          <Button
            sx={{
              marginLeft: "0px",
              marginRight: "-8px",
              backgroundColor: "#e66300",
              width: "150px",
              height: "50px",
              padding: "0",
              fontSize: "18px",
              fontFamily: "'Roboto Bold', 'Meiryo', sans-serif",
              fontWeight: "bold",
              color: "white",
              borderRadius: "0px",
              borderBottomRightRadius: "2px",
              ":hover": {
                bgcolor: "#FFFFFF",
                color: "#40475D",
              },
              textTransform: 'none'
            }}
            onClick={handleClickWADATSUMI}>
            OK
          </Button>
        </DialogActions>
      </Dialog>

      {/* 認可チェックダイアログ */}
      <Dialog
        open={authCheck}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "600px",  // Set your width here
              height: "100%",
              maxHeight: "210px",  // Set your width here
            },
          },
        }}
      >
        <DialogTitle
          sx={{
            bgcolor: "#9ea2ad",
            height: "50px",
            color: "white",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center"
          }}>
          Auth check
        </DialogTitle>
        <DialogContent
          sx={{
            background: "linear-gradient(#40475d, #0d1532)",
            height: "100px",
          }}>
          <Typography align="left" sx={{ marginTop: "32px", color: 'white', fontSize: 13, fontFamily: 'Roboto Condensed', fontWeight: '400', wordWrap: 'break-word' }}>
            {authMessage === "" ? "" : authMessage}
          </Typography>
        </DialogContent>
        <DialogActions
          sx={{
            height: "50px",
            bgcolor: "#474C53",
          }}>
          <Button
            sx={{
              marginLeft: "0px",
              marginRight: "-8px",
              backgroundColor: "#e66300",
              width: "150px",
              height: "50px",
              padding: "0",
              fontSize: "18px",
              fontFamily: "'Roboto Bold', 'Meiryo', sans-serif",
              fontWeight: "bold",
              color: "white",
              borderRadius: "0px",
              borderBottomRightRadius: "2px",
              ":hover": {
                bgcolor: "#FFFFFF",
                color: "#40475D",
              },
              textTransform: 'none'
            }}
            onClick={() => setAuthCheck(false)}>
            OK
          </Button>
        </DialogActions>
      </Dialog>


      {/* menu bar */}
      <MyAppBar company_id={company_id} group_id={group_id} user_id={user_id} role={role} />

      {/* side bar */}
      <Drawer
        variant="permanent"
        open={openDrwer}
        sx={{
          // background: 'linear-gradient(180deg, #3B3D43 0%, #383A40 100%)',
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            background: '#282F35',
            boxSizing: 'border-box',
            borderRight: "1px solid rgb(201,201,201,0.2)"
          },
        }}>
        
        {/* side bar */}
        <React.Fragment>
        </React.Fragment >
      </Drawer >

      {/* メイン部分 */}
      <Main sx={{overflow: "hidden"}}>
        FleetOverview
      </Main>
    </Box>
  );
} 