
import * as React from 'react';

import axios, { AxiosError } from "axios";

import './EuSimulation.css'; // スタイルのために追加
import { BootstrapInput } from '../../../components/styled/styled';
import { EuEditConditionsDialog } from './EuEditConditionsDialog';

// MUI @mui/material/
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';

// MUI @mui/icons-material/
import SettingsIcon from '@mui/icons-material/Settings';

// Dialog
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

// Select Date
import Select, { SelectChangeEvent } from '@mui/material/Select';

// アイコン
import RightArrow from '../../../assets/images/right_arrow.svg';
import EditConditionsIcon from '../../../assets/images/simulation_edit_condition.svg';
import Input from '@mui/material/Input';
import { ThemeProvider } from '@mui/material/styles';
import { SelectTheme } from '../../../components/theme/SelectTheme';
import FormControl from '@mui/material/FormControl';
import InputAdornment from '@mui/material/InputAdornment';
import MenuItem from '@mui/material/MenuItem';
import HighchartsReact from 'highcharts-react-official';
import * as Highcharts from 'highcharts';

// Axios Error
interface ErrorData {
  message: string;
  status: number;
  error: string;
}


// 親コンポーネントから持ち回る値
export type Props = {
  changeVesselFlg: boolean;
  setChangeVesselFlg: (value: boolean) => void;
  vesselMaster: any;
  setVesselMaster: (value: any) => void;
  focFormulaBallast: any[];
  setFocFormulaBallast: (value: any[]) => void;
  focFormulaLaden: any[];
  setFocFormulaLaden: (value: any[]) => void;
  focFormuAux: any;
  setFocFormuAux: (value: any) => void;
  setAuthCheck: (value: boolean) => void;
  setAuthMessage: (value: any) => void;

  SeachInfo: any;
  setSeachInfo: (value: any) => void;
}

function formatNumber(num) {
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

// EU Simulation Component--------------------------------------------------------------------
export const EuSimulation: React.FC<Props> = props => {

  // Loading--------------------------------------------------------------------
  const [loading_UpperBody, setLoading_UpperBody] = React.useState(false);
  const [success_UpperBody, setSuccess_UpperBody] = React.useState(true);
  const [loading_LowerBody, setLoading_LowerBody] = React.useState(false);
  const [success_LowerBody, setSuccess_LowerBody] = React.useState(true);

  const [jwt, setJwt] = React.useState<string>();
  const [userId, setUserId] = React.useState<string>();

  // URL
  const [base_url, setBase_url] = React.useState(`${process.env.REACT_APP_API_URL}/EuEtsFuelEu/Simulation/`);
  // 燃料リスト
  const [fuelOileList, setFuelOileList] = React.useState<any>([]);
  // Leg情報リスト
  const [legInfoList, setLegInfoList] = React.useState<any>([]);
  
  // 取得データ
  const [rows, setRows] = React.useState<any>([])

  // 初期処理
  React.useEffect(() => {

    // Loading表示
    setLoading_UpperBody(true)
    setSuccess_UpperBody(false)
    setLoading_LowerBody(true)
    setSuccess_LowerBody(false)

    // VoyagePlanタブ選択状態にする。
    setActiveSimulationTab(0);
    
    // API呼び出し
    // 暫定対応：：初回のみ、UserIDをpayloadから取得
    let payloadUserId = ""
    let payloadJwt = ""
    const pay_json = localStorage.getItem("payload")
    if (pay_json !== null) {
      const pay = JSON.parse(pay_json)
      payloadUserId = pay["user_id"];
      payloadJwt = pay["jwt"];
      setUserId(payloadUserId);
      setJwt(payloadJwt);
    }

    if (props.SeachInfo.imoNo !== undefined && props.SeachInfo.imoNo !== "") {
      const url = `${base_url}Init/%23?&user=${payloadUserId}&imo=${props.SeachInfo.imoNo}&plan=Voyage`
      getSimulationResultInit(url, payloadJwt);
    }
    else {
      // Loading解除
      setLoading_UpperBody(false)
      setSuccess_UpperBody(true)
      setLoading_LowerBody(false)
      setSuccess_LowerBody(true)
    }
  }, []);

  // VesselName変更
  React.useEffect(() => {

    // Loading表示
    setLoading_UpperBody(true)
    setSuccess_UpperBody(false)
    setLoading_LowerBody(true)
    setSuccess_LowerBody(false)

    // VoyagePlan選択状態に変更
    setActiveSimulationTab(0);

    reset_all()
    
    // 親コンポーネントからchangeVesselFlgがtrueで飛んできた場合、検索処理実行
    if (props.changeVesselFlg === true) {
      const url = `${base_url}Init/%23?&user=${userId}&imo=${props.SeachInfo.imoNo}&plan=Voyage`     
      getSimulationResultInit(url, jwt);
    }
    else {
      // Loading表示
      setLoading_UpperBody(false)
      setSuccess_UpperBody(true)
      setLoading_LowerBody(false)
      setSuccess_LowerBody(true)
    }
    
    // changeVesselFlgをfalseに更新
    props.setChangeVesselFlg(false);

  }, [props.changeVesselFlg]);

  // ダイアログ関連--------------------------------------------------------------------
  const [openDialogEditConditions, setOpenDialogEditConditions] = React.useState(false);
  const handleClickOpenDialogEditConditions = () => {
    setOpenDialogEditConditions(true);
  };

  // legInfoListが更新されたとき（EditConditionsでSave押下後に呼ばれる）
  const [onClickSaveFlg, setOnClickSaveFlg] = React.useState(false);
  React.useEffect(() => {

    if (onClickSaveFlg) {
      // 画面下部の初期化
      // EUA
      setEUA([])
      setEUA_YAXIS({ tickInterval: 1000, max: 2000 })
      // CB
      setCB([])
      setCB_Simulation([])
      setCB_YAXIS({ tickInterval: 1000, max: 1000, min: -1000 })
      // 画面下部の右側
      setVoyageInformationTotal(undefined);

      setOnClickSaveFlg(false);
    }
  }, [onClickSaveFlg]);

  // VoyageプランのSimulationボタン押下
  const handleClickSimulationVoyage = () => {

    // Loading表示
    setLoading_LowerBody(true)
    setSuccess_LowerBody(false)

    // SimulationResultの右側初期化
    setVoyageInformationTotal(undefined);

    // API呼び出し
    const url = `${base_url}VoyagePlan/%23?&user=${userId}&imo=${props.SeachInfo.imoNo}&plan=Voyage`   
    getSimulationResultVoyage(url, jwt);
  };

  // SpeedプランのSimulationボタン押下
  const handleClickSimulationSpeed = () => {

    let checkResult: any = [];

    // 入力チェック
    // FuelRateの合計が100%になるかのチェック
    let tmpFuelRate1 = 0
    let tmpFuelRate2 = 0
    let tmpFuelRate3 = 0
    let tmpTotalFuelRate = 0
    if (SpeedPlan_FuelRate1 !== "") { tmpFuelRate1 = Number(SpeedPlan_FuelRate1) }
    if (SpeedPlan_FuelRate2 !== "") { tmpFuelRate2 = Number(SpeedPlan_FuelRate2) }
    if (SpeedPlan_FuelRate3 !== "") { tmpFuelRate3 = Number(SpeedPlan_FuelRate3) }
    tmpTotalFuelRate = tmpFuelRate1 + tmpFuelRate2 + tmpFuelRate3
    if (tmpTotalFuelRate !== 100) {
      checkResult.push("Please enter FuelRate so that the total is 100.")
    }

    // EU Rateが0 or 50 or 100のいずれかであることのチェック
    if (SpeedPlan_EuRate !== "0" && SpeedPlan_EuRate !== "50" && SpeedPlan_EuRate !== "100" ) {
      checkResult.push("Please enter a value of 0, 50, or 100 for EURate.")
    }

    if (checkResult.length !== 0) {
      // エラーメッセージを作成しalertで表示

      let error_string = "";

      checkResult.forEach(function(element) {
        if (error_string !== "") {
          error_string = error_string + "\n";
        }
        error_string = error_string + element;
      });
      alert(error_string);
      return;
    }

    // Loading表示
    setLoading_LowerBody(true)
    setSuccess_LowerBody(false)

    // SimulationResultの右側初期化
    setVoyageInformationTotal(undefined);

    // 登録用データ作成
    let tempFuelString = "";

    // fuel登録用の文字列作成
    if (SpeedPlan_Fuel1 !== "-") {
      tempFuelString = "(" + SpeedPlan_Fuel1 + "," + SpeedPlan_FuelRate1 + ")"
    }
    if (SpeedPlan_Fuel2 !== "-") {

      if (tempFuelString !== "") {
        tempFuelString = tempFuelString + ","
      }

      tempFuelString = tempFuelString + "(" + SpeedPlan_Fuel2 + "," + SpeedPlan_FuelRate2 + ")"
    }
    if (SpeedPlan_Fuel3 !== "-") {

      if (tempFuelString !== "") {
        tempFuelString = tempFuelString + ","
      }

      tempFuelString = tempFuelString + "(" + SpeedPlan_Fuel3 + "," + SpeedPlan_FuelRate3 + ")"
    }

    const value = {
      // SpeedPlan
      "imo": String(props.SeachInfo.imoNo),
      "operator": SpeedPlan_Operator,
      "salling_rate": SpeedPlan_Sailing,
      "dispracement_rate": SpeedPlan_Ballast,
      "log_speed_ballast": SpeedPlan_Log_Speed_Ballast,
      "log_speed_laden": SpeedPlan_Log_Speed_Laden,
      "fuel": tempFuelString,
      "eu_rate": SpeedPlan_EuRate,
    }

    // API呼び出し
    const url = `${base_url}SpeedPlan/%23?&user=${userId}&imo=${props.SeachInfo.imoNo}&plan=Speed`   
    getSimulationResultSpeed(url, value, jwt);
  };

  // タブがクリックされた時に呼び出されるハンドラー関数
  const [activeSimulationTab, setActiveSimulationTab] = React.useState(0);
  const handleSimulationTabClick = (index) => {
    setActiveSimulationTab(index);

    reset_all();

    // Loading表示
    setLoading_UpperBody(true)
    setSuccess_UpperBody(false)
    setLoading_LowerBody(true)
    setSuccess_LowerBody(false)

    // API呼び出し   
    if (index === 0) {
      // VoyagePlanの場合、VoyagePlanのSimulation呼び出し
      const url = `${base_url}Init/%23?&user=${userId}&imo=${props.SeachInfo.imoNo}&plan=Voyage`
      getSimulationResultInit(url, jwt);
    }
    else if (index === 1) {
      // SpeedPlanの場合、SpeedPlanのSimulation呼び出し
      const url = `${base_url}Init/%23?&user=${userId}&imo=${props.SeachInfo.imoNo}&plan=Speed`
      getSimulationResultInit(url, jwt);
    }
  };

  // 初期表示用Simulationデータ取得
  const getSimulationResultInit = (url, jwt) => {
    console.log("EU Simulation Init");

    const options = {
      headers: {
        'Authorization': jwt,
      }
    }
    console.log("URL", url);
    axios.get(url, options).then((res) => {

      console.log("axios", res);

      // 認可チェック
      if (res.data === 401) {
        props.setAuthCheck(true)
        props.setAuthMessage("不正な操作が行われた可能性があります。")
      } else if (res.data === 500) {
        props.setAuthCheck(true)
        props.setAuthMessage("JWTが改ざんされている可能性があります。")
      } else {
        props.setAuthCheck(false)
      }

      props.setVesselMaster(res.data.VESSELMASTER);
      props.setFocFormulaBallast(res.data.FOCFormulas.ME_Ballast)
      props.setFocFormulaLaden(res.data.FOCFormulas.ME_Laden)
      props.setFocFormuAux(res.data.FOCFormulas.AuxiliryEquipment)
      setFuelOileList(res.data.FuelOilList)
      setLegInfoList(res.data.SimulationInformationVoyageList)

      setEUA(res.data.EUAList);
      setCB(res.data.CBList_YeartoDate);
      setCB_Simulation(res.data.CBList_Simulation);
      setX_Axis_List(res.data.XAxisList);
      setEUA_YAXIS(res.data.EUA_YAXIS)
      setCB_YAXIS(res.data.CB_YAXIS)
      // setFOCyAxisMin(0)
      // setFOCyAxisMax(res.data.FOC_YAXIS.max)
      // setFOCyAxisMaxShow(0)
      // setFOCyAxisMaxShow(res.data.FOC_YAXIS.max)
      setVoyageInformationTotal(res.data.SimulationResultTotal);

      console.log("time:" + res.data.SimulationInformationSpeedList[0].time_to_endofyear)
      if (res.data.SimulationInformationSpeedList.length > 0) {
        setSpeedPlan_TimeToEndofYear(res.data.SimulationInformationSpeedList[0].time_to_endofyear);
        setSpeedPlan_Operator(res.data.SimulationInformationSpeedList[0].operator);
        setSpeedPlan_Sailing(res.data.SimulationInformationSpeedList[0].servise_rate_salling);
        setSpeedPlan_Ballast(res.data.SimulationInformationSpeedList[0].displacement_rate_ballast);
        setSpeedPlan_Log_Speed_Ballast(res.data.SimulationInformationSpeedList[0].avg_speed_ballast);
        setSpeedPlan_Log_Speed_Laden(res.data.SimulationInformationSpeedList[0].avg_speed_laden);

        // Fuelの設定値作成
        let tmpFuel1 = "-";
        let tmpFuelRate1 = "";
        let tmpFuel2 = "-";
        let tmpFuelRate2 = "";
        let tmpFuel3 = "-";
        let tmpFuelRate3 = "";
        if (res.data.SimulationInformationSpeedList[0].fuel !== "" ) {         
          res.data.SimulationInformationSpeedList[0].fuel.forEach(function(fuelInfo, index) {   
            // idexは3が最大
            if (index === 0) {
              tmpFuel1 = fuelInfo.fuel_type;
              tmpFuelRate1 = fuelInfo.fuel_rate;
            }
            else if (index === 1) {
              tmpFuel2 = fuelInfo.fuel_type;
              tmpFuelRate2 = fuelInfo.fuel_rate;
            }
            else if (index === 2) {
              tmpFuel3 = fuelInfo.fuel_type;
              tmpFuelRate3 = fuelInfo.fuel_rate;
            }
        
          });
        }
        setSpeedPlan_Fuel1(tmpFuel1);
        setSpeedPlan_Fuel2(tmpFuel2);
        setSpeedPlan_Fuel3(tmpFuel3);
        setSpeedPlan_FuelRate1(tmpFuelRate1);
        setSpeedPlan_FuelRate2(tmpFuelRate2);
        setSpeedPlan_FuelRate3(tmpFuelRate3);
        setSpeedPlan_EuRate(res.data.SimulationInformationSpeedList[0].eu_rate);
        setSpeedPlan_Distance(res.data.SimulationInformationSpeedList[0].distance);
        setSpeedPlan_Foc(res.data.SimulationInformationSpeedList[0].foc);
        setSpeedPlan_EUA(res.data.SimulationInformationSpeedList[0].eua);
        setSpeedPlan_CB(res.data.SimulationInformationSpeedList[0].cb);
      }

      // Loading解除
      setLoading_UpperBody(false)
      setSuccess_UpperBody(true)
      setLoading_LowerBody(false)
      setSuccess_LowerBody(true)
    })
      .catch((e: AxiosError<ErrorData>) => {
        // reset_all()
        setLoading_UpperBody(false)
        setSuccess_UpperBody(true)
        setLoading_LowerBody(false)
        setSuccess_LowerBody(true)
        if (e.response === undefined) {
          console.log("undefinedError: " + e);
          return;
        }
        console.log(e + ", message: " + e.response.data.message)
      });
  }

  // VoyagePlanのSimulationデータ取得
  const getSimulationResultVoyage = (url, jwt) => {
    console.log("Simulation Voyage Plan");

    const options = {
      headers: {
        'Authorization': jwt,
      }
    }
    console.log("url[SimulationVoyagePlan]:", url);
    axios.get(url, options).then((res) => {

      console.log("axios", res);

      // 認可チェック
      if (res.data === 401) {
        props.setAuthCheck(true)
        props.setAuthMessage("不正な操作が行われた可能性があります。")
      } else if (res.data === 500) {
        props.setAuthCheck(true)
        props.setAuthMessage("JWTが改ざんされている可能性があります。")
      } else {
        props.setAuthCheck(false)
      }

      setEUA(res.data.EUAList);
      setCB(res.data.CBList_YeartoDate);
      setCB_Simulation(res.data.CBList_Simulation);
      setX_Axis_List(res.data.XAxisList);
      setEUA_YAXIS(res.data.EUA_YAXIS)
      setCB_YAXIS(res.data.CB_YAXIS)
      // setFOCyAxisMin(0)
      // setFOCyAxisMax(res.data.FOC_YAXIS.max)
      // setFOCyAxisMaxShow(0)
      // setFOCyAxisMaxShow(res.data.FOC_YAXIS.max)
      setVoyageInformationTotal(res.data.SimulationResultTotal);

      // Loading解除
      setLoading_LowerBody(false)
      setSuccess_LowerBody(true)
    })
      .catch((e: AxiosError<ErrorData>) => {
        reset_all()
        setLoading_LowerBody(false)
        setSuccess_LowerBody(true)
        if (e.response === undefined) {
          console.log("undefinedError: " + e);
          return;
        }
        console.log(e + ", message: " + e.response.data.message)
      });
  }

  // SpeedPlanのSimulationデータ取得
  const getSimulationResultSpeed = (url, value, jwt) => {
    console.log("Simulation Speed Plan");

    if (props.SeachInfo.imoNo !== "" && jwt !== undefined) {
      console.log("insertFocFormualsスタート", value)
      const formData = new FormData();
      Object.keys(value).forEach(key => {
        formData.append(key, value[key]);
      });
      const options = {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': jwt
        }
      }
      console.log("url[SimulationSpeedPlan]:", url);
      axios.post(url, value, options).then((res) => {
        console.log("API Response", res.data);

        // 認可チェック
        if (res.data === 401) {
          props.setAuthCheck(true)
          props.setAuthMessage("不正な操作が行われた可能性があります。")
        } else if (res.data === 500) {
          props.setAuthCheck(true)
          props.setAuthMessage("JWTが改ざんされている可能性があります。")
        } else {
          props.setAuthCheck(false)
        }

        setEUA(res.data.EUAList);
        setCB(res.data.CBList_YeartoDate);
        setCB_Simulation(res.data.CBList_Simulation);
        setX_Axis_List(res.data.XAxisList);
        setEUA_YAXIS(res.data.EUA_YAXIS)
        setCB_YAXIS(res.data.CB_YAXIS)
        // setFOCyAxisMin(0)
        // setFOCyAxisMax(res.data.FOC_YAXIS.max)
        // setFOCyAxisMaxShow(0)
        // setFOCyAxisMaxShow(res.data.FOC_YAXIS.max)
        setVoyageInformationTotal(res.data.SimulationResultTotal);
  
        if (res.data.SimulationInformationSpeedList.length > 0) {
          setSpeedPlan_TimeToEndofYear(res.data.SimulationInformationSpeedList[0].time_to_endofyear);
          setSpeedPlan_Operator(res.data.SimulationInformationSpeedList[0].operator);
          setSpeedPlan_Distance(res.data.SimulationInformationSpeedList[0].distance);
          setSpeedPlan_EuRate(res.data.SimulationInformationSpeedList[0].eu_rate);
          setSpeedPlan_Foc(res.data.SimulationInformationSpeedList[0].foc);
          setSpeedPlan_EUA(res.data.SimulationInformationSpeedList[0].eua);
          setSpeedPlan_CB(res.data.SimulationInformationSpeedList[0].cb);
        }

        // Loading解除
        setLoading_LowerBody(false)
        setSuccess_LowerBody(true)

      })
        .catch((e: AxiosError<ErrorData>) => {
          // reset_all()
          setLoading_LowerBody(false)
          setSuccess_LowerBody(true)
          if (e.response === undefined) {
            console.log("undefinedError: " + e);
            return;
          }
          console.log(e + ", message: " + e.response.data.message);
        });
    }
  }

  // Rateグラフのリセットボタンがクリックされたら
  const [changeClickGraphReSet, setChangeClickGraphReSet] = React.useState<number>(0);
  // const [ClickGraphReSetOn, setClickGraphReSetOn] = React.useState<boolean>(false);
  const onClickGraphReSet = (event: any) => {
    console.log("onClickGraphReSet")
    setChangeClickGraphReSet((prev) => (prev + 1));
    setClickItemGraph({ index: 0, x: null, y: null })
  }

  // グラフ上の線をクリックされたら
  const [changeClickGraph, setChangeClickGraph] = React.useState<number>(0);
  const [clickItemGraph, setClickItemGraph] = React.useState({ index: 0, x: null, y: null }); // クリックした点の値

  const onClickGraph = (event: any) => { //グラフ上の点をクリック
    setChangeClickGraph((prev) => (prev + 1));
    setClickItemGraph({ index: event.point.index, x: event.point.x, y: event.point.y });
  }

  const [key, setKey] = React.useState(0);

  const [EUA, setEUA] = React.useState<Array<Array<any>>>();
  const [CB, setCB] = React.useState<Array<Array<any>>>();
  const [CB_Simulation, setCB_Simulation] = React.useState<Array<Array<any>>>();
  const [X_Axis_List, setX_Axis_List] = React.useState<Array<any>>();
  const [EUA_YAXIS, setEUA_YAXIS] = React.useState({ tickInterval: 1000, max: 2000 });
  const [CB_YAXIS, setCB_YAXIS] = React.useState({ tickInterval: 1000, max: 1000, min: -1000 });
  const [voyageInformationTotal, setVoyageInformationTotal] = React.useState<any>();
  const [CIINone, setCIINone] = React.useState<any>({ name: "", top: "", left: "" });

  const [openDialogyAxis, setOpenDialogAxis] = React.useState(false);
  const handleClickOpenDialogAxis = () => {
    setOpenDialogAxis(true);
  };
  const [FOCyAxisMin, setFOCyAxisMin] = React.useState<any>(0);
  const [FOCyAxisMax, setFOCyAxisMax] = React.useState<any>(2000);
  const [FOCyAxisMinShow, setFOCyAxisMinShow] = React.useState<any>(0);
  const [FOCyAxisMaxShow, setFOCyAxisMaxShow] = React.useState<any>(2000);
  const handleNoClickOpenDialogAxis = () => {
    setFOCyAxisMinShow(FOCyAxisMin)
    setFOCyAxisMaxShow(FOCyAxisMax)
    setOpenDialogAxis(false);
  };
  const handleClickFOCyAxisMinShow = (event) => {
    if (/^\d*$/.test(event)) { setFOCyAxisMinShow(event); }
    else { alert("Please enter numbers in half-width characters.") }
  }
  const handleClickFOCyAxisMaxShow = (event) => {
    if (/^\d*$/.test(event)) { setFOCyAxisMaxShow(event); }
    else { alert("Please enter numbers in half-width characters.") }
  }
  const handleClickSetAxis = () => {
    if (FOCyAxisMin >= FOCyAxisMax) {
      alert("Max must be greater than Min.")
    }
    else if (FOCyAxisMin === "" || FOCyAxisMax === "" || FOCyAxisMin === undefined || FOCyAxisMax === undefined) {
      alert("No spaces allowed. Please enter a value.")
    }
    else {
      setFOCyAxisMin(FOCyAxisMinShow)
      setFOCyAxisMax(FOCyAxisMaxShow)
      setOpenDialogAxis(false);
      setKey(key + 1);
    }
  };

  // EuaCbOptions--------------------------------------------------------------------
  const EuaCbOptions: Highcharts.Options = {
    navigation: {
      buttonOptions: {
        enabled: false,
      }
    },
    credits: {
      enabled: false
    },
    chart: {
      // marginTop: window.innerHeight * 0.1,
      height: window.innerHeight * 0.3,
      width: window.innerWidth - 800,
      marginLeft: 120,
      zooming: { type: "xy" },
      alignTicks: false,
      backgroundColor: 'rgba(0, 0, 0, 0)', // 背景色を透明にする
      // plotBackgroundColor: {
      //   linearGradient: { x1: 0, x2: 0, y1: 1, y2: 0 },
      //   stops: [
      //     [0, 'rgba(255, 255, 255, 0.03)'],
      //     [0.8529, 'rgba(255, 255, 255, 0.13)'],
      //     [1, 'rgba(255, 255, 255, 0.15)']
      //   ]
      // },
      events: {
        click: function (event) {
          onClickGraphReSet(event)
        },
      }
    },
    tooltip: {
      enabled: false
    },
    legend: {
      enabled: false,
    },
    title: {
      text: '時系列の折れ線グラフと棒グラフの組み合わせ',
      style: {
        color: 'transparent'
      }
    },
    plotOptions: {
      column: {
        grouping: true,
        pointPlacement: 'on',
        // groupPadding: 5,
      },
      series: {
        cursor: 'pointer',
        point: {
          events: {
            click: (event) => {
              onClickGraph(event);
            }
          }
        },
      },
    },
    xAxis: [
      {
        type: 'category',
        title: {
          text: "",
          style: {
            color: 'white'
          }
        },
        // type: 'datetime',
        labels: {
          enabled: true,
          style: {
            color: 'rgba(255, 255, 255, 0.70)',
            fontFamily: 'Roboto Condensed',
            fontSize: '13px'
          }
        },
        gridLineWidth: 0
      },
    ],
    yAxis: [
      { // EUA
        opposite: false,
        min: 0,
        max: 2000,
        tickInterval: 1000,
        gridLineDashStyle: 'Dot',
        gridLineWidth: 0.5,
        title: {
          text: 'EUA',
          // align: 'center',
          rotation: -90,
          // x: 0,
          // y: 0,
          style: {
            color: '#ffffff',
            opacity: 0.70,
            fontSize: "13px",
            fontFamily: 'Roboto Condensed',
            fontWeight: '400',
            lineHeight: "24px",
            wordWrap: 'break-word'
          },
        },
        labels: {
          // x: 0,
          // y: 0,
          enabled: true,
          style: {
            color: '#ffffff',
            opacity: 0.70,
            fontSize: "13px",
            fontFamily: 'Roboto Condensed',
            fontWeight: '400',
            lineHeight: "24px",
            wordWrap: 'break-word'
          },
          formatter: function (this: Highcharts.AxisLabelsFormatterContextObject) {
            const test = this.value.valueOf().toString()
            return test;
          }
        },
      },
      { // Compliance Balance
        tickInterval: 1000,
        min: -1000,
        max: 1000,
        opposite: true,
        gridLineWidth: 0,
        gridLineDashStyle: 'Dot',
        title: {
          text: 'Compliance Balance',
          align: 'low',
          rotation: 90,
          x: 5,
          y: -50,
          style: {
            color: 'rgba(255, 255, 255, 0.70)',
            opacity: 0.70,
            fontSize: "13px",
            fontWeight: '400',
            lineHeight: "24px",
            wordWrap: 'break-word'
          },
        },
        labels: {
          // align: 'left',
          x: 10,
          y: 0,
          enabled: true,
          style: {
            color: 'rgba(255, 255, 255, 0.70)',
            opacity: 0.70,
            fontSize: "13px",
            fontWeight: '400',
            lineHeight: "24px",
            wordWrap: 'break-word',
          },
          formatter: function (this: Highcharts.AxisLabelsFormatterContextObject) {
            const test = this.value.valueOf().toString()
            return test;
          }
        },
      },
    ],
    series: [
      {
        type: 'column',
        name: "EUA",
        visible: true,
        yAxis: 0,
        data: EUA,
        borderWidth: 0,
      },
      {
        type: 'line',
        name: "Compliance Balance",
        color: '#FFBF83',
        visible: true,
        yAxis: 1,
        data: CB,
        lineWidth: 0.5,
        marker: {
          radius: 8,
          fillColor: {
            radialGradient: { cx: 0.5, cy: 0.5, r: 0.5 },
            stops: [
              [0.14, '#FFE6CE'],
              [0.86, '#FF800C']
            ]
          }
        },
        // pointPlacement: 'on'
      },
      {
        type: 'line',
        name: "Compliance Balance Simulation",
        color: '#FFBF83',
        visible: true,
        yAxis: 1,
        data: CB_Simulation,
        dashStyle: 'LongDash',
        lineWidth: 0.5,
        marker: {
          symbol: 'circle', // 点の形を丸に指定
          radius: 8,
          fillColor: {
            radialGradient: { cx: 0.5, cy: 0.5, r: 0.5 },
            stops: [
              [0.14, 'rgba(255, 230, 206, 0.8)'],
              [0.86, 'rgba(255, 128, 255, 0.3)']
            ]
          }
        },
        // pointPlacement: 'on'
      },
    ]
  };

  const chartComponentRef = React.useRef(null);
  const [hcOptions, setHcOptions] = React.useState<any>(EuaCbOptions);

  // APIから取得データでHcOptionsを更新
  React.useEffect(() => {
    if (EUA !== undefined && CB !== undefined) {
      // EUA&CB
      const newHcOptions = { ...hcOptions };

      // EUA
      newHcOptions.series[0].data = JSON.parse(JSON.stringify(EUA))
      newHcOptions.yAxis[0].max = EUA_YAXIS.max * 1.1
      newHcOptions.yAxis[0].tickInterval = EUA_YAXIS.tickInterval

      // CB、CB(Simulation)
      newHcOptions.series[1].data = JSON.parse(JSON.stringify(CB))
      newHcOptions.series[2].data = JSON.parse(JSON.stringify(CB_Simulation))
      newHcOptions.yAxis[1].max = CB_YAXIS.max
      newHcOptions.yAxis[1].min = CB_YAXIS.min
      newHcOptions.yAxis[1].tickInterval = CB_YAXIS.tickInterval


      // X軸設定
      newHcOptions.xAxis[0].categories = X_Axis_List;
           
      setHcOptions(newHcOptions)
    }
    setKey(key + 1);
  }, [EUA, CB, EUA_YAXIS])

  // 画面表示情報をクリア
  const reset_all = () => {

    // EUA
    setEUA([])
    setEUA_YAXIS({ tickInterval: 1000, max: 2000 })

    // CB
    setCB([])
    setCB_Simulation([]);
    setCB_YAXIS({ tickInterval: 1000, max: 1000, min: -1000 })

    setLegInfoList([])

    // SpeedPlan入力欄
    setSpeedPlan_TimeToEndofYear("");
    setSpeedPlan_Operator("");
    setSpeedPlan_Sailing("");
    setSpeedPlan_Ballast("");
    setSpeedPlan_Log_Speed_Ballast("");
    setSpeedPlan_Log_Speed_Laden("");
    setSpeedPlan_Fuel1("-");
    setSpeedPlan_Fuel2("-");
    setSpeedPlan_Fuel3("-");
    setSpeedPlan_FuelRate1("");
    setSpeedPlan_FuelRate2("");
    setSpeedPlan_FuelRate3("");
    setSpeedPlan_EuRate("");
    setSpeedPlan_Distance("");
    setSpeedPlan_Foc("");
    setSpeedPlan_EUA("");
    setSpeedPlan_CB("");

    setVoyageInformationTotal(undefined);
  }

  // SpeedPlan用変数定義
  const [SpeedPlan_TimeToEndofYear, setSpeedPlan_TimeToEndofYear] = React.useState<any>();
  const [SpeedPlan_Operator, setSpeedPlan_Operator] = React.useState<String>("");
  const [SpeedPlan_Sailing, setSpeedPlan_Sailing] = React.useState<any>();
  const [SpeedPlan_Ballast, setSpeedPlan_Ballast] = React.useState<any>();
  const [SpeedPlan_Log_Speed_Ballast, setSpeedPlan_Log_Speed_Ballast] = React.useState<any>();
  const [SpeedPlan_Log_Speed_Laden, setSpeedPlan_Log_Speed_Laden] = React.useState<any>();
  const [SpeedPlan_Fuel1, setSpeedPlan_Fuel1] = React.useState<any>("-");
  const [SpeedPlan_Fuel2, setSpeedPlan_Fuel2] = React.useState<any>("-");
  const [SpeedPlan_Fuel3, setSpeedPlan_Fuel3] = React.useState<any>("-");
  const [SpeedPlan_FuelRate1, setSpeedPlan_FuelRate1] = React.useState<any>();
  const [SpeedPlan_FuelRate2, setSpeedPlan_FuelRate2] = React.useState<any>();
  const [SpeedPlan_FuelRate3, setSpeedPlan_FuelRate3] = React.useState<any>();
  const [SpeedPlan_EuRate, setSpeedPlan_EuRate] = React.useState<any>();
  const [SpeedPlan_Distance, setSpeedPlan_Distance] = React.useState<any>();
  const [SpeedPlan_Foc, setSpeedPlan_Foc] = React.useState<any>();
  const [SpeedPlan_EUA, setSpeedPlan_EUA] = React.useState<any>();
  const [SpeedPlan_CB, setSpeedPlan_CB] = React.useState<any>();
  const [SpeedPlan_SimulationEnableFlg, setSpeedPlan_SimulationEnableFlg] = React.useState<boolean>(false);

  // SpeedPlanの入力欄をチェックし、Simulationボタンの活性、非活性を制御する。
  React.useEffect(() => {

    if (SpeedPlan_Operator.trim() !== "" && /^-?\d+(\.\d+)?$/.test(SpeedPlan_Sailing) && /^-?\d+(\.\d+)?$/.test(SpeedPlan_Ballast) &&
      /^-?\d+(\.\d+)?$/.test(SpeedPlan_Log_Speed_Ballast) && /^-?\d+(\.\d+)?$/.test(SpeedPlan_Log_Speed_Laden) &&
      /^-?\d+(\.\d+)?$/.test(SpeedPlan_EuRate)) {
      
      // Fuel、FuelRateのチェック
      let ng_flag = false;
      if (SpeedPlan_Fuel1 !== "-" && (SpeedPlan_FuelRate1 === undefined || SpeedPlan_FuelRate1 === "")){
        ng_flag = true;
      }
      if (SpeedPlan_Fuel1 === "-" && (SpeedPlan_FuelRate1 !== undefined && SpeedPlan_FuelRate1 !== "")){
        ng_flag = true;
      }
      if (SpeedPlan_Fuel2 !== "-" && (SpeedPlan_FuelRate2 === undefined || SpeedPlan_FuelRate2 === "")){
        ng_flag = true;
      }
      if (SpeedPlan_Fuel2 === "-" && (SpeedPlan_FuelRate2 !== undefined && SpeedPlan_FuelRate2 !== "")){
        ng_flag = true;
      }
      if (SpeedPlan_Fuel3 !== "-" && (SpeedPlan_FuelRate3 === undefined || SpeedPlan_FuelRate3 === "")){
        ng_flag = true;
      }
      if (SpeedPlan_Fuel3 === "-" && (SpeedPlan_FuelRate3 !== undefined && SpeedPlan_FuelRate3 !== "")){
        ng_flag = true;
      }

      let ok_flg = false;
      if (SpeedPlan_Fuel1 !== "-" && /^-?\d+(\.\d+)?$/.test(SpeedPlan_FuelRate1)){
        ok_flg = true;
      }
      if (SpeedPlan_Fuel1 !== "-" && /^-?\d+(\.\d+)?$/.test(SpeedPlan_FuelRate1)){
        ok_flg = true;
      }
      if (SpeedPlan_Fuel1 !== "-" && /^-?\d+(\.\d+)?$/.test(SpeedPlan_FuelRate1)){
        ok_flg = true;
      }

      if (ng_flag === true) {
        setSpeedPlan_SimulationEnableFlg(false);
      }
      else if (ok_flg === true) {
        setSpeedPlan_SimulationEnableFlg(true);
      }
    }
    else {
      setSpeedPlan_SimulationEnableFlg(false);
    }

  }, [SpeedPlan_Operator, SpeedPlan_Sailing, SpeedPlan_Ballast, SpeedPlan_Log_Speed_Ballast, SpeedPlan_Log_Speed_Laden, SpeedPlan_EuRate,
    SpeedPlan_Fuel1, SpeedPlan_Fuel2, SpeedPlan_Fuel3, SpeedPlan_FuelRate1, SpeedPlan_FuelRate2, SpeedPlan_FuelRate3
  ]);

  // SpeedPlan入力欄関連--------------------------------------------------------------------
  // Operator変更
  const handleChangeOperator = (event) => {
    // 入力値が空の場合はそのまま許可
    if (event === '') {
      setSpeedPlan_Operator('');
      return;
    }
    setSpeedPlan_Operator(event);
  }
  // Sailing変更
  const handleChangeSailing = (event) => {
    // 入力値が空の場合はそのまま許可
    if (event === '') {
      setSpeedPlan_Sailing('');
      return;
    }
    // 入力値を整数に変換し、条件を満たす場合のみ更新
    if (/^(0|[1-9][0-9]{0,2})$/.test(event) && Number(event) <= 100) {
      setSpeedPlan_Sailing(event);
    }
  }
  // Ballast変更
  const handleChangeBallast = (event) => {
    // 入力値が空の場合はそのまま許可
    if (event === '') {
      setSpeedPlan_Ballast('');
      return;
    }
    // 入力値を整数に変換し、条件を満たす場合のみ更新
    if (/^(0|[1-9][0-9]{0,2})$/.test(event) && Number(event) <= 100) {
      setSpeedPlan_Ballast(event);
    }
  }
  // LogSpeed Ballast変更
  const handleChangeLogSpeedBallast = (event) => {
    if (/^(\d*|\d*\.\d*)$/.test(event)) {
      setSpeedPlan_Log_Speed_Ballast(event);
    }
  }
  // LogSpeed Laden変更
  const handleChangeLogSpeedLaden = (event) => {
    if (/^(\d*|\d*\.\d*)$/.test(event)) {
      setSpeedPlan_Log_Speed_Laden(event);
    }
  }
  // fuel1変更
  const handleChangeFuel1 = (event: SelectChangeEvent) => {
    setSpeedPlan_Fuel1(event.target.value as string);
  }
  // fuel2変更
  const handleChangeFuel2 = (event: SelectChangeEvent) => {
    setSpeedPlan_Fuel2(event.target.value as string);
  }
  // fuel3変更
  const handleChangeFuel3 = (event: SelectChangeEvent) => {
    setSpeedPlan_Fuel3(event.target.value as string);
  }

  // fuelRate1変更
  const handleChangeFuelRate1 = (event) => {
    // 入力値が空の場合はそのまま許可
    if (event === '') {
      setSpeedPlan_FuelRate1('');
      return;
    }
    // 入力値を整数に変換し、条件を満たす場合のみ更新
    if (/^(0|[1-9][0-9]{0,2})$/.test(event) && Number(event) <= 100) {
      setSpeedPlan_FuelRate1(event);
    }
  }
  // fuelRate2変更
  const handleChangeFuelRate2 = (event) => {
    // 入力値が空の場合はそのまま許可
    if (event === '') {
      setSpeedPlan_FuelRate2('');
      return;
    }
    // 入力値を整数に変換し、条件を満たす場合のみ更新
    if (/^(0|[1-9][0-9]{0,2})$/.test(event) && Number(event) <= 100) {
      setSpeedPlan_FuelRate2(event);
    }
  }
  // fuelRate3変更
  const handleChangeFuelRate3 = (event) => {
    // 入力値が空の場合はそのまま許可
    if (event === '') {
      setSpeedPlan_FuelRate3('');
      return;
    }
    // 入力値を整数に変換し、条件を満たす場合のみ更新
    if (/^(0|[1-9][0-9]{0,2})$/.test(event) && Number(event) <= 100) {
      setSpeedPlan_FuelRate3(event);
    }
  }
  // EURate変更
  const handleChangeEuRate = (event) => {
    // 入力値が空の場合はそのまま許可
    if (event === '') {
      setSpeedPlan_EuRate('');
      return;
    }
    // 入力値を整数に変換し、条件を満たす場合のみ更新
    if (/^(0|[1-9][0-9]{0,2})$/.test(event) && Number(event) <= 100) {
      setSpeedPlan_EuRate(event);
    }
  }

  return (
    <>

      <Box sx={{height: "51.25%"}}>
        <Box sx={{ width: "100%", height: "25px"}}>
        </Box>
        {/* ↓↓ タブ ↓↓ */}
        <Button
          sx={{
              width: "351px",
              height: "31px",
              borderBottomLeftRadius: "0px",
              borderBottomRightRadius: "0px",
              fontSize: "14px",
              fontFamily: "'Roboto', 'Meiryo', sans-serif",
              fontWeight: "700",
              color: `${activeSimulationTab === 0 ? "rgba(255, 255, 255, 0.8)" : "rgba(255, 255, 255, 0.4)"}`,
              textTransform: 'none',
              ":hover": { bgcolor: "rgba(255, 255, 255, 0.6)", color: "rgba(255, 255, 255, 0.8)", },
              marginRight: "5px",
              border: "1px solid #5A5A5A",
              borderBottomWidth: "2px",
              backgroundColor: `${activeSimulationTab === 0 ? "rgba(224, 224, 224, 0.15)" : "rgba(121, 121, 121, 0.1)"}`,
              borderBottomColor: `${activeSimulationTab === 0 ? "#E66300" : "#5A5A5A"}`,                
            }}
          onClick={() => handleSimulationTabClick(0)}
          >
          <Stack direction={"row"}>
            <Box sx={{ textAlign: "center" }}>Simulation Conditions (Based on Voyage Plan)</Box>
          </Stack>
        </Button>
        <Button
          sx={{
              width: "351px",
              height: "31px",
              borderBottomLeftRadius: "0px",
              borderBottomRightRadius: "0px",
              fontSize: "14px",
              fontFamily: "'Roboto', 'Meiryo', sans-serif",
              fontWeight: "bold",
              color: `${activeSimulationTab === 1 ? "rgba(255, 255, 255, 0.8)" : "rgba(255, 255, 255, 0.4)"}`,
              textTransform: 'none',
              ":hover": { bgcolor: "rgba(255, 255, 255, 0.6)", color: "rgba(255, 255, 255, 0.8)", },
              border: "1px solid #5A5A5A",
              borderBottomWidth: "2px",
              backgroundColor: `${activeSimulationTab === 1 ? "rgba(224, 224, 224, 0.15)" : "rgba(121, 121, 121, 0.1)"}`,
              borderBottomColor: `${activeSimulationTab === 1 ? "#E66300" : "#5A5A5A"}`,                 
            }}
          onClick={() => handleSimulationTabClick(1)}
          >
          <Stack direction={"row"}>
            <Box sx={{ textAlign: "center" }}>Simulation Conditions (Based on Speed Plan)</Box>
          </Stack>
        </Button>
        {/* ↑↑ タブ ↑↑ */}

        {/* リスト */}
        {activeSimulationTab === 0 ?
          // Simulation Conditions (Based on Voyage Plan)
          <Box 
            sx={{
              height: "85%",
              borderTop:"1px solid #5A5A5A", 
              borderTopWidth: "1px",
              backgroundColor: "rgba(65, 66, 68, 0.20)"
            }}>
            {/* Edit Conditions */}
            <Stack sx={{ position: "relative", height: "70px"}}>
              {/* 船選択中 かつ FOC Formulasが入力済みの場合、EditConditionsボタンを活性にする。 */}
              {props.SeachInfo.imoNo !== ""  && props.focFormulaBallast.length !== 0 ?
                <Button
                  variant="contained"
                  onClick={handleClickOpenDialogEditConditions}
                  sx={{
                    position: "absolute",
                    top: "24px",
                    left: "23px",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "30px",
                    width: "180px",
                    background: 'rgba(255, 255, 255, 0.15)',
                    borderRadius: 30,
                    border: "1px solid #C8C8C8",
                    color: "#fff",
                    ":hover": {
                      bgcolor: "rgba(255, 255, 255, 0.6)",
                      color: "rgba(255, 255, 255, 0.8)",
                    },
                    textTransform: 'none'
                  }}
                >
                  <img src={EditConditionsIcon} alt="editCondition" style={{ height: "17px", width: "17px", marginRight: "2px"}} />
                  <Typography align="center" sx={{ fontSize: 14, fontFamily: "'Roboto Bold', 'Meiryo', sans-serif", fontWeight: "700"}}>
                    &nbsp;Edit Conditions
                  </Typography>
                </Button>
                  :
                  <Button
                    variant="contained"
                    sx={{
                      pointerEvents: "none",
                      position: "absolute",
                      top: "24px",
                      left: "23px",
                      alignItems: "center",
                      justifyContent: "center",
                      height: "30px",
                      width: "180px",
                      background: 'rgba(121, 121, 121, 0.1)',
                      borderRadius: 30,
                      border: "1px solid #C8C8C8",
                      color: "rgba(255, 255, 255, 0.4)",
                      textTransform: 'none'
                    }}
                    >
                    <img src={EditConditionsIcon} alt="editCondition" style={{ height: "17px", width: "17px", marginRight: "2px"}} />
                    <Typography align="center" sx={{ fontSize: 14, fontFamily: "'Roboto Bold', 'Meiryo', sans-serif", fontWeight: "700"}}>
                      &nbsp;Edit Conditions
                    </Typography>
                  </Button>
              }
            </Stack>

            {/* Edit Conditions Daialog */}
            <EuEditConditionsDialog
              openDialogFlg = {openDialogEditConditions}
              setOpenDialogFlg={(updateValue: boolean) => setOpenDialogEditConditions(updateValue)}
              loading = {loading_UpperBody}
              setLoading={(updateValue: boolean) => setLoading_UpperBody(updateValue)}
              success = {success_UpperBody}
              setSuccess={(updateValue: boolean) => setSuccess_UpperBody(updateValue)}
              fuelOileList={fuelOileList}
              paretsLegInfoList = {legInfoList}
              setParetsLegInfoList = {(updateValue: any) => setLegInfoList(updateValue)}
              onClickSaveFlg = {onClickSaveFlg}
              setOnClickSaveFlg={(updateValue: boolean) => setOnClickSaveFlg(updateValue)}
              SeachInfo = {props.SeachInfo}
              setSeachInfo = {(updateValue: any) => props.setSeachInfo(updateValue)}
            />         
            
            <Box className='eu-simulation-list-wrapper'>
              {/* LEG List */}
              <table className='simulation-list'>
                <thead>
                  <tr>
                    <th className='leg-no'>
                      <span>LEG No.</span>
                    </th>
                    <th className='operator'>
                      <span>Operator</span>
                    </th>
                    <th className='departure'>
                      <span>Departure</span>
                    </th>
                    <th className='padding1'>
                      <span></span>
                    </th>
                    <th className='arrival'>
                      <span>Arrival</span>
                    </th>
                    <th className='total-time'>
                      <span>Total Time (h)</span>
                    </th>
                    <th className='distance'>
                      <span>Distance (NM)</span>
                    </th>
                    <th className='eu-rate'>
                      <span>EU Rate</span>
                    </th>
                    <th className='padding2'>
                      <span></span>
                    </th>
                    <th className='fuel'>
                      <span>Fuel</span>
                    </th>
                    <th className='displacement'>
                      <span>Displacement</span>
                    </th>
                    <th className='log-speed'>
                      <span>Log Speed (knot)</span>
                    </th>
                    <th className='foc'>
                      <span>FOC (MT)</span>
                    </th>
                    <th className='eua'>
                      <span>EUA</span>
                    </th>
                    <th className='copliance-balance'>
                      <span>CB</span>
                    </th>
                  </tr>
                </thead>
                {success_UpperBody ?
                  <tbody>
                    {legInfoList.length !== 0 ?
                      <>
                        {
                          legInfoList.map((element, index) => {
                            return (
                              <tr>
                                <td className='leg-no'>
                                  <span>{element.leg_no}</span>
                                </td>
                                <td className='operator'>
                                  <span>{element.operator}</span>
                                </td>
                                <td className='departure'>
                                  <span>{element.departure_port}<br/>{element.departure_time}</span>
                                </td>
                                <td className='padding1'>
                                  {/* ▶▶ */}
                                  <Box sx={{ width: "100%" , height: "35px"}}>
                                    <img src={RightArrow} alt="right" style={{ height: "15px", width: "15px", marginLeft: "0px", marginTop: "10px"}} />
                                  </Box>
                                </td>
                                <td className='arrival'>
                                  <span>{element.arrival_port}<br/>{element.arrival_time}</span>
                                </td>
                                <td className='total-time'>
                                  <span>{formatNumber(element.total_time)}</span>
                                </td>
                                <td className='distance'>
                                  <span>{formatNumber(element.distance)}</span>
                                </td>
                                <td className='eu-rate'>
                                  <span>{element.eu_rate}</span>
                                </td>
                                <td className='padding2'>
                                  <span></span>
                                </td>
                                <td className='fuel'>
                                  {element.fuel.map((item, index) => (
                                    <span>{item.fuel_type} ({item.fuel_rate}%)<br/></span>
                                  ))}
                                </td>
                                <td className='displacement'>
                                  <span>{element.displacement}</span>
                                </td>
                                <td className='log-speed'>
                                  <span>{element.log_speed}</span>
                                </td>
                                <td className='foc'>
                                  <span>{formatNumber(element.foc)}</span>
                                </td>
                                <td className='eua'>
                                  <span>{formatNumber(element.eua)}</span>
                                </td>
                                <td className='copliance-balance'>
                                  <span>{formatNumber(element.cb)}</span>
                                </td>
                              </tr>
                            )
                          })
                        }
                      </>
                      :
                      ""
                    }
                  </tbody>
                  :
                  <Box>
                    {loading_UpperBody && (
                      <CircularProgress
                        size={68}
                        sx={{
                          color: "grey",
                          position: 'absolute',
                          top: 230,
                          left: 720,
                          zIndex: 1,
                        }}
                      />
                    )}
                  </Box>
                }  
              </table>
              
              {/* Search */}
              <Stack sx={{ position: "relative" }}>
                <Button
                  variant="contained"
                  onClick={handleClickSimulationVoyage}
                  sx={{
                    // この二つで中央揃えにできる。
                    display: "block",
                    margin: "auto",

                    pointerEvents: `${legInfoList.length !== 0 && props.focFormulaBallast.length !== 0 ? "aute" : "none"}`,
                    width: 200,
                    background: `${legInfoList.length !== 0 && props.focFormulaBallast.length !== 0 ? "#E66300" : "rgba(230, 99, 0, 0.4)"}`,
                    borderRadius: 30,
                    color: `${legInfoList.length !== 0 && props.focFormulaBallast.length !== 0 ? "#fff" : "rgba(255, 255, 255, 0.4)"}`,
                    ":hover": {
                      bgcolor: "#FFFFFF",
                      color: "#40475D",
                    },
                    textTransform: 'none'
                  }}
                >
                  <Typography align="center" sx={{ fontSize: 15, fontFamily: "'Roboto', 'Meiryo', sans-serif", fontWeight: "bold" }}>Simulate</Typography>
                </Button>
              </Stack>
            </Box>
          </Box>
          :
          <Box 
            sx={{
              height: "85%",
              borderTop:"1px solid #5A5A5A", 
              borderTopWidth: "1px",
              backgroundColor: "rgba(65, 66, 68, 0.20)"
            }}>
            <Box sx={{height: "30px"}}></Box>
            
            <Box className='list-wrapper'>

              {/* Simulation Conditions (Based on Speed Plan) header */}
              <Stack direction={"row"} sx={{ height: "50px",}}>
                <Box className="simulation-speed-plan-header" sx={{width: "20px"}}>
                </Box>
                <Box className="simulation-speed-plan-header" sx={{width: "100px", paddingLeft: "5px"}}>
                  <Stack>Time to</Stack>
                  <Stack>End of Year</Stack>
                </Box>
                <Box className="simulation-speed-plan-header" sx={{width: "95px", }}>
                  <Stack sx={{marginTop: "22px"}}>Operator</Stack>
                </Box>
                <Box className="simulation-speed-plan-header" sx={{width: "120px"}}>
                  <Box style={{ width: "100%", height: "20px", background: 'rgba(83, 84, 89, 0.50)', }}>
                    <Typography align="center" sx={{ marginTop: "2px", color: 'rgba(255, 255, 255, 0.50)', fontSize: 14, fontFamily: 'Roboto', fontWeight: '700', wordWrap: 'break-word' }}>
                      Service Rate
                    </Typography>
                  </Box>
                  <Stack direction={"row"}>
                    <Stack sx={{width: "60%"}}>Saling</Stack>
                    <Stack sx={{width: "40%"}}>Port</Stack>
                  </Stack>    
                </Box>
                <Box className="simulation-speed-plan-header" sx={{width: "25px"}}></Box>
                <Box className="simulation-speed-plan-header" sx={{width: "140px"}}>
                  <Box style={{ width: "100%", height: "20px", background: 'rgba(83, 84, 89, 0.50)', }}>
                    <Typography align="center" sx={{ marginTop: "2px", color: 'rgba(255, 255, 255, 0.50)', fontSize: 14, fontFamily: 'Roboto', fontWeight: '700', wordWrap: 'break-word' }}>
                      Displacement Rate
                    </Typography>
                  </Box>
                  <Stack direction={"row"}>
                    <Stack sx={{width: "60%"}}>Ballast</Stack>
                    <Stack sx={{width: "40%"}}>Laden</Stack>
                  </Stack>    
                </Box>
                <Box className="simulation-speed-plan-header" sx={{width: "25px"}}></Box>
                <Box className="simulation-speed-plan-header" sx={{width: "180px"}}>
                  <Box style={{ width: "100%", height: "20px", background: 'rgba(83, 84, 89, 0.50)', }}>
                    <Typography align="center" sx={{ marginTop: "2px", color: 'rgba(255, 255, 255, 0.50)', fontSize: 14, fontFamily: 'Roboto', fontWeight: '700', wordWrap: 'break-word' }}>
                      Average Log Speed
                    </Typography>
                  </Box>
                  <Stack direction={"row"}>
                    <Stack sx={{width: "50%"}}>Ballast</Stack>
                    <Stack sx={{width: "50%"}}>Laden</Stack>
                  </Stack>    
                </Box>
                <Box className="simulation-speed-plan-header" sx={{width: "25px"}}></Box>
                <Box className="simulation-speed-plan-header" sx={{width: "190px"}}>
                  <Stack sx={{marginTop: "22px"}}>Fuel</Stack>
                </Box>
                <Box className="simulation-speed-plan-header" sx={{width: "100px"}}>
                  <Stack sx={{marginTop: "22px"}}>Fuel Rate</Stack>
                </Box>
                <Box className="simulation-speed-plan-header" sx={{width: "120px"}}>
                  <Box style={{ width: "100%", height: "20px", background: 'rgba(83, 84, 89, 0.50)', }}>
                    <Typography align="center" sx={{ marginTop: "2px", color: 'rgba(255, 255, 255, 0.50)', fontSize: 14, fontFamily: 'Roboto', fontWeight: '700', wordWrap: 'break-word' }}>
                      EU Rate
                    </Typography>
                  </Box>
                  <Stack direction={"row"}>
                    <Stack sx={{width: "60%"}}>EU</Stack>
                    <Stack sx={{width: "40%"}}>Else</Stack>
                  </Stack>    
                </Box>
                <Box className="simulation-speed-plan-header" sx={{width: "20px"}}></Box>
                <Box className="simulation-speed-plan-header" sx={{width: "110px"}}>
                  <Stack sx={{marginTop: "22px"}}>Distance (NM)</Stack>
                </Box>
                <Box className="simulation-speed-plan-header" sx={{width: "80px"}}>
                  <Stack sx={{marginTop: "22px"}}>FOC (MT)</Stack>
                </Box>
                <Box className="simulation-speed-plan-header" sx={{width: "80px"}}>
                  <Stack sx={{marginTop: "22px"}}>EUA</Stack>
                </Box>
                <Box className="simulation-speed-plan-header" sx={{width: "80px"}}>
                  <Stack sx={{marginTop: "22px"}}>CB</Stack>
                </Box>
              </Stack>
              
              {/* 点線 */}
              <Stack direction={"row"}>
                <Box sx={{ width: "20px", }}></Box>
                <Box sx={{ width: "calc(100% - 40px)", border:"1px dotted #5A5A5A", }}></Box>
                <Box sx={{ width: "20px", }}></Box>
              </Stack>

              {/* Simulation Conditions (Based on Speed Plan) data */}
              <Stack direction={"row"} sx={{ height: "214px",}}>
                {success_UpperBody ?
                  <Stack direction={"row"} sx={{ height: "214px",}}>
                    <Box className="simulation-speed-plan-header" sx={{width: "20px"}}>
                    </Box>
                    {/* Time to End of Year */}
                    <Box className="simulation-speed-plan-header" sx={{width: "100px", display: "flex"}}>
                      <Stack sx={{marginLeft: "20px", marginTop: "10px"}}>
                        {SpeedPlan_TimeToEndofYear !== undefined && SpeedPlan_TimeToEndofYear !== "" ? formatNumber(SpeedPlan_TimeToEndofYear) : "-"}
                      </Stack>
                      <Stack sx={{marginLeft: "6px", marginTop: "10px", fontSize: "13px", fontWeight: "400", fontFamily: "Roboto Condensed"}}>h</Stack>
                    </Box>
                    {/* Operator */}
                    <Box className="simulation-speed-plan-header" sx={{width: "95px", }}>
                      <Input
                        value={SpeedPlan_Operator} onChange={(event: React.ChangeEvent<HTMLInputElement>) => { handleChangeOperator(event.target.value) }}
                        type='tel'
                        sx={{
                          marginTop: "5px",
                          width: "60px", fontFamily: 'Roboto', fontWeight: '400', wordWrap: 'break-word', border: '0px grey solid', color: "white", fontSize: "13px",
                          "& .MuiInput-input": { height: "25px", borderRadius: '0px', border: '0px white solid', background: "rgba(255, 255, 255, 0.15)", textAlign: "center", },
                          ":hover": { backgroundColor: "#7a86a0", border: '0px #CBCBCB solid', borderBottom: "0px solid white", },
                          ":hover:after": { borderBottom: "0px solid green", },
                          ":hover:not(.Mui-disabled, .Mui-error):before": { borderBottom: "2px solid white", },
                          ":after": { borderBottom: "2px solid white", },
                          ":before": { borderBottom: "2px solid #A4A7B1", },
                        }} />
                    </Box>
                    {/* Service Rate */}
                    <Box className="simulation-speed-plan-header" sx={{width: "120px"}}>
                      <Stack direction={"row"}>
                        {/* Sailing */}
                        <Stack sx={{width: "60%"}}>
                          <Stack direction={"row"}>
                            <Input
                              value={SpeedPlan_Sailing} onChange={(event: React.ChangeEvent<HTMLInputElement>) => { handleChangeSailing(event.target.value) }}
                              type='tel'
                              sx={{
                                marginTop: "5px",
                                width: "50px", fontFamily: 'Roboto', fontWeight: '400', wordWrap: 'break-word', border: '0px grey solid', color: "white", fontSize: "13px",
                                "& .MuiInput-input": { height: "25px", borderRadius: '0px', border: '0px white solid', background: "rgba(255, 255, 255, 0.15)", textAlign: "center", },
                                ":hover": { backgroundColor: "#7a86a0", border: '0px #CBCBCB solid', borderBottom: "0px solid white", },
                                ":hover:after": { borderBottom: "0px solid green", },
                                ":hover:not(.Mui-disabled, .Mui-error):before": { borderBottom: "2px solid white", },
                                ":after": { borderBottom: "2px solid white", },
                                ":before": { borderBottom: "2px solid #A4A7B1", },
                              }} />
                            <Typography sx={{marginTop: "20px", marginLeft: "3px", fontFamily: 'Roboto', fontWeight: '400', wordWrap: 'break-word', color: "rgba(255, 255, 255, 0.70)", fontSize: "12px"}}>%</Typography>
                          </Stack>
                        </Stack>
                        {/* Port */}
                        <Stack sx={{width: "40%"}}>
                          <Stack direction={"row"}>
                            <Stack sx={{marginTop: "10px", fontWeight: '400'}}>
                              {SpeedPlan_Sailing !== undefined && SpeedPlan_Sailing !== "" ? 100 - SpeedPlan_Sailing : "-"}
                            </Stack>
                            <Stack sx={{marginTop: "10px", fontWeight: '400', fontSize: "12px"}}>&nbsp;%</Stack>
                          </Stack>
                        </Stack>
                      </Stack>                      
                    </Box>
                    <Box className="simulation-speed-plan-header" sx={{width: "25px"}}></Box>
                    {/* Displacement Rate */}
                    <Box className="simulation-speed-plan-header" sx={{width: "140px"}}>
                      {/* Displacement Rate Ballast */}
                      <Stack direction={"row"}>
                        <Stack sx={{width: "60%"}}>
                          <Stack direction={"row"}>
                            <Input
                              value={SpeedPlan_Ballast} onChange={(event: React.ChangeEvent<HTMLInputElement>) => { handleChangeBallast(event.target.value) }}
                              type='tel'
                              sx={{
                                marginTop: "5px",
                                width: "50px", fontFamily: 'Roboto', fontWeight: '400', wordWrap: 'break-word', border: '0px grey solid', color: "white", fontSize: "13px",
                                "& .MuiInput-input": { height: "25px", borderRadius: '0px', border: '0px white solid', background: "rgba(255, 255, 255, 0.15)", textAlign: "center", },
                                ":hover": { backgroundColor: "#7a86a0", border: '0px #CBCBCB solid', borderBottom: "0px solid white", },
                                ":hover:after": { borderBottom: "0px solid green", },
                                ":hover:not(.Mui-disabled, .Mui-error):before": { borderBottom: "2px solid white", },
                                ":after": { borderBottom: "2px solid white", },
                                ":before": { borderBottom: "2px solid #A4A7B1", },
                              }} />
                            <Typography sx={{marginTop: "20px", marginLeft: "3px", fontFamily: 'Roboto', fontWeight: '400', wordWrap: 'break-word', color: "rgba(255, 255, 255, 0.70)", fontSize: "12px"}}>%</Typography>
                          </Stack>
                        </Stack>
                        {/* Displacement Rate Laden */}
                        <Stack sx={{width: "40%"}}>
                          <Stack direction={"row"}>
                            <Stack sx={{marginTop: "10px", fontWeight: '400'}}>
                              {SpeedPlan_Ballast !== undefined && SpeedPlan_Ballast !== "" ? 100 - SpeedPlan_Ballast : "-"}
                            </Stack>
                            <Stack sx={{marginTop: "10px", fontWeight: '400', fontSize: "12px"}}>&nbsp;%</Stack>
                          </Stack>
                        </Stack>
                      </Stack> 
                    </Box>
                    <Box className="simulation-speed-plan-header" sx={{width: "25px"}}></Box>
                    {/* Average Log Speed */}
                    <Box className="simulation-speed-plan-header" sx={{width: "180px"}}>
                      {/* Average Log Speed Ballast */}
                      <Stack direction={"row"}>
                        <Stack sx={{width: "50%"}}>
                          <Stack direction={"row"}>
                            <Input
                              value={SpeedPlan_Log_Speed_Ballast} onChange={(event: React.ChangeEvent<HTMLInputElement>) => { handleChangeLogSpeedBallast(event.target.value) }}
                              type='tel'
                              sx={{
                                marginTop: "5px",
                                width: "60px", fontFamily: 'Roboto', fontWeight: '400', wordWrap: 'break-word', border: '0px grey solid', color: "white", fontSize: "13px",
                                "& .MuiInput-input": { height: "25px", borderRadius: '0px', border: '0px white solid', background: "rgba(255, 255, 255, 0.15)", textAlign: "center", },
                                ":hover": { backgroundColor: "#7a86a0", border: '0px #CBCBCB solid', borderBottom: "0px solid white", },
                                ":hover:after": { borderBottom: "0px solid green", },
                                ":hover:not(.Mui-disabled, .Mui-error):before": { borderBottom: "2px solid white", },
                                ":after": { borderBottom: "2px solid white", },
                                ":before": { borderBottom: "2px solid #A4A7B1", },
                              }} />
                            <Typography sx={{marginTop: "20px", marginLeft: "2px", fontFamily: 'Roboto Condensed', wordWrap: 'break-word', color: "rgba(255, 255, 255, 0.50)", fontSize: "14px"}}>knot</Typography>
                          </Stack>
                        </Stack>
                        {/* Average Log Speed Laden */}
                        <Stack sx={{width: "50%"}}>
                          <Stack direction={"row"}>
                            <Input
                              value={SpeedPlan_Log_Speed_Laden} onChange={(event: React.ChangeEvent<HTMLInputElement>) => { handleChangeLogSpeedLaden(event.target.value) }}
                              type='tel'
                              sx={{
                                marginTop: "5px",
                                width: "60px", fontFamily: 'Roboto', fontWeight: '400', wordWrap: 'break-word', border: '0px grey solid', color: "white", fontSize: "13px",
                                "& .MuiInput-input": { height: "25px", borderRadius: '0px', border: '0px white solid', background: "rgba(255, 255, 255, 0.15)", textAlign: "center", },
                                ":hover": { backgroundColor: "#7a86a0", border: '0px #CBCBCB solid', borderBottom: "0px solid white", },
                                ":hover:after": { borderBottom: "0px solid green", },
                                ":hover:not(.Mui-disabled, .Mui-error):before": { borderBottom: "2px solid white", },
                                ":after": { borderBottom: "2px solid white", },
                                ":before": { borderBottom: "2px solid #A4A7B1", },
                              }} />
                            <Typography sx={{marginTop: "20px", marginLeft: "2px", fontFamily: 'Roboto Condensed', wordWrap: 'break-word', color: "rgba(255, 255, 255, 0.50)", fontSize: "14px"}}>knot</Typography>
                          </Stack>                        
                        </Stack>
                      </Stack> 
                    </Box>
                    <Box className="simulation-speed-plan-header" sx={{width: "25px"}}></Box>
                    {/* Fuel */}
                    <Box className="simulation-speed-plan-header" sx={{width: "190px"}}>                     
                      <Box sx={{height: "4px"}}></Box>
                      {/* Fuelプルダウン1 */}
                      <Box>
                        <ThemeProvider theme={SelectTheme}>
                          <FormControl>
                            <Select
                              IconComponent={() =>
                                <div style={{ position: "absolute", top: "20px", left: "150px" }}>
                                  <InputAdornment position="start" disablePointerEvents>
                                    <Box sx={{ borderTop: "solid 2px rgb(255, 255, 255, 0.7)", borderRight: "solid 2px rgb(255, 255, 255, 0.7)", width: "8px", height: "8px", transform: "rotate(135deg)" }}></Box>
                                  </InputAdornment>
                                </div>
                              }
                              MenuProps={{
                                MenuListProps: {
                                  disablePadding: true,
                                },
                                // style: { zIndex: 100000, }
                              }}
                              sx={{
                                width: 180,
                                height: 40,
                                color: "white",
                                borderRadius: 30,
                                ":hover": { backgroundColor: "#7a86a0" }
                              }}
                              input={<BootstrapInput />}
                              onChange={handleChangeFuel1}
                              value={SpeedPlan_Fuel1}
                            >
                              <MenuItem key={"hyphen"} value={"-"}>{"-"}</MenuItem>
                              {fuelOileList.map((fuel) => {
                                return (
                                  <MenuItem key={fuel} value={fuel}>{fuel}</MenuItem>
                                )
                              })}
                            </Select>
                          </FormControl>
                        </ThemeProvider>
                      </Box>
                      <Box sx={{height: "4px"}}></Box>
                      {/* Fuelプルダウン2 */}
                      <Box>
                        <ThemeProvider theme={SelectTheme}>
                          <FormControl>
                            <Select
                              IconComponent={() =>
                                <div style={{ position: "absolute", top: "20px", left: "150px" }}>
                                  <InputAdornment position="start" disablePointerEvents>
                                    <Box sx={{ borderTop: "solid 2px rgb(255, 255, 255, 0.7)", borderRight: "solid 2px rgb(255, 255, 255, 0.7)", width: "8px", height: "8px", transform: "rotate(135deg)" }}></Box>
                                  </InputAdornment>
                                </div>
                              }
                              MenuProps={{
                                MenuListProps: {
                                  disablePadding: true,
                                },
                                // style: { zIndex: 100000, }
                              }}
                              sx={{
                                width: 180,
                                height: 40,
                                color: "white",
                                borderRadius: 30,
                                ":hover": { backgroundColor: "#7a86a0" }
                              }}
                              input={<BootstrapInput />}
                              onChange={handleChangeFuel2}
                              value={SpeedPlan_Fuel2}
                            >
                              <MenuItem key={"hyphen"} value={"-"}>{"-"}</MenuItem>
                              {fuelOileList.map((fuel) => {
                                return (
                                  <MenuItem key={fuel} value={fuel}>{fuel}</MenuItem>
                                )
                              })}
                            </Select>
                          </FormControl>
                        </ThemeProvider>
                      </Box>
                      <Box sx={{height: "4px"}}></Box>
                      {/* Fuelプルダウン3 */}
                      <Box>
                        <ThemeProvider theme={SelectTheme}>
                          <FormControl>
                            <Select
                              IconComponent={() =>
                                <div style={{ position: "absolute", top: "20px", left: "150px" }}>
                                  <InputAdornment position="start" disablePointerEvents>
                                    <Box sx={{ borderTop: "solid 2px rgb(255, 255, 255, 0.7)", borderRight: "solid 2px rgb(255, 255, 255, 0.7)", width: "8px", height: "8px", transform: "rotate(135deg)" }}></Box>
                                  </InputAdornment>
                                </div>
                              }
                              MenuProps={{
                                MenuListProps: {
                                  disablePadding: true,
                                },
                                // style: { zIndex: 100000, }
                              }}
                              sx={{
                                width: 180,
                                height: 40,
                                color: "white",
                                borderRadius: 30,
                                ":hover": { backgroundColor: "#7a86a0" }
                              }}
                              input={<BootstrapInput />}
                              onChange={handleChangeFuel3}
                              value={SpeedPlan_Fuel3}
                            >
                              <MenuItem key={"hyphen"} value={"-"}>{"-"}</MenuItem>
                              {fuelOileList.map((fuel) => {
                                return (
                                  <MenuItem key={fuel} value={fuel}>{fuel}</MenuItem>
                                )
                              })}
                            </Select>
                          </FormControl>
                        </ThemeProvider>
                      </Box>
                    </Box>
                    {/* Fuel Rate */}
                    <Box className="simulation-speed-plan-header" sx={{width: "100px"}}>
                      {/* Fuel Rate1 */}
                      <Stack direction={"row"}>
                        <Input
                          value={SpeedPlan_FuelRate1} onChange={(event: React.ChangeEvent<HTMLInputElement>) => { handleChangeFuelRate1(event.target.value) }}
                          type='tel'
                          sx={{
                            marginTop: "5px",
                            width: "60px", fontFamily: 'Roboto', fontWeight: '400', wordWrap: 'break-word', border: '0px grey solid', color: "white", fontSize: "13px",
                            "& .MuiInput-input": { height: "25px", borderRadius: '0px', border: '0px white solid', background: "rgba(255, 255, 255, 0.15)", textAlign: "center", },
                            ":hover": { backgroundColor: "#7a86a0", border: '0px #CBCBCB solid', borderBottom: "0px solid white", },
                            ":hover:after": { borderBottom: "0px solid green", },
                            ":hover:not(.Mui-disabled, .Mui-error):before": { borderBottom: "2px solid white", },
                            ":after": { borderBottom: "2px solid white", },
                            ":before": { borderBottom: "2px solid #A4A7B1", },
                          }} />
                        <Typography sx={{marginTop: "20px", marginLeft: "3px", fontFamily: 'Roboto', fontWeight: '400', wordWrap: 'break-word', color: "rgba(255, 255, 255, 0.70)", fontSize: "12px"}}>%</Typography>
                      </Stack>
                      {/* Fuel Rate2 */}
                      <Stack direction={"row"}>
                        <Input
                          value={SpeedPlan_FuelRate2} onChange={(event: React.ChangeEvent<HTMLInputElement>) => { handleChangeFuelRate2(event.target.value) }}
                          type='tel'
                          sx={{
                            marginTop: "10px",
                            width: "60px", fontFamily: 'Roboto', fontWeight: '400', wordWrap: 'break-word', border: '0px grey solid', color: "white", fontSize: "13px",
                            "& .MuiInput-input": { height: "25px", borderRadius: '0px', border: '0px white solid', background: "rgba(255, 255, 255, 0.15)", textAlign: "center", },
                            ":hover": { backgroundColor: "#7a86a0", border: '0px #CBCBCB solid', borderBottom: "0px solid white", },
                            ":hover:after": { borderBottom: "0px solid green", },
                            ":hover:not(.Mui-disabled, .Mui-error):before": { borderBottom: "2px solid white", },
                            ":after": { borderBottom: "2px solid white", },
                            ":before": { borderBottom: "2px solid #A4A7B1", },
                          }} />
                        <Typography sx={{marginTop: "20px", marginLeft: "3px", fontFamily: 'Roboto', fontWeight: '400', wordWrap: 'break-word', color: "rgba(255, 255, 255, 0.70)", fontSize: "12px"}}>%</Typography>
                      </Stack> 
                      {/* Fuel Rate3 */}
                      <Stack direction={"row"}>
                        <Input
                          value={SpeedPlan_FuelRate3} onChange={(event: React.ChangeEvent<HTMLInputElement>) => { handleChangeFuelRate3(event.target.value) }}
                          type='tel'
                          sx={{
                            marginTop: "10px",
                            width: "60px", fontFamily: 'Roboto', fontWeight: '400', wordWrap: 'break-word', border: '0px grey solid', color: "white", fontSize: "13px",
                            "& .MuiInput-input": { height: "25px", borderRadius: '0px', border: '0px white solid', background: "rgba(255, 255, 255, 0.15)", textAlign: "center", },
                            ":hover": { backgroundColor: "#7a86a0", border: '0px #CBCBCB solid', borderBottom: "0px solid white", },
                            ":hover:after": { borderBottom: "0px solid green", },
                            ":hover:not(.Mui-disabled, .Mui-error):before": { borderBottom: "2px solid white", },
                            ":after": { borderBottom: "2px solid white", },
                            ":before": { borderBottom: "2px solid #A4A7B1", },
                          }} />
                        <Typography sx={{marginTop: "20px", marginLeft: "3px", fontFamily: 'Roboto', fontWeight: '400', wordWrap: 'break-word', color: "rgba(255, 255, 255, 0.70)", fontSize: "12px"}}>%</Typography>
                      </Stack> 
                    </Box>
                    {/* EU Rate */}
                    <Box className="simulation-speed-plan-header" sx={{width: "120px"}}>
                      <Stack direction={"row"}>
                        {/* EU Rate EU */}
                        <Stack sx={{width: "60%"}}>
                          <Stack direction={"row"}>
                            <Input
                              value={SpeedPlan_EuRate} onChange={(event: React.ChangeEvent<HTMLInputElement>) => { handleChangeEuRate(event.target.value) }}
                              type='tel'
                              sx={{
                                marginTop: "5px",
                                width: "50px", fontFamily: 'Roboto', fontWeight: '400', wordWrap: 'break-word', border: '0px grey solid', color: "white", fontSize: "13px",
                                "& .MuiInput-input": { height: "25px", borderRadius: '0px', border: '0px white solid', background: "rgba(255, 255, 255, 0.15)", textAlign: "center", },
                                ":hover": { backgroundColor: "#7a86a0", border: '0px #CBCBCB solid', borderBottom: "0px solid white", },
                                ":hover:after": { borderBottom: "0px solid green", },
                                ":hover:not(.Mui-disabled, .Mui-error):before": { borderBottom: "2px solid white", },
                                ":after": { borderBottom: "2px solid white", },
                                ":before": { borderBottom: "2px solid #A4A7B1", },
                              }} />
                            <Typography sx={{marginTop: "20px", marginLeft: "3px", fontFamily: 'Roboto', fontWeight: '400', wordWrap: 'break-word', color: "rgba(255, 255, 255, 0.70)", fontSize: "12px"}}>%</Typography>
                          </Stack>
                        </Stack>
                        {/* EU Rate Else */}
                        <Stack sx={{width: "40%"}}>
                          <Stack direction={"row"}>
                            <Stack sx={{marginTop: "10px", fontWeight: '400'}}>
                              {SpeedPlan_EuRate !== undefined && SpeedPlan_EuRate !== "" ? 100 - SpeedPlan_EuRate : "-"}
                            </Stack>
                            <Stack sx={{marginTop: "10px", fontWeight: '400', fontSize: "12px"}}>&nbsp;%</Stack>
                          </Stack>
                        </Stack>
                      </Stack>    
                    </Box>
                    <Box sx={{width: "20px"}}></Box>
                    {/* Distance */}
                    <Box className="simulation-speed-plan-data" sx={{width: "110px"}}>
                      <Stack direction={"row"} justifyItems={"end"} sx={{ position: "relative"}}>
                        <Stack sx={{marginTop: "10px", fontWeight: '400', position: "absolute", right: "20px"}}>
                          {SpeedPlan_Distance !== undefined && SpeedPlan_Distance !== "" ? formatNumber(SpeedPlan_Distance) : "-"}
                        </Stack>
                      </Stack>
                    </Box>
                    {/* FOC */}
                    <Box className="simulation-speed-plan-data" sx={{width: "80px"}}>
                      <Stack direction={"row"} justifyItems={"end"} sx={{ position: "relative"}}>
                        <Stack sx={{marginTop: "10px", fontWeight: '400', position: "absolute", right: "20px"}}>
                          {SpeedPlan_Foc !== undefined && SpeedPlan_Foc !== "" ? formatNumber(SpeedPlan_Foc) : "-"}
                        </Stack>
                      </Stack>
                    </Box>
                    {/* EUA */}
                    <Box className="simulation-speed-plan-data" sx={{width: "80px"}}>
                      <Stack direction={"row"} justifyItems={"end"} sx={{ position: "relative"}}>
                        <Stack sx={{marginTop: "10px", fontWeight: '400', position: "absolute", right: "20px"}}>
                          {SpeedPlan_EUA !== undefined && SpeedPlan_EUA !== "" ? formatNumber(SpeedPlan_EUA) : "-"}
                        </Stack>
                      </Stack>
                    </Box>
                    {/* CB */}
                    <Box className="simulation-speed-plan-data" sx={{width: "80px"}}>
                      <Stack direction={"row"} justifyItems={"end"} sx={{ position: "relative"}}>
                        <Stack sx={{marginTop: "10px", fontWeight: '400', position: "absolute", right: "30px"}}>
                          {SpeedPlan_CB !== undefined && SpeedPlan_CB !== "" ? formatNumber(SpeedPlan_CB) : "-"}
                        </Stack>
                      </Stack>
                    </Box>
                  </Stack>
                  :
                  <Box>
                    {loading_UpperBody && (
                      <CircularProgress
                        size={68}
                        sx={{
                          color: "grey",
                          position: 'absolute',
                          top: 220,
                          left: 700,
                          zIndex: 1,
                        }}
                      />
                    )}
                  </Box>
                }
              </Stack>
              
              
              {/* Simulation Button */}
              <Stack sx={{ position: "relative" }}>
                <Button
                  variant="contained"
                  onClick={handleClickSimulationSpeed}
                  sx={{
                    // この二つで中央揃えにできる。
                    display: "block",
                    margin: "auto",

                    pointerEvents: `${SpeedPlan_SimulationEnableFlg === true && props.focFormulaBallast.length !== 0 ? "aute" : "none"}`,
                    marginTop: "10px",
                    width: 200,
                    background: `${SpeedPlan_SimulationEnableFlg === true && props.focFormulaBallast.length !== 0 ? "#E66300" : "rgba(230, 99, 0, 0.4)"}`,
                    borderRadius: 30,
                    color: `${SpeedPlan_SimulationEnableFlg === true && props.focFormulaBallast.length !== 0 ? "#fff" : "rgba(255, 255, 255, 0.4)"}`,
                    ":hover": {
                      bgcolor: "#FFFFFF",
                      color: "#40475D",
                    },
                    textTransform: 'none'
                  }}
                >
                  <Typography align="center" sx={{ fontSize: 15, fontFamily: "'Roboto', 'Meiryo', sans-serif", fontWeight: "bold" }}>Simulate</Typography>
                </Button>
              </Stack>
            </Box>
          </Box>               
        }
      </Box>
      {/* ↑↑ リストエリア ↑↑ */}
      
      <Box sx={{ width: "100%", height: "10px"}}>
      </Box>

      {/* ↓↓ グラフエリア ↓↓ */}
      <Box sx={{height: "46%", backgroundColor: "rgba(65, 66, 68, 0.20)"}}>
        <Stack sx={{height: "45px"}}>
          <Typography sx={{marginTop: "10px", marginLeft: "10px", color: "white", fontSize: 15, fontFamily: "'Roboto', 'Meiryo', sans-serif", fontWeight: "700"}}>Simulation Result</Typography>
        </Stack>
        <Box sx={{display: "flex" , height: "85%"}}>
          {/* グラフ */}
          <Box sx={{position:"relative", width: "calc(100% - 370px)", height: "100%"}}>
            {/* Y軸設定ダイアログ */}
            <Box
              sx={{
                position: "absolute",
                top: "20px",
                left: "40px",
                width: "20px",
                height: "20px",
                background: "rgb(255,255,255,0.3)",
                cursor: "pointer",
                borderRadius: "50%",
                border: "2px solid rgb(255,255,255,0.7)",
                zIndex: 10,

              }}
              onClick={handleClickOpenDialogAxis}
              >
              <SettingsIcon sx={{ position: "absolute", top: "1px", left: "1px", width: "14px", height: "14px", color: "rgb(255,255,255,0.7)" }} />
            </Box>

            <Dialog
              open={openDialogyAxis}
              sx={{
                "& .MuiDialog-container": {
                  "& .MuiPaper-root": {
                    width: "100%",
                    maxWidth: "500px",
                    height: "100%",
                    maxHeight: "250px",
                  },
                },
              }}
              >
                <DialogTitle
                  sx={{
                    bgcolor: "#474C53",
                    height: "50px",
                    color: "white",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}>
                  Axis Setting
                  <IconButton
                    aria-label="close"
                    onClick={handleNoClickOpenDialogAxis}
                    sx={{
                      position: 'absolute',
                      right: 8,
                      top: 8,
                      color: (theme) => theme.palette.grey[1000],
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                </DialogTitle>
                <DialogContent
                  sx={{
                    background: "linear-gradient(180deg, #0E1B25 0%, #17232D 100%)",
                  }}>

                  <Box sx={{ position: "relative" }}>
                    <Box sx={{ position: "absolute", top: "60px", left: "20px", height: "1px", width: "400px", borderRadius: '0px ', border: "1px dotted #A4A7B1" }}></Box>
                    <Typography align="center"
                      sx={{
                        position: "absolute", top: "30px", left: "40px",
                        color: 'rgba(255, 255, 255, 0.70)', fontSize: 13, fontFamily: 'Roboto Condensed', fontWeight: '400', lineHeight: "24px", wordWrap: 'break-word'
                      }}>
                      Channel Name
                    </Typography>
                    <Typography align="center"
                      sx={{
                        position: "absolute", top: "30px", left: "240px",
                        color: 'rgba(255, 255, 255, 0.70)', fontSize: 13, fontFamily: 'Roboto Condensed', fontWeight: '400', lineHeight: "24px", wordWrap: 'break-word'
                      }}>
                      Min
                    </Typography>
                    <Typography align="center"
                      sx={{
                        position: "absolute", top: "30px", left: "350px",
                        color: 'rgba(255, 255, 255, 0.70)', fontSize: 13, fontFamily: 'Roboto Condensed', fontWeight: '400', lineHeight: "24px", wordWrap: 'break-word'
                      }}>
                      Max
                    </Typography>
                  </Box>
                  <Box sx={{ position: "relative" }}>
                    <Stack direction={"row"}>
                      <Typography align="left"
                        sx={{
                          position: "absolute", top: "90px", left: "70px",
                          color: 'rgba(255, 255, 255, 0.70)', fontSize: 13, fontFamily: 'Roboto Condensed', fontWeight: '400', lineHeight: "24px", wordWrap: 'break-word'
                        }}>
                        FOC
                      </Typography>
                      <FormControl>
                        <Input
                          value={FOCyAxisMinShow} onChange={(event: React.ChangeEvent<HTMLInputElement>) => { handleClickFOCyAxisMinShow(event.target.value) }}
                          type='tel'
                          sx={{
                            position: "absolute", top: "80px", left: "200px", width: "100px", fontFamily: 'Roboto', fontWeight: '700', wordWrap: 'break-word', border: '0px grey solid', color: "white", fontSize: "15px",
                            "& .MuiInput-input": { height: "30px", borderRadius: '0px', border: '0px white solid', background: "rgba(255, 255, 255, 0.15)", textAlign: "center", },
                            ":hover": { backgroundColor: "#7a86a0", border: '0px #CBCBCB solid', borderBottom: "0px solid white", },
                            ":hover:after": { borderBottom: "0px solid green", },
                            ":hover:not(.Mui-disabled, .Mui-error):before": { borderBottom: "2px solid white", },
                            ":after": { borderBottom: "2px solid white", },
                            ":before": { borderBottom: "2px solid #A4A7B1", },
                          }} />
                      </FormControl>
                      <Box sx={{ position: "absolute", top: "100px", left: "300px", height: "1px", width: "20px", borderRadius: '0px ', border: "1px solid #A4A7B1" }}></Box>
                      <FormControl>
                        <Input
                          value={FOCyAxisMaxShow} onChange={(event: React.ChangeEvent<HTMLInputElement>) => { handleClickFOCyAxisMaxShow(event.target.value) }}
                          type='tel'
                          sx={{
                            position: "absolute", top: "80px", left: "320px", width: "100px", fontFamily: 'Roboto', fontWeight: '700', wordWrap: 'break-word', border: '0px grey solid', color: "white", fontSize: "15px",
                            "& .MuiInput-input": { height: "30px", borderRadius: '0px', border: '0px white solid', background: "rgba(255, 255, 255, 0.15)", textAlign: "center", },
                            ":hover": { backgroundColor: "#7a86a0", border: '0px #CBCBCB solid', borderBottom: "0px solid white", },
                            ":hover:after": { borderBottom: "0px solid green", },
                            ":hover:not(.Mui-disabled, .Mui-error):before": { borderBottom: "2px solid white", },
                            ":after": { borderBottom: "2px solid white", },
                            ":before": { borderBottom: "2px solid #A4A7B1", },
                          }} />
                      </FormControl>
                    </Stack>
                  </Box>
                </DialogContent>
                <DialogActions sx={{
                  height: "50px",
                  bgcolor: "#474C53"
                }}>
                  <Button
                    sx={{
                      marginLeft: "0px",
                      marginRight: "-8px",
                      backgroundColor: "rgba(255, 255, 255, 0.5)",
                      width: "127px",
                      height: "50px",
                      padding: "0",
                      fontSize: "18px",
                      fontFamily: "'Roboto Bold', 'Meiryo', sans-serif",
                      fontWeight: "bold",
                      color: "white",
                      borderRadius: "0px",
                      ":hover": {
                        bgcolor: "#FFFFFF",
                        color: "#40475D",
                      },
                      textTransform: 'none'
                    }}
                    onClick={handleNoClickOpenDialogAxis}
                  >
                    Cancel
                  </Button>
                  <Button
                    sx={{
                      marginLeft: "0px",
                      marginRight: "-8px",
                      backgroundColor: "#e66300",
                      width: "127px",
                      height: "50px",
                      padding: "0",
                      fontSize: "18px",
                      fontFamily: "'Roboto Bold', 'Meiryo', sans-serif",
                      fontWeight: "bold",
                      color: "white",
                      borderRadius: "0px",
                      ":hover": {
                        bgcolor: "#FFFFFF",
                        color: "#40475D",
                      },
                      textTransform: 'none'
                    }}
                    onClick={handleClickSetAxis}
                  >
                    Save
                  </Button>
                </DialogActions>
            </Dialog>

            <Box sx={{ position: "relative", height: "100%" }}>
              {success_LowerBody ?
                <>
                  {/* ↓ Highchartsグラフ ↓ */}
                  <Stack sx={{ position: "relative", top: "-25px", left: "0px", height: "95%", width: "100%" }}>
                    <div onBlur={onClickGraphReSet}>
                      <HighchartsReact
                        key={key}
                        highcharts={Highcharts}
                        options={hcOptions}
                        ref={chartComponentRef}
                        allowChartUpdate={true}
                        updateArgs={[true, true, true]}
                        />
                    </div>
                  </Stack>
                  <Stack sx={{ position: "absolute", top: `${CIINone.top}px`, left: `${CIINone.left}px` }}>
                    <Typography align="left" sx={{ color: 'white', opacity: 0.50, fontSize: 13, fontFamily: 'Roboto Condensed', fontWeight: '400', lineHeight: "24px", wordWrap: 'break-word' }}>
                      {CIINone.name}
                    </Typography>
                  </Stack>

                  {/* 凡例 */}
                  <Stack sx={{ position: "relative", width: "100%", marginTop: "30px" , bottom: "40px"}}>
                    <Grid container alignItems={"center"} justifyContent={"center"} >
                      {/* 左余白 */}
                      {/* EUA */}
                      <Grid container alignItems={"center"} justifyContent={"start"} item xs={4}>
                        <Grid item xs={9}></Grid>
                        <Grid item xs={3}>
                          <Box sx={{ display: "flex" }} >
                            <Box style={{ width: '15px', height: '20px', background: 'linear-gradient(180deg, #1164B1 0%, #1478D5 100%)' }} />
                            <Box style={{ width: '10px', height: '23px'}} />
                            <Typography align="left" sx={{ color: 'white', width: "100%", opacity: 0.70, fontSize: "min(0.7vw,14px)", fontFamily: 'Roboto Condensed', fontWeight: '400', lineHeight: "24px", wordWrap: 'break-word' }}>
                              EUA
                            </Typography>
                          </Box>
                        </Grid>
                      </Grid>
                      {/* CB */}
                      <Grid container alignItems={"center"} justifyContent={"start"} item xs={4}>
                        <Grid item xs={0.5}></Grid>
                        <Grid item xs={9.5}>
                          <Box sx={{ position: "relative" }} >
                            <Box sx={{ position: "absolute", top: "0px", left: "0px", width: '8px', height: '1px',
                              border: '1px #FF8514 solid'
                            }}></Box>
                            <Box sx={{ 
                              position: "absolute", top: "-5px", left: "8px", width: '10px', height: '10px', 
                              background: 'radial-gradient(rgba(255, 191, 131, 1) 0%, rgba(255, 133, 20, 1) 100%)' ,
                              borderRadius: "5px" 
                            }}></Box>
                            <Box sx={{ position: "absolute", top: "0px", left: "18px", width: '8px', height: '1px', 
                              border: '1px #FF8514 solid'
                            }}></Box>
                            <Typography 
                              sx={{ color: 'white', width: "100%", opacity: 0.70, fontSize: "min(0.7vw,14px)", 
                                    fontFamily: 'Roboto Condensed', fontWeight: '400', lineHeight: "24px", 
                                    wordWrap: 'break-word', position: "absolute", top: "-12px", left: "38px",}}>
                              Compliance Balance
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid item xs={2}>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Stack>
                </>
                :
                <Box>
                  {loading_LowerBody && (
                    <CircularProgress
                      size={68}
                      sx={{
                        color: "grey",
                        position: 'absolute',
                        top: "150px",
                        left: "550px",
                        zIndex: 1,
                      }}
                    />
                  )}
                </Box>
              }
            </Box>
            {/* ↑ Highchartsグラフ ↑ */}

          </Box>

          {/* 数値エリア */}
          <Box sx={{width: "370px", height: "100%"}}>
            <Box sx={{height: "7%"}}></Box>
            <Grid className='simulation-result-titole' container sx={{width: "100%"}}>
              <Grid item xs={12}>Distance</Grid>
            </Grid>
            <Grid container sx={{width: "100%"}}>
              <Grid item xs={12}>
                <span className='simulation-result-param' >
                  {voyageInformationTotal !== undefined && voyageInformationTotal !== null ? formatNumber(voyageInformationTotal.distance) : "-"}
                </span>
                <span className='simulation-result-unit'>NM</span>
              </Grid>
            </Grid>
            <Box sx={{height: "7%"}}></Box>
            <Grid className='simulation-result-titole' container sx={{width: "100%"}}>
              <Grid item xs={5}>FOC</Grid>
              <Grid item xs={7}>GHG</Grid>
            </Grid>
            <Grid container sx={{width: "100%"}}>
              <Grid item xs={5}>
                <span className='simulation-result-param' >
                  {voyageInformationTotal !== undefined && voyageInformationTotal !== null ? formatNumber(voyageInformationTotal.foc) : "-"}
                </span>
                <span className='simulation-result-unit'>MT</span>
              </Grid>
              <Grid item xs={7}>
                <span className='simulation-result-param' >
                  {voyageInformationTotal !== undefined && voyageInformationTotal !== null ? formatNumber(voyageInformationTotal.ghg) : "-"}
                </span>
                <span className='simulation-result-unit'>MT</span>
              </Grid>
            </Grid>
            <Box sx={{height: "7%"}}></Box>
            <Grid className='simulation-result-titole' container sx={{width: "100%"}}>
              <Grid item xs={5}>EUA</Grid>
              <Grid item xs={7}>EUA Cost</Grid>
            </Grid>
            <Grid className='simulation-result-parametar' container sx={{width: "100%"}}>
              <Grid item xs={5}>
                <span className='simulation-result-param' >
                  {voyageInformationTotal !== undefined && voyageInformationTotal !== null ? formatNumber(voyageInformationTotal.eua) : "-"}
                </span>                   
              </Grid>
              <Grid item xs={7}>
                <span className='simulation-result-param' >
                  {voyageInformationTotal !== undefined && voyageInformationTotal !== null ? formatNumber(voyageInformationTotal.eua_cost) : "-"}
                </span>
                <span className='simulation-result-unit'>€</span> 
              </Grid>
            </Grid>
            <Box sx={{height: "7%"}}></Box>
            <Grid className='simulation-result-titole' container sx={{width: "100%"}}>
              <Grid item xs={5}>CB</Grid>
              <Grid item xs={7}>CB Cost</Grid>
            </Grid>
            <Grid className='simulation-result-parametar' container sx={{width: "100%"}}>
              <Grid item xs={5}>
                <span className='simulation-result-param' >
                  {voyageInformationTotal !== undefined && voyageInformationTotal !== null ? formatNumber(voyageInformationTotal.cb) : "-"}
                </span> 
              </Grid>
              <Grid item xs={7}>
                <span className='simulation-result-param' >
                  {voyageInformationTotal !== undefined && voyageInformationTotal !== null ? formatNumber(voyageInformationTotal.cb_cost) : "-"}
                </span>
                <span className='simulation-result-unit'>€</span> 
              </Grid>
            </Grid>
          </Box>
          
        </Box>
      </Box>
      {/* ↑↑ グラフエリア ↑↑ */}
    </>
  );
} 

export default EuSimulation;