
import * as React from 'react';

import { SearchContext } from '../../App';

// MUI @mui/material/
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import Input from '@mui/material/Input';

// MUI @mui/icons-material/


// Dialog
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

// Date
import { getMonth, getYear } from 'date-fns'
// import _ from 'lodash'


// Select Date
import dayjs from "dayjs";
import ja_locale from 'dayjs/locale/ja';
import utc_plugin from "dayjs/plugin/utc";
import isBetween from "dayjs/plugin/isBetween";
import duration from "dayjs/plugin/duration";
import customParseFormat from "dayjs/plugin/customParseFormat";


export const SelectDate = (props) => {

    // コンテクスト取得----------------------------------------------------------------------------------------------------------------------------
    const SeachInfo: any = React.useContext(SearchContext);

    // DataRnage----------------------------------------------------------------------------------------------------------------------------
    const handleCloseDialog = () => {
        console.log("handleCloseDialog")
        console.log("SeachInfo.from", SeachInfo.from)
        console.log("SeachInfo.to", SeachInfo.to)

        console.log("SeachInfo[select-date]", SeachInfo)
        if (selectDateModalOptions_wada.startDate !== "" && selectDateModalOptions_wada.endDate !== null) {
            // dayjsオブジェクトとして取得
            const selectedDate_start = dayjs(selectDateModalOptions_wada.startDate);
            const selectedDate_end = dayjs(selectDateModalOptions_wada.endDate).endOf('day');

            // ISO形式の日付フォーマットに変換
            const isoFormattedStartDate = selectedDate_start.format("YYYY-MM-DDTHH:mm:ss") + "Z";
            const isoFormattedEndDate = selectedDate_end.format("YYYY-MM-DDTHH:mm:ss") + "Z";

            console.log("isoFormattedStartDate", isoFormattedStartDate)
            console.log("isoFormattedEndDate", isoFormattedEndDate)

            props.setSeachInfo({ imoNo: `${SeachInfo.imoNo}`, from: isoFormattedStartDate, to: isoFormattedEndDate, gid: props.SeachInfo.gid, gidList: props.SeachInfo.gidList, imoList: props.SeachInfo.imoList })
        } else {
            // 元に戻す
            selectDateModalOptions_wada.startDate = dayjs(SeachInfo.from).utc().year() + "/" + (dayjs(SeachInfo.from).utc().month() + 1) + "/" + dayjs(SeachInfo.from).utc().date()
            setYearLeft_wada(getYear(SeachInfo.from))
            setMonthLeft_wada(getMonth(SeachInfo.from) + 1)
            selectDateModalOptions_wada.endDate = dayjs(SeachInfo.to).utc().year() + "/" + (dayjs(SeachInfo.to).utc().month() + 1) + "/" + dayjs(SeachInfo.to).utc().date()
            setYearRight_wada(getYear(SeachInfo.to))
            setMonthRight_wada(getMonth(SeachInfo.to) + 1)
        }
        props.setOpenDialog(false);
    };
    // React.useEffect(() => {
    //     console.log("SeachInfo[select-date]", SeachInfo)
    // }, [SeachInfo])


    const handleNoCloseDialog = () => {
        console.log("handleNoCloseDialog")
        console.log("SeachInfo.from", SeachInfo.from)
        console.log("SeachInfo.to", SeachInfo.to)
        selectDateModalOptions_wada.startDate = dayjs(SeachInfo.from).utc().year() + "/" + (dayjs(SeachInfo.from).utc().month() + 1) + "/" + dayjs(SeachInfo.from).utc().date()
        setYearLeft_wada(getYear(SeachInfo.from))
        setMonthLeft_wada(getMonth(SeachInfo.from) + 1)
        selectDateModalOptions_wada.endDate = dayjs(SeachInfo.to).utc().year() + "/" + (dayjs(SeachInfo.to).utc().month() + 1) + "/" + dayjs(SeachInfo.to).utc().date()
        setYearRight_wada(getYear(SeachInfo.to))
        setMonthRight_wada(getMonth(SeachInfo.to) + 1)
        props.setOpenDialog(false);
    };

    dayjs.locale(ja_locale);
    dayjs.extend(isBetween);
    dayjs.extend(utc_plugin);
    dayjs.extend(duration);
    dayjs.extend(customParseFormat);


    const [selectFlg_wada, setSelectFlg_wada] = React.useState(0); //0:startに保存, 1:endに保存 (カスタムレンジのstart/endどちらに対応するかを判定するため)
    const weeks_wada = ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'];
    const months_wada = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    // const date = dayjs().utc();
    const date_from = dayjs(SeachInfo.from).utc();
    const date_to = dayjs(SeachInfo.to).utc();
    const [selectedTerm_wada, setSelectedTerm_wada] = React.useState<string>('');


    type SelectDateModalOptions = {
        term: string;
        termName: string;
        termIndex: number;
        startDate: any;
        endDate: any;
        event: any;
        isCustomRange: boolean;
        splitNumber: string;
        startHour: string;
        endHour: string;
    }
    const [selectDateModalOptions_wada, setSelectDateModalOptions_wada] = React.useState<SelectDateModalOptions>({
        term: '3Days',
        termName: '3 Days',
        termIndex: 1,
        startDate: date_from.year() + "/" + (date_from.month() + 1) + "/" + date_from.date(),
        endDate: date_to.year() + "/" + (date_to.month() + 1) + "/" + date_to.date(),
        event: null,
        isCustomRange: false,
        splitNumber: '',
        startHour: '',
        endHour: '',
    });
    const [selectedShowData, setSelectedShowData] = React.useState<any>({
        startDate: date_from.year() + "/" + (date_from.month() + 1) + "/" + date_from.date(),
        endDate: date_to.year() + "/" + (date_to.month() + 1) + "/" + date_to.date(),
    });
    // const [beforeShowData, setbeforeShowData] = React.useState<any>({
    //     startDate: date_from.year() + "/" + (date_from.month() + 1) + "/" + date_from.date(),
    //     endDate: date_to.year() + "/" + (date_to.month() + 1) + "/" + date_to.date(),
    // });

    // 左側用
    const [yearLeft_wada, setYearLeft_wada] = React.useState(date_from.year()); //今月の年
    const [monthLeft_wada, setMonthLeft_wada] = React.useState(date_from.month() + 1); //今月の月
    const startDate_wada = new Date(yearLeft_wada, monthLeft_wada - 1, 1); //今月の最初の日
    const endDate_wada = new Date(yearLeft_wada, monthLeft_wada, 0); //今月の最後の日
    const endDayCount_wada = endDate_wada.getDate(); //今月の末日
    const lastMonthEndDate_wada = new Date(yearLeft_wada, monthLeft_wada - 1, 0); //前月の最後の日
    const lastMonthEndDayCount_wada = lastMonthEndDate_wada.getDate(); //前月の末日
    const startDay_wada = startDate_wada.getDay(); //今月の最初の曜日
    let dayCountLeft_wada = 1; //日にちのカウント


    // 右側用
    const [yearRight_wada, setYearRight_wada] = React.useState(date_to.year()); //来月の年
    const [monthRight_wada, setMonthRight_wada] = React.useState(date_to.month() + 1); //来月の月
    const nextMonthStartDate_wada = new Date(yearRight_wada, monthRight_wada - 1, 1); //来月の最初の日
    const nextMonthEndDate_wada = new Date(yearRight_wada, monthRight_wada, 0); //来月の最後の日
    const nextMonthEndDayCount_wada = nextMonthEndDate_wada.getDate(); //来月の末日
    const monthEndDate_wada = new Date(yearRight_wada, monthRight_wada - 1, 0); //今月の最後の日
    const monthEndDayCount_wada = monthEndDate_wada.getDate(); //今月の末日
    const nextMonthStartDay_wada = nextMonthStartDate_wada.getDay(); //来月の最初の曜日
    let dayCountRight_wada = 1; //日にちのカウント



    const changeMonth = (num) => {  // 前月, 翌日に移動
        if (num === 0) { //左側 前の年
            let currentYear = yearLeft_wada;
            currentYear--;

            setYearLeft_wada(currentYear);
        }
        else if (num === 1) { //左側 前の月
            let currentMonth = monthLeft_wada;
            currentMonth--;
            if (currentMonth < 1) {
                setYearLeft_wada(yearLeft_wada - 1);
                currentMonth = 12;
            }
            setMonthLeft_wada(currentMonth);
        } else if (num === 2) { //左側 次の月
            let currentMonth = monthLeft_wada;
            currentMonth++;
            if (currentMonth > 12) {
                setYearLeft_wada(yearLeft_wada + 1);
                currentMonth = 1;
            }
            setMonthLeft_wada(currentMonth);
        } else if (num === 3) { //左側 次の年
            let currentYear = yearLeft_wada;
            currentYear++;
            setYearLeft_wada(currentYear);
        } else if (num === 4) { //右側の前の年
            let currentYear = yearRight_wada;
            currentYear--;
            setYearRight_wada(currentYear);
        } else if (num === 5) { //右側の前の月
            let currentMonth = monthRight_wada;
            currentMonth--;
            if (currentMonth < 1) {
                setYearRight_wada(yearRight_wada - 1);
                currentMonth = 12;
            }
            setMonthRight_wada(currentMonth);
        } else if (num === 6) { //右側 次の月
            let currentMonth = monthRight_wada;
            currentMonth++;
            if (currentMonth > 12) {
                setYearRight_wada(yearRight_wada + 1);
                currentMonth = 1;
            }
            setMonthRight_wada(currentMonth);
        } else if (num === 7) { //右側 次の年
            let currentYear = yearRight_wada;
            currentYear++;
            setYearRight_wada(currentYear);
        }
    }


    //カスタムレンジで日付クリック
    const rangeClicked = (event, year, month, stage, number) => { // stage 0:左側, 1:右側,   number 0:前月の薄色, 1:翌月の薄色 2:表示月
        console.log("select date", year + "/" + month + ", stage: " + stage + ", number: " + number)

        if (selectedTerm_wada !== 'Custom Range') {
            setSelectedTerm_wada('Custom Range');
        }

        if (stage === 0) { //左側
            if (number === 0) { //前月薄色
                month--;
                if (month < 1) { //左12月, 右1月に変更
                    console.log("左側:前月薄色1", month)
                    year--;
                    month = 12;
                    setYearLeft_wada(year);
                    setYearRight_wada(year + 1);
                    setMonthLeft_wada(month);
                    setMonthRight_wada(1);
                } else if (month === 11) { //左側が11月, 右側12月
                    console.log("左側:前月薄色2", month)
                    setYearRight_wada(year);
                    setMonthLeft_wada(month);
                    setMonthRight_wada(month + 1);
                } else {
                    console.log("左側:前月薄色3", month)
                    setMonthLeft_wada(month);
                    setMonthRight_wada(month + 1);
                }
            } else if (number === 1) { //翌日薄色
                month++;
                if (month > 12) { //左12月, 右1月
                    console.log("左側:翌日薄色1", month)
                    year++;
                    month = 1;
                    setYearRight_wada(year);
                    setMonthRight_wada(month);
                } else {
                    console.log("左側:翌日薄色2", month)
                    setYearRight_wada(year)
                    setMonthRight_wada(month);
                }
            }
        } else if (stage === 1) { //右側
            if (number === 0) { //前月薄色
                month--;
                if (month < 1) { //左12月, 右1月
                    console.log("右側:前月薄色1", month)
                    year--;
                    month = 12;
                    setYearRight_wada(year);
                    setMonthRight_wada(month);
                } else {
                    console.log("右側:前月薄色2", month)
                    setYearRight_wada(year);
                    setMonthRight_wada(month);
                }
            } else if (number === 1) { //翌日薄色
                month++; //押した月
                if (month > 12) { //左1月, 右2月に変更
                    console.log("右側:翌日薄色1", month)
                    year++;
                    month = 1;
                    setYearRight_wada(year);
                    setMonthRight_wada(month);
                } else if (month === 12) { //左側が12月, 右側1月
                    console.log("右側:翌日薄色2", month)
                    setYearRight_wada(year);
                    setMonthRight_wada(month);
                } else {
                    console.log("右側:翌日薄色3", month)
                    setYearRight_wada(year);
                    setMonthRight_wada(month);
                }
            }
        }


        // 日付取得
        const date_wada = Number.parseInt(event.target.value)
        if (event.target.value !== undefined) {
            const currentDate = year + "/" + month + "/" + date_wada;
            // console.log("currentDate: ", currentDate)

            const newSelectDateModalOptions = { ...selectDateModalOptions_wada };
            newSelectDateModalOptions.isCustomRange = true;

            // setbeforeShowData({ startDate: newSelectDateModalOptions.startDate, endDate: newSelectDateModalOptions.endDate });

            if (selectFlg_wada === 0) {
                // console.log("selectFlg_wada0: ", selectFlg_wada)
                newSelectDateModalOptions.startDate = currentDate;
                newSelectDateModalOptions.endDate = null;
                setSelectedShowData({ startDate: currentDate, endDate: null });
                setSelectFlg_wada(1);
            } else if (selectFlg_wada === 1) {
                const newSelectedShowData = { ...selectedShowData };
                if (dayjs(currentDate).isBefore(newSelectDateModalOptions.startDate)) { // 前に選択した日より前だったらstartに入れる
                    // console.log("selectFlg_wada1-if: ", selectFlg_wada)
                    newSelectDateModalOptions.startDate = currentDate;
                    newSelectDateModalOptions.endDate = null;
                    newSelectedShowData.startDate = currentDate;
                    newSelectedShowData.endDate = null;
                    setSelectFlg_wada(1);
                } else {
                    // console.log("selectFlg_wada1-else: ", selectFlg_wada)
                    newSelectDateModalOptions.endDate = currentDate;
                    newSelectedShowData.endDate = currentDate;
                    setSelectFlg_wada(0);
                }
                setSelectedShowData(newSelectedShowData);

            }
            setSelectDateModalOptions_wada(newSelectDateModalOptions);
            console.log("newSelectDateModalOptions", newSelectDateModalOptions)
        }
    }


    return (
        <>
            <Dialog open={props.openDialog} maxWidth={"lg"} sx={{}}>
                <DialogTitle sx={{ bgcolor: "#474C53", width: "1000px", height: "50px", color: "white", display: "flex", alignItems: "center" }}>
                    Select Date
                    <IconButton
                        aria-label="close"
                        onClick={handleNoCloseDialog}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            opacity: 0.50,
                            // color: (theme) => theme.palette.grey[1000],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>

                </DialogTitle>

                <DialogContent sx={{ background: "linear-gradient(180deg, #0E1B25 0%, #17232D 100%)", position: "relative", top: "0px", height: "500px", color: "white", display: "flex", justifyContent: "space-between", alignItems: "center" }}>

                    {/* カレンダー */}
                    <Stack direction={"row"} sx={{}}>
                        {/* 左と右の隙間 */}
                        <Box sx={{ border: "1px dotted rgba(255, 255, 255, 0.5)", height: "1px", width: "110px", position: "absolute", top: "250px", left: "440px" }}></Box>

                        {/* カレンダー左側 */}
                        <Box sx={{ position: "absolute", top: "50px", left: "130px" }}>
                            {/* ドット枠線 */}
                            <Box sx={{ border: "1px dotted rgba(255, 255, 255, 0.5)", height: "320px", width: "310px", position: "absolute", top: "50px", left: "0px" }}></Box>
                            {/* カレンダー本体 */}
                            <Box sx={{ position: "absolute", top: "0px", left: "15px" }}>
                                <table >
                                    <thead>
                                        <tr>
                                            <th>
                                                <Box sx={{
                                                    width: "100%", height: "100%", color: 'white', top: "0px", transform: "rotate(-90deg)", cursor: "pointer",
                                                    ":hover .leftCalLeftTwoTriangles": { borderStyle: "solid", borderWidth: "0 5px 9.775px 5px", borderColor: "transparent transparent white transparent", transition: "0.2s" }
                                                }} onClick={() => changeMonth(0)}>
                                                    <Stack sx={{ width: "100%" }} direction={"column"} alignItems={"center"}>
                                                        <Box className="leftCalLeftTwoTriangles" sx={{ borderStyle: "solid", borderWidth: "0 5px 9.775px 5px", borderColor: "transparent transparent rgb(255,255,255,0.4) transparent", cursor: "pointer" }}></Box>
                                                        <Box className="leftCalLeftTwoTriangles" sx={{ borderStyle: "solid", borderWidth: "0 5px 9.775px 5px", borderColor: "transparent transparent rgb(255,255,255,0.4) transparent", cursor: "pointer" }}></Box>
                                                    </Stack>
                                                </Box>
                                            </th>
                                            <th>
                                                <Box sx={{
                                                    width: "100%", height: "100%", color: 'white', top: "0px", transform: "rotate(-90deg)", cursor: "pointer",
                                                    ":hover .leftCalLeftOneTriangle": { borderStyle: "solid", borderWidth: "0 5px 9.775px 5px", borderColor: "transparent transparent white transparent", transition: "0.2s" }
                                                }} onClick={() => changeMonth(1)}>
                                                    <Stack sx={{ width: "100%" }} direction={"column"} alignItems={"center"}>
                                                        <Box className="leftCalLeftOneTriangle" sx={{ borderStyle: "solid", borderWidth: "0 5px 9.775px 5px", borderColor: "transparent transparent rgb(255,255,255,0.4) transparent", cursor: "pointer" }}></Box>
                                                    </Stack>
                                                </Box>
                                            </th>
                                            <th colSpan={3}>
                                                <Box sx={{ color: 'white', top: "0px", fontSize: "25px" }}>
                                                    <Stack alignItems={"center"}>{months_wada[monthLeft_wada - 1]} {yearLeft_wada}</Stack>
                                                </Box>
                                            </th>
                                            <th>
                                                <Box sx={{
                                                    width: "100%", height: "100%", color: 'white', top: "0px", transform: "rotate(90deg)", cursor: "pointer",
                                                    ":hover .leftCalRightOneTriangle": { borderStyle: "solid", borderWidth: "0 5px 9.775px 5px", borderColor: "transparent transparent white transparent", transition: "0.2s" }
                                                }} onClick={() => changeMonth(2)}>
                                                    <Stack sx={{ width: "100%" }} direction={"column"} alignItems={"center"}>
                                                        <Box className="leftCalRightOneTriangle" sx={{ borderStyle: "solid", borderWidth: "0 5px 9.775px 5px", borderColor: "transparent transparent rgb(255,255,255,0.4) transparent", cursor: "pointer" }}></Box>
                                                    </Stack>
                                                </Box>
                                            </th>
                                            <th>
                                                <Box sx={{
                                                    width: "100%", height: "100%", color: 'white', top: "0px", transform: "rotate(90deg)", cursor: "pointer",
                                                    ":hover .leftCalRightTwoTriangles": { borderStyle: "solid", borderWidth: "0 5px 9.775px 5px", borderColor: "transparent transparent white transparent", transition: "0.2s" }
                                                }} onClick={() => changeMonth(3)}>
                                                    <Stack sx={{ width: "100%" }} direction={"column"} alignItems={"center"}>
                                                        <Box className="leftCalRightTwoTriangles" sx={{ borderStyle: "solid", borderWidth: "0 5px 9.775px 5px", borderColor: "transparent transparent rgb(255,255,255,0.4) transparent", cursor: "pointer" }}></Box>
                                                        <Box className="leftCalRightTwoTriangles" sx={{ borderStyle: "solid", borderWidth: "0 5px 9.775px 5px", borderColor: "transparent transparent rgb(255,255,255,0.4) transparent", cursor: "pointer" }}></Box>
                                                    </Stack>
                                                </Box>
                                            </th>
                                        </tr>

                                        {/* ヘッダーと曜日の隙間 */}
                                        <tr><th colSpan={7}><Box sx={{ color: 'white', bgcolor: "grey", opacity: 0 }}>blank</Box></th></tr>

                                        {/* 曜日 */}
                                        <tr>
                                            {weeks_wada.map((week) => {
                                                return (
                                                    <th key={week}><Box sx={{ color: "white" }}>{week}</Box></th>
                                                )
                                            })}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {[...Array(6)].map((week, w) => {
                                            return (
                                                <tr key={w} className="day">
                                                    {[...Array(7)].map((day, d) => {

                                                        if (!(w === 0 && d < startDay_wada)) {
                                                            dayCountLeft_wada++;

                                                        }
                                                        return (
                                                            <React.Fragment key={`${w}${d}`}>
                                                                {selectDateModalOptions_wada !== null && ((w === 0 && d < startDay_wada) ?
                                                                    <td>
                                                                        <Box sx={{}}>
                                                                            {(dayjs(dayjs(selectDateModalOptions_wada.startDate).format('YYYY/MM/DD')).isSame(dayjs(`${monthLeft_wada - 1 < 1 ? yearLeft_wada - 1 : yearLeft_wada}/${monthLeft_wada - 1 < 1 ? 12 : monthLeft_wada - 1}/${lastMonthEndDayCount_wada - startDay_wada + d + 1}`)) || dayjs(dayjs(selectDateModalOptions_wada.endDate).format('YYYY/MM/DD')).isSame(dayjs(`${monthLeft_wada - 1 < 0 ? yearLeft_wada - 1 : yearLeft_wada}/${monthLeft_wada - 1 < 1 ? 12 : monthLeft_wada - 1}/${lastMonthEndDayCount_wada - startDay_wada + d + 1}`))) ?
                                                                                <Input readOnly inputProps={{ style: { textAlign: "center" } }} type="text" value={lastMonthEndDayCount_wada - startDay_wada + d + 1} onClick={(event) => rangeClicked(event, yearLeft_wada, monthLeft_wada, 0, 0)}
                                                                                    sx={{
                                                                                        background: "#00ffff", color: "#40475d", borderRadius: '50%', height: "40px", width: "40px",
                                                                                        ".MuiInput-input": { cursor: "pointer" }, ":hover": { backgroundColor: "#00ffff", transition: "0.2s" }, ":hover:not(.Mui-disabled, .Mui-error):before": { borderBottom: "0px solid white" }, ":hover:after": { borderBottom: "0px solid white" }, ":before": { borderBottom: "0px solid white" }, ":after": { borderBottom: "0px solid white" },
                                                                                    }} />
                                                                                :
                                                                                <Input readOnly inputProps={{ style: { textAlign: "center" } }} type="text" value={lastMonthEndDayCount_wada - startDay_wada + d + 1} onClick={(event) => rangeClicked(event, yearLeft_wada, monthLeft_wada, 0, 0)}
                                                                                    sx={{
                                                                                        background: "transparent", color: "#808aa3", borderRadius: '50%', height: "40px", width: "40px",
                                                                                        ".MuiInput-input": { cursor: "pointer" }, ":hover": { backgroundColor: "white", transition: "0.2s" }, ":hover:not(.Mui-disabled, .Mui-error):before": { borderBottom: "0px solid white" }, ":hover:after": { borderBottom: "0px solid white" }, ":before": { borderBottom: "0px solid white" }, ":after": { borderBottom: "0px solid white" },
                                                                                    }} />
                                                                            }
                                                                        </Box>
                                                                    </td>
                                                                    :
                                                                    ((dayCountLeft_wada - 1 > endDayCount_wada) ?
                                                                        <td>
                                                                            <Box sx={{}}>
                                                                                {(dayjs(dayjs(selectDateModalOptions_wada.startDate).format('YYYY/MM/DD')).isSame(dayjs(`${monthLeft_wada + 1 > 12 ? yearLeft_wada + 1 : yearLeft_wada}/${monthLeft_wada + 1 > 12 ? 1 : monthLeft_wada + 1}/${dayCountLeft_wada - 1 - endDayCount_wada}`)) || dayjs(dayjs(selectDateModalOptions_wada.endDate).format('YYYY/MM/DD')).isSame(dayjs(`${monthLeft_wada + 1 > 12 ? yearLeft_wada + 1 : yearLeft_wada}/${monthLeft_wada + 1 > 12 ? 1 : monthLeft_wada + 1}/${dayCountLeft_wada - 1 - endDayCount_wada}`))) ?
                                                                                    <Input readOnly inputProps={{ style: { textAlign: "center" } }} type="text" value={dayCountLeft_wada - 1 - endDayCount_wada} onClick={(event) => rangeClicked(event, yearLeft_wada, monthLeft_wada, 0, 1)}
                                                                                        sx={{
                                                                                            background: "#00ffff", color: "#40475d", borderRadius: '50%', height: "40px", width: "40px",
                                                                                            ".MuiInput-input": { cursor: "pointer" }, ":hover": { backgroundColor: "#00ffff", transition: "0.2s" }, ":hover:not(.Mui-disabled, .Mui-error):before": { borderBottom: "0px solid white" }, ":hover:after": { borderBottom: "0px solid white" }, ":before": { borderBottom: "0px solid white" }, ":after": { borderBottom: "0px solid white" },
                                                                                        }} />
                                                                                    :
                                                                                    <Input readOnly inputProps={{ style: { textAlign: "center" } }} type="text" value={dayCountLeft_wada - 1 - endDayCount_wada} onClick={(event) => rangeClicked(event, yearLeft_wada, monthLeft_wada, 0, 1)}
                                                                                        sx={{
                                                                                            background: "transparent", color: "#808aa3", borderRadius: '50%', height: "40px", width: "40px",
                                                                                            ".MuiInput-input": { cursor: "pointer" }, ":hover": { backgroundColor: "white", transition: "0.2s" }, ":hover:not(.Mui-disabled, .Mui-error):before": { borderBottom: "0px solid white" }, ":hover:after": { borderBottom: "0px solid white" }, ":before": { borderBottom: "0px solid white" }, ":after": { borderBottom: "0px solid white" },
                                                                                        }} />
                                                                                }
                                                                            </Box>
                                                                        </td>
                                                                        :
                                                                        <td>
                                                                            <Box sx={{}}>
                                                                                {dayjs(selectDateModalOptions_wada.startDate).isSame(dayjs(`${yearLeft_wada}/${monthLeft_wada}/${dayCountLeft_wada - 1}`)) && dayjs(selectDateModalOptions_wada.endDate).isSame(dayjs(`${yearLeft_wada}/${monthLeft_wada}/${dayCountLeft_wada - 1}`)) ?
                                                                                    <Box sx={{}}>
                                                                                        {dayjs(selectDateModalOptions_wada.startDate).isSame(dayjs(`${monthLeft_wada + 1 > 12 ? yearLeft_wada + 1 : yearLeft_wada}/${monthLeft_wada + 1 > 12 ? 1 : monthLeft_wada + 1}/${dayCountLeft_wada - 1 - endDayCount_wada}`)) || dayjs(selectDateModalOptions_wada.endDate).isSame(dayjs(`${monthLeft_wada + 1 > 12 ? yearLeft_wada + 1 : yearLeft_wada}/${monthLeft_wada + 1 > 12 ? 1 : monthLeft_wada + 1}/${dayCountLeft_wada - 1 - endDayCount_wada}`)) ?
                                                                                            <Input readOnly inputProps={{ style: { textAlign: "center" } }} type="text" value={dayCountLeft_wada - 1} onClick={(event) => rangeClicked(event, yearLeft_wada, monthLeft_wada, 0, 2)}
                                                                                                sx={{
                                                                                                    background: "blue", borderRadius: '50%', height: "40px", width: "40px",
                                                                                                    ".MuiInput-input": { cursor: "pointer" }, ":hover": { backgroundColor: "#7a86a0", transition: "0.2s" }, ":hover:not(.Mui-disabled, .Mui-error):before": { borderBottom: "0px solid white" }, ":hover:after": { borderBottom: "0px solid white" }, ":before": { borderBottom: "0px solid white" }, ":after": { borderBottom: "0px solid white" },
                                                                                                }} />
                                                                                            :
                                                                                            <Input readOnly inputProps={{ style: { textAlign: "center" } }} type="text" value={dayCountLeft_wada - 1} onClick={(event) => rangeClicked(event, yearLeft_wada, monthLeft_wada, 0, 2)}
                                                                                                sx={{
                                                                                                    background: "#00ffff", color: "#40475d", borderRadius: '50%', height: "40px", width: "40px",
                                                                                                    ".MuiInput-input": { cursor: "pointer" }, ":hover": { backgroundColor: "#00ffff", transition: "0.2s" }, ":hover:not(.Mui-disabled, .Mui-error):before": { borderBottom: "0px solid white" }, ":hover:after": { borderBottom: "0px solid white" }, ":before": { borderBottom: "0px solid white" }, ":after": { borderBottom: "0px solid white" },
                                                                                                }} />
                                                                                        }
                                                                                    </Box>
                                                                                    :
                                                                                    dayjs(`${yearLeft_wada}/${monthLeft_wada}/${dayCountLeft_wada - 1}`).isSame(dayjs(selectDateModalOptions_wada.startDate)) ?
                                                                                        <Box sx={{}}>
                                                                                            {dayjs(selectDateModalOptions_wada.startDate).isSame(dayjs(`${monthLeft_wada + 1 > 12 ? yearLeft_wada + 1 : yearLeft_wada}/${monthLeft_wada + 1 > 12 ? 1 : monthLeft_wada + 1}/${dayCountLeft_wada - 1 - endDayCount_wada}`)) || dayjs(selectDateModalOptions_wada.endDate).isSame(dayjs(`${monthLeft_wada + 1 > 12 ? yearLeft_wada + 1 : yearLeft_wada}/${monthLeft_wada + 1 > 12 ? 1 : monthLeft_wada + 1}/${dayCountLeft_wada - 1 - endDayCount_wada}`)) ?
                                                                                                <Input readOnly inputProps={{ style: { textAlign: "center" } }} type="text" value={dayCountLeft_wada - 1} onClick={(event) => rangeClicked(event, yearLeft_wada, monthLeft_wada, 0, 2)}
                                                                                                    sx={{
                                                                                                        background: "#00ffff", color: "#40475d", borderTopLeftRadius: '50%', borderBottomLeftRadius: '50%', height: "40px", width: "40px",
                                                                                                        ".MuiInput-input": { cursor: "pointer" }, ":hover": { backgroundColor: "#00ffff", transition: "0.2s" }, ":hover:not(.Mui-disabled, .Mui-error):before": { borderBottom: "0px solid white" }, ":hover:after": { borderBottom: "0px solid white" }, ":before": { borderBottom: "0px solid white" }, ":after": { borderBottom: "0px solid white" },
                                                                                                    }} />
                                                                                                :
                                                                                                <Input readOnly inputProps={{ style: { textAlign: "center" } }} type="text" value={dayCountLeft_wada - 1} onClick={(event) => rangeClicked(event, yearLeft_wada, monthLeft_wada, 0, 2)}
                                                                                                    sx={{
                                                                                                        background: "#00ffff", color: "#40475d", borderTopLeftRadius: '50%', borderBottomLeftRadius: '50%', height: "40px", width: "40px",
                                                                                                        ".MuiInput-input": { cursor: "pointer" }, ":hover": { backgroundColor: "#00ffff", transition: "0.2s" }, ":hover:not(.Mui-disabled, .Mui-error):before": { borderBottom: "0px solid white" }, ":hover:after": { borderBottom: "0px solid white" }, ":before": { borderBottom: "0px solid white" }, ":after": { borderBottom: "0px solid white" },
                                                                                                    }} />
                                                                                            }
                                                                                        </Box>
                                                                                        :
                                                                                        dayjs(`${yearLeft_wada}/${monthLeft_wada}/${dayCountLeft_wada - 1}`).isBetween(dayjs(selectDateModalOptions_wada.startDate), dayjs(selectDateModalOptions_wada.endDate)) ?
                                                                                            <Box sx={{}}>
                                                                                                {dayjs(selectDateModalOptions_wada.startDate).isSame(dayjs(`${monthLeft_wada + 1 > 12 ? yearLeft_wada + 1 : yearLeft_wada}/${monthLeft_wada + 1 > 12 ? 1 : monthLeft_wada + 1}/${dayCountLeft_wada - 1 - endDayCount_wada}`)) || dayjs(selectDateModalOptions_wada.endDate).isSame(dayjs(`${monthLeft_wada + 1 > 12 ? yearLeft_wada + 1 : yearLeft_wada}/${monthLeft_wada + 1 > 12 ? 1 : monthLeft_wada + 1}/${dayCountLeft_wada - 1 - endDayCount_wada}`)) ?
                                                                                                    <Input readOnly inputProps={{ style: { textAlign: "center" } }} type="text" value={dayCountLeft_wada - 1} onClick={(event) => rangeClicked(event, yearLeft_wada, monthLeft_wada, 0, 2)}
                                                                                                        sx={{
                                                                                                            background: "midnightblue", borderRadius: '50%', height: "40px", width: "40px",
                                                                                                            ".MuiInput-input": { cursor: "pointer" }, ":hover": { backgroundColor: "#7a86a0", transition: "0.2s" }, ":hover:not(.Mui-disabled, .Mui-error):before": { borderBottom: "0px solid white" }, ":hover:after": { borderBottom: "0px solid white" }, ":before": { borderBottom: "0px solid white" }, ":after": { borderBottom: "0px solid white" },
                                                                                                        }} />
                                                                                                    :
                                                                                                    <Input readOnly inputProps={{ style: { textAlign: "center", } }} type="text" value={dayCountLeft_wada - 1} onClick={(event) => rangeClicked(event, yearLeft_wada, monthLeft_wada, 0, 2)}
                                                                                                        sx={{
                                                                                                            background: "#1396a3", height: "40px", width: "40px", color: "white",
                                                                                                            ".MuiInput-input": { cursor: "pointer" }, ":hover": { backgroundColor: "white", borderRadius: '50%', color: "#40475d", transition: "0.2s" }, ":hover:not(.Mui-disabled, .Mui-error):before": { borderBottom: "0px solid white" }, ":hover:after": { borderBottom: "0px solid white" }, ":before": { borderBottom: "0px solid white" }, ":after": { borderBottom: "0px solid white" },
                                                                                                        }} />
                                                                                                }
                                                                                            </Box>
                                                                                            :
                                                                                            dayjs(`${yearLeft_wada}/${monthLeft_wada}/${dayCountLeft_wada - 1}`).isSame(dayjs(selectDateModalOptions_wada.endDate)) ?
                                                                                                <Box sx={{}}>
                                                                                                    {dayjs(selectDateModalOptions_wada.startDate).isSame(dayjs(`${monthLeft_wada + 1 > 12 ? yearLeft_wada + 1 : yearLeft_wada}/${monthLeft_wada + 1 > 12 ? 1 : monthLeft_wada + 1}/${dayCountLeft_wada - 1 - endDayCount_wada}`)) || dayjs(selectDateModalOptions_wada.endDate).isSame(dayjs(`${monthLeft_wada + 1 > 12 ? yearLeft_wada + 1 : yearLeft_wada}/${monthLeft_wada + 1 > 12 ? 1 : monthLeft_wada + 1}/${dayCountLeft_wada - 1 - endDayCount_wada}`)) ?
                                                                                                        <Input readOnly inputProps={{ style: { textAlign: "center" } }} type="text" value={dayCountLeft_wada - 1} onClick={(event) => rangeClicked(event, yearLeft_wada, monthLeft_wada, 0, 2)}
                                                                                                            sx={{
                                                                                                                background: "#00ffff", color: "#40475d", borderTopRightRadius: '50%', borderBottomRightRadius: '50%', height: "40px", width: "40px",
                                                                                                                ".MuiInput-input": { cursor: "pointer" }, ":hover": { backgroundColor: "#00ffff", transition: "0.2s" }, ":hover:not(.Mui-disabled, .Mui-error):before": { borderBottom: "0px solid white" }, ":hover:after": { borderBottom: "0px solid white" }, ":before": { borderBottom: "0px solid white" }, ":after": { borderBottom: "0px solid white" },
                                                                                                            }} />
                                                                                                        :
                                                                                                        <Input readOnly inputProps={{ style: { textAlign: "center" } }} type="text" value={dayCountLeft_wada - 1} onClick={(event) => rangeClicked(event, yearLeft_wada, monthLeft_wada, 0, 2)}
                                                                                                            sx={{
                                                                                                                background: "#00ffff", color: "#40475d", borderTopRightRadius: '50%', borderBottomRightRadius: '50%', height: "40px", width: "40px",
                                                                                                                ".MuiInput-input": { cursor: "pointer" }, ":hover": { backgroundColor: "#00ffff", transition: "0.2s" }, ":hover:not(.Mui-disabled, .Mui-error):before": { borderBottom: "0px solid white" }, ":hover:after": { borderBottom: "0px solid white" }, ":before": { borderBottom: "0px solid white" }, ":after": { borderBottom: "0px solid white" },
                                                                                                            }} />
                                                                                                    }
                                                                                                </Box>
                                                                                                :
                                                                                                <Box sx={{}}>
                                                                                                    {dayjs(selectDateModalOptions_wada.startDate).isSame(dayjs(`${monthLeft_wada + 1 > 12 ? yearLeft_wada + 1 : yearLeft_wada}/${monthLeft_wada + 1 > 12 ? 1 : monthLeft_wada + 1}/${dayCountLeft_wada - 1 - endDayCount_wada}`)) || dayjs(selectDateModalOptions_wada.endDate).isSame(dayjs(`${monthLeft_wada + 1 > 12 ? yearLeft_wada + 1 : yearLeft_wada}/${monthLeft_wada + 1 > 12 ? 1 : monthLeft_wada + 1}/${dayCountLeft_wada - 1 - endDayCount_wada}`)) ?
                                                                                                        <Input readOnly inputProps={{ style: { textAlign: "center" } }} type="text" value={dayCountLeft_wada - 1} onClick={(event) => rangeClicked(event, yearLeft_wada, monthLeft_wada, 0, 2)}
                                                                                                            sx={{
                                                                                                                background: "pink", borderRadius: '50%', height: "40px", width: "40px",
                                                                                                                ".MuiInput-input": { cursor: "pointer" }, ":hover": { backgroundColor: "#7a86a0", transition: "0.2s" }, ":hover:not(.Mui-disabled, .Mui-error):before": { borderBottom: "0px solid white" }, ":hover:after": { borderBottom: "0px solid white" }, ":before": { borderBottom: "0px solid white" }, ":after": { borderBottom: "0px solid white" },
                                                                                                            }} />
                                                                                                        :
                                                                                                        <Input readOnly inputProps={{ style: { textAlign: "center" } }} type="text" value={dayCountLeft_wada - 1} onClick={(event) => rangeClicked(event, yearLeft_wada, monthLeft_wada, 0, 2)}
                                                                                                            sx={{
                                                                                                                background: "transparent", color: "white", borderRadius: '50%', height: "40px", width: "40px",
                                                                                                                ".MuiInput-input": { cursor: "pointer" }, ":hover": { backgroundColor: "white", transition: "0.2s", color: "#40475d" }, ":hover:not(.Mui-disabled, .Mui-error):before": { borderBottom: "0px solid white" }, ":hover:after": { borderBottom: "0px solid white" }, ":before": { borderBottom: "0px solid white" }, ":after": { borderBottom: "0px solid white" },
                                                                                                            }} />
                                                                                                    }
                                                                                                </Box>
                                                                                }
                                                                            </Box>
                                                                        </td>
                                                                    ))}
                                                            </React.Fragment>
                                                        )
                                                    })}
                                                </tr>
                                            )
                                        })}
                                        <tr><td colSpan={7}></td></tr>
                                    </tbody>
                                </table>
                            </Box>
                            {/* セレクト年月日 */}
                            <Box sx={{ position: "absolute", top: "360px", left: "115px" }}>
                                <Box>
                                    <Input readOnly inputProps={{ style: { textAlign: "center" } }} type="text" value={selectedShowData.startDate}
                                        sx={{
                                            ".MuiInput-input": { cursor: "pointer", color: "white", borderRadius: '0%', height: "50px", width: "100px", }, ":hover": { backgroundColor: "transparent", transition: "0.2s" }, ":hover:not(.Mui-disabled, .Mui-error):before": { borderBottom: "0px solid white" }, ":hover:after": { borderBottom: "0px solid white" }, ":before": { borderBottom: "0px solid white" }, ":after": { borderBottom: "0px solid white" },
                                        }} />
                                </Box>
                            </Box>
                        </Box>

                        {/* カレンダー右側 */}
                        <Box sx={{ position: "absolute", top: "50px", left: "550px" }}>
                            {/* ドット枠線 */}
                            <Box sx={{ border: "1px dotted rgba(255, 255, 255, 0.5)", height: "320px", width: "310px", position: "absolute", top: "50px", left: "0px" }}></Box>
                            {/* カレンダー本体 */}
                            <Box sx={{ position: "absolute", top: "0px", left: "15px" }}>
                                <table>
                                    <thead>
                                        <tr>
                                            <th>
                                                <Box sx={{
                                                    width: "100%", height: "100%", color: 'white', top: "0px", transform: "rotate(-90deg)", cursor: "pointer",
                                                    ":hover .rightCalLeftTwoTriangles": { borderStyle: "solid", borderWidth: "0 5px 9.775px 5px", borderColor: "transparent transparent white transparent", transition: "0.2s" }
                                                }} onClick={() => changeMonth(4)}>
                                                    <Stack sx={{ width: "100%" }} direction={"column"} alignItems={"center"}>
                                                        <Box className="rightCalLeftTwoTriangles" sx={{ borderStyle: "solid", borderWidth: "0 5px 9.775px 5px", borderColor: "transparent transparent rgb(255,255,255,0.4) transparent", cursor: "pointer" }}></Box>
                                                        <Box className="rightCalLeftTwoTriangles" sx={{ borderStyle: "solid", borderWidth: "0 5px 9.775px 5px", borderColor: "transparent transparent rgb(255,255,255,0.4) transparent", cursor: "pointer" }}></Box>
                                                    </Stack>
                                                </Box>
                                            </th>
                                            <th>
                                                <Box sx={{
                                                    width: "100%", height: "100%", color: 'white', top: "0px", transform: "rotate(-90deg)", cursor: "pointer",
                                                    ":hover .rightCalLeftOneTriangle": { borderStyle: "solid", borderWidth: "0 5px 9.775px 5px", borderColor: "transparent transparent white transparent", transition: "0.2s" }
                                                    }} onClick={() => changeMonth(5)}>
                                                    <Stack sx={{ width: "100%" }} direction={"column"} alignItems={"center"}>
                                                        <Box className="rightCalLeftOneTriangle" sx={{ borderStyle: "solid", borderWidth: "0 5px 9.775px 5px", borderColor: "transparent transparent rgb(255,255,255,0.4) transparent", cursor: "pointer" }}></Box>
                                                    </Stack>
                                                </Box>
                                            </th>
                                            <th colSpan={3}>
                                                <Box sx={{ color: 'white', top: "0px", fontSize: "25px" }}>
                                                    <Stack alignItems={"center"}>{months_wada[monthRight_wada - 1]} {yearRight_wada}</Stack>
                                                </Box>
                                            </th>
                                            <th>
                                                <Box sx={{
                                                    width: "100%", height: "100%", color: 'white', top: "0px", transform: "rotate(90deg)", cursor: "pointer",
                                                    ":hover .rightCalRightOneTriangle": { borderStyle: "solid", borderWidth: "0 5px 9.775px 5px", borderColor: "transparent transparent white transparent", transition: "0.2s" }
                                                }} onClick={() => changeMonth(6)}>
                                                    <Stack sx={{ width: "100%" }} direction={"column"} alignItems={"center"}>
                                                        <Box className="rightCalRightOneTriangle" sx={{ borderStyle: "solid", borderWidth: "0 5px 9.775px 5px", borderColor: "transparent transparent rgb(255,255,255,0.4) transparent", cursor: "pointer" }}></Box>
                                                    </Stack>
                                                </Box>
                                            </th>
                                            <th>
                                                <Box sx={{
                                                    width: "100%", height: "100%", color: 'white', top: "0px", transform: "rotate(90deg)", cursor: "pointer",
                                                    ":hover .rightCalRightTwoTriangles": { borderStyle: "solid", borderWidth: "0 5px 9.775px 5px", borderColor: "transparent transparent white transparent", transition: "0.2s" }
                                                    }} onClick={() => changeMonth(7)}>
                                                    <Stack sx={{ width: "100%" }} direction={"column"} alignItems={"center"}>
                                                        <Box className="rightCalRightTwoTriangles" sx={{ borderStyle: "solid", borderWidth: "0 5px 9.775px 5px", borderColor: "transparent transparent rgb(255,255,255,0.4) transparent", cursor: "pointer" }}></Box>
                                                        <Box className="rightCalRightTwoTriangles" sx={{ borderStyle: "solid", borderWidth: "0 5px 9.775px 5px", borderColor: "transparent transparent rgb(255,255,255,0.4) transparent", cursor: "pointer" }}></Box>
                                                    </Stack>
                                                </Box>
                                            </th>
                                        </tr>

                                        {/* ヘッダーと曜日の隙間 */}
                                        <tr><th colSpan={7}><Box sx={{ color: 'white', bgcolor: "grey", opacity: 0 }}>blank</Box></th></tr>

                                        {/* 曜日 */}
                                        <tr>
                                            {weeks_wada.map((week) => {
                                                return (
                                                    <th key={week}><Box sx={{ color: "white" }}>{week}</Box></th>
                                                )
                                            })}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {[...Array(6)].map((week, w) => {
                                            return (
                                                <tr key={w} className="day">
                                                    {[...Array(7)].map((day, d) => {
                                                        if (!(w === 0 && d < nextMonthStartDay_wada)) {
                                                            dayCountRight_wada++;
                                                        }

                                                        return (
                                                            <React.Fragment key={`${w}${d}`}>
                                                                {selectDateModalOptions_wada !== null && ((w === 0 && d < nextMonthStartDay_wada) ?
                                                                    <td>
                                                                        <Box sx={{}}>
                                                                            {(dayjs(dayjs(selectDateModalOptions_wada.startDate).format('YYYY/MM/DD')).isSame(dayjs(`${monthRight_wada - 1 < 1 ? yearRight_wada - 1 : yearRight_wada}/${monthRight_wada - 1 < 1 ? 12 : monthRight_wada - 1}/${monthEndDayCount_wada - nextMonthStartDay_wada + d + 1}`)) || dayjs(dayjs(selectDateModalOptions_wada.endDate).format('YYYY/MM/DD')).isSame(dayjs(`${monthRight_wada - 1 < 0 ? yearRight_wada - 1 : yearRight_wada}/${monthRight_wada - 1 < 1 ? 12 : monthRight_wada - 1}/${monthEndDayCount_wada - nextMonthStartDay_wada + d + 1}`))) ?
                                                                                <Input readOnly inputProps={{ style: { textAlign: "center" } }} type="text" value={monthEndDayCount_wada - nextMonthStartDay_wada + d + 1} onClick={(event) => rangeClicked(event, yearRight_wada, monthRight_wada, 1, 0)}
                                                                                    sx={{
                                                                                        background: "#00ffff", color: "#40475d", borderRadius: '50%', height: "40px", width: "40px",
                                                                                        ".MuiInput-input": { cursor: "pointer" }, ":hover": { backgroundColor: "#00ffff", transition: "0.2s" }, ":hover:not(.Mui-disabled, .Mui-error):before": { borderBottom: "0px solid white" }, ":hover:after": { borderBottom: "0px solid white" }, ":before": { borderBottom: "0px solid white" }, ":after": { borderBottom: "0px solid white" },
                                                                                    }} />
                                                                                :
                                                                                <Input readOnly inputProps={{ style: { textAlign: "center" } }} type="text" value={monthEndDayCount_wada - nextMonthStartDay_wada + d + 1} onClick={(event) => rangeClicked(event, yearRight_wada, monthRight_wada, 1, 0)}
                                                                                    sx={{
                                                                                        background: "transparent", color: "#808aa3", borderRadius: '50%', height: "40px", width: "40px",
                                                                                        ".MuiInput-input": { cursor: "pointer" }, ":hover": { backgroundColor: "white", transition: "0.2s" }, ":hover:not(.Mui-disabled, .Mui-error):before": { borderBottom: "0px solid white" }, ":hover:after": { borderBottom: "0px solid white" }, ":before": { borderBottom: "0px solid white" }, ":after": { borderBottom: "0px solid white" },
                                                                                    }} />
                                                                            }
                                                                        </Box>
                                                                    </td>
                                                                    :
                                                                    ((dayCountRight_wada - 1 > nextMonthEndDayCount_wada) ?
                                                                        <td>
                                                                            <Box sx={{}}>
                                                                                {(dayjs(dayjs(selectDateModalOptions_wada.startDate).format('YYYY/MM/DD')).isSame(dayjs(`${monthRight_wada + 1 > 12 ? yearRight_wada + 1 : yearRight_wada}/${monthRight_wada + 1 > 12 ? 1 : monthRight_wada + 1}/${dayCountRight_wada - 1 - nextMonthEndDayCount_wada}`)) || dayjs(dayjs(selectDateModalOptions_wada.endDate).format('YYYY/MM/DD')).isSame(dayjs(`${monthRight_wada + 1 > 12 ? yearRight_wada + 1 : yearRight_wada}/${monthRight_wada + 1 > 12 ? 1 : monthRight_wada + 1}/${dayCountRight_wada - 1 - nextMonthEndDayCount_wada}`))) ?
                                                                                    <Input readOnly inputProps={{ style: { textAlign: "center" } }} type="text" value={dayCountRight_wada - 1 - nextMonthEndDayCount_wada} onClick={(event) => rangeClicked(event, yearRight_wada, monthRight_wada, 1, 1)}
                                                                                        sx={{
                                                                                            background: "#00ffff", color: "#40475d", borderRadius: '50%', height: "40px", width: "40px",
                                                                                            ".MuiInput-input": { cursor: "pointer" }, ":hover": { backgroundColor: "#00ffff", transition: "0.2s" }, ":hover:not(.Mui-disabled, .Mui-error):before": { borderBottom: "0px solid white" }, ":hover:after": { borderBottom: "0px solid white" }, ":before": { borderBottom: "0px solid white" }, ":after": { borderBottom: "0px solid white" },
                                                                                        }} />
                                                                                    :
                                                                                    <Input readOnly inputProps={{ style: { textAlign: "center" } }} type="text" value={dayCountRight_wada - 1 - nextMonthEndDayCount_wada} onClick={(event) => rangeClicked(event, yearRight_wada, monthRight_wada, 1, 1)}
                                                                                        sx={{
                                                                                            background: "transparent", color: "#808aa3", borderRadius: '50%', height: "40px", width: "40px",
                                                                                            ".MuiInput-input": { cursor: "pointer" }, ":hover": { backgroundColor: "white", transition: "0.2s" }, ":hover:not(.Mui-disabled, .Mui-error):before": { borderBottom: "0px solid white" }, ":hover:after": { borderBottom: "0px solid white" }, ":before": { borderBottom: "0px solid white" }, ":after": { borderBottom: "0px solid white" },
                                                                                        }} />
                                                                                }
                                                                            </Box>
                                                                        </td>
                                                                        :
                                                                        <td>
                                                                            <Box sx={{}}>
                                                                                {(dayjs(selectDateModalOptions_wada.startDate).isSame(dayjs(`${yearRight_wada}/${monthRight_wada}/${dayCountRight_wada - 1}`)) && dayjs(selectDateModalOptions_wada.endDate).isSame(dayjs(`${yearRight_wada}/${monthRight_wada}/${dayCountRight_wada - 1}`))) ?
                                                                                    <Box sx={{}}>
                                                                                        {dayjs(selectDateModalOptions_wada.startDate).isSame(dayjs(`${yearRight_wada}/${monthRight_wada}/${dayCountRight_wada - 1}`)) || dayjs(selectDateModalOptions_wada.endDate).isSame(dayjs(`${yearRight_wada}/${monthRight_wada}/${dayCountRight_wada - 1}`)) ?
                                                                                            <Input readOnly inputProps={{ style: { textAlign: "center" } }} type="text" value={dayCountRight_wada - 1} onClick={(event) => rangeClicked(event, yearRight_wada, monthRight_wada, 1, 2)}
                                                                                                sx={{
                                                                                                    background: "#00ffff", color: "#40475d", borderRadius: '50%', height: "40px", width: "40px",
                                                                                                    ".MuiInput-input": { cursor: "pointer" }, ":hover": { backgroundColor: "#7a86a0", transition: "0.2s" }, ":hover:not(.Mui-disabled, .Mui-error):before": { borderBottom: "0px solid white" }, ":hover:after": { borderBottom: "0px solid white" }, ":before": { borderBottom: "0px solid white" }, ":after": { borderBottom: "0px solid white" },
                                                                                                }} />
                                                                                            :
                                                                                            <Input readOnly inputProps={{ style: { textAlign: "center" } }} type="text" value={dayCountRight_wada - 1} onClick={(event) => rangeClicked(event, yearRight_wada, monthRight_wada, 1, 2)}
                                                                                                sx={{
                                                                                                    background: "#00ffff", color: "#40475d", borderRadius: '50%', height: "40px", width: "40px",
                                                                                                    ".MuiInput-input": { cursor: "pointer" }, ":hover": { backgroundColor: "#00ffff", transition: "0.2s" }, ":hover:not(.Mui-disabled, .Mui-error):before": { borderBottom: "0px solid white" }, ":hover:after": { borderBottom: "0px solid white" }, ":before": { borderBottom: "0px solid white" }, ":after": { borderBottom: "0px solid white" },
                                                                                                }} />
                                                                                        }
                                                                                    </Box>
                                                                                    :
                                                                                    dayjs(`${yearRight_wada}/${monthRight_wada}/${dayCountRight_wada - 1}`).isSame(dayjs(selectDateModalOptions_wada.startDate)) ?
                                                                                        <Box sx={{}}>
                                                                                            {dayjs(selectDateModalOptions_wada.startDate).isSame(dayjs(`${yearRight_wada}/${monthRight_wada}/${dayCountRight_wada - 1}`)) || dayjs(selectDateModalOptions_wada.endDate).isSame(dayjs(`${yearRight_wada}/${monthRight_wada}/${dayCountRight_wada - 1}`)) ?
                                                                                                <Input readOnly inputProps={{ style: { textAlign: "center" } }} type="text" value={dayCountRight_wada - 1} onClick={(event) => rangeClicked(event, yearRight_wada, monthRight_wada, 1, 2)}
                                                                                                    sx={{
                                                                                                        background: "#00ffff", color: "#40475d", borderTopLeftRadius: '50%', borderBottomLeftRadius: '50%', height: "40px", width: "40px",
                                                                                                        ".MuiInput-input": { cursor: "pointer" }, ":hover": { backgroundColor: "#00ffff", transition: "0.2s" }, ":hover:not(.Mui-disabled, .Mui-error):before": { borderBottom: "0px solid white" }, ":hover:after": { borderBottom: "0px solid white" }, ":before": { borderBottom: "0px solid white" }, ":after": { borderBottom: "0px solid white" },
                                                                                                    }} />
                                                                                                :
                                                                                                <Input readOnly inputProps={{ style: { textAlign: "center" } }} type="text" value={dayCountRight_wada - 1} onClick={(event) => rangeClicked(event, yearRight_wada, monthRight_wada, 1, 2)}
                                                                                                    sx={{
                                                                                                        background: "#00ffff", color: "#40475d", borderTopLeftRadius: '50%', borderBottomLeftRadius: '50%', height: "40px", width: "40px",
                                                                                                        ".MuiInput-input": { cursor: "pointer" }, ":hover": { backgroundColor: "#00ffff", transition: "0.2s" }, ":hover:not(.Mui-disabled, .Mui-error):before": { borderBottom: "0px solid white" }, ":hover:after": { borderBottom: "0px solid white" }, ":before": { borderBottom: "0px solid white" }, ":after": { borderBottom: "0px solid white" },
                                                                                                    }} />
                                                                                            }
                                                                                        </Box>
                                                                                        :
                                                                                        dayjs(`${yearRight_wada}/${monthRight_wada}/${dayCountRight_wada - 1}`).isBetween(dayjs(selectDateModalOptions_wada.startDate), dayjs(selectDateModalOptions_wada.endDate)) ?
                                                                                            <Box sx={{}}>
                                                                                                {dayjs(selectDateModalOptions_wada.startDate).isSame(dayjs(`${yearRight_wada}/${monthRight_wada}/${dayCountRight_wada - 1}`)) || dayjs(selectDateModalOptions_wada.endDate).isSame(dayjs(`${yearRight_wada}/${monthRight_wada}/${dayCountRight_wada - 1}`)) ?
                                                                                                    <Input readOnly inputProps={{ style: { textAlign: "center" } }} type="text" value={dayCountRight_wada - 1} onClick={(event) => rangeClicked(event, yearRight_wada, monthRight_wada, 1, 2)}
                                                                                                        sx={{
                                                                                                            background: "midnightblue", borderRadius: '50%', height: "40px", width: "40px",
                                                                                                            ".MuiInput-input": { cursor: "pointer" }, ":hover": { backgroundColor: "#7a86a0", transition: "0.2s" }, ":hover:not(.Mui-disabled, .Mui-error):before": { borderBottom: "0px solid white" }, ":hover:after": { borderBottom: "0px solid white" }, ":before": { borderBottom: "0px solid white" }, ":after": { borderBottom: "0px solid white" },
                                                                                                        }} />
                                                                                                    :
                                                                                                    <Input readOnly inputProps={{ style: { textAlign: "center" } }} type="text" value={dayCountRight_wada - 1} onClick={(event) => rangeClicked(event, yearRight_wada, monthRight_wada, 1, 2)}
                                                                                                        sx={{
                                                                                                            background: "#1396a3", height: "40px", width: "40px", color: "white",
                                                                                                            ".MuiInput-input": { cursor: "pointer" }, ":hover": { backgroundColor: "white", borderRadius: '50%', color: "#40475d", transition: "0.2s" }, ":hover:not(.Mui-disabled, .Mui-error):before": { borderBottom: "0px solid white" }, ":hover:after": { borderBottom: "0px solid white" }, ":before": { borderBottom: "0px solid white" }, ":after": { borderBottom: "0px solid white" },
                                                                                                        }} />
                                                                                                }
                                                                                            </Box>
                                                                                            :
                                                                                            dayjs(`${yearRight_wada}/${monthRight_wada}/${dayCountRight_wada - 1}`).isSame(dayjs(selectDateModalOptions_wada.endDate)) ?
                                                                                                <Box sx={{}}>
                                                                                                    {dayjs(selectDateModalOptions_wada.startDate).isSame(dayjs(`${yearRight_wada}/${monthRight_wada}/${dayCountRight_wada - 1}`)) || dayjs(selectDateModalOptions_wada.endDate).isSame(dayjs(`${yearRight_wada}/${monthRight_wada}/${dayCountRight_wada - 1}`)) ?
                                                                                                        <Input readOnly inputProps={{ style: { textAlign: "center" } }} type="text" value={dayCountRight_wada - 1} onClick={(event) => rangeClicked(event, yearRight_wada, monthRight_wada, 1, 2)}
                                                                                                            sx={{
                                                                                                                background: "#00ffff", color: "#40475d", borderTopRightRadius: '50%', borderBottomRightRadius: '50%', height: "40px", width: "40px",
                                                                                                                ".MuiInput-input": { cursor: "pointer" }, ":hover": { backgroundColor: "#00ffff", transition: "0.2s" }, ":hover:not(.Mui-disabled, .Mui-error):before": { borderBottom: "0px solid white" }, ":hover:after": { borderBottom: "0px solid white" }, ":before": { borderBottom: "0px solid white" }, ":after": { borderBottom: "0px solid white" },
                                                                                                            }} />
                                                                                                        :
                                                                                                        <Input readOnly inputProps={{ style: { textAlign: "center" } }} type="text" value={dayCountRight_wada - 1} onClick={(event) => rangeClicked(event, yearRight_wada, monthRight_wada, 1, 2)}
                                                                                                            sx={{
                                                                                                                background: "#00ffff", color: "#40475d", borderTopRightRadius: '50%', borderBottomRightRadius: '50%', height: "40px", width: "40px",
                                                                                                                ".MuiInput-input": { cursor: "pointer" }, ":hover": { backgroundColor: "#00ffff", transition: "0.2s" }, ":hover:not(.Mui-disabled, .Mui-error):before": { borderBottom: "0px solid white" }, ":hover:after": { borderBottom: "0px solid white" }, ":before": { borderBottom: "0px solid white" }, ":after": { borderBottom: "0px solid white" },
                                                                                                            }} />
                                                                                                    }
                                                                                                </Box>
                                                                                                :
                                                                                                <Box sx={{}}>
                                                                                                    {dayjs(selectDateModalOptions_wada.startDate).isSame(dayjs(`${yearRight_wada}/${monthRight_wada}/${dayCountRight_wada - 1}`)) || dayjs(selectDateModalOptions_wada.endDate).isSame(dayjs(`${yearRight_wada}/${monthRight_wada}/${dayCountRight_wada - 1}`)) ?
                                                                                                        <Input readOnly inputProps={{ style: { textAlign: "center" } }} type="text" value={dayCountRight_wada - 1} onClick={(event) => rangeClicked(event, yearRight_wada, monthRight_wada, 1, 2)}
                                                                                                            sx={{
                                                                                                                background: "pink", borderRadius: '50%', height: "40px", width: "40px",
                                                                                                                ".MuiInput-input": { cursor: "pointer" }, ":hover": { backgroundColor: "#7a86a0", transition: "0.2s" }, ":hover:not(.Mui-disabled, .Mui-error):before": { borderBottom: "0px solid white" }, ":hover:after": { borderBottom: "0px solid white" }, ":before": { borderBottom: "0px solid white" }, ":after": { borderBottom: "0px solid white" },
                                                                                                            }} />
                                                                                                        :
                                                                                                        <Input readOnly inputProps={{ style: { textAlign: "center" } }} type="text" value={dayCountRight_wada - 1} onClick={(event) => rangeClicked(event, yearRight_wada, monthRight_wada, 1, 2)}
                                                                                                            sx={{
                                                                                                                background: "transparent", color: "white", borderRadius: '50%', height: "40px", width: "40px",
                                                                                                                ".MuiInput-input": { cursor: "pointer" }, ":hover": { backgroundColor: "white", transition: "0.2s", color: "#40475d" }, ":hover:not(.Mui-disabled, .Mui-error):before": { borderBottom: "0px solid white" }, ":hover:after": { borderBottom: "0px solid white" }, ":before": { borderBottom: "0px solid white" }, ":after": { borderBottom: "0px solid white" },
                                                                                                            }} />
                                                                                                    }
                                                                                                </Box>
                                                                                }
                                                                            </Box>
                                                                        </td>
                                                                    ))}
                                                            </React.Fragment>
                                                        )
                                                    })}
                                                </tr>
                                            )
                                        })}
                                        <tr><td colSpan={7}></td></tr>
                                    </tbody>
                                </table>
                            </Box>
                            {/* セレクト年月日 */}
                            <Box sx={{ position: "absolute", top: "360px", left: "115px" }}>
                                <Box>
                                    <Input readOnly inputProps={{ style: { textAlign: "center" } }} type="text" value={selectedShowData.endDate}
                                        sx={{
                                            ".MuiInput-input": { cursor: "pointer", color: "white", borderRadius: '0%', height: "50px", width: "100px", }, ":hover": { backgroundColor: "transparent", transition: "0.2s" }, ":hover:not(.Mui-disabled, .Mui-error):before": { borderBottom: "0px solid white" }, ":hover:after": { borderBottom: "0px solid white" }, ":before": { borderBottom: "0px solid white" }, ":after": { borderBottom: "0px solid white" },
                                        }} />
                                </Box>
                            </Box>
                        </Box>
                    </Stack>
                </DialogContent>

                <DialogActions sx={{ bgcolor: "#474C53", width: "1000px", height: "50px", color: "white", display: "flex", alignItems: "center" }}>
                    <Button
                        sx={{
                            marginLeft: "0px",
                            marginRight: "-8px",
                            backgroundColor: "#acacac",
                            opacity: 0.7,
                            width: "127px",
                            height: "50px",
                            padding: "0",
                            fontSize: "18px",
                            fontFamily: "'Roboto', 'Meiryo', sans-serif",
                            fontWeight: "bold",
                            color: "white",
                            borderRadius: "0px",
                            ":hover": {
                                bgcolor: "#FFFFFF",
                                color: "#40475D",
                            },
                            textTransform: 'none'
                        }}
                        onClick={handleNoCloseDialog}
                    >
                        Cancel
                    </Button>
                    <Button
                        sx={{
                            marginLeft: "0px",
                            marginRight: "-8px",
                            backgroundColor: "#e66300",
                            width: "127px",
                            height: "50px",
                            padding: "0",
                            fontSize: "18px",
                            fontFamily: "'Roboto', 'Meiryo', sans-serif",
                            fontWeight: "bold",
                            color: "white",
                            borderRadius: "0px",
                            borderBottomRightRadius: "2px",
                            ":hover": {
                                bgcolor: "#FFFFFF",
                                color: "#40475D",
                            },
                            textTransform: 'none'
                        }}
                        onClick={handleCloseDialog}
                    >
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
