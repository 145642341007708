
import * as React from 'react';

import axios, { AxiosError } from "axios";

import './EuEmissionBoard.css'; // スタイルのために追加

// MUI @mui/material/
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import FormControl from '@mui/material/FormControl';
import Input from '@mui/material/Input';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

// MUI @mui/icons-material/
import SettingsIcon from '@mui/icons-material/Settings';

// Highcharts
import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

// アイコン
import RightArrow from '../../../assets/images/right_arrow.svg';
import State_Sailing from '../../../assets/images/state_sailing.svg';
import State_Port from '../../../assets/images/state_port.svg';


// Axios Error
interface ErrorData {
  message: string;
  status: number;
  error: string;
}


// 親コンポーネントから持ち回る値
export type Props = {
  unit: String;
  clickSearchFlg: boolean;
  setClickSearchFlg: (value: boolean) => void;
  vesselMaster: any;
  setVesselMaster: (value: any) => void;
  setAuthCheck: (value: boolean) => void;
  setAuthMessage: (value: any) => void;

  SeachInfo: any;
  setSeachInfo: (value: any) => void;
}

function formatNumber(num) {
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

// EU EmissionBoard Component--------------------------------------------------------------------
export const EuEmissionBoard: React.FC<Props> = props => {

  let initFlg = true;
  const [jwt, setJwt] = React.useState<string>();
  const [userId, setUserId] = React.useState<string>();

  // 検索フォーム----------------------------------------------------------------------------------------------------------------------------
  const [base_url, setBase_url] = React.useState(`${process.env.REACT_APP_API_URL}/EuEtsFuelEu/EmissionBoard/`);

  React.useEffect(() => {

    // API呼び出し
    // 初回のみ、必要項目をpayloadから取得
    let payloadUserId = ""
    let payloadJwt = ""
    const pay_json = localStorage.getItem("payload")
    if (pay_json !== null) {
      const pay = JSON.parse(pay_json)
      payloadUserId = pay["user_id"];
      payloadJwt = pay["jwt"];
      setUserId(payloadUserId);
      setJwt(pay["jwt"]);
    }
    const url = `${base_url}${props.SeachInfo.imoNo}?&user_id=${payloadUserId}&Timestamp_from=${props.SeachInfo.from}&Timestamp_to=${props.SeachInfo.to}&Unit=${props.unit}`
    if (initFlg) {
      if (props.unit !== undefined && props.unit !== "") {
        // Loading表示
        setLoading(true)
        setSuccess(false)
        // API呼び出し
        getNoonReport(url, payloadJwt);
      }
      else {
        // Loading解除
        setSuccess(true);
        setLoading(false);
      }
      initFlg = false
    }     
  }, []);

  React.useEffect(() => {
    console.log(props.clickSearchFlg);
    
    // 親コンポーネントからclickSearchFlgがtrueで飛んできた場合、検索処理実行
    if (props.clickSearchFlg === true) {
      // Loading表示
      setLoading(true)
      setSuccess(false)
      // 画面初期化
      reset_all()
      // API呼び出し
      getNoonReport(`${base_url}${props.SeachInfo.imoNo}?&user_id=${userId}&Timestamp_from=${props.SeachInfo.from}&Timestamp_to=${props.SeachInfo.to}&Unit=${props.unit}`, jwt);
    }
    
    // clickSearchFlgをfalseに更新
    props.setClickSearchFlg(false);

  }, [props.clickSearchFlg]);

  // Seach実行
  const getNoonReport = (url, jwt) => {

    const options = {
      headers: {
        'Authorization': jwt,
      }
    }
    console.log("URL", url);
    axios.get(url, options).then((res) => {

      console.log("axios", res);

      // 認可チェック
      if (res.data === 401) {
        props.setAuthCheck(true)
        props.setAuthMessage("不正な操作が行われた可能性があります。")
      } else if (res.data === 500) {
        props.setAuthCheck(true)
        props.setAuthMessage("JWTが改ざんされている可能性があります。")
      } else {
        props.setAuthCheck(false)
      }

      // LOG Speed Rate
      setLOGSpeedRate(res.data.LOGSpeedRate)
      setLOGSpeedRateUnit(res.data.LOGSpeedRateUnit)
      setLOGSpeedRate_Accumulation(res.data.LOGSpeedRate_Accumulation)
      setLOGSpeedRateUnit_Accumulation(res.data.LOGSpeedRateUnit_Accumulation)

      // ME Load Rate
      setMELoadRate(res.data.MELoadRate)
      setMELoadRateUnit(res.data.MELoadRateUnit)
      setMELoadRate_Accumulation(res.data.MELoadRate_Accumulation)
      setMELoadRateUnit_Accumulation(res.data.MELoadRateUnit_Accumulation)

      // Displacement Rate
      // setDisplacement_Categories(res.data.datas.Displacement_Categories)
      setDisplacementRate(res.data.DisplacementRate)
      setDisplacementRateUnit(res.data.DisplacementRateUnit)
      setDisplacementRate_Accumulation(res.data.DisplacementRate_Accumulation)
      setDisplacementRateUnit_Accumulation(res.data.DisplacementRateUnit_Accumulation)

      // Beaufort Rate
      setBeaufortRate(res.data.BeaufortRate)
      setBeaufortRateUnit(res.data.BeaufortRateUnit)
      setBeaufortRate_Accumulation(res.data.BeaufortRate_Accumulation)
      setBeaufortRateUnit_Accumulation(res.data.BeaufortRateUnit_Accumulation)

      // EUA
      setEUA(res.data.EUAList)
      setEUA_YAXIS(res.data.EUA_YAXIS)

      // Compliance Balance
      setCB(res.data.CBList)
      setCB_YAXIS(res.data.CB_YAXIS)

      // Voyage Information(Total)
      setVoyageInfomationTotal(res.data.VoyageInformationTotal)

      // Voyage Information
      console.log("props.unit:" + props.unit)
      if (props.unit === "Leg") {
        setLegList(res.data.VoyageInformationList)
      }
      else {
        setVoyageList(res.data.VoyageInformationList)
      }

      // Average
      setAverage(res.data.Average)
      setAverageUnit(res.data.AverageUnit)

      if (res.data.Average.length) {
        setLogSpeedAverage(res.data.Average[res.data.Average.length - 1].Avg_LOG_Speed)
        setBeaufortAverage(res.data.Average[res.data.Average.length - 1].Avg_Beaufort)
        setDisplacementAverage(res.data.Average[res.data.Average.length - 1].Avg_Displacement)
        setMeLoadAverage(res.data.Average[res.data.Average.length - 1].Avg_LOAD)
      }

      // VesselMaster
      props.setVesselMaster(res.data.VESSELMASTER)
      // Unit
      setUnit(props.unit)

      // クリックインデックスをリセット
      // setClickItemGraph({ index: 0, x: null, y: null })
      // setClickGraphReSetOn(true)

      // Loading解除
      setLoading(false)
      setSuccess(true)
    })
      .catch((e: AxiosError<ErrorData>) => {
        reset_all()
        setLoading(false)
        setSuccess(true)
        if (e.response === undefined) {
          console.log("undefinedError: " + e);
          return;
        }
        console.log(e + ", message: " + e.response.data.message)
      });
  }

  const reset_all = () => {
    // LOG Speed Rate
    setLOGSpeedRate([])
    setLOGSpeedRateUnit([])
    setLOGSpeedRate_Accumulation([])
    setLOGSpeedRateUnit_Accumulation([])

    // Displacement Rate
    // setDisplacement_Categories([])
    setDisplacementRate([])
    setDisplacementRateUnit([])
    setDisplacementRate_Accumulation([])
    setDisplacementRateUnit_Accumulation([])

    // Beaufort Rate
    setBeaufortRate([])
    setBeaufortRateUnit([])
    setBeaufortRate_Accumulation([])
    setBeaufortRateUnit_Accumulation([])

    // EUA
    setEUA([])
    setEUA_YAXIS({ tickInterval: 1000, max: 2000 })

    // CB
    setCB([])
    setCB_YAXIS({ tickInterval: 1000, max: 1000, min: -1000 })

    // Voyage Information
    setVoyageInfomationTotal(undefined)
    setLegList([])
    setVoyageList([])

    // Average
    setAverage([])
    setAverageUnit([])
    setLogSpeedAverage(undefined)
    setBeaufortAverage(undefined)
    setDisplacementAverage(undefined)
    setMeLoadAverage(undefined)

    // VesselMaster
    props.setVesselMaster("")

  }

  // Loading--------------------------------------------------------------------
  const [loading, setLoading] = React.useState(false);
  const [success, setSuccess] = React.useState(true);

  // 取得データ
  const [legList, setLegList] = React.useState<any>([]);
  const [voyageList, setVoyageList] = React.useState<any>([]);
  const [voyageInfomationTotal, setVoyageInfomationTotal] = React.useState<any>();

  // Aggregation Unit
  const [Unit, setUnit] = React.useState<String>("Leg");
  
  // DataSet
  const [LOGSpeedRate, setLOGSpeedRate] = React.useState<Array<any>>();
  const [LOGSpeedRateUnit, setLOGSpeedRateUnit] = React.useState<Array<any>>();
  const [LOGSpeedRate_Accumulation, setLOGSpeedRate_Accumulation] = React.useState<Array<any>>();
  const [LOGSpeedRateUnit_Accumulation, setLOGSpeedRateUnit_Accumulation] = React.useState<Array<any>>();
  const [LOGSpeedRateNone, setLOGSpeedRateNone] = React.useState<any>({ name: "", top: "", left: "", yname: "", ytop: "", yleft: "" });
  const [MELoadRate, setMELoadRate] = React.useState<Array<any>>();
  const [MELoadRateUnit, setMELoadRateUnit] = React.useState<Array<any>>();
  const [MELoadRate_Accumulation, setMELoadRate_Accumulation] = React.useState<Array<any>>();
  const [MELoadRateUnit_Accumulation, setMELoadRateUnit_Accumulation] = React.useState<Array<any>>();
  const [MELoadRateNone, setMELoadRateNone] = React.useState<any>({ name: "", top: "", left: "", yname: "", ytop: "", yleft: "" });

  // const [Displacement_Categories, setDisplacement_Categories] = React.useState<Array<any>>();
  const [DisplacementRate, setDisplacementRate] = React.useState<Array<any>>();
  const [DisplacementRateUnit, setDisplacementRateUnit] = React.useState<Array<any>>();
  const [DisplacementRate_Accumulation, setDisplacementRate_Accumulation] = React.useState<Array<any>>();
  const [DisplacementRateUnit_Accumulation, setDisplacementRateUnit_Accumulation] = React.useState<Array<any>>();

  const [BeaufortRate, setBeaufortRate] = React.useState<Array<any>>();
  const [BeaufortRateUnit, setBeaufortRateUnit] = React.useState<Array<any>>();
  const [BeaufortRate_Accumulation, setBeaufortRate_Accumulation] = React.useState<Array<any>>();
  const [BeaufortRateUnit_Accumulation, setBeaufortRateUnit_Accumulation] = React.useState<Array<any>>();
  const [BeaufortRateNone, setBeaufortRateNone] = React.useState<any>({ name: "", top: "", left: "", yname: "", ytop: "", yleft: "" });

  const [CB, setCB] = React.useState<Array<Array<any>>>();
  const [EUA, setEUA] = React.useState<Array<Array<any>>>();
  const [EUACBNone, setEUACBNone] = React.useState<any>({ name: "", top: "", left: "" });
  const [CB_YAXIS, setCB_YAXIS] = React.useState({ tickInterval: 1000, max: 1000, min: -1000 });
  const [EUA_YAXIS, setEUA_YAXIS] = React.useState({ tickInterval: 1000, max: 2000 });

  const [Average, setAverage] = React.useState<any | null>();
  const [AverageUnit, setAverageUnit] = React.useState<any | null>();
  // 各Averageの画面表示用
  const [LogSpeedAverage, setLogSpeedAverage] = React.useState<any>();
  const [BeaufortAverage, setBeaufortAverage] = React.useState<any>();
  const [DisplacementAverage, setDisplacementAverage] = React.useState<any>();
  const [MeLoadAverage, setMeLoadAverage] = React.useState<any>();

  // EUACBOptions--------------------------------------------------------------------
  const EUACBOptions: Highcharts.Options = {
    navigation: {
      buttonOptions: {
        enabled: false,
      }
    },
    credits: {
      enabled: false
    },
    chart: {
      // marginTop: window.innerHeight * 0.06,
      height: window.innerHeight * 0.36,
      zooming: { type: "xy" },
      alignTicks: false,
      backgroundColor: 'rgba(255, 255, 255, 0)', // 背景色を透明にする
      plotBackgroundColor: 'rgba(207, 207, 207, 0.03)',
      // plotBackgroundColor: {
      //   linearGradient: { x1: 0, x2: 0, y1: 1, y2: 0 },
      //   stops: [
      //     [0, 'rgba(255, 255, 255, 0.03)'],
      //     [0.8529, 'rgba(255, 255, 255, 0.13)'],
      //     [1, 'rgba(255, 255, 255, 0.15)']
      //   ]
      // },
      events: {
        click: function (event) {
          onClickGraphReSet(event)
        },
      }
    },
    tooltip: {
      enabled: false
    },
    legend: {
      enabled: false,
    },
    title: {
      text: '時系列の折れ線グラフと棒グラフの組み合わせ',
      style: {
        color: 'transparent'
      }
    },
    plotOptions: {
      column: {
        grouping: true,
        // groupPadding: 5,
      },
      series: {
        cursor: 'pointer',
        point: {
          events: {
            click: (event) => {
              onClickGraph(event);
            }
          }
        },
      },
    },
    xAxis: [
      {
        title: {
          text: "",
          style: {
            color: 'white'
          }
        },
        // type: 'datetime',
        labels: {
          enabled: true,
          style: {
            color: 'rgba(255, 255, 255, 0.70)',
            fontFamily: 'Roboto Condensed',
            fontSize: '13px'
          }
        },
        gridLineWidth: 0
      },
    ],
    yAxis: [
      { // EUA
        opposite: false,
        min: 0,
        max: 2000,
        tickInterval: 1000,
        gridLineWidth: 0.5,
        gridLineDashStyle: 'Dot',
        title: {
          text: 'EUA',
          rotation: -90,
          style: {
            color: 'rgba(255, 255, 255, 0.70)',
            opacity: 0.70,
            fontSize: "13px",
            fontWeight: '400',
            lineHeight: "24px",
            wordWrap: 'break-word'
          },
        },
        labels: {
          enabled: true,
          style: {
            color: 'rgba(255, 255, 255, 0.70)',
            opacity: 0.70,
            fontSize: "13px",
            fontWeight: '400',
            lineHeight: "24px",
            wordWrap: 'break-word'
          },
          formatter: function (this: Highcharts.AxisLabelsFormatterContextObject) {
            const test = this.value.valueOf().toString()
            return test;
          }
        },
      },
      { // Compliance Balance
        tickInterval: 1000,
        min: -1000,
        max: 1000,
        opposite: true,
        gridLineWidth: 0,
        gridLineDashStyle: 'Dot',
        title: {
          text: 'Compliance Balance',
          align: 'low',
          rotation: 90,
          x: 5,
          y: -50,
          style: {
            color: 'rgba(255, 255, 255, 0.70)',
            opacity: 0.70,
            fontSize: "13px",
            fontWeight: '400',
            lineHeight: "24px",
            wordWrap: 'break-word'
          },
        },
        labels: {
          // align: 'left',
          x: 10,
          y: 0,
          enabled: true,
          style: {
            color: 'rgba(255, 255, 255, 0.70)',
            opacity: 0.70,
            fontSize: "13px",
            fontWeight: '400',
            lineHeight: "24px",
            wordWrap: 'break-word'
          },
          formatter: function (this: Highcharts.AxisLabelsFormatterContextObject) {
            const test = this.value.valueOf().toString()
            return test;
          }
        },
      },
      
    ],
    series: [
      {
        type: 'column',
        name: "EUA",
        visible: true,
        yAxis: 0,
        data: EUA,
        borderWidth: 0,
        // pointWidth: 20,
        color: {
          linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
          stops: [
            [0, 'rgb(18, 87, 151, 1)'],
            [1, 'rgb(19, 101, 178, 1)']
          ]
        },
        // pointPlacement: 1
      },
      {
        type: 'line',
        name: "Compliance Balance",
        color: '#FFBF83',
        visible: true,
        yAxis: 1,
        data: CB,
        lineWidth: 0.5,
        marker: {
          radius: 8,
          fillColor: {
            radialGradient: { cx: 0.5, cy: 0.5, r: 0.5 },
            stops: [
              [0.14, '#FFE6CE'],
              [0.86, '#FF800C']
            ]
          }
        },
        // pointPlacement: 'on'
      },
    ]
  };
  const chartComponentRef = React.useRef(null);
  const [hcOptions, setHcOptions] = React.useState<any>(EUACBOptions);

  // logSpeedOptions--------------------------------------------------------------------
  const logSpeedOptions = {
    navigation: {
      buttonOptions: {
        enabled: false,
      }
    },
    credits: {
      enabled: false
    },
    chart: {
      height: 220,
      alignTicks: false,
      backgroundColor: 'rgba(0, 0, 0, 0)', // 背景色を透明にする
      // plotBackgroundColor: {
      //   linearGradient: { x1: 0, x2: 0, y1: 1, y2: 0 },
      //   stops: [
      //     [0, 'rgba(255, 255, 255, 0.03)'],
      //     [0.8529, 'rgba(255, 255, 255, 0.13)'],
      //     [1, 'rgba(255, 255, 255, 0.15)']
      //   ]
      // },
    },
    title: {
      text: ''
    },
    subtitle: {
      text: ''
    },
    xAxis: {
      crosshair: true,
      // title: {
      //   text: '(knot)',
      //   align: 'low',
      //   // rotation: -90,
      //   x: 290,
      //   y: -10,
      //   style: {
      //     color: 'rgba(255, 255, 255, 0.70)',
      //     opacity: 0.70,
      //     fontSize: "13px",
      //     fontWeight: '400',
      //     lineHeight: "24px",
      //     wordWrap: 'break-word'
      //   },
      // },
      tickPixelInterval: 10,
      labels: {
        step: 2, // 2つのラベルごとに2つ表示
        textOverflow: 'none', // ラベルが切れないようにする
        rotation: 0, // X軸ラベルを水平に表示
        enabled: true,
        style: {
          color: 'rgba(255, 255, 255, 0.70)',
          opacity: 0.70,
          fontSize: "13px",
          fontWeight: '400',
          lineHeight: "24px",
          wordWrap: 'break-word'
        },
      }
    },
    yAxis: {
      title: {
        text: ''
      },
      labels: {
        enabled: true,
        style: {
          color: 'rgba(255, 255, 255, 0.70)',
          opacity: 0.70,
          fontSize: "13px",
          fontWeight: '400',
          lineHeight: "24px",
          wordWrap: 'break-word'
        }
      },
      opposite: false,
      gridLineWidth: 0.5,
      gridLineDashStyle: 'Dot',
    },
    tooltip: {
      enabled: false,
      headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
      pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
        '<td style="padding:0"><b>{point.y:.1f} mm</b></td></tr>',
      footerFormat: '</table>',
      shared: true,
      useHTML: true
    },
    plotOptions: {
      column: {
        pointPadding: 0,
        borderWidth: 0.1,
        groupPadding: 0,
        shadow: false,
        minPointLength: 0,
      },
      line: {
        lineWidth: 1
      },
    },
    legend: {
      enabled: false,
    },
    series: [{
      type: 'column',
      data: LOGSpeedRate
    },
    {
      type: 'line',
      data: LOGSpeedRate_Accumulation,
      color: "rgba(176, 176, 37, 1)",
      marker: {
        enabled: false
      },
    }]
  };
  const [hcOptionsLogSpeed, setHcOptionsLogSpeed] = React.useState<any>(logSpeedOptions);

  // MELoadOptions--------------------------------------------------------------------
  const MELoadOptions = {
    navigation: {
      buttonOptions: {
        enabled: false,
      }
    },
    credits: {
      enabled: false
    },
    chart: {
      height: 220,
      alignTicks: false,
      backgroundColor: 'rgba(0, 0, 0, 0)', // 背景色を透明にする
      // plotBackgroundColor: {
      //   linearGradient: { x1: 0, x2: 0, y1: 1, y2: 0 },
      //   stops: [
      //     [0, 'rgba(255, 255, 255, 0.03)'],
      //     [0.8529, 'rgba(255, 255, 255, 0.13)'],
      //     [1, 'rgba(255, 255, 255, 0.15)']
      //   ]
      // },
    },
    title: {
      text: ''
    },
    subtitle: {
      text: ''
    },
    xAxis: {
      crosshair: true,
      labels: {
        step: 2, // 2つのラベルごとに2つ表示
        textOverflow: 'none', // ラベルが切れないようにする
        rotation: 0, // X軸ラベルを水平に表示
        enabled: true,
        style: {
          color: 'rgba(255, 255, 255, 0.70)',
          opacity: 0.70,
          fontSize: "13px",
          fontWeight: '400',
          lineHeight: "24px",
          wordWrap: 'break-word'
        },
      }
    },
    yAxis: {
      title: {
        text: ''
      },
      labels: {
        enabled: true,
        style: {
          color: 'rgba(255, 255, 255, 0.70)',
          opacity: 0.70,
          fontSize: "13px",
          fontWeight: '400',
          lineHeight: "24px",
          wordWrap: 'break-word'
        }
      },
      opposite: false,
      gridLineWidth: 0.5,
      gridLineDashStyle: 'Dot',
    },
    tooltip: {
      enabled: false,
      headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
      pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
        '<td style="padding:0"><b>{point.y:.1f} mm</b></td></tr>',
      footerFormat: '</table>',
      shared: true,
      useHTML: true
    },
    plotOptions: {
      column: {
        pointPadding: 0,
        borderWidth: 0.1,
        groupPadding: 0,
        shadow: false,
        minPointLength: 0,
      },
      line: {
        lineWidth: 1
      },
    },
    legend: {
      enabled: false,
    },
    series: [{
      type: 'column',
      data: MELoadRate
    },
    {
      type: 'line',
      data: MELoadRate_Accumulation,
      color: "rgba(166, 65, 206, 1)",
      marker: {
        enabled: false
      },
    }]
  };
  const [hcOptionsMELoad, setHcOptionsMELoad] = React.useState<any>(MELoadOptions);

  // BeaufortOptions--------------------------------------------------------------------
  const BeaufortOptions = {
    navigation: {
      buttonOptions: {
        enabled: false,
      }
    },
    credits: {
      enabled: false
    },
    chart: {
      height: 220,
      alignTicks: false,
      backgroundColor: 'rgba(0, 0, 0, 0)',
      // plotBackgroundColor: {
      //   linearGradient: { x1: 0, x2: 0, y1: 1, y2: 0 },
      //   stops: [
      //     [0, 'rgba(255, 255, 255, 0.03)'],
      //     [0.8529, 'rgba(255, 255, 255, 0.13)'],
      //     [1, 'rgba(255, 255, 255, 0.15)']
      //   ]
      // },
    },
    title: {
      text: ''
    },
    subtitle: {
      text: ''
    },
    xAxis: {
      crosshair: true,
      labels: {
        step: 1, // 1つのラベルごとに1つ表示
        textOverflow: 'none', // ラベルが切れないようにする
        rotation: 0, // X軸ラベルを水平に表示
        enabled: true,
        style: {
          color: 'rgba(255, 255, 255, 0.70)',
          opacity: 0.70,
          fontSize: "13px",
          fontWeight: '400',
          lineHeight: "24px",
          wordWrap: 'break-word'
        },
      }
    },
    yAxis: {
      title: {
        text: ''
      },
      labels: {
        enabled: true,
        style: {
          color: 'rgba(255, 255, 255, 0.70)',
          opacity: 0.70,
          fontSize: "13px",
          fontWeight: '400',
          lineHeight: "24px",
          wordWrap: 'break-word'
        }
      },
      opposite: false,
      gridLineWidth: 0.5,
      gridLineDashStyle: 'Dot',
    },
    tooltip: {
      enabled: false,
      headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
      pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
        '<td style="padding:0"><b>{point.y:.1f} mm</b></td></tr>',
      footerFormat: '</table>',
      shared: true,
      useHTML: true
    },
    plotOptions: {
      column: {
        pointPadding: 0,
        borderWidth: 0.1,
        groupPadding: 0,
        shadow: false,
        minPointLength: 0,
      },
      line: {
        lineWidth: 1
      },
    },
    legend: {
      enabled: false,
    },
    series: [{
      type: 'column',
      name: 'Data',
      data: BeaufortRate,
    },
    {
      type: 'line',
      data: BeaufortRate_Accumulation,
      color: "rgba(47, 228, 196, 1)",
      marker: {
        enabled: false
      },
    }]
  };
  const [hcOptionsBeaufort, setHcOptionsBeaufort] = React.useState<any>(BeaufortOptions);

  // DisplacementOptions--------------------------------------------------------------------
  const DisplacementOptions = {
    navigation: {
      buttonOptions: {
        enabled: false,
      }
    },
    credits: {
      enabled: false
    },
    chart: {
      type: 'pie',
      marginTop: 5,
      height: 200,
      alignTicks: false,
      backgroundColor: 'rgba(0, 0, 0, 0)',
      // plotBackgroundColor: {
      //   linearGradient: { x1: 0, x2: 0, y1: 1, y2: 0 },
      //   stops: [
      //     [0, 'rgba(255, 255, 255, 0.03)'],
      //     [0.8529, 'rgba(255, 255, 255, 0.13)'],
      //     [1, 'rgba(255, 255, 255, 0.15)']
      //   ]
      // },
      borderWidth: 0
    },
    title: {
      text: ''
    },
    subtitle: {
      text: ''
    },
    tooltip: {
      enabled: false,
      headerFormat: '<span style="font-size:10px">Displacement</span><table>',
      pointFormat: '<tr>'
        + '<p><td style="color:{series.color};padding:0">DataRange: </td>'
        + '<td style="padding:0"><b>{point.x:.0f} MT</br></td></p>'
        + '</tr>'
        + '<tr>'
        + '<p><td style="color:{series.color};padding:0">Rate: </td>'
        + '<td style="padding:0"><b>{point.y:.1f} %</br></td></p>'
        + '</tr>',
      footerFormat: '</table>',
      shared: true,
      useHTML: true
    },
    plotOptions: {
      pie: {
        size: '100%',
        innerSize: '70%',
        borderRadius: "0%",
        borderWidth: 0,
        colorByPoint: true,
        dataLabels: {
          enabled: false
        },
        point: {
          events: {}
        }
      }
    },
    series: [{
      name: '',
      data: []
    }],
  };
  const [hcOptionsDisplacement, setHcOptionsDisplacement] = React.useState<any>(DisplacementOptions)
  // const DisplacementchartComponentRef = React.useRef(null);
  const DisplacementchartComponentRef = React.useRef<HighchartsReact.RefObject>(null);

  // yAxis
  const [FOCyAxisMin, setFOCyAxisMin] = React.useState<any>(0);
  const [FOCyAxisMax, setFOCyAxisMax] = React.useState<any>(2000);
  const [FOCyAxisMinShow, setFOCyAxisMinShow] = React.useState<any>(0);
  const [FOCyAxisMaxShow, setFOCyAxisMaxShow] = React.useState<any>(2000);
  const [openDialogyAxis, setOpenDialogAxis] = React.useState(false);
  const handleClickOpenDialogAxis = () => {
    setOpenDialogAxis(true);
  };
  const handleNoClickOpenDialogAxis = () => {
    setFOCyAxisMinShow(FOCyAxisMin)
    setFOCyAxisMaxShow(FOCyAxisMax)
    setOpenDialogAxis(false);
  };
  const handleClickSetAxis = () => {
    if (FOCyAxisMin >= FOCyAxisMax) {
      alert("Max must be greater than Min.")
    }
    else if (FOCyAxisMin === "" || FOCyAxisMax === "" || FOCyAxisMin === undefined || FOCyAxisMax === undefined) {
      alert("No spaces allowed. Please enter a value.")
    }
    else {
      setFOCyAxisMin(FOCyAxisMinShow)
      setFOCyAxisMax(FOCyAxisMaxShow)
      setOpenDialogAxis(false);
      setKey(key + 1);
    }
  };
  const handleClickFOCyAxisMinShow = (event) => {
    if (/^\d*$/.test(event)) { setFOCyAxisMinShow(event); }
    else { alert("Please enter numbers in half-width characters.") }
  }
  const handleClickFOCyAxisMaxShow = (event) => {
    if (/^\d*$/.test(event)) { setFOCyAxisMaxShow(event); }
    else { alert("Please enter numbers in half-width characters.") }
  }

  // Highchartsグラフの表示ON/OFF
  const [CBFlag, setCBFlag] = React.useState(true);
  const [EUAFlag, setEUAFlag] = React.useState(true);
  const handleClickGraphLabel = (number) => {
    const newHcOptions = { ...hcOptions };
    for (var i = 0; i <= 2; i++) {
      if (i === number) {
        newHcOptions.series[i].visible = !newHcOptions.series[i].visible;
        if ( i === 0) {
          setEUAFlag(!EUAFlag)
        }
        else if(i === 1) {
          setCBFlag(!CBFlag)
        }
      }
    }
    setHcOptions(newHcOptions);
  };

  // APIから取得データHcOptionsを更新
  React.useEffect(() => {
    if (EUA !== undefined && EUA_YAXIS !== undefined && CB !== undefined && CB_YAXIS !== undefined
      && LOGSpeedRate !== undefined && LOGSpeedRate_Accumulation !== undefined
      && DisplacementRate !== undefined && DisplacementRate_Accumulation !== undefined
      && BeaufortRate !== undefined && BeaufortRate_Accumulation !== undefined
      && MELoadRate !== undefined && MELoadRate_Accumulation !== undefined
    ) {

      // EUA&CB
      const newHcOptions = { ...hcOptions };

      // EUA
      newHcOptions.series[0].data = JSON.parse(JSON.stringify(EUA));
      newHcOptions.yAxis[0].max = EUA_YAXIS.max;
      newHcOptions.yAxis[0].tickInterval = EUA_YAXIS.tickInterval;

      // CB
      newHcOptions.series[1].data = JSON.parse(JSON.stringify(CB));
      newHcOptions.yAxis[1].max = CB_YAXIS.max;
      newHcOptions.yAxis[1].min = CB_YAXIS.min;
      newHcOptions.yAxis[1].tickInterval = CB_YAXIS.tickInterval;

      // X軸設定
      newHcOptions.xAxis[0].labels.formatter = function () {
        if (props.unit === "Leg") {
          return "LEG " + this.value;
        }
        else {
          return "Voyage " + this.value;
        }
      };

      if(EUA.length === 0){
        setEUACBNone({ name: "Data Nothing", top: "200", left: "500" })
      }
      else {
        setEUACBNone({ name: "", top: "", left: "" })
      }
      
      setHcOptions(newHcOptions)

      // LOGSpeedRate
      const newHcOptionsLogSpeed = { ...hcOptionsLogSpeed };
      if (LOGSpeedRate.length) {
        newHcOptionsLogSpeed.xAxis.categories = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '', 'knot']
        newHcOptionsLogSpeed.yAxis.min = 0
        newHcOptionsLogSpeed.yAxis.max = 100
        newHcOptionsLogSpeed.yAxis.tickInterval = 25
        newHcOptionsLogSpeed.series[0].data = JSON.parse(JSON.stringify(LOGSpeedRate[LOGSpeedRate.length - 1]))
        newHcOptionsLogSpeed.series[1].data = JSON.parse(JSON.stringify(LOGSpeedRate_Accumulation[LOGSpeedRate_Accumulation.length - 1]))
        setLOGSpeedRateNone({ xname: "", xtop: "", xleft: "", yname: "", ytop: "0", yleft: "8" })
      } else {
        newHcOptionsLogSpeed.xAxis.categories = []
        newHcOptionsLogSpeed.yAxis.min = ""
        newHcOptionsLogSpeed.yAxis.max = ""
        newHcOptionsLogSpeed.yAxis.tickInterval = ""
        newHcOptionsLogSpeed.series[0].data = []
        newHcOptionsLogSpeed.series[1].data = []
        setLOGSpeedRateNone({ xname: "Data Nothing", xtop: "110", xleft: "140", yname: "", ytop: "", yleft: "" })
      }
      setHcOptionsLogSpeed(newHcOptionsLogSpeed)

      // MELoadRate
      const newHcOptionsMELoad = { ...hcOptionsMELoad };
      if (MELoadRate.length) {
        newHcOptionsMELoad.xAxis.categories = ['0', '5', '10', '15', '20', '25', '30', '35', '40', '45', '50', '55', '60', '65', '70', '75', '80', '85', '90', '95', '100', '', '%']
        newHcOptionsMELoad.yAxis.min = 0
        newHcOptionsMELoad.yAxis.max = 100
        newHcOptionsMELoad.yAxis.tickInterval = 25
        newHcOptionsMELoad.series[0].data = JSON.parse(JSON.stringify(MELoadRate[MELoadRate.length - 1]))
        newHcOptionsMELoad.series[1].data = JSON.parse(JSON.stringify(MELoadRate_Accumulation[MELoadRate_Accumulation.length - 1]))
        setMELoadRateNone({ xname: "", xtop: "", xleft: "", yname: "", ytop: "0", yleft: "8" })
      } else {
        newHcOptionsMELoad.xAxis.categories = []
        newHcOptionsMELoad.yAxis.min = ""
        newHcOptionsMELoad.yAxis.max = ""
        newHcOptionsMELoad.yAxis.tickInterval = ""
        newHcOptionsMELoad.series[0].data = []
        newHcOptionsMELoad.series[1].data = []
        setMELoadRateNone({ xname: "Data Nothing", xtop: "110", xleft: "140", yname: "", ytop: "", yleft: "" })
      }
      setHcOptionsMELoad(newHcOptionsMELoad)

      // Beaufort
      const newHcOptionsBeaufort = { ...hcOptionsBeaufort };
      if (BeaufortRate.length) {
        newHcOptionsBeaufort.xAxis.categories = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '', '']
        newHcOptionsBeaufort.yAxis.min = 0
        newHcOptionsBeaufort.yAxis.max = 100
        newHcOptionsBeaufort.yAxis.tickInterval = 25
        newHcOptionsBeaufort.series[0].data = JSON.parse(JSON.stringify(BeaufortRate[BeaufortRate.length - 1]))
        newHcOptionsBeaufort.series[1].data = JSON.parse(JSON.stringify(BeaufortRate_Accumulation[BeaufortRate_Accumulation.length - 1]))
        setBeaufortRateNone({ xname: "", xtop: "", xleft: "", yname: "", ytop: "0", yleft: "8" })
      } else {
        newHcOptionsBeaufort.xAxis.categories = []
        newHcOptionsBeaufort.yAxis.min = ""
        newHcOptionsBeaufort.yAxis.max = ""
        newHcOptionsBeaufort.yAxis.tickInterval = ""
        newHcOptionsBeaufort.series[0].data = []
        newHcOptionsBeaufort.series[1].data = []
        setBeaufortRateNone({ xname: "Data Nothing", xtop: "110", xleft: "140", yname: "", ytop: "", yleft: "" })
      }
      setHcOptionsBeaufort(newHcOptionsBeaufort)

      // DisplacementRate
      const newHcOptionsDisplacement = { ...hcOptionsDisplacement };

      if (DisplacementRate.length) {
        newHcOptionsDisplacement.series[0].data = JSON.parse(JSON.stringify(DisplacementRate[DisplacementRate.length - 1]))
        newHcOptionsDisplacement.title = {
          text:
            `<div style="color: rgba(255,255,255,0.6); font-size: 14px; font-weight: lighter; font-family: Roboto Condensed, Arial, sans-serif; margin-top:70px;">Ballast</div>
             <div style="color: rgba(255,255,255,0.6); font-size: 14px; font-weight: lighter; font-family: Roboto Condensed, Arial, sans-serif; text-align: center;"><span style="font-size: 15px; color: white;">${DisplacementRate[DisplacementRate.length - 1][0].y.toFixed(1)}</span>&nbsp;%</div>`,
          useHTML: true,
        }
        newHcOptionsDisplacement.chart.events = {
          load() {
            const chart = this;
            chart.series[0].points.forEach(point => {
              point.onMouseOver = function () {
                console.log("this", this)
                let name
                if (this.index === 0) {
                  name = "Ballast"
                } else if (this.index === 1) {
                  name = "Laden"
                }
                chart.update({
                  title: {
                    text:
                      `<div style="color: rgba(255,255,255,0.6); font-size: 14px; font-weight: lighter; font-family: Roboto Condensed, Arial, sans-serif; margin-top:70px;">${name}</div>
                      <div style="color: rgba(255,255,255,0.6); font-size: 14px; font-weight: lighter; font-family: Roboto Condensed, Arial, sans-serif; text-align: center;"><span style="font-size: 15px; color: white;">${this.y.toFixed(1)}</span>&nbsp;%</div>`,
                    useHTML: true
                  },
                  align: 'center',
                  verticalAlign: 'middle',
                  y: 0 // タイトルの垂直位置を中央に設定
                });
              };
            });
          }
        }
      } else {
        newHcOptionsDisplacement.series[0].data = JSON.parse(JSON.stringify([]))
        newHcOptionsDisplacement.title = {
          text:
            `<div style="color: rgba(255,255,255,0.5); font-size: 14px; font-weight: lighter; font-family: Roboto Condensed, Arial, sans-serif; margin-top:60px;">Data Nothing</div>`,
          useHTML: true,
        }
      }
      setHcOptionsDisplacement(newHcOptionsDisplacement)

    }
    setKey(key + 1);
  }, [EUA, EUA_YAXIS, CB, CB_YAXIS, LOGSpeedRate, LOGSpeedRate_Accumulation, DisplacementRate, DisplacementRate_Accumulation,
    BeaufortRate, BeaufortRate_Accumulation, MELoadRate, MELoadRate_Accumulation
  ])

  const [clickItemGraph, setClickItemGraph] = React.useState({ index: 0, x: null, y: null }); // クリックした点の値
  const [changeClickGraph, setChangeClickGraph] = React.useState<number>(0);

  // グラフ上の線をクリックされたら
  const onClickGraph = (event: any) => { //グラフ上の点をクリック
    setChangeClickGraph((prev) => (prev + 1));
    setClickItemGraph({ index: event.point.index, x: event.point.x, y: event.point.y });
  }

  const [key, setKey] = React.useState(0);
  // クリックされた棒グラフの集計値をセット
  React.useEffect(() => {
    if (changeClickGraph > 0) {
      const item = clickItemGraph;
      console.log("item", item)

      if (LOGSpeedRateUnit !== undefined && LOGSpeedRateUnit_Accumulation !== undefined
        && DisplacementRateUnit !== undefined && DisplacementRateUnit_Accumulation !== undefined
        && BeaufortRateUnit !== undefined && BeaufortRateUnit_Accumulation !== undefined
        && MELoadRateUnit !== undefined && MELoadRateUnit_Accumulation !== undefined
        && EUA !== undefined && CB !== undefined
      ) {

        // FOC&CII
        const newHcOptions = { ...hcOptions };
        newHcOptions.series[0].data = JSON.parse(JSON.stringify(EUA))
        newHcOptions.series[1].data = JSON.parse(JSON.stringify(CB))

        // クリックされた月の色をセット
        for (let i = 0; i < EUA.length; i++) {
          let EUA_color_a = 'rgb(18, 87, 151, 0.2)'
          let EUA_color_b = 'rgb(19, 101, 178, 0.2)'
          let CB_color_a = 'rgba(255, 191, 131, 0.2)'
          let CB_color_b = 'rgba(255, 133, 20, 0.2)'
          if (i === item.index) {
            EUA_color_a = 'rgb(18, 87, 151, 1)'
            EUA_color_b = 'rgb(19, 101, 178, 1)'
            CB_color_a = 'rgba(255, 191, 131, 1)'
            CB_color_b = 'rgba(255, 133, 20, 1)'
          }
          newHcOptions.series[0].data[i] = JSON.parse(JSON.stringify({
            x: EUA[i][0],
            y: EUA[i][1],
            color: {
              linearGradient: {
                x1: 0, x2: 0, y1: 0, y2: 1
              },
              stops: [
                [0, EUA_color_a],
                [1, EUA_color_b]
              ]
            },
          }))
          newHcOptions.series[1].data[i] = JSON.parse(JSON.stringify({
            x: CB[i][0],
            y: CB[i][1],
            lineWidth: 0.5,
            marker: {
              radius: 8,
              fillColor: {
                radialGradient: { cx: 0.5, cy: 0.5, r: 0.5 },
                stops: [
                  [0, CB_color_a],
                  [1, CB_color_b]
                ]
              }
            },
          }))
        }

        setHcOptions(newHcOptions)

        // LOGSpeedRate
        const newHcOptionsLogSpeed = { ...hcOptionsLogSpeed };
        if (LOGSpeedRateUnit[item.index] !== undefined && LOGSpeedRateUnit[item.index].length) {
          newHcOptionsLogSpeed.xAxis.categories = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '', '(knot)']
          newHcOptionsLogSpeed.yAxis.min = 0
          newHcOptionsLogSpeed.yAxis.max = 100
          newHcOptionsLogSpeed.yAxis.tickInterval = 25
          newHcOptionsLogSpeed.series[0].data = JSON.parse(JSON.stringify(LOGSpeedRateUnit[item.index]))
          newHcOptionsLogSpeed.series[1].data = JSON.parse(JSON.stringify(LOGSpeedRateUnit_Accumulation[item.index]))
          setLOGSpeedRateNone({ xname: "", xtop: "", xleft: "", yname: "(%)", ytop: "0", yleft: "8" })
        } else {
          newHcOptionsLogSpeed.xAxis.categories = []
          newHcOptionsLogSpeed.yAxis.min = ""
          newHcOptionsLogSpeed.yAxis.max = ""
          newHcOptionsLogSpeed.yAxis.tickInterval = ""
          newHcOptionsLogSpeed.series[0].data = []
          newHcOptionsLogSpeed.series[1].data = []
          setLOGSpeedRateNone({ xname: "Data Nothing", xtop: "110", xleft: "140", yname: "", ytop: "", yleft: "" })
        }
        setHcOptionsLogSpeed(newHcOptionsLogSpeed)

        // MELoadRate
        const newHcOptionsMELoad = { ...hcOptionsMELoad };
        if (MELoadRateUnit[item.index] !== undefined && MELoadRateUnit[item.index].length) {
          newHcOptionsMELoad.xAxis.categories = ['0', '5', '10', '15', '20', '25', '30', '35', '40', '45', '50', '55', '60', '65', '70', '75', '80', '85', '90', '95', '100', '', '(%)']
          newHcOptionsMELoad.yAxis.min = 0
          newHcOptionsMELoad.yAxis.max = 100
          newHcOptionsMELoad.yAxis.tickInterval = 25
          newHcOptionsMELoad.series[0].data = JSON.parse(JSON.stringify(MELoadRateUnit[item.index]))
          newHcOptionsMELoad.series[1].data = JSON.parse(JSON.stringify(MELoadRateUnit_Accumulation[item.index]))
          setMELoadRateNone({ xname: "", xtop: "", xleft: "", yname: "(%)", ytop: "0", yleft: "8" })
        } else {
          newHcOptionsMELoad.xAxis.categories = []
          newHcOptionsMELoad.yAxis.min = ""
          newHcOptionsMELoad.yAxis.max = ""
          newHcOptionsMELoad.yAxis.tickInterval = ""
          newHcOptionsMELoad.series[0].data = []
          newHcOptionsMELoad.series[1].data = []
          setMELoadRateNone({ xname: "Data Nothing", xtop: "110", xleft: "140", yname: "", ytop: "", yleft: "" })
        }
        setHcOptionsMELoad(newHcOptionsMELoad)

        // Beaufort
        const newHcOptionsBeaufort = { ...hcOptionsBeaufort };
        if (BeaufortRateUnit[item.index] !== undefined && BeaufortRateUnit[item.index].length) {
          newHcOptionsBeaufort.xAxis.categories = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '', '(BF)']
          newHcOptionsBeaufort.yAxis.min = 0
          newHcOptionsBeaufort.yAxis.max = 100
          newHcOptionsBeaufort.yAxis.tickInterval = 25
          newHcOptionsBeaufort.series[0].data = JSON.parse(JSON.stringify(BeaufortRateUnit[item.index]))
          newHcOptionsBeaufort.series[1].data = JSON.parse(JSON.stringify(BeaufortRateUnit_Accumulation[item.index]))
          setBeaufortRateNone({ xname: "", xtop: "", xleft: "", yname: "(%)", ytop: "0", yleft: "8" })
        } else {
          newHcOptionsBeaufort.xAxis.categories = []
          newHcOptionsBeaufort.yAxis.min = ""
          newHcOptionsBeaufort.yAxis.max = ""
          newHcOptionsBeaufort.yAxis.tickInterval = ""
          newHcOptionsBeaufort.series[0].data = []
          newHcOptionsBeaufort.series[1].data = []
          setBeaufortRateNone({ xname: "Data Nothing", xtop: "110", xleft: "140", yname: "", ytop: "", yleft: "" })
        }
        setHcOptionsBeaufort(newHcOptionsBeaufort)

        // DisplacementRate
        const newHcOptionsDisplacement = { ...hcOptionsDisplacement };
        if (DisplacementRateUnit[item.index] !== undefined && DisplacementRateUnit[item.index].length) {
          newHcOptionsDisplacement.series[0].data = JSON.parse(JSON.stringify(DisplacementRateUnit[item.index]))
          newHcOptionsDisplacement.title = {
            text:
              `<div style="color: rgba(255,255,255,0.6); font-size: 14px; font-weight: lighter; font-family: Roboto Condensed, Arial, sans-serif; margin-top:70px;">Ballast</div>
              <div style="color: rgba(255,255,255,0.6); font-size: 14px; font-weight: lighter; font-family: Roboto Condensed, Arial, sans-serif; text-align: center;"><span style="font-size: 15px; color: white;">${DisplacementRateUnit[item.index][0].y.toFixed(1)}</span>&nbsp;%</div>`,
 
            useHTML: true,
          }
          newHcOptionsDisplacement.chart.events = {
            load() {
              const chart = this;
              chart.series[0].points.forEach(point => {
                point.onMouseOver = function () {
                  console.log("this", this)
                  let name
                  if (this.index === 0) {
                    name = "Ballast"
                  } else if (this.index === 1) {
                    name = "Laden"
                  }
                  chart.update({
                    title: {
                      text:
                        `<div style="color: rgba(255,255,255,0.6); font-size: 14px; font-weight: lighter; font-family: Roboto Condensed, Arial, sans-serif; margin-top:70px;">${name}</div>
                        <div style="color: rgba(255,255,255,0.6); font-size: 14px; font-weight: lighter; font-family: Roboto Condensed, Arial, sans-serif; text-align: center;"><span style="font-size: 15px; color: white;">${this.y.toFixed(1)}</span>&nbsp;%</div>`,
  
                      useHTML: true
                    },
                  });
                };
              });
            }
          }
        } else {
          newHcOptionsDisplacement.series[0].data = JSON.parse(JSON.stringify([]))
          newHcOptionsDisplacement.title = {
            text:
              `<div style="color: rgba(255,255,255,0.5); font-size: 13px; font-weight: lighter; font-family:Roboto, Arial, sans-serif; margin-top:90px;">Data Nothing</div>`,
            useHTML: true,
          }
        }
        setHcOptionsDisplacement(newHcOptionsDisplacement)

        // Average
        if (AverageUnit[item.index] !== undefined) {
          setLogSpeedAverage(AverageUnit[item.index].Avg_LOG_Speed);
          setBeaufortAverage(AverageUnit[item.index].Avg_Beaufort);
          setDisplacementAverage(AverageUnit[item.index].Avg_Displacement);
          setMeLoadAverage(AverageUnit[item.index].Avg_LOAD);
        }
        else {
          setLogSpeedAverage(undefined);
          setBeaufortAverage(undefined);
          setDisplacementAverage(undefined);
          setMeLoadAverage(undefined);
        }

        // クリックしたグラフに該当するリストに移動、色を付ける----------------------------------
        // 該当する行を取得
        const targetRow = document.querySelector(`#data-table tr[data-category="${item.index}"]`);

        // 背景色を変更
        if (targetRow instanceof HTMLElement) {

          // targetRowに飛ぶ
          targetRow.scrollIntoView({ behavior: 'smooth', block: 'center' });

          // 一瞬色を付ける。
          targetRow.style.backgroundColor = "rgba(255, 255, 50, 0.5)";
          targetRow.style.boxShadow = "0 0 2px 2px rgba(255, 255, 50, 0.5)";
          targetRow.style.transition = "box-shadow 0.3s ease, background-color 0.3s ease";
  
          // 少し時間をおいてCSS削除
          setTimeout(() => {
            targetRow.style.backgroundColor = "";
            targetRow.style.boxShadow = "";
          }, 1000); // 1sだけ色変える
        }
      }
    }
    setKey(key + 1);
  }, [changeClickGraph, clickItemGraph]);

  // Rateグラフのリセットボタンがクリックされたら
  const [changeClickGraphReSet, setChangeClickGraphReSet] = React.useState<number>(0);
  // const [ClickGraphReSetOn, setClickGraphReSetOn] = React.useState<boolean>(false);
  const onClickGraphReSet = (event: any) => {
    console.log("onClickGraphReSet")
    setChangeClickGraphReSet((prev) => (prev + 1));
    setClickItemGraph({ index: 0, x: null, y: null })
  }

  React.useEffect(() => {
    if (changeClickGraphReSet > 0) {
      changeGraphReSet()
    }
  }, [changeClickGraphReSet])

  const changeGraphReSet = () => {

    if (LOGSpeedRate !== undefined && LOGSpeedRate_Accumulation !== undefined
      && MELoadRate !== undefined && MELoadRate_Accumulation !== undefined
      && DisplacementRate !== undefined && DisplacementRate_Accumulation !== undefined
      && BeaufortRate !== undefined && BeaufortRate_Accumulation !== undefined) {

      // EUA&CB
      const newHcOptionsEUACB = { ...hcOptions };
      newHcOptionsEUACB.series[0].data = JSON.parse(JSON.stringify(EUA))
      newHcOptionsEUACB.series[1].data = JSON.parse(JSON.stringify(CB))
      setHcOptions(newHcOptionsEUACB)

      // LOGSpeedRate
      const newHcOptionsLogSpeed = { ...hcOptionsLogSpeed };
      if (LOGSpeedRate.length) {
        newHcOptionsLogSpeed.xAxis.categories = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '', 'knot']
        newHcOptionsLogSpeed.yAxis.min = 0
        newHcOptionsLogSpeed.yAxis.max = 100
        newHcOptionsLogSpeed.yAxis.tickInterval = 25
        newHcOptionsLogSpeed.series[0].data = JSON.parse(JSON.stringify(LOGSpeedRate[LOGSpeedRate.length - 1]))
        newHcOptionsLogSpeed.series[1].data = JSON.parse(JSON.stringify(LOGSpeedRate_Accumulation[LOGSpeedRate_Accumulation.length - 1]))
        setLOGSpeedRateNone({ xname: "", xtop: "", xleft: "", yname: "", ytop: "0", yleft: "8" })
      } else {
        newHcOptionsLogSpeed.xAxis.categories = []
        newHcOptionsLogSpeed.yAxis.min = ""
        newHcOptionsLogSpeed.yAxis.max = ""
        newHcOptionsLogSpeed.yAxis.tickInterval = ""
        newHcOptionsLogSpeed.series[0].data = []
        newHcOptionsLogSpeed.series[1].data = []
        setLOGSpeedRateNone({ xname: "Data Nothing", xtop: "110", xleft: "140", yname: "", ytop: "", yleft: "" })
      }
      setHcOptionsLogSpeed(newHcOptionsLogSpeed)

      // MELoadRate
      const newHcOptionsMELoad = { ...hcOptionsMELoad };
      if (MELoadRate.length) {
        newHcOptionsMELoad.xAxis.categories = ['0', '5', '10', '15', '20', '25', '30', '35', '40', '45', '50', '55', '60', '65', '70', '75', '80', '85', '90', '95', '100', '', '%']
        newHcOptionsMELoad.yAxis.min = 0
        newHcOptionsMELoad.yAxis.max = 100
        newHcOptionsMELoad.yAxis.tickInterval = 25
        newHcOptionsMELoad.series[0].data = JSON.parse(JSON.stringify(MELoadRate[MELoadRate.length - 1]))
        newHcOptionsMELoad.series[1].data = JSON.parse(JSON.stringify(MELoadRate_Accumulation[MELoadRate_Accumulation.length - 1]))
        setMELoadRateNone({ xname: "", xtop: "", xleft: "", yname: "", ytop: "0", yleft: "8" })
      } else {
        newHcOptionsMELoad.xAxis.categories = []
        newHcOptionsMELoad.yAxis.min = ""
        newHcOptionsMELoad.yAxis.max = ""
        newHcOptionsMELoad.yAxis.tickInterval = ""
        newHcOptionsMELoad.series[0].data = []
        newHcOptionsMELoad.series[1].data = []
        setMELoadRateNone({ xname: "Data Nothing", xtop: "110", xleft: "140", yname: "", ytop: "", yleft: "" })
      }
      setHcOptionsMELoad(newHcOptionsMELoad)

      // Beaufort
      const newHcOptionsBeaufort = { ...hcOptionsBeaufort };
      if (BeaufortRate.length) {
        newHcOptionsBeaufort.xAxis.categories = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '', '']
        newHcOptionsBeaufort.yAxis.min = 0
        newHcOptionsBeaufort.yAxis.max = 100
        newHcOptionsBeaufort.yAxis.tickInterval = 25
        newHcOptionsBeaufort.series[0].data = JSON.parse(JSON.stringify(BeaufortRate[BeaufortRate.length - 1]))
        newHcOptionsBeaufort.series[1].data = JSON.parse(JSON.stringify(BeaufortRate_Accumulation[BeaufortRate_Accumulation.length - 1]))
        setBeaufortRateNone({ xname: "", xtop: "", xleft: "", yname: "", ytop: "0", yleft: "8" })
      } else {
        newHcOptionsBeaufort.xAxis.categories = []
        newHcOptionsBeaufort.yAxis.min = ""
        newHcOptionsBeaufort.yAxis.max = ""
        newHcOptionsBeaufort.yAxis.tickInterval = ""
        newHcOptionsBeaufort.series[0].data = []
        newHcOptionsBeaufort.series[1].data = []
        setBeaufortRateNone({ xname: "Data Nothing", xtop: "110", xleft: "140", yname: "", ytop: "", yleft: "" })
      }
      setHcOptionsBeaufort(newHcOptionsBeaufort)

      // DisplacementRate
      const newHcOptionsDisplacement = { ...hcOptionsDisplacement };
      if (DisplacementRate.length) {
        newHcOptionsDisplacement.series[0].data = JSON.parse(JSON.stringify(DisplacementRate[DisplacementRate.length - 1]))
        newHcOptionsDisplacement.title = {
          text:
            `<div style="color: rgba(255,255,255,0.6); font-size: 14px; font-weight: lighter; font-family: Roboto Condensed, Arial, sans-serif; margin-top:70px;">Ballast</div>
             <div style="color: rgba(255,255,255,0.6); font-size: 14px; font-weight: lighter; font-family: Roboto Condensed, Arial, sans-serif; text-align: center;"><span style="font-size: 15px; color: white;">${DisplacementRate[DisplacementRate.length - 1][0].y.toFixed(1)}</span>&nbsp;%</div>`,
            useHTML: true,
        }
        newHcOptionsDisplacement.chart.events = {
          load() {
            const chart = this;
            chart.series[0].points.forEach(point => {
              point.onMouseOver = function () {
                console.log("this", this)
                let name
                if (this.index === 0) {
                  name = "Ballast"
                } else if (this.index === 1) {
                  name = "Laden"
                }
                chart.update({
                  title: {
                    text:
                      `<div style="color: rgba(255,255,255,0.6); font-size: 14px; font-weight: lighter; font-family: Roboto Condensed, Arial, sans-serif; margin-top:70px;">${name}</div>
                      <div style="color: rgba(255,255,255,0.6); font-size: 14px; font-weight: lighter; font-family: Roboto Condensed, Arial, sans-serif; text-align: center;"><span style="font-size: 15px; color: white;">${this.y.toFixed(1)}</span>&nbsp;%</div>`,
                      useHTML: true
                  },
                  align: 'center',
                  verticalAlign: 'middle',
                  y: 0 // タイトルの垂直位置を中央に設定
                });
              };
            });
          }
        }
      } else {
        newHcOptionsDisplacement.series[0].data = JSON.parse(JSON.stringify([]))
        newHcOptionsDisplacement.title = {
          text:
            `<div style="color: rgba(255,255,255,0.5); font-size: 14px; font-weight: lighter; font-family: Arial, sans-serif; margin-top:90px;">Data Nothing</div>`,
          useHTML: true,
        }
      }
      setHcOptionsDisplacement(newHcOptionsDisplacement)

      // Average
      if (Average.length) {
        setLogSpeedAverage(Average[Average.length - 1].Avg_LOG_Speed);
        setBeaufortAverage(Average[Average.length - 1].Avg_Beaufort);
        setDisplacementAverage(Average[Average.length - 1].Avg_Displacement);
        setMeLoadAverage(Average[Average.length - 1].Avg_LOAD);
      }
      else {
        setLogSpeedAverage(undefined);
        setBeaufortAverage(undefined);
        setDisplacementAverage(undefined);
        setMeLoadAverage(undefined);
      }
    }
    setKey(key + 1);
  }

  // // Localテスト用データ（後で消す）
  // const Leg1 = {
  //   "leg_no": "1",
  //   "state": "Sailing",
  //   "departure_port": "Osaka",
  //   "departure_time": "2024/01/01 00:00",
  //   "arrival_port": "Rotterdam",
  //   "arrival_time": "2024/01/28 00:00",
  //   "total_time": "627",
  //   "eu_rate": "50",
  //   "displacement": "17485",
  //   "distance": "13588",
  //   "fuel": [
  //     {
  //       "fuel_type": "HFO",
  //       "fuel_rate": "80"
  //     },
  //     {
  //       "fuel_type": "LFO",
  //       "fuel_rate": "20"
  //     },
  //   ],
  //   "foc": "289.4",
  //   "eua": "924",
  //   "cb": "-212.2",
  // }
  // const Leg2 = {
  //   "leg_no": "2",
  //   "state": "Port",
  //   "departure_port": "Rotterdam",
  //   "departure_time": "2024/01/28 00:00",
  //   "arrival_port": "Rotterdam",
  //   "arrival_time": "2024/01/28 12:00",
  //   "total_time": "36",
  //   "eu_rate": "100",
  //   "displacement": "",
  //   "distance": "",
  //   "fuel": [
  //     {
  //       "fuel_type": "LFO",
  //       "fuel_rate": "100"
  //     },
  //   ],
  //   "foc": "28.1",
  //   "eua": "8",
  //   "cb": "-7.5",
  // }
  // const Leg3 = {
  //   "leg_no": "3",
  //   "state": "Sailing",
  //   "departure_port": "Rotterdam",
  //   "departure_time": "2024/01/29 12:00",
  //   "arrival_port": "Antwerp",
  //   "arrival_time": "2024/02/03 12:00",
  //   "total_time": "120",
  //   "eu_rate": "100",
  //   "displacement": "13278",
  //   "distance": "926",
  //   "fuel": [
  //     {
  //       "fuel_type": "HFO",
  //       "fuel_rate": "100"
  //     },
  //   ],
  //   "foc": "121.4",
  //   "eua": "62",
  //   "cb": "-28.1",
  // }
  // const Voyage1 = {
  //   "voyage_no": "1",
  //   "operator": "NYK",
  //   "departure_port": "Osaka",
  //   "departure_time": "2024/01/01 00:00",
  //   "arrival_port": "Rotterdam",
  //   "arrival_time": "2024/01/28 00:00",
  //   "total_time": "627",
  //   "displacement": "17485",
  //   "distance": "13588",
  //   "fuel": [
  //     {
  //       "fuel_type": "HFO",
  //       "fuel_rate": "80"
  //     },
  //     {
  //       "fuel_type": "LFO",
  //       "fuel_rate": "20"
  //     },
  //   ],
  //   "foc": "289.4",
  //   "eua": "924",
  //   "cb": "-212.2",
  // }
  // const Voyage2 = {
  //   "voyage_no": "2",
  //   "operator": "NYK",
  //   "departure_port": "Rotterdam",
  //   "departure_time": "2024/01/28 00:00",
  //   "arrival_port": "Rotterdam",
  //   "arrival_time": "2024/01/28 12:00",
  //   "total_time": "36",
  //   "displacement": "",
  //   "distance": "",
  //   "fuel": [
  //     {
  //       "fuel_type": "LFO",
  //       "fuel_rate": "100"
  //     },
  //   ],
  //   "foc": "28.1",
  //   "eua": "8",
  //   "cb": "-7.5",
  // }
  // const Voyage3 = {
  //   "voyage_no": "3",
  //   "operator": "NYK",
  //   "departure_port": "Rotterdam",
  //   "departure_time": "2024/01/29 12:00",
  //   "arrival_port": "Antwerp",
  //   "arrival_time": "2024/02/03 12:00",
  //   "total_time": "120",
  //   "displacement": "13278",
  //   "distance": "926",
  //   "fuel": [
  //     {
  //       "fuel_type": "HFO",
  //       "fuel_rate": "100"
  //     },
  //   ],
  //   "foc": "121.4",
  //   "eua": "62",
  //   "cb": "-28.1",
  // }  
  // const legList = [Leg1, Leg2, Leg3];
  // const voyageList = [Voyage1, Voyage2, Voyage3];

  return (
    <>
      <Box sx={{ height: "2%" }}></Box>
      <Box sx={{ height: "98.5%" }}>


          <Grid container spacing={2} sx={{height: "100%"}}>

            {/* ↓ メイン左側 ↓ */}
            <Grid item xs={8.75} sx={{height: "100%"}}>

              {/* ↓ FOC & CII Scoreエリア ↓ */}
              <Stack
                direction='column'
                spacing={0}
                sx={{
                  background: 'linear-gradient(180deg, rgba(45.82, 46.41, 47.81, 0.20) 0%, rgba(65.37, 66.21, 68.21, 0.20) 20%)',
                  height: "50%",
                  width: "100%",
                }}>

                {/* ↓ タイトル ↓ */}
                <Box sx={{ position: "relative", height: "40px" }}>
                  <Typography sx={{ position: "absolute", top: "5px", left: "10px", color: 'white', fontSize: "14px", fontFamily: "'Roboto'", fontWeight: 'bold', wordWrap: 'break-word' }}>
                    EUA & Compliance Balance
                  </Typography>

                  {/* Y軸設定ダイアログ */}
                  <Box
                    sx={{
                      position: "absolute",
                      top: "50px",
                      left: "10px",
                      width: "20px",
                      height: "20px",
                      background: "rgb(255,255,255,0.3)",
                      cursor: "pointer",
                      borderRadius: "50%",
                      border: "2px solid rgb(255,255,255,0.7)",
                      zIndex: 10,

                    }}
                    onClick={handleClickOpenDialogAxis}
                  >
                    <SettingsIcon sx={{ position: "absolute", top: "1px", left: "1px", width: "14px", height: "14px", color: "rgb(255,255,255,0.7)" }} />
                  </Box>

                  <Dialog
                    open={openDialogyAxis}
                    sx={{
                      "& .MuiDialog-container": {
                        "& .MuiPaper-root": {
                          width: "100%",
                          maxWidth: "500px",
                          height: "100%",
                          maxHeight: "250px",
                        },
                      },
                    }}
                  >
                    <DialogTitle
                      sx={{
                        bgcolor: "#474C53",
                        height: "50px",
                        color: "white",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}>
                      Axis Setting
                      <IconButton
                        aria-label="close"
                        onClick={handleNoClickOpenDialogAxis}
                        sx={{
                          position: 'absolute',
                          right: 8,
                          top: 8,
                          color: (theme) => theme.palette.grey[1000],
                        }}
                      >
                        <CloseIcon />
                      </IconButton>
                    </DialogTitle>
                    <DialogContent
                      sx={{
                        background: "linear-gradient(180deg, #0E1B25 0%, #17232D 100%)",
                      }}>

                      <Box sx={{ position: "relative" }}>
                        <Box sx={{ position: "absolute", top: "60px", left: "20px", height: "1px", width: "400px", borderRadius: '0px ', border: "1px dotted #A4A7B1" }}></Box>
                        <Typography align="center"
                          sx={{
                            position: "absolute", top: "30px", left: "40px",
                            color: 'rgba(255, 255, 255, 0.70)', fontSize: 13, fontFamily: 'Roboto', fontWeight: '400', lineHeight: "24px", wordWrap: 'break-word'
                          }}>
                          Channel Name
                        </Typography>
                        <Typography align="center"
                          sx={{
                            position: "absolute", top: "30px", left: "240px",
                            color: 'rgba(255, 255, 255, 0.70)', fontSize: 13, fontFamily: 'Roboto', fontWeight: '400', lineHeight: "24px", wordWrap: 'break-word'
                          }}>
                          Min
                        </Typography>
                        <Typography align="center"
                          sx={{
                            position: "absolute", top: "30px", left: "350px",
                            color: 'rgba(255, 255, 255, 0.70)', fontSize: 13, fontFamily: 'Roboto', fontWeight: '400', lineHeight: "24px", wordWrap: 'break-word'
                          }}>
                          Max
                        </Typography>
                      </Box>
                      <Box sx={{ position: "relative" }}>
                        <Stack direction={"row"}>
                          <Typography align="left"
                            sx={{
                              position: "absolute", top: "90px", left: "70px",
                              color: 'rgba(255, 255, 255, 0.70)', fontSize: 13, fontFamily: 'Roboto', fontWeight: '400', lineHeight: "24px", wordWrap: 'break-word'
                            }}>
                            FOC
                          </Typography>
                          <FormControl>
                            <Input
                              value={FOCyAxisMinShow} onChange={(event: React.ChangeEvent<HTMLInputElement>) => { handleClickFOCyAxisMinShow(event.target.value) }}
                              type='tel'
                              sx={{
                                position: "absolute", top: "80px", left: "200px", width: "100px", fontFamily: 'Roboto', fontWeight: '700', wordWrap: 'break-word', border: '0px grey solid', color: "white", fontSize: "15px",
                                "& .MuiInput-input": { height: "30px", borderRadius: '0px', border: '0px white solid', background: "rgba(255, 255, 255, 0.15)", textAlign: "center", },
                                ":hover": { backgroundColor: "#7a86a0", border: '0px #CBCBCB solid', borderBottom: "0px solid white", },
                                ":hover:after": { borderBottom: "0px solid green", },
                                ":hover:not(.Mui-disabled, .Mui-error):before": { borderBottom: "2px solid white", },
                                ":after": { borderBottom: "2px solid white", },
                                ":before": { borderBottom: "2px solid #A4A7B1", },
                              }} />
                          </FormControl>
                          <Box sx={{ position: "absolute", top: "100px", left: "300px", height: "1px", width: "20px", borderRadius: '0px ', border: "1px solid #A4A7B1" }}></Box>
                          <FormControl>
                            <Input
                              value={FOCyAxisMaxShow} onChange={(event: React.ChangeEvent<HTMLInputElement>) => { handleClickFOCyAxisMaxShow(event.target.value) }}
                              type='tel'
                              sx={{
                                position: "absolute", top: "80px", left: "320px", width: "100px", fontFamily: 'Roboto', fontWeight: '700', wordWrap: 'break-word', border: '0px grey solid', color: "white", fontSize: "15px",
                                "& .MuiInput-input": { height: "30px", borderRadius: '0px', border: '0px white solid', background: "rgba(255, 255, 255, 0.15)", textAlign: "center", },
                                ":hover": { backgroundColor: "#7a86a0", border: '0px #CBCBCB solid', borderBottom: "0px solid white", },
                                ":hover:after": { borderBottom: "0px solid green", },
                                ":hover:not(.Mui-disabled, .Mui-error):before": { borderBottom: "2px solid white", },
                                ":after": { borderBottom: "2px solid white", },
                                ":before": { borderBottom: "2px solid #A4A7B1", },
                              }} />
                          </FormControl>
                        </Stack>
                      </Box>
                    </DialogContent>
                    <DialogActions sx={{
                      height: "50px",
                      bgcolor: "#474C53"
                    }}>
                      <Button
                        sx={{
                          marginLeft: "0px",
                          marginRight: "-8px",
                          backgroundColor: "rgba(255, 255, 255, 0.5)",
                          width: "127px",
                          height: "50px",
                          padding: "0",
                          fontSize: "18px",
                          fontFamily: "'Roboto', 'Meiryo', sans-serif",
                          fontWeight: "bold",
                          color: "white",
                          borderRadius: "0px",
                          ":hover": {
                            bgcolor: "#FFFFFF",
                            color: "#40475D",
                          },
                          textTransform: 'none'
                        }}
                        onClick={handleNoClickOpenDialogAxis}
                      >
                        Cancel
                      </Button>
                      <Button
                        sx={{
                          marginLeft: "0px",
                          marginRight: "-8px",
                          backgroundColor: "#e66300",
                          width: "127px",
                          height: "50px",
                          padding: "0",
                          fontSize: "18px",
                          fontFamily: "'Roboto', 'Meiryo', sans-serif",
                          fontWeight: "bold",
                          color: "white",
                          borderRadius: "0px",
                          ":hover": {
                            bgcolor: "#FFFFFF",
                            color: "#40475D",
                          },
                          textTransform: 'none'
                        }}
                        onClick={handleClickSetAxis}
                      >
                        Save
                      </Button>
                    </DialogActions>
                  </Dialog>
                </Box>
                {/* ↑ タイトル ↑ */}

                <Box sx={{ position: "relative", height: "100%" }}>
                  {success ?
                    <>
                      {/* ↓ Highchartsグラフ ↓ */}
                      <Stack sx={{ position: "relative", top: "0px", left: "0px", height: "90%", width: "100%" }}>
                        <div style={{marginBottom: "15px"}} onBlur={onClickGraphReSet}>
                          <HighchartsReact
                            key={key}
                            highcharts={Highcharts}
                            options={hcOptions}
                            ref={chartComponentRef}
                            allowChartUpdate={true}
                            updateArgs={[true, true, true]}
                          />
                        </div>
                      </Stack>
                      <Stack sx={{ position: "absolute", top: `${EUACBNone.top}px`, left: `${EUACBNone.left}px` }}>
                        <Typography align="left" sx={{ color: 'white', opacity: 0.50, fontSize: 13, fontFamily: 'Roboto', fontWeight: '400', lineHeight: "24px", wordWrap: 'break-word' }}>
                          {EUACBNone.name}
                        </Typography>
                      </Stack>
                      {/* ↑ Highchartsグラフ ↑ */}

                      {/* ↓ 凡例 ↓ */}
                      <Stack sx={{ position: "relative", width: "100%", marginTop: "16px" , bottom: "10px"}}>
                        <Grid container alignItems={"center"} justifyContent={"center"} >
                          {/* EUA */}
                          <Grid container alignItems={"center"} justifyContent={"start"} item xs={4} onClick={() =>handleClickGraphLabel(0)}>
                            <Grid item xs={9}></Grid>
                            <Grid item xs={2}>
                              <Box className="eu-emission-board-pointer-area" sx={{ display: "flex" }} >
                                <Box style={{ width: '10px', height: '23px'}} />
                                <Box style={{ marginTop: "2px", width: '15px', height: '20px', background: (EUAFlag ? 'linear-gradient(180deg, #1164B1 0%, #1478D5 100%)' : 'linear-gradient(180deg, rgba(17, 100, 177, 0.4) 0%, rgba(20, 120, 213, 0.4) 100%)') }} />
                                <Box style={{ width: '10px', height: '23px'}} />
                                <Typography align="left" sx={{ color: (EUAFlag ? 'white' : 'rgba(255, 255, 255, 0.4)'), width: "100%", opacity: 0.70, fontSize: "min(0.7vw,14px)", fontFamily: 'Roboto', fontWeight: '400', lineHeight: "24px", wordWrap: 'break-word' }}>
                                  EUA
                                </Typography>
                              </Box>
                            </Grid>
                            <Grid item xs={1}></Grid>
                          </Grid>
                          {/* CB */}
                          <Grid container alignItems={"center"} justifyContent={"start"} item xs={4} onClick={() =>handleClickGraphLabel(1)}>
                            <Grid item xs={0.5}></Grid>
                            <Grid item xs={5.5}>
                              <Box className="eu-emission-board-pointer-area" sx={{ position: "relative" }} >
                                <Box sx={{ position: "absolute", top: "12px", left: "4px", width: '8px', height: '1px',
                                  border: (CBFlag ? '1px #FF8514 solid' : '1px rgba(255, 133, 20, 0.4) solid')
                                }}></Box>
                                <Box sx={{ 
                                  position: "absolute", top: "7px", left: "12px", width: '10px', height: '10px', 
                                  background: (CBFlag ? 'radial-gradient(rgba(255, 191, 131, 1) 0%, rgba(255, 133, 20, 1) 100%)' : 'radial-gradient(rgba(255, 191, 131, 0.4) 0%, rgba(255, 133, 20, 0.4) 100%)') ,
                                  borderRadius: "5px" 
                                }}></Box>
                                <Box sx={{ position: "absolute", top: "12px", left: "22px", width: '8px', height: '1px', 
                                  border: (CBFlag ? '1px #FF8514 solid' : '1px rgba(255, 133, 20, 0.4) solid')
                                }}></Box>
                                <Typography  
                                  sx={{ color: (CBFlag ? 'white': "rgba(255,255,255,0.4)"), width: "100%", opacity: 0.70, fontSize: "min(0.7vw,14px)", 
                                        fontFamily: 'Roboto', fontWeight: '400', lineHeight: "24px", 
                                        wordWrap: 'break-word', paddingLeft: "37px" }}>
                                  Compliance Balance
                                </Typography>
                              </Box>
                            </Grid>
                            <Grid item xs={6}>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Stack>
                      {/* ↑ 凡例 ↑ */}
                    </>
                    :
                    <Box>
                      {loading && (
                        <CircularProgress
                          size={68}
                          sx={{
                            color: "grey",
                            position: 'absolute',
                            top: "150px",
                            left: "500px",
                            zIndex: 1,
                          }}
                        />
                      )}
                    </Box>
                  }
                </Box>
              </Stack>
              {/* ↑ FOC & CII Scoreエリア ↑ */}


              {/* ↓ Voyage Information ↓ */}
              <Stack
                direction='column'
                spacing={0}
                sx={{
                  position: "relative",
                  background: 'linear-gradient(180deg, rgba(45.82, 46.41, 47.81, 0.20) 0%, rgba(65.37, 66.21, 68.21, 0.20) 20%)',
                  marginTop: "16px",
                  // height: "295px",
                  height: "48%",
                  width: "100%",
                }}>

                {/* ↓ タイトル ↓ */}
                {/* <Box sx={{ position: "relative", top: "0px", left: "0px", width: "100%", height: "50px" }}> */}
                <Box sx={{ width: "100%", height: "50px" }}>
                  <Typography sx={{ marginLeft: "10px", marginTop: "10px", color: 'white', fontSize: "14px", fontFamily: "'Roboto'", fontWeight: 'bold', wordWrap: 'break-word' }}>
                    Voyage Information
                  </Typography>
                </Box>
                {/* ↑ タイトル ↑ */}

                {/* Total */}
                <div className='eua-emission-board-total-table-wrapper'>
                  <table className="eua-emission-board-total-table">
                    <thead>
                      <tr>
                        <th className="padding1">
                          <span></span>
                        </th>
                        <th className="total-foc">
                          <span>FOC</span>
                        </th>
                        <th className="total-ghg">
                          <span>GHG</span>
                        </th>
                        <th className="total-distance">
                          <span>Distance</span>
                        </th>
                        <th className="total-eua">
                          <span>EUA</span>
                        </th>
                        <th className="total-eua-cost">
                          <span>EUA Cost</span>
                        </th>
                        <th className="total-compliance-balance">
                          <span>Compliance Balance</span>
                        </th>
                        <th className="total-compliance-balance-cost">
                          <span>Compliance Balance Cost</span>
                        </th>
                        <th className="padding2">
                          <span></span>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="padding1">
                          <span></span>
                        </td>
                        <td className="total-foc">
                          <span className='eu-emission-board-total-table-data'>
                            {voyageInfomationTotal !== undefined && EUA !== undefined && EUA.length > 0 ? formatNumber(voyageInfomationTotal.foc) : "-"}
                          </span>
                          <span className='eu-emission-board-total-table-unit'>&nbsp;MT</span>
                        </td>
                        <td className="total-ghg">
                          <span className='eu-emission-board-total-table-data'>
                            {voyageInfomationTotal !== undefined && EUA !== undefined && EUA.length > 0 ? formatNumber(voyageInfomationTotal.ghg) : "-"}
                          </span>
                          <span className='eu-emission-board-total-table-unit'>&nbsp;MT</span>
                        </td>
                        <td className="total-distance">
                          <span className='eu-emission-board-total-table-data'>
                            {voyageInfomationTotal !== undefined && EUA !== undefined && EUA.length > 0 ? formatNumber(voyageInfomationTotal.distance) : "-"}
                          </span>
                          <span className='eu-emission-board-total-table-unit'>&nbsp;NM</span>
                        </td>
                        <td className="total-eua">
                          <span className='eu-emission-board-total-table-data'>
                            {voyageInfomationTotal !== undefined && EUA !== undefined && EUA.length > 0 ? formatNumber(voyageInfomationTotal.eua) : "-"}
                          </span>
                        </td>
                        <td className="total-eua-cost">
                          <span className='eu-emission-board-total-table-data'>
                            {voyageInfomationTotal !== undefined && EUA !== undefined && EUA.length > 0 ? formatNumber(voyageInfomationTotal.eua_cost) : "-"}
                          </span>
                          <span className='eu-emission-board-total-table-unit'>&nbsp;€</span>
                        </td>
                        <td className="total-compliance-balance">
                          <span className='eu-emission-board-total-table-data'>
                            {voyageInfomationTotal !== undefined && EUA !== undefined && EUA.length > 0 ? formatNumber(voyageInfomationTotal.cb) : "-"}
                          </span>
                        </td>
                        <td className="total-compliance-balance-cost">
                          <span className='eu-emission-board-total-table-data'>
                            {voyageInfomationTotal !== undefined && EUA !== undefined && EUA.length > 0 ? formatNumber(voyageInfomationTotal.cb_cost) : "-"}
                          </span>
                          <span className='eu-emission-board-total-table-unit'>&nbsp;€</span>
                        </td>
                        <td className="padding2">
                          <span></span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                {/* List */}
                {Unit === 'Leg' ?
                  // Leg
                  <div className='eua-emission-board-list-wrapper'>
                    <table className='eua-emission-board-list-leg' id="data-table">
                      <thead>
                        <tr>
                          <th className="list-padding">
                            <span></span>
                          </th>
                          <th className="list-leg-no">
                            <span>LEG<br/>No.</span>
                          </th>
                          <th className="list-state">
                            <span>State</span>
                          </th>
                          <th className="list-departure">
                            <span>Departure</span>
                          </th>
                          <th className="list-padding1">
                            <span></span>
                          </th>
                          <th className="list-arrival">
                            <span>Arrival</span>
                          </th>
                          <th className="list-time-total">
                            <span>Total<br/>Time(h)</span>
                          </th>
                          <th className="list-eu-rate">
                            <span>EU Rate</span>
                          </th>
                          <th className="list-displacement">
                            <span>Displacement<br/>(MT)</span>
                          </th>
                          <th className="list-distance">
                            <span>Distance<br/>(NM)</span>
                          </th>
                          <th className="list-fuel">
                            <span>Fuel</span>
                          </th>
                          <th className="list-foc">
                            <span>FOC<br/>(MT)</span>
                          </th>
                          <th className="list-eua">
                            <span>EUA</span>
                          </th>
                          <th className="list-cb">
                            <span>CB</span>
                          </th>
                          <th className="list-padding2">
                            <span></span>
                          </th>
                        </tr>
                      </thead>
                      {success ?
                        <tbody>
                          {legList.length !== 0 ?
                            <>
                              {
                                legList.map((element, index) => {
                                  return (
                                    <tr data-category={String(index)}>
                                      <td className="list-padding">
                                        <span></span>
                                      </td>
                                      <td className="list-leg-no">
                                        <span>{element.leg_no}</span>
                                      </td>
                                      <td className="list-state">
                                        {
                                          element.state === "Sailing" ? 
                                            <img src={State_Sailing} alt="right" style={{ height: "25px", width: "25px", marginLeft: "0px", marginTop: "5px"}} />
                                            : <img src={State_Port} alt="right" style={{ height: "20px", width: "20px", marginLeft: "0px", marginTop: "5px"}} />
                                        }
                                      </td>
                                      <td className="list-departure">
                                        <span>{element.departure_port}<br/>{element.departure_time}</span>
                                      </td>
                                      <td className="list-padding1">
                                        <Box sx={{ width: "100%" , height: "35px"}}>
                                          <img src={RightArrow} alt="right" style={{ height: "15px", width: "15px", marginLeft: "0px", marginTop: "10px"}} />
                                        </Box>
                                      </td>
                                      <td className="list-arrival">
                                        <span>{element.arrival_port}<br/>{element.arrival_time}</span>
                                      </td>
                                      <td className="list-time-total">
                                        <span>{formatNumber(element.total_time)}</span>
                                      </td>
                                      <td className="list-eu-rate">
                                        <span>{element.eu_rate}</span>
                                      </td>
                                      <td className="list-displacement">
                                        <span>{element.displacement !== "" ? formatNumber(element.displacement) : "ー"}</span>
                                      </td>
                                      <td className="list-distance">
                                        <span>{formatNumber(element.distance)}</span>
                                      </td>
                                      <td className="list-fuel">
                                        {element.fuel.map((item, index) => (
                                          <span>{item[0]} ({item[1]}%)<br/></span>
                                        ))}
                                      </td>
                                      <td className="list-foc">
                                        <span>{formatNumber(element.foc)}</span>
                                      </td>
                                      <td className="list-eua">
                                        <span>{formatNumber(element.eua)}</span>
                                      </td>
                                      <td className="list-cb">
                                        <span>{formatNumber(element.cb)}</span>
                                      </td>
                                      <td className="list-padding2">
                                        <span></span>
                                      </td>
                                    </tr>
                                  )
                                })
                              }
                            </>
                            :
                            ""
                          }
                        </tbody>
                        :
                        <Box>
                          {loading && (
                            <CircularProgress
                              size={68}
                              sx={{
                                color: "grey",
                                position: 'absolute',
                                top: 220,
                                left: 500,
                                zIndex: 1,
                              }}
                            />
                          )}
                        </Box>
                      }
                    </table>
                  </div>
                  :
                  // Voyage
                  <div className='eua-emission-board-list-wrapper'>
                    <table className='eua-emission-board-list-voyage' id="data-table">
                      <thead>
                        <tr>
                          <th className="list-padding">
                            <span></span>
                          </th>
                          <th className="list-voyage-no">
                            <span>Voyage<br/>No.</span>
                          </th>
                          <th className="list-operator">
                            <span>Operator</span>
                          </th>
                          <th className="list-departure">
                            <span>Departure</span>
                          </th>
                          <th className="list-padding1">
                            <span></span>
                          </th>
                          <th className="list-arrival">
                            <span>Arrival</span>
                          </th>
                          <th className="list-time-total">
                            <span>Total<br/>Time(h)</span>
                          </th>
                          <th className="list-displacement">
                            <span>Displacement<br/>(MT)</span>
                          </th>
                          <th className="list-distance">
                            <span>Distance<br/>(NM)</span>
                          </th>
                          <th className="list-fuel">
                            <span>Fuel</span>
                          </th>
                          <th className="list-foc">
                            <span>FOC<br/>(MT)</span>
                          </th>
                          <th className="list-eua">
                            <span>EUA</span>
                          </th>
                          <th className="list-cb">
                            <span>CB</span>
                          </th>
                          <th className="list-padding2">
                            <span></span>
                          </th>
                        </tr>
                      </thead>
                      {success ?
                        <tbody>
                          {voyageList.length !== 0 ?
                            <>
                              {
                                voyageList.map((element, index) => {
                                  return (
                                    <tr data-category={String(index)}>
                                      <td className="list-padding">
                                        <span></span>
                                      </td>
                                      <td className="list-voyage-no">
                                        <span>{element.serial_no}</span>
                                      </td>
                                      <td className="list-operator">
                                        <span>{element.operator}</span>
                                      </td>
                                      <td className="list-departure">
                                        <span>{element.departure_port}<br/>{element.departure_time}</span>
                                      </td>
                                      <td className="list-padding1">
                                        <Box sx={{ width: "100%" , height: "35px"}}>
                                          <img src={RightArrow} alt="right" style={{ height: "15px", width: "15px", marginLeft: "0px", marginTop: "10px"}} />
                                        </Box>
                                      </td>
                                      <td className="list-arrival">
                                        <span>{element.arrival_port}<br/>{element.arrival_time}</span>
                                      </td>
                                      <td className="list-time-total">
                                        <span>{formatNumber(element.total_time)}</span>
                                      </td>
                                      <td className="list-displacement">
                                        <span>{element.displacement !== "" ? formatNumber(element.displacement) : "ー"}</span>
                                      </td>
                                      <td className="list-distance">
                                        <span>{formatNumber(element.distance)}</span>
                                      </td>
                                      <td className="list-fuel">
                                        {element.fuel.map((item, index) => (
                                          <span>{item[0]} ({item[1]}%)<br/></span>
                                        ))}
                                      </td>
                                      <td className="list-foc">
                                        <span>{formatNumber(element.foc)}</span>
                                      </td>
                                      <td className="list-eua">
                                        <span>{formatNumber(element.eua)}</span>
                                      </td>
                                      <td className="list-cb">
                                        <span>{formatNumber(element.cb)}</span>
                                      </td>
                                      <td className="list-padding2">
                                        <span></span>
                                      </td>
                                    </tr>
                                  )
                                })
                              }
                            </>
                            :
                            ""
                          }
                        </tbody>
                        :
                        <Box>
                          {loading && (
                            <CircularProgress
                              size={68}
                              sx={{
                                color: "grey",
                                position: 'absolute',
                                top: 220,
                                left: 500,
                                zIndex: 1,
                              }}
                            />
                          )}
                        </Box>
                      }
                    </table>
                  </div>
                }


              </Stack >
              {/* ↑ Voyage Information ↑ */}

            </Grid >
            {/* ↑ メイン左側 ↑ */}

            {/* メイン右側 */}
            <Grid item xs={3.25} sx={{height: "100%"}}>

              <Stack
                direction={"column"}
                sx={{
                  height: "100%",
                  maxHeight: "calc(100vh - 80px)",
                  // maxWidth: "200px",
                  overflowX: 'hidden',
                  overflowY: 'scroll',
                  bgcolor: 'linear-gradient(180deg, rgba(45.82, 46.41, 47.81, 0.20) 0%, rgba(65.37, 66.21, 68.21, 0.20) 20%)',

                  '&::-webkit-scrollbar': {
                    width: "6px",
                    height: "6px",
                  },
                  '&::-webkit-scrollbar-track': {
                    borderRadius: "10px",
                    background: "rgba(255, 255, 255, 0.5)",
                  },
                  '&::-webkit-scrollbar-thumb': {
                    background: "#fff",
                    borderRadius: "10px",
                  },
                  '&::-webkit-scrollbar-thumb:hover': {
                    backgroundColor: '#555',
                  },
                  "&::-webkit-scrollbar-corner": {
                    display: "none",
                  }
                }}
              >

                {/* ↓ LOG Speed Rate ↓ */}
                <Stack
                  direction='column'
                  sx={{
                    background: 'linear-gradient(180deg, rgba(45.82, 46.41, 47.81, 0.20) 0%, rgba(65.37, 66.21, 68.21, 0.20) 100%)',
                    minHeight: "271px",
                    maxHheight: "271px",
                    marginBottom: "16px"
                  }}>


                  {/* ↓ タイトル ↓ */}
                  <Box sx={{ position: "relative", height: "40px", }}>
                    <Typography sx={{ position: "absolute", top: "10px", left: "10px", color: 'white', fontSize: "15px", fontFamily: "'Roboto'", fontWeight: 'bold', wordWrap: 'break-word' }}>
                      LOG Speed Rate
                    </Typography>
                    <Box sx={{ width: "100%", position: "absolute", top: "10px" }}>
                      <Box sx={{ width: "100%", textAlign: "right",  }}>
                        <span className='average-titole'>Average&nbsp;&nbsp;:&nbsp;</span>
                        <span className='average-value'>{LogSpeedAverage !== undefined ? LogSpeedAverage : "-"}</span>
                        <span className='average-unit'>knot</span>
                      </Box>
                    </Box>
                  </Box>
                  {/* ↑ タイトル ↑ */}


                  <Box sx={{ position: "relative" }}>
                    {success ?
                      <>
                        <Stack direction='column' sx={{ position: "absolute", top: "16px", left: "0px", height: "220px", width: "99%" }} >
                          <HighchartsReact
                            key={key}
                            highcharts={Highcharts}
                            options={hcOptionsLogSpeed}
                            ref={chartComponentRef}
                            allowChartUpdate={true}
                            updateArgs={[true, true, true]}
                          />
                        </Stack>
                        <Stack sx={{ position: "absolute", top: `${LOGSpeedRateNone.xtop}px`, left: `${LOGSpeedRateNone.xleft}px` }}>
                          <Typography align="left" sx={{ color: 'white', opacity: 0.50, fontSize: 13, fontFamily: 'Roboto', fontWeight: '400', lineHeight: "24px", wordWrap: 'break-word' }}>
                            {LOGSpeedRateNone.xname}
                          </Typography>
                        </Stack>
                        <Stack sx={{ position: "absolute", top: `${LOGSpeedRateNone.ytop}px`, left: `${LOGSpeedRateNone.yleft}px` }}>
                          <Typography align="left" sx={{ color: 'white', opacity: 0.50, fontSize: 13, fontFamily: 'Roboto', fontWeight: '400', lineHeight: "24px", wordWrap: 'break-word' }}>
                            {LOGSpeedRateNone.yname}
                          </Typography>
                        </Stack>
                      </>
                      :
                      <Box>
                        {loading && (
                          <CircularProgress
                            size={68}
                            sx={{
                              color: "grey",
                              position: 'absolute',
                              top: "30px",
                              left: "150px",
                              zIndex: 1,
                            }}
                          />
                        )}
                      </Box>
                    }
                  </Box>
                </Stack>
                {/* ↑ LOG Speed Rate ↑ */}

                {/* ↓ Beaufort Rate ↓ */}
                <Stack
                  direction='column'
                  spacing={0}
                  sx={{
                    background: 'linear-gradient(180deg, rgba(45.82, 46.41, 47.81, 0.20) 0%, rgba(65.37, 66.21, 68.21, 0.20) 20%)',
                    minHeight: "271px",
                    maxHeight: "271px",
                    marginBottom: "16px"
                  }}>

                  {/* ↓ タイトル ↓ */}
                  <Box sx={{ position: "relative", height: "40px" }}>
                    <Typography sx={{ position: "absolute", top: "10px", left: "10px", color: 'white', fontSize: "15px", fontFamily: "'Roboto'", fontWeight: 'bold', wordWrap: 'break-word' }}>
                      Beaufort Rate
                    </Typography>
                    <Box sx={{ width: "100%", position: "absolute", top: "10px" }}>
                      <Box sx={{ width: "100%", textAlign: "right",  }}>
                        <span className='average-titole'>Average&nbsp;&nbsp;:&nbsp;</span>
                        <span className='average-value'>{BeaufortAverage !== undefined ? BeaufortAverage : "-"}</span>
                        <span className='average-unit'></span>
                      </Box>
                    </Box>
                  </Box>
                  {/* ↑ タイトル ↑ */}

                  <Box sx={{ position: "relative" }}>
                    {success ?
                      <>
                        <Stack direction='column' sx={{ position: "absolute", top: "16px", left: "0px", height: "220px", width: "99%" }} >
                          <HighchartsReact
                            key={key}
                            highcharts={Highcharts}
                            options={hcOptionsBeaufort}
                            ref={chartComponentRef}
                            allowChartUpdate={true}
                            updateArgs={[true, true, true]}
                          />
                        </Stack>
                        <Stack sx={{ position: "absolute", top: `${BeaufortRateNone.xtop}px`, left: `${BeaufortRateNone.xleft}px` }}>
                          <Typography align="left" sx={{ color: 'white', opacity: 0.50, fontSize: 13, fontFamily: 'Roboto', fontWeight: '400', lineHeight: "24px", wordWrap: 'break-word' }}>
                            {BeaufortRateNone.xname}
                          </Typography>
                        </Stack>
                        <Stack sx={{ position: "absolute", top: `${BeaufortRateNone.ytop}px`, left: `${BeaufortRateNone.yleft}px` }}>
                          <Typography align="left" sx={{ color: 'white', opacity: 0.50, fontSize: 13, fontFamily: 'Roboto', fontWeight: '400', lineHeight: "24px", wordWrap: 'break-word' }}>
                            {BeaufortRateNone.yname}
                          </Typography>
                        </Stack>
                      </>
                      :
                      <Box>
                        {loading && (
                          <CircularProgress
                            size={68}
                            sx={{
                              color: "grey",
                              position: 'absolute',
                              top: "50px",
                              left: "150px",
                              zIndex: 1,
                            }}
                          />
                        )}
                      </Box>
                    }
                  </Box>
                </Stack>
                {/* ↑ Beaufort Rate ↑ */}

                {/* ↓ Displacement Rate ↓ */}
                <Stack
                  direction='column'
                  spacing={0}
                  sx={{
                    background: 'linear-gradient(180deg, rgba(45.82, 46.41, 47.81, 0.20) 0%, rgba(65.37, 66.21, 68.21, 0.20) 20%)',
                    minHeight: "231px",
                    maxHeight: "231px",
                    marginBottom: "18px"
                    // height: "190px",
                  }}>

                  {/* ↓ タイトル ↓ */}
                  <Box sx={{ position: "relative", height: "40px", }}>
                    <Typography sx={{ position: "absolute", top: "10px", left: "10px", color: 'white', fontSize: "15px", fontFamily: "'Roboto'", fontWeight: 'bold', wordWrap: 'break-word' }}>
                      Displacement Rate
                    </Typography>
                    <Box sx={{ width: "100%", position: "absolute", top: "10px" }}>
                      <Box sx={{ width: "100%", textAlign: "right",  }}>
                        <span className='average-titole'>Average&nbsp;&nbsp;:&nbsp;</span>
                        <span className='average-value'>{DisplacementAverage !== undefined ? formatNumber(DisplacementAverage) : "-"}</span>
                        <span className='average-unit'>MT</span>
                      </Box>
                    </Box>
                  </Box>

                  <Box sx={{ position: "relative" }}>
                    {/* ↑ タイトル ↑ */}
                    {success ?
                      <>
                        <Stack direction='column' sx={{ position: "absolute", top: "0px", left: "35px", height: "250px", width: "95%", }} >
                          <Grid item xs={10} alignItems={"center"}>
                            <HighchartsReact
                              key={key}
                              highcharts={Highcharts}
                              options={hcOptionsDisplacement}
                              ref={DisplacementchartComponentRef}
                              allowChartUpdate={true}
                              updateArgs={[true, true, true]}
                            />
                          </Grid>
                        </Stack>
                      </>
                      :
                      <Box>
                        {loading && (
                          <CircularProgress
                            size={68}
                            sx={{
                              color: "grey",
                              position: 'absolute',
                              top: "80px",
                              left: "150px",
                              zIndex: 1,
                            }}
                          />
                        )}
                      </Box>
                    }
                  </Box>
                </Stack>
                {/* ↑ Displacement Rate ↑ */}

                {/* ↓ M/E Load Rate ↓ */}
                <Stack
                  direction='column'
                  spacing={0}
                  sx={{
                    background: 'linear-gradient(180deg, rgba(45.82, 46.41, 47.81, 0.20) 0%, rgba(65.37, 66.21, 68.21, 0.20) 20%)',
                    minHeight: "267px",
                    maxHheight: "267px",
                    marginBottom: "16px"
                  }}>


                  {/* ↓ タイトル ↓ */}
                  <Box sx={{ position: "relative", height: "40px" }}>
                    <Typography sx={{ position: "absolute", top: "10px", left: "10px", color: 'white', fontSize: "14px", fontFamily: "'Roboto'", fontWeight: 'bold', wordWrap: 'break-word' }}>
                      M/E Load Rate
                    </Typography>
                    <Box sx={{ width: "100%", position: "absolute", top: "10px" }}>
                      <Box sx={{ width: "100%", textAlign: "right",  }}>
                        <span className='average-titole'>Average&nbsp;&nbsp;:&nbsp;</span>
                        <span className='average-value'>{MeLoadAverage !== undefined ? MeLoadAverage : "-"}</span>
                        <span className='average-unit'>%</span>
                      </Box>
                    </Box>
                  </Box>
                  {/* ↑ タイトル ↑ */}


                  <Box sx={{ position: "relative" }}>
                    {success ?
                      <>
                        <Stack direction='column' sx={{ position: "absolute", top: "16px", left: "0px", height: "220px", width: "99%" }} >
                          <HighchartsReact
                            key={key}
                            highcharts={Highcharts}
                            options={hcOptionsMELoad}
                            ref={chartComponentRef}
                            allowChartUpdate={true}
                            updateArgs={[true, true, true]}
                          />
                        </Stack>
                        <Stack sx={{ position: "absolute", top: `${MELoadRateNone.xtop}px`, left: `${MELoadRateNone.xleft}px` }}>
                          <Typography align="left" sx={{ color: 'white', opacity: 0.50, fontSize: 13, fontFamily: 'Roboto', fontWeight: '400', lineHeight: "24px", wordWrap: 'break-word' }}>
                            {MELoadRateNone.xname}
                          </Typography>
                        </Stack>
                        <Stack sx={{ position: "absolute", top: `${MELoadRateNone.ytop}px`, left: `${MELoadRateNone.yleft}px` }}>
                          <Typography align="left" sx={{ color: 'white', opacity: 0.50, fontSize: 13, fontFamily: 'Roboto', fontWeight: '400', lineHeight: "24px", wordWrap: 'break-word' }}>
                            {MELoadRateNone.yname}
                          </Typography>
                        </Stack>
                      </>
                      :
                      <Box>
                        {loading && (
                          <CircularProgress
                            size={68}
                            sx={{
                              color: "grey",
                              position: 'absolute',
                              top: "30px",
                              left: "150px",
                              zIndex: 1,
                            }}
                          />
                        )}
                      </Box>
                    }
                  </Box>
                </Stack>
                {/* ↑ M/E Load Rate ↑ */}

              </Stack>


            </Grid>

          </Grid >


      </Box>
    </>
  );
} 

export default EuEmissionBoard;