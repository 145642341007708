
import * as React from 'react';

import './EuEditLeg.css'; // スタイルのために追加
import { BootstrapInput } from '../../../components/styled/styled';
import { CommonCalendar } from '../../../components/CommonCalendar/CommonCalendar';


// MUI @mui/material/
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

// Select Date
import Select, { SelectChangeEvent } from '@mui/material/Select';

// アイコン
import DownIcon from '../../../assets/images/down.svg';
import Calender from '../../../assets/images/calendar.svg';
import Input from '@mui/material/Input';
import { ThemeProvider } from '@mui/material/styles';
import { SelectTheme } from '../../../components/theme/SelectTheme';
import FormControl from '@mui/material/FormControl';
import InputAdornment from '@mui/material/InputAdornment';
import MenuItem from '@mui/material/MenuItem';
import Radio from '@mui/material/Radio';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Modal from "react-modal";

// 親コンポーネントから持ち回る値
export type Props = {
  elementKey: number;
  SeachInfo: any;
  legInfo: any;
  setSeachInfo: (value: any) => void;
  setDeleteLegFlg: (value: boolean) => void;
  setLegInfo: (value: any) => void;
  fuelOileList: any[];
}

// EU Edit Leg Component--------------------------------------------------------------------
export const EuEditLeg: React.FC<Props> = props => {

  // DepartureTime,ArrivalTIme（hour）のプルダウンデータ
  const hourList = [
    "00", "01", "02", "03", "04", "05", "06", "07", "08", "09"
    , "10", "11", "12", "13", "14", "15", "16", "17", "18", "19"
    , "20", "21", "22", "23"
  ];

  // DepartureTime,ArrivalTIme（minutes）のプルダウンデータ
  const minutesList = [
    "00", "01", "02", "03", "04", "05", "06", "07", "08", "09"
    , "10", "11", "12", "13", "14", "15", "16", "17", "18", "19"
    , "20", "21", "22", "23", "24", "25", "26", "27", "28", "29"
    , "30", "31", "32", "33", "34", "35", "36", "37", "38", "39"
    , "40", "41", "42", "43", "44", "45", "46", "47", "48", "49"
    , "50", "51", "52", "53", "54", "55", "56", "57", "58", "59"
  ];

  // DepartureTime（hour）リストClassName
  const [departureTimeHourListClassName, setDepartureTimeHourListClassName] = React.useState("eu-edit-leg-ymlist-area");
  // DepartureTime（minuets）リストClassName
  const [departureTimeMinutesListClassName, setDepartureTimeMinutesListClassName] = React.useState("eu-edit-leg-ymlist-area");
  // ArrivalTIme（hour）リストClassName
  const [arrivalTimeHourListClassName, setArrivalTimeHourListClassName] = React.useState("eu-edit-leg-ymlist-area");
  // ArrivalTIme（minutes）リストClassName
  const [arrivalTimeMinutesListClassName, setArrivalTimeMinutesListClassName] = React.useState("eu-edit-leg-ymlist-area");

  // Displacementラジオボタン
  // const [displacementValue, setDisplacementValue] = React.useState('Ballast');
  const handleChangeDisplacementRadioButton = (event: React.ChangeEvent<HTMLInputElement>) => {
    handleChangeDisplacement(event.target.value);
  };

  // EU Rateラジオボタン
  // const [euRateValue, setEuRateValue] = React.useState('0');
  const handleChangeEuRateRadioButton = (event: React.ChangeEvent<HTMLInputElement>) => {
    handleChangeEuRate(event.target.value);
  };

  // ×ボタン押下
  const handleClickDeleteLeg = () => {
    props.setDeleteLegFlg(true);
  };

  // 日付形式チェック
  const checkDateType = (date: string) => {
    console.log("check:"+date)
    if (date === undefined) { 
      return true;
    }
    const month = date.substring(5, 7);
    const checkDate = new Date(date);
    if (!isNaN(checkDate.getDate())) {
      if (checkDate.getMonth() + 1 === Number(month)) {
        return true;
      }
    }
    return false;
  }

  // departurePort変更
  const handleChangeDeparturePort = (event) => {
    props.legInfo.departurePort = event;
    props.setLegInfo(props.legInfo);
  }
  // departureTime_yyyymmdd変更
  const [DepartureTime_yyyymmdd_Messge, setDepartureTime_yyyymmdd_Messge] = React.useState<any>("");
  const handleChangeDepartureTime_yyyymmdd = (event) => {
    if (!/^[0-9]{4}\/[0-9]{2}\/[0-9]{2}$/.test(event)) {
      setDepartureTime_yyyymmdd_Messge("Enter in yyyy/mm/dd format.");
    }
    else if (!checkDateType(event)) {
      setDepartureTime_yyyymmdd_Messge("The date does not exist.");
    }
    else {
      setDepartureTime_yyyymmdd_Messge("");
    }
    props.legInfo.departureTime_yyyymmdd = event;
    props.setLegInfo(props.legInfo);
  }
  // departureTime_hour変更
  const handleChangeDepartureTime_hour = (event) => {
    event = event.replace(/[^0-9]/g, "");
    if (event.length === 2) {
      if (!hourList.includes(event)) {
        event = event.substring(0, 1);
      }
    }
    props.legInfo.departureTime_hour = event;
    props.setLegInfo(props.legInfo);
  }
  // departureTime_min変更
  const handleChangeDepartureTime_min = (event) => {
    event = event.replace(/[^0-9]/g, "");
    if (event.length === 2) {
      if (!minutesList.includes(event)) {
        event = event.substring(0, 1);
      }
    }
    props.legInfo.departureTime_min = event;
    props.setLegInfo(props.legInfo);
  }
  // arrivalPort変更
  const handleChangeArrivalPort = (event) => {
    props.legInfo.arrivalPort = event;
    props.setLegInfo(props.legInfo);
  }
  // arrivalTime_yyyymmdd変更
  const [ArrivalTime_yyyymmdd_Messge, setArrivalTime_yyyymmdd_Messge] = React.useState<any>("");
  const handleChangeArrivalTime_yyyymmdd = (event) => {
    if (!/^[0-9]{4}\/[0-9]{2}\/[0-9]{2}$/.test(event)) {
      setArrivalTime_yyyymmdd_Messge("Enter in yyyy/mm/dd format.");
    }
    else if (!checkDateType(event)) {
      setArrivalTime_yyyymmdd_Messge("The date does not exist.");
    }
    else {
      setArrivalTime_yyyymmdd_Messge("");
    }
    props.legInfo.arrivalTime_yyyymmdd = event;
    props.setLegInfo(props.legInfo);
  }
  // arrivalTime_hour変更
  const handleChangeArrivalTime_hour = (event) => {
    event = event.replace(/[^0-9]/g, "");
    if (event.length === 2) {
      if (!hourList.includes(event)) {
        event = event.substring(0, 1);
      }
    }
    props.legInfo.arrivalTime_hour = event;
    props.setLegInfo(props.legInfo); 
  }
  // arrivalTime_min変更
  const handleChangeArrivalTime_min = (event) => {
    event = event.replace(/[^0-9]/g, "");
    if (event.length === 2) {
      if (!minutesList.includes(event)) {
        event = event.substring(0, 1);
      }
    }
    props.legInfo.arrivalTime_min = event;
    props.setLegInfo(props.legInfo);
  }
  // distance変更
  const handleChangeDistance = (event) => {
    if (/^\d*$/.test(event)) {
      const newValue = event.replace(/^0+/, ""); // 先頭の0を削除
      props.legInfo.distance = newValue;
      props.setLegInfo(props.legInfo);
    }
  }
  // operator変更
  const handleChangeOperator = (event) => {
    props.legInfo.operator = event;
    props.setLegInfo(props.legInfo);
  }
  // displacement変更
  const handleChangeDisplacement = (event) => {
    props.legInfo.displacement = event;
    props.setLegInfo(props.legInfo);
  }
  // fuel1変更
  const handleChangeFuel1 = (event: SelectChangeEvent) => {
    props.legInfo.fuel1 = event.target.value as string;
    props.setLegInfo(props.legInfo);
  }
  // fuelRate1変更
  const handleChangeFuelRate1 = (event) => {
    // 入力値が空の場合はそのまま許可
    if (event === '') {
      props.legInfo.fuelRate1 = "";
      props.setLegInfo(props.legInfo);
      return;
    }
    // 入力値を整数に変換し、条件を満たす場合のみ更新
    if (/^(0|[1-9][0-9]{0,2})$/.test(event) && Number(event) <= 100) {
      props.legInfo.fuelRate1 = event;
      props.setLegInfo(props.legInfo);
    }
  }
  // fuel2変更
  const handleChangeFuel2 = (event: SelectChangeEvent) => {
    props.legInfo.fuel2 = event.target.value as string;
    props.setLegInfo(props.legInfo);
  }
  // fuelRate2変更
  const handleChangeFuelRate2 = (event) => {
    // 入力値が空の場合はそのまま許可
    if (event === '') {
      props.legInfo.fuelRate2 = "";
      props.setLegInfo(props.legInfo);
      return;
    }
    // 入力値を整数に変換し、条件を満たす場合のみ更新
    if (/^(0|[1-9][0-9]{0,2})$/.test(event) && Number(event) <= 100) {
      props.legInfo.fuelRate2 = event;
      props.setLegInfo(props.legInfo);
    }
  }
  // fuel3変更
  const handleChangeFuel3 = (event: SelectChangeEvent) => {
    props.legInfo.fuel3 = event.target.value as string;
    props.setLegInfo(props.legInfo);
  }
  // fuelRate3変更
  const handleChangeFuelRate3 = (event) => {
    // 入力値が空の場合はそのまま許可
    if (event === '') {
      props.legInfo.fuelRate3 = "";
      props.setLegInfo(props.legInfo);
      return;
    }
    // 入力値を整数に変換し、条件を満たす場合のみ更新
    if (/^(0|[1-9][0-9]{0,2})$/.test(event) && Number(event) <= 100) {
      props.legInfo.fuelRate3 = event;
      props.setLegInfo(props.legInfo);
    }
  }
  // euRate変更
  const handleChangeEuRate = (event) => {
    props.legInfo.euRate = event;
    props.setLegInfo(props.legInfo);
  }

  // Departure用カレンダーダイアログ関連-------------------------------------------
  const [openCalenderDialogDeparture, setOpenCalenderDialogDeparture] = React.useState(false);
  const handleClickOpenCalenderDialogDeparture = () => {
    setOpenCalenderDialogDeparture(true);
  };
  const closeDepartureDateCalendar = () => {
    setOpenCalenderDialogDeparture(false);
  };
  // -------------------------------------------

  // Arrival用カレンダーダイアログ関連-------------------------------------------
  const [openCalenderDialogArrival, setOpenCalenderDialogArrival] = React.useState(false);
  const handleClickOpenCalenderDialogArrival = () => {
    setOpenCalenderDialogArrival(true);
  };
  const closeArrivalDateCalendar = () => {
    setOpenCalenderDialogArrival(false);
  };
  // -------------------------------------------

  // Departure（hour）ref
  const departureHourRef = React.useRef<HTMLInputElement>(null);
  // Departure（minutes）ref
  const departureMinutesRef = React.useRef<HTMLInputElement>(null);
  // Arrival（hour）ref
  const arrivalHourRef = React.useRef<HTMLInputElement>(null);
  // Arrival（minutes）ref
  const arrivalMinutesRef = React.useRef<HTMLInputElement>(null);

  // DepartureTime（hour）リスト表示
  const dispDepartureHourList = () => {
    // 位置を取得
    let position = departureHourRef.current?.getBoundingClientRect().top;

    // 半分より下の場合はリストを上向きに表示
    if (position !== undefined && position > 550) {
      setDepartureTimeHourListClassName("eu-edit-leg-ymlist-area-up");
    } else {
      setDepartureTimeHourListClassName("eu-edit-leg-ymlist-area");
    }
  }
  // DepartureTime（minutes）リスト表示
  const dispDepartureMinutesList = () => {

    // 位置を取得
    let position = departureMinutesRef.current?.getBoundingClientRect().top;

    // 半分より下の場合はリストを上向きに表示
    if (position !== undefined && position > 550) {
      setDepartureTimeMinutesListClassName("eu-edit-leg-ymlist-area-up");
    } else {
      setDepartureTimeMinutesListClassName("eu-edit-leg-ymlist-area");
    }
  }
  // ArrivalTime（hour）リスト表示
  const dispArrivalHourList = () => {

    // 位置を取得
    let position = arrivalHourRef.current?.getBoundingClientRect().top;

    // 半分より下の場合はリストを上向きに表示
    if (position !== undefined && position > 550) {
      setArrivalTimeHourListClassName("eu-edit-leg-ymlist-area-up");
    } else {
      setArrivalTimeHourListClassName("eu-edit-leg-ymlist-area");
    }

  }
  // ArrivalTime（minutes）リスト表示
  const dispArrivalMinutesList = () => {
    // 位置を取得
    let position = arrivalMinutesRef.current?.getBoundingClientRect().top;

    // 半分より下の場合はリストを上向きに表示
    if (position !== undefined && position > 550) {
      setArrivalTimeMinutesListClassName("eu-edit-leg-ymlist-area-up");
    } else {
      setArrivalTimeMinutesListClassName("eu-edit-leg-ymlist-area");
    }

  }

  // DepartureTime（hour）の0埋め
  const zeroPaddingDepartureHour = (inputValue: string) => {
    if (inputValue !== undefined
      && inputValue.length === 1) {
        inputValue = "0" + inputValue;
        handleChangeDepartureTime_hour(inputValue);
    }
  }
  // DepartureTime（minutes）の0埋め
  const zeroPaddingDepartureMinutes = (inputValue: string) => {
    if (inputValue !== undefined
      && inputValue.length === 1) {
        inputValue = "0" + inputValue;
        handleChangeDepartureTime_min(inputValue);
    }
  }
  // ArrivalTime（hour）の0埋め
  const zeroPaddingArrivalHour = (inputValue: string) => {
    if (inputValue !== undefined
      && inputValue.length === 1) {
        inputValue = "0" + inputValue;
        handleChangeArrivalTime_hour(inputValue);
    }
  }
  // ArrivalTime（minutes）の0埋め
  const zeroPaddingArrivalMinutes = (inputValue: string) => {
    if (inputValue !== undefined
      && inputValue.length === 1) {
        inputValue = "0" + inputValue;
        handleChangeArrivalTime_min(inputValue);
    }
  }

  // DepartureTime（hour）のセット
  const clickDepartureTimeHour = (e: React.MouseEvent<HTMLElement>) => {
    handleChangeDepartureTime_hour(String(e.currentTarget.getAttribute("data-value")));
    setDepartureTimeHourListClassName("eu-edit-leg-ymlist-area-none");
  }

  // DepartureTime（minutes）のセット
  const clickDepartureTimeMinutes = (e: React.MouseEvent<HTMLElement>) => {
    handleChangeDepartureTime_min(String(e.currentTarget.getAttribute("data-value")));
    setDepartureTimeMinutesListClassName("eu-edit-leg-ymlist-area-none");
  }

  // ArrivalTime（hour）のセット
  const clickArrivalTimeHour = (e: React.MouseEvent<HTMLElement>) => {
    handleChangeArrivalTime_hour(String(e.currentTarget.getAttribute("data-value")));
    setArrivalTimeHourListClassName("eu-edit-leg-ymlist-area-none");
  }

  // ArrivalTime（hour）のセット
  const clickArrivalTimeMinutes = (e: React.MouseEvent<HTMLElement>) => {
    handleChangeArrivalTime_min(String(e.currentTarget.getAttribute("data-value")));
    setArrivalTimeMinutesListClassName("eu-edit-leg-ymlist-area-none");
  }

  return (
    <>

      {/* LEG */}
      <Box sx={{ height: "235px", width: "1450px", marginLeft: "55px", marginRight: "55px"}}>
        <Stack direction="row">
          <Typography sx={{fontFamily: "Roboto", fontSize: "14px", fontWeight: "700", color: "rgb(255, 255, 255, 0.5)"}}>LEG No.:</Typography>
          <Typography sx={{marginLeft: "8px", fontFamily: "Roboto", fontSize: "15px", color: "white"}}>E{props.elementKey + 1}</Typography>
          <Typography sx={{marginTop: "2px", marginLeft: "14px", fontFamily: "Roboto", fontSize: "13px", color: "red"}}>*Required</Typography>
        </Stack>
        <Box sx={{ height: "210px", width: "100%", backgroundColor: "rgb(69, 72, 80, 0.25)"}}>
          <Stack direction="row" sx={{ height: "100%", width: "100%" }}>

            {/* Departure & Arrival */}
            <Box sx={{ height: "100%", width: "37%"}}>

              {/* Departure */}
              <Stack direction="row">
                <Typography sx={{ marginLeft: "30px", marginTop: "20px", marginBottom: "7px", width: "180px",
                    fontFamily: "Roboto", fontSize: "14px", fontWeight: "700", color: "rgb(255, 255, 255, 0.5)"}}>
                  Departure Port
                </Typography>
                <Typography sx={{ marginTop: "20px",
                    fontFamily: "Roboto", fontSize: "14px", fontWeight: "700", color: "rgb(255, 255, 255, 0.5)"}}>
                  Departure time
                </Typography>
                <Typography sx={{marginTop: "17px", marginLeft: "1px", fontFamily: "Roboto", fontSize: "13px", color: "red"}}>
                  *
                </Typography>
              </Stack>
              <Stack direction="row">
                <Box sx={{ marginLeft: "30px", width: "180px"}}>
                  <Input
                  value={props.legInfo.departurePort} onChange={(event: React.ChangeEvent<HTMLInputElement>) => { handleChangeDeparturePort(event.target.value) }}
                  type='tel'
                  sx={{
                    
                    width: "157px", fontFamily: 'Roboto', fontWeight: '400', wordWrap: 'break-word', border: '0px grey solid', color: "white", fontSize: "13px",
                    "& .MuiInput-input": { height: "26px", borderRadius: '0px', border: '0px white solid', background: "rgba(255, 255, 255, 0.15)", textAlign: "center", },
                    ":hover": { backgroundColor: "#7a86a0", border: '0px #CBCBCB solid', borderBottom: "0px solid white", },
                    ":hover:after": { borderBottom: "0px solid green", },
                    ":hover:not(.Mui-disabled, .Mui-error):before": { borderBottom: "2px solid white", },
                    ":after": { borderBottom: "2px solid white", },
                    ":before": { borderBottom: "2px solid #A4A7B1", },
                  }} />
                </Box>
                <Box sx={{ width: "160px", position: "relative"}}>
                  <Box className="eu-edit-leg-pointer-area" sx={{position: "absolute", top: "6px", left: "5px", height: "25px", width: "25px", borderRadius: "50%",}}>
                    <img src={Calender} alt="calender" 
                    style={{ position: "absolute", height: "17px", width: "17px", marginLeft: "4px", marginTop: "4px", zIndex: 10000, }} 
                    onClick={handleClickOpenCalenderDialogDeparture}/>
                  </Box>                 
                  <Input
                  value={props.legInfo.departureTime_yyyymmdd} onChange={(event: React.ChangeEvent<HTMLInputElement>) => { handleChangeDepartureTime_yyyymmdd(event.target.value) }}
                  type='tel'
                  placeholder="yyyy/mm/dd" autoComplete="off"
                  sx={{
                    paddingLeft: "15px", background: "rgba(255, 255, 255, 0.15)", width: "145px", fontFamily: 'Roboto', fontWeight: '400', wordWrap: 'break-word', border: '0px grey solid', color: "white", fontSize: "13px",
                    "& .MuiInput-input": { height: "26px", borderRadius: '0px', border: '0px white solid', textAlign: "center", },
                    ":hover": { backgroundColor: "#7a86a0", border: '0px #CBCBCB solid', borderBottom: "0px solid white", },
                    ":hover:after": { borderBottom: "0px solid green", },
                    ":hover:not(.Mui-disabled, .Mui-error):before": { borderBottom: "2px solid white", },
                    ":after": { borderBottom: "2px solid white", },
                    ":before": { borderBottom: "2px solid #A4A7B1", }, fontStyle: props.legInfo.departureTime_yyyymmdd === '' ? "italic" : "normal",
                  }} />
                </Box>
                <Box className="eu-edit-leg-time" sx={{ width: "60px", position: "relative"}}>
                  {/* 下アイコン */}
                  <div style={{ position: "absolute", top: "15px", left: "40px" }}>
                    <InputAdornment position="start" disablePointerEvents>
                      <Box sx={{ borderTop: "solid 2px rgb(255, 255, 255, 0.5)", borderRight: "solid 2px rgb(255, 255, 255, 0.5)", width: "7px", height: "7px", transform: "rotate(135deg)" }}></Box>
                    </InputAdornment>
                  </div>
                  {/* hour入力テキストボックス */}
                  <input className='eu-edit-leg-time-hhmm'
                    value={props.legInfo.departureTime_hour} onChange={(event: React.ChangeEvent<HTMLInputElement>) => { handleChangeDepartureTime_hour(event.target.value) }}
                    type='tel' placeholder="00" autoComplete="off" onFocus={dispDepartureHourList} onBlur={event => zeroPaddingDepartureHour(event.target.value)} 
                    maxLength={2} ref={departureHourRef}
                  />
                  {/* hourListのプルダウン */}
                  <div className={departureTimeHourListClassName}>
                    <div className="eu-edit-leg-ymlist">
                      {hourList.map((hour) => {
                        return (
                          <div className="eu-edit-leg-ym" onClick={clickDepartureTimeHour} data-value={hour}>
                            <div>
                              {hour}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </Box>
                <Box sx={{ marginTop: "10px", marginLeft: "3px", marginRight: "3px", fontFamily: "Roboto", fontSize: "18px", color: "white"}}>:</Box>
                <Box className="eu-edit-leg-time" sx={{ width: "60px", position: "relative"}}>
                  {/* 下アイコン */}
                  <div style={{ position: "absolute", top: "15px", left: "40px" }}>
                    <InputAdornment position="start" disablePointerEvents>
                      <Box sx={{ borderTop: "solid 2px rgb(255, 255, 255, 0.5)", borderRight: "solid 2px rgb(255, 255, 255, 0.5)", width: "7px", height: "7px", transform: "rotate(135deg)" }}></Box>
                    </InputAdornment>
                  </div>
                  {/* Minutes入力テキストボックス */}
                  <input className='eu-edit-leg-time-hhmm'
                    value={props.legInfo.departureTime_min} onChange={(event: React.ChangeEvent<HTMLInputElement>) => { handleChangeDepartureTime_min(event.target.value) }}
                    type='tel' placeholder="00" autoComplete="off" onFocus={dispDepartureMinutesList} onBlur={event => zeroPaddingDepartureMinutes(event.target.value)} 
                    maxLength={2} ref={departureMinutesRef}
                  />
                  {/* MinutesListのプルダウン */}
                  <div className={departureTimeMinutesListClassName}>
                    <div className="eu-edit-leg-ymlist">
                      {minutesList.map((min) => {
                        return (
                          <div className="eu-edit-leg-ym" onClick={clickDepartureTimeMinutes} data-value={min}>
                            <div>
                              {min}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </Box>
              </Stack>

              {/* ▼▼ */}
              <Box sx={{ width: "100%" , height: "35px", position: "relative"}}>
                <img src={DownIcon} alt="down" style={{ height: "17px", width: "17px", marginLeft: "100px", marginTop: "10px"}} />
                <Typography sx={{ position: "absolute", top: "0px", left: "210px",
                    fontFamily: "Roboto", fontSize: "12px", color: "red"}}>
                  {DepartureTime_yyyymmdd_Messge}
                </Typography>
              </Box>

              {/* Arrival */}
              <Stack direction="row">
                <Typography sx={{ marginLeft: "30px", marginBottom: "7px", width: "180px",
                    fontFamily: "Roboto", fontSize: "14px", fontWeight: "700", color: "rgb(255, 255, 255, 0.5)"}}>
                  Arrival Port
                </Typography>
                <Typography sx={{ fontFamily: "Roboto", fontSize: "14px", fontWeight: "700", color: "rgb(255, 255, 255, 0.5)"}}>
                  Arrival time
                </Typography>
                <Typography sx={{ marginLeft: "1px", fontFamily: "Roboto", fontSize: "13px", color: "red"}}>
                  *
                </Typography>
              </Stack>
              <Stack direction="row">
                <Box sx={{ marginLeft: "30px", width: "180px"}}>
                  <Input
                  value={props.legInfo.arrivalPort} onChange={(event: React.ChangeEvent<HTMLInputElement>) => { handleChangeArrivalPort(event.target.value) }}
                  type='tel'
                  sx={{
                    
                    width: "157px", fontFamily: 'Roboto', fontWeight: '400', wordWrap: 'break-word', border: '0px grey solid', color: "white", fontSize: "13px",
                    "& .MuiInput-input": { height: "26px", borderRadius: '0px', border: '0px white solid', background: "rgba(255, 255, 255, 0.15)", textAlign: "center", },
                    ":hover": { backgroundColor: "#7a86a0", border: '0px #CBCBCB solid', borderBottom: "0px solid white", },
                    ":hover:after": { borderBottom: "0px solid green", },
                    ":hover:not(.Mui-disabled, .Mui-error):before": { borderBottom: "2px solid white", },
                    ":after": { borderBottom: "2px solid white", },
                    ":before": { borderBottom: "2px solid #A4A7B1", },
                  }} />
                </Box>
                <Box sx={{ width: "160px", position: "relative"}}>
                  <Box className="eu-edit-leg-pointer-area" sx={{position: "absolute", top: "6px", left: "5px", height: "25px", width: "25px", borderRadius: "50%",}}>
                    <img src={Calender} alt="calender" 
                      style={{ position: "absolute", height: "17px", width: "17px", marginLeft: "4px", marginTop: "4px",zIndex: 10000, }} 
                      onClick={handleClickOpenCalenderDialogArrival}/>
                  </Box>
                  <Input
                  value={props.legInfo.arrivalTime_yyyymmdd} onChange={(event: React.ChangeEvent<HTMLInputElement>) => { handleChangeArrivalTime_yyyymmdd(event.target.value) }}
                  type='tel'
                  placeholder="yyyy/mm/dd" autoComplete="off"
                  sx={{
                    paddingLeft: "15px", width: "145px", fontFamily: 'Roboto', fontWeight: '400', wordWrap: 'break-word', border: '0px grey solid', color: "white", fontSize: "13px", background: "rgba(255, 255, 255, 0.15)",
                    "& .MuiInput-input": { height: "26px", borderRadius: '0px', border: '0px white solid', textAlign: "center", },
                    ":hover": { backgroundColor: "#7a86a0", border: '0px #CBCBCB solid', borderBottom: "0px solid white", },
                    ":hover:after": { borderBottom: "0px solid green", },
                    ":hover:not(.Mui-disabled, .Mui-error):before": { borderBottom: "2px solid white", },
                    ":after": { borderBottom: "2px solid white", },
                    ":before": { borderBottom: "2px solid #A4A7B1", }, fontStyle: props.legInfo.arrivalTime_yyyymmdd === '' ? "italic" : "normal", 
                  }} />
                </Box>
                <Box className="eu-edit-leg-time" sx={{ width: "60px", position: "relative"}}>
                  {/* 下アイコン */}
                  <div style={{ position: "absolute", top: "15px", left: "40px" }}>
                    <InputAdornment position="start" disablePointerEvents>
                      <Box sx={{ borderTop: "solid 2px rgb(255, 255, 255, 0.5)", borderRight: "solid 2px rgb(255, 255, 255, 0.5)", width: "7px", height: "7px", transform: "rotate(135deg)" }}></Box>
                    </InputAdornment>
                  </div>
                  {/* hour入力テキストボックス */}
                  <input className='eu-edit-leg-time-hhmm'
                    value={props.legInfo.arrivalTime_hour} onChange={(event: React.ChangeEvent<HTMLInputElement>) => { handleChangeArrivalTime_hour(event.target.value) }}
                    type='tel' placeholder="00" autoComplete="off" onFocus={dispArrivalHourList} onBlur={event => zeroPaddingArrivalHour(event.target.value)} 
                    maxLength={2} ref={arrivalHourRef}
                  />
                  {/* hourListのプルダウン */}
                  <div className={arrivalTimeHourListClassName}>
                    <div className="eu-edit-leg-ymlist">
                      {hourList.map((hour) => {
                        return (
                          <div className="eu-edit-leg-ym" onClick={clickArrivalTimeHour} data-value={hour}>
                            <div>
                              {hour}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </Box>
                <Box sx={{ marginTop: "10px", marginLeft: "3px", marginRight: "3px", fontFamily: "Roboto", fontSize: "18px", color: "white"}}>:</Box>
                <Box className="eu-edit-leg-time" sx={{ width: "60px", position: "relative"}}>
                  {/* 下アイコン */}
                  <div style={{ position: "absolute", top: "15px", left: "40px" }}>
                    <InputAdornment position="start" disablePointerEvents>
                      <Box sx={{ borderTop: "solid 2px rgb(255, 255, 255, 0.5)", borderRight: "solid 2px rgb(255, 255, 255, 0.5)", width: "7px", height: "7px", transform: "rotate(135deg)" }}></Box>
                    </InputAdornment>
                  </div>
                  {/* Minutes入力テキストボックス */}
                  <input className='eu-edit-leg-time-hhmm'
                    value={props.legInfo.arrivalTime_min} onChange={(event: React.ChangeEvent<HTMLInputElement>) => { handleChangeArrivalTime_min(event.target.value) }}
                    type='tel' placeholder="00" autoComplete="off" onFocus={dispArrivalMinutesList} onBlur={event => zeroPaddingArrivalMinutes(event.target.value)} 
                    maxLength={2} ref={arrivalMinutesRef}
                  />
                  {/* MinutesListのプルダウン */}
                  <div className={arrivalTimeMinutesListClassName}>
                    <div className="eu-edit-leg-ymlist">
                      {minutesList.map((min) => {
                        return (
                          <div className="eu-edit-leg-ym" onClick={clickArrivalTimeMinutes} data-value={min}>
                            <div>
                              {min}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </Box>
              </Stack>
              <Box sx={{ position: "relative" }}>
                <Typography sx={{ position: "absolute", left: "210px",
                  fontFamily: "Roboto", fontSize: "12px", color: "red"}}>
                  {ArrivalTime_yyyymmdd_Messge}
                </Typography>
              </Box>
            </Box>

            {/* 区切り線 */}
            <Box sx={{ height: "100%", width: "1%"}}>
              <Stack sx={{ height: "100%", width: "100%" }} alignItems={"center"} justifyContent={"center"}>
                <Box sx={{ height: "100px", width: "1px", background: "linear-gradient(to top, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0))" }}></Box>
              </Stack>
            </Box>

            {/* Distance、Operator */}
            <Box sx={{ height: "100%", width: "15%"}}>
              <Box sx={{ eight: "100%", width: "100%"}}>
                <Stack direction="row">
                  <Typography sx={{ marginTop: "35px", marginLeft: "30px", marginBottom: "7px",
                    fontFamily: "Roboto", fontSize: "14px", fontWeight: "700", color: "rgb(255, 255, 255, 0.5)"}}>
                    Distance
                  </Typography>
                  <Typography sx={{marginTop: "30px", marginLeft: "1px", fontFamily: "Roboto", fontSize: "13px", color: "red"}}>
                    *
                  </Typography>
                </Stack>
                <Stack direction="row">
                  <Box>
                    <Input
                      value={props.legInfo.distance} onChange={(event: React.ChangeEvent<HTMLInputElement>) => { handleChangeDistance(event.target.value) }}
                      type='tel'
                      sx={{
                        marginLeft: "30px",
                        width: "78px", fontFamily: 'Roboto', fontWeight: '400', wordWrap: 'break-word', border: '0px grey solid', color: "white", fontSize: "13px",
                        "& .MuiInput-input": { height: "26px", borderRadius: '0px', border: '0px white solid', background: "rgba(255, 255, 255, 0.15)", textAlign: "center", },
                        ":hover": { backgroundColor: "#7a86a0", border: '0px #CBCBCB solid', borderBottom: "0px solid white", },
                        ":hover:after": { borderBottom: "0px solid green", },
                        ":hover:not(.Mui-disabled, .Mui-error):before": { borderBottom: "2px solid white", },
                        ":after": { borderBottom: "2px solid white", },
                        ":before": { borderBottom: "2px solid #A4A7B1", },
                      }} />
                  </Box>
                  <Typography sx={{ marginTop: "19px", marginLeft: "5px",
                    fontFamily: "Roboto Condensed", fontSize: "14px", color: "rgb(255, 255, 255, 0.5)"}}>
                    NM
                  </Typography>    
                </Stack>
                <Stack>
                  <Typography sx={{ marginTop: "12px", marginLeft: "30px", marginBottom: "7px",
                    fontFamily: "Roboto", fontSize: "14px", fontWeight: "700", color: "rgb(255, 255, 255, 0.5)"}}>
                    Operator
                  </Typography>
                </Stack>
                <Input
                  value={props.legInfo.operator} onChange={(event: React.ChangeEvent<HTMLInputElement>) => { handleChangeOperator(event.target.value) }}
                  type='email'
                  sx={{
                    marginLeft: "30px",
                    width: "157px", fontFamily: 'Roboto', fontWeight: '400', wordWrap: 'break-word', border: '0px grey solid', color: "white", fontSize: "13px",
                    "& .MuiInput-input": { height: "26px", borderRadius: '0px', border: '0px white solid', background: "rgba(255, 255, 255, 0.15)", textAlign: "center", },
                    ":hover": { backgroundColor: "#7a86a0", border: '0px #CBCBCB solid', borderBottom: "0px solid white", },
                    ":hover:after": { borderBottom: "0px solid green", },
                    ":hover:not(.Mui-disabled, .Mui-error):before": { borderBottom: "2px solid white", },
                    ":after": { borderBottom: "2px solid white", },
                    ":before": { borderBottom: "2px solid #A4A7B1", },
                  }} />                
              </Box>
            </Box>

            {/* 区切り線 */}
            <Box sx={{ height: "100%", width: "1%"}}>
              <Stack sx={{ height: "100%", width: "100%" }} alignItems={"center"} justifyContent={"center"}>
                <Box sx={{ height: "100px", width: "1px", background: "linear-gradient(to top, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0))" }}></Box>
              </Stack>
            </Box>

            {/* Displacement */}
            <Box sx={{ height: "100%", width: "12%", paddingLeft: "7px"}}>
              <Box sx={{ height: "40px" }}></Box>
              <Box sx={{ eight: "100%", width: "100%"}}>
                <Stack direction="row">
                  <Typography sx={{ marginTop: "27px", marginLeft: "30px",
                    fontFamily: "Roboto", fontSize: "14px", fontWeight: "700", color: "rgb(255, 255, 255, 0.5)"}}>
                    Displacement
                  </Typography>
                  <Typography sx={{marginTop: "22px", marginLeft: "1px", fontFamily: "Roboto", fontSize: "13px", color: "red"}}>
                    *
                  </Typography>
                </Stack>
                <Stack direction="row">
                  <Radio
                    sx={{ marginLeft: "20px",marginTop: "-2px", color: "rgba(255,255,255,0.5)", '&.Mui-checked': { color: "rgba(255,255,255,0.5)" }, }}
                    value="Ballast"
                    onChange={handleChangeDisplacementRadioButton}
                    checked={props.legInfo.displacement !== 'Laden' ? true : false}
                    />
                  <Typography sx={{
                      color: 'white',
                      fontSize: "14px",
                      fontFamily: "'Roboto'",                          
                      marginTop: "9px"
                    }}>
                    Ballast
                  </Typography>
                </Stack>
                <Stack direction="row" sx={{ marginTop: "-10px"}}>
                  <Radio
                    sx={{ marginLeft: "20px", marginTop: "-3px", color: "rgba(255,255,255,0.5)", '&.Mui-checked': { color: "rgba(255,255,255,0.5)" }, }}
                    value="Laden"
                    onChange={handleChangeDisplacementRadioButton}
                    checked={props.legInfo.displacement === 'Laden' ? true : false}
                    />
                  <Typography sx={{
                      color: 'white',
                      fontSize: "14px",
                      fontFamily: "'Roboto'",                          
                      marginTop: "9px"
                    }}>
                    Laden
                  </Typography>
                </Stack>
              </Box>
            </Box>

            {/* 区切り線 */}
            <Box sx={{ height: "100%", width: "1%"}}>
              <Stack sx={{ height: "100%", width: "100%" }} alignItems={"center"} justifyContent={"center"}>
                <Box sx={{ height: "100px", width: "1px", background: "linear-gradient(to top, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0))" }}></Box>
              </Stack>
            </Box>

            {/* Fuel、FuelRate */}
            <Box sx={{ height: "100%", width: "22%"}}>
              <Stack direction="row">
                <Box sx={{width: "205px"}}>
                  <Stack direction="row">
                    <Typography sx={{ marginLeft: "20px", marginTop: "20px", marginBottom: "7px",
                        fontFamily: "Roboto", fontSize: "14px", fontWeight: "700", color: "rgb(255, 255, 255, 0.5)"}}>
                      Fuel
                    </Typography>
                    <Typography sx={{marginTop: "17px", marginLeft: "1px", fontFamily: "Roboto", fontSize: "13px", color: "red"}}>
                      *
                    </Typography>
                  </Stack>
                </Box>
                <Typography sx={{ marginTop: "20px",
                    fontFamily: "Roboto", fontSize: "14px", fontWeight: "700", color: "rgb(255, 255, 255, 0.5)"}}>
                  Fuel Rate
                </Typography>
                <Typography sx={{marginTop: "17px", marginLeft: "1px", fontFamily: "Roboto", fontSize: "13px", color: "red"}}>
                  *
                </Typography>
              </Stack>

              {/* Fuelプルダウン1 */}
              <Stack direction="row" sx={{marginLeft: "20px" }}>
                <ThemeProvider theme={SelectTheme}>
                  <FormControl>
                    <Select
                      IconComponent={() =>
                        <div style={{ position: "absolute", top: "20px", left: "150px" }}>
                          <InputAdornment position="start" disablePointerEvents>
                            <Box sx={{ borderTop: "solid 2px rgb(255, 255, 255, 0.7)", borderRight: "solid 2px rgb(255, 255, 255, 0.7)", width: "8px", height: "8px", transform: "rotate(135deg)" }}></Box>
                          </InputAdornment>
                        </div>
                      }
                      MenuProps={{
                        MenuListProps: {
                          disablePadding: true,
                        },
                        // style: { zIndex: 100000, }
                      }}
                      sx={{
                        width: 174,
                        height: 40,
                        color: "white",
                        borderRadius: 30,
                        ":hover": { backgroundColor: "#7a86a0" }
                      }}
                      input={<BootstrapInput />}
                      onChange={handleChangeFuel1}
                      value={props.legInfo.fuel1}
                    >
                      <MenuItem key={"hyphen"} value={"-"}>{"-"}</MenuItem>
                      {props.fuelOileList.map((fuel) => {
                        return (
                          <MenuItem key={fuel} value={fuel}>{fuel}</MenuItem>
                        )
                      })}
                    </Select>
                  </FormControl>
                </ThemeProvider>
                <Box sx={{width: "12px"}}></Box>
                <Box>
                  <Input
                    value={props.legInfo.fuelRate1} onChange={(event: React.ChangeEvent<HTMLInputElement>) => { handleChangeFuelRate1(event.target.value) }}
                    type='tel'
                    sx={{
                      width: "60px", fontFamily: 'Roboto', fontWeight: '400', wordWrap: 'break-word', border: '0px grey solid', color: "white", fontSize: "13px",
                      "& .MuiInput-input": { height: "26px", borderRadius: '0px', border: '0px white solid', background: "rgba(255, 255, 255, 0.15)", textAlign: "center", },
                      ":hover": { backgroundColor: "#7a86a0", border: '0px #CBCBCB solid', borderBottom: "0px solid white", },
                      ":hover:after": { borderBottom: "0px solid green", },
                      ":hover:not(.Mui-disabled, .Mui-error):before": { borderBottom: "2px solid white", },
                      ":after": { borderBottom: "2px solid white", },
                      ":before": { borderBottom: "2px solid #A4A7B1", },
                    }} />
                </Box>
                <Typography sx={{ marginTop: "14px", marginLeft: "5px",
                   fontSize: "14px", color: "rgb(255, 255, 255, 0.5)", fontFamily: "Roboto Condensed"}}>
                  %
                </Typography> 
              </Stack>
              <Box sx={{height: "10px"}}></Box>
              {/* Fuelプルダウン2 */}
              <Stack direction="row" sx={{marginLeft: "20px" }}>
                <ThemeProvider theme={SelectTheme}>
                  <FormControl>
                    <Select
                      IconComponent={() =>
                        <div style={{ position: "absolute", top: "20px", left: "150px" }}>
                          <InputAdornment position="start" disablePointerEvents>
                            <Box sx={{ borderTop: "solid 2px rgb(255, 255, 255, 0.7)", borderRight: "solid 2px rgb(255, 255, 255, 0.7)", width: "8px", height: "8px", transform: "rotate(135deg)" }}></Box>
                          </InputAdornment>
                        </div>
                      }
                      MenuProps={{
                        MenuListProps: {
                          disablePadding: true,
                        },
                        // style: { zIndex: 100000, }
                      }}
                      sx={{
                        width: 174,
                        height: 40,
                        color: "white",
                        borderRadius: 30,
                        ":hover": { backgroundColor: "#7a86a0" }
                      }}
                      input={<BootstrapInput />}
                      onChange={handleChangeFuel2}
                      value={props.legInfo.fuel2}
                    >
                      <MenuItem key={"hyphen"} value={"-"}>{"-"}</MenuItem>
                      {props.fuelOileList.map((fuel) => {
                        return (
                          <MenuItem key={fuel} value={fuel}>{fuel}</MenuItem>
                        )
                      })}
                    </Select>
                  </FormControl>
                </ThemeProvider>
                <Box sx={{width: "12px"}}></Box>
                <Box>
                  <Input
                    value={props.legInfo.fuelRate2} onChange={(event: React.ChangeEvent<HTMLInputElement>) => { handleChangeFuelRate2(event.target.value) }}
                    type='tel'
                    sx={{
                      width: "60px", fontFamily: 'Roboto', fontWeight: '400', wordWrap: 'break-word', border: '0px grey solid', color: "white", fontSize: "13px",
                      "& .MuiInput-input": { height: "26px", borderRadius: '0px', border: '0px white solid', background: "rgba(255, 255, 255, 0.15)", textAlign: "center", },
                      ":hover": { backgroundColor: "#7a86a0", border: '0px #CBCBCB solid', borderBottom: "0px solid white", },
                      ":hover:after": { borderBottom: "0px solid green", },
                      ":hover:not(.Mui-disabled, .Mui-error):before": { borderBottom: "2px solid white", },
                      ":after": { borderBottom: "2px solid white", },
                      ":before": { borderBottom: "2px solid #A4A7B1", },
                    }} />
                </Box>
                <Typography sx={{ marginTop: "14px", marginLeft: "5px",
                   fontSize: "14px", color: "rgb(255, 255, 255, 0.5)", fontFamily: "Roboto Condensed"}}>
                  %
                </Typography> 
              </Stack>
              <Box sx={{height: "10px"}}></Box>

              {/* Fuelプルダウン3 */}
              <Stack direction="row" sx={{marginLeft: "20px" }}>
                <ThemeProvider theme={SelectTheme}>
                  <FormControl>
                    <Select
                      IconComponent={() =>
                        <div style={{ position: "absolute", top: "20px", left: "150px" }}>
                          <InputAdornment position="start" disablePointerEvents>
                            <Box sx={{ borderTop: "solid 2px rgb(255, 255, 255, 0.7)", borderRight: "solid 2px rgb(255, 255, 255, 0.7)", width: "8px", height: "8px", transform: "rotate(135deg)" }}></Box>
                          </InputAdornment>
                        </div>
                      }
                      MenuProps={{
                        MenuListProps: {
                          disablePadding: true,
                        },
                        // style: { zIndex: 100000, }
                      }}
                      sx={{
                        width: 174,
                        height: 40,
                        color: "white",
                        borderRadius: 30,
                        ":hover": { backgroundColor: "#7a86a0" }
                      }}
                      input={<BootstrapInput />}
                      onChange={handleChangeFuel3}
                      value={props.legInfo.fuel3}
                    >
                      <MenuItem key={"hyphen"} value={"-"}>{"-"}</MenuItem>
                      {props.fuelOileList.map((fuel) => {
                        return (
                          <MenuItem key={fuel} value={fuel}>{fuel}</MenuItem>
                        )
                      })}
                    </Select>
                  </FormControl>
                </ThemeProvider>
                <Box sx={{width: "12px"}}></Box>
                <Box>
                  <Input
                    value={props.legInfo.fuelRate3} onChange={(event: React.ChangeEvent<HTMLInputElement>) => { handleChangeFuelRate3(event.target.value) }}
                    type='tel'
                    sx={{
                      width: "60px", fontFamily: 'Roboto', fontWeight: '400', wordWrap: 'break-word', border: '0px grey solid', color: "white", fontSize: "13px",
                      "& .MuiInput-input": { height: "26px", borderRadius: '0px', border: '0px white solid', background: "rgba(255, 255, 255, 0.15)", textAlign: "center", },
                      ":hover": { backgroundColor: "#7a86a0", border: '0px #CBCBCB solid', borderBottom: "0px solid white", },
                      ":hover:after": { borderBottom: "0px solid green", },
                      ":hover:not(.Mui-disabled, .Mui-error):before": { borderBottom: "2px solid white", },
                      ":after": { borderBottom: "2px solid white", },
                      ":before": { borderBottom: "2px solid #A4A7B1", },
                    }} />
                </Box>
                <Typography sx={{ marginTop: "14px", marginLeft: "5px",
                   fontSize: "14px", color: "rgb(255, 255, 255, 0.5)", fontFamily: "Roboto Condensed"}}>
                  %
                </Typography> 
              </Stack>
            </Box>
            
            {/* 区切り線 */}
            <Box sx={{ height: "100%", width: "1%"}}>
              <Stack sx={{ height: "100%", width: "100%" }} alignItems={"center"} justifyContent={"center"}>
                <Box sx={{ height: "100px", width: "1px", background: "linear-gradient(to top, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0))" }}></Box>
              </Stack>
            </Box>

            {/* EU Rate */}
            <Box sx={{ height: "100%", width: "10%"}}>

              {/* Leg情報入力欄の×ボタン */}
              <Box sx={{ position: "relative", height: "40px" }}>
                {props.elementKey !== 0 ?
                  <IconButton
                    aria-label="close"
                    
                    onClick={handleClickDeleteLeg}
                    sx={{
                      position: "absolute",
                      top: "-5px",
                      right: "2px",
                      color: (theme) => theme.palette.grey[600],
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                  :
                  <></>
                }
              </Box>
              <Stack direction="row">
                <Box>
                  <Stack direction="row">
                    <Typography sx={{ marginLeft: "25px", marginTop: "5px", 
                        fontFamily: "Roboto", fontSize: "14px", fontWeight: "700", color: "rgb(255, 255, 255, 0.5)"}}>
                      EU Rate
                    </Typography>
                    <Typography sx={{marginTop: "3px", marginLeft: "1px", fontFamily: "Roboto", fontSize: "13px", color: "red"}}>
                      *
                    </Typography>
                  </Stack>
                </Box>
              </Stack>

              {/* Radio Button 0% */}
              <Stack direction="row">
                <Radio
                  sx={{ marginLeft: "15px", color: "rgba(255,255,255,0.5)", '&.Mui-checked': { color: "rgba(255,255,255,0.5)" }, }}
                  value="0"
                  onChange={handleChangeEuRateRadioButton}
                  checked={ props.legInfo.euRate === '0' ? true : false }
                  />
                <Typography sx={{
                    color: 'white',
                    fontSize: "14px",
                    fontFamily: "'Roboto'",                          
                    marginTop: "9px"
                  }}>
                  0%
                </Typography>
              </Stack>

              {/* Radio Button 50% */}
              <Stack direction="row" sx={{ marginTop: "-10px"}}>
                <Radio
                  sx={{ marginLeft: "15px", color: "rgba(255,255,255,0.5)", '&.Mui-checked': { color: "rgba(255,255,255,0.5)" }, }}
                  value="50"
                  onChange={handleChangeEuRateRadioButton}
                  checked={ props.legInfo.euRate === '50' ? true : false }
                  />
                <Typography sx={{
                    color: 'white',
                    fontSize: "14px",
                    fontFamily: "'Roboto'",                          
                    marginTop: "9px"
                  }}>
                  50%
                </Typography>
              </Stack>

              {/* Radio Button 100% */}
              <Stack direction="row" sx={{ marginTop: "-10px"}}>
                <Radio
                  sx={{ marginLeft: "15px", color: "rgba(255,255,255,0.5)", '&.Mui-checked': { color: "rgba(255,255,255,0.5)" }, }}
                  value="100"
                  onChange={handleChangeEuRateRadioButton}
                  checked={ props.legInfo.euRate === '100' ? true : false  }
                  />
                <Typography sx={{
                    color: 'white',
                    fontSize: "14px",
                    fontFamily: "'Roboto'",                          
                    marginTop: "9px"
                  }}>
                  100%
                </Typography>
              </Stack>
            </Box>

          </Stack>

        </Box>
      </Box>

      {/* Depauture用カレンダーダイアログ */}
      <Modal isOpen={openCalenderDialogDeparture} className="com01-calendar-modal" overlayClassName="com01-calendar-overlay">
        <CommonCalendar
          selectDate={props.legInfo.departureTime_yyyymmdd}
          closeCalendar={closeDepartureDateCalendar}
          setDate={(updateValue: any) => handleChangeDepartureTime_yyyymmdd(updateValue)}
          isMentenanceReport={false}
        />
      </Modal>

      {/* Arrival用カレンダーダイアログ */}
      <Modal isOpen={openCalenderDialogArrival} className="com01-calendar-modal" overlayClassName="com01-calendar-overlay">
        <CommonCalendar
          selectDate={props.legInfo.arrivalTime_yyyymmdd}
          closeCalendar={closeArrivalDateCalendar}
          setDate={(updateValue: any) => handleChangeArrivalTime_yyyymmdd(updateValue)}
          isMentenanceReport={false}
        />
      </Modal>              

    </>
  );
} 

export default EuEditLeg;